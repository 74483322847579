import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { ProjectMetadata } from 'openapi/models/ProjectMetadata'

import { SafeRecord } from 'utils/safe-types'

import {
  FetchVaultProjectMetadata,
  FetchVaultProjectsMetadata,
} from './vault-fetcher'

export const useVaultProjectMetadata = (projectId: string) => {
  const { data, isFetching } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.VaultProjectMetadata, projectId],
    queryFn: () => FetchVaultProjectMetadata(projectId),
    enabled: !!projectId,
    // Disable refetching on window focus to avoid unnecessary network requests
    refetchOnWindowFocus: false,
  })
  return { projectMetadata: data, isLoadingMetadata: isFetching && !data }
}

export const useVaultProjectsMetadata = (projectIds: string[]) => {
  const { data, isFetching } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.VaultProjectsMetadata, projectIds],
    queryFn: () => FetchVaultProjectsMetadata(projectIds),
    enabled: projectIds.length > 0,
    // Disable refetching on window focus to avoid unnecessary network requests
    refetchOnWindowFocus: false,
    select(data) {
      return data.reduce(
        (acc, curr) => {
          acc[curr.projectId] = curr
          return acc
        },
        {} as SafeRecord<string, ProjectMetadata>
      )
    },
  })
  return {
    projectsMetadata: data,
    isLoadingProjectsMetadata: isFetching && !data,
  }
}
