/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkflowBlockTypes = {
    AND: 'And',
    ANSWER: 'Answer',
    ANTITRUST_FILINGS_PARSE: 'AntitrustFilingsParse',
    ANTITRUST_FILINGS_ANALYSIS: 'AntitrustFilingsAnalysis',
    ANTITRUST_FILINGS_RENDER: 'AntitrustFilingsRender',
    AUDIO_TRANSCRIPT_FILE_EXTRACTION: 'AudioTranscriptFileExtraction',
    AUDIO_TRANSCRIPTION: 'AudioTranscription',
    AUDIO_TRANSCRIPTION_FILE_TYPE_BRANCH: 'AudioTranscriptionFileTypeBranch',
    BOOLEAN: 'Boolean',
    CHRONOLOGY: 'Chronology',
    DILIGENCE_REQUEST_LIST: 'DiligenceRequestList',
    CHAT: 'Chat',
    CHAT_THINKING_STATES: 'ChatThinkingStates',
    DISCOVERY: 'Discovery',
    DRAFT: 'Draft',
    EQUALS: 'Equals',
    NUDGE_IMPROVED_QUESTIONS: 'NudgeImprovedQuestions',
    EDGAR_PLAN: 'EdgarPlan',
    EDGAR_RESEARCH: 'EdgarResearch',
    FILE_UPLOAD: 'FileUpload',
    FILE_METADATA: 'FileMetadata',
    FILES: 'Files',
    LANCE_UPLOAD: 'LanceUpload',
    FINAL_REPORT_GENERATOR: 'FinalReportGenerator',
    FOLLOW_UPS: 'FollowUps',
    INCLUDES: 'Includes',
    LOADING_STATE: 'LoadingState',
    OR: 'Or',
    POST_CLOSING_TIMELINE: 'PostClosingTimeline',
    PROOFREAD: 'Proofread',
    REDLINES_DOC_TITLE: 'RedlinesDocTitle',
    REDLINES_EDIT_EXTRACTION: 'RedlinesEditExtraction',
    REDLINES_SUMMARY: 'RedlinesSummary',
    RELATED_QUESTIONS: 'RelatedQuestions',
    REPORT_GEN_SECTION_SUGGESTIONS: 'ReportGenSectionSuggestions',
    RICH_PROMPT: 'RichPrompt',
    RICH_PROMPT_CHAT: 'RichPromptChat',
    SELECT: 'Select',
    ANTITRUST_SIMPLE_FILE_NAMES: 'AntitrustSimpleFileNames',
    TABLE: 'Table',
    TABLE_SELECT: 'TableSelect',
    TEXT: 'Text',
    TRANSCRIPT_LITIGATION_EXTRACTION: 'TranscriptLitigationExtraction',
    TRANSCRIPT_LITIGATION_THEMES: 'TranscriptLitigationThemes',
    TRANSCRIPT_LITIGATION_SUMMARIES: 'TranscriptLitigationSummaries',
    TRANSCRIPT_SUMMARIZATION: 'TranscriptSummarization',
    TRANSLATE: 'Translate',
    VALUE: 'Value'
} as const;
export type WorkflowBlockTypes = typeof WorkflowBlockTypes[keyof typeof WorkflowBlockTypes];


export function instanceOfWorkflowBlockTypes(value: any): boolean {
    for (const key in WorkflowBlockTypes) {
        if (Object.prototype.hasOwnProperty.call(WorkflowBlockTypes, key)) {
            if (WorkflowBlockTypes[key as keyof typeof WorkflowBlockTypes] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WorkflowBlockTypesFromJSON(json: any): WorkflowBlockTypes {
    return WorkflowBlockTypesFromJSONTyped(json, false);
}

export function WorkflowBlockTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowBlockTypes {
    return json as WorkflowBlockTypes;
}

export function WorkflowBlockTypesToJSON(value?: WorkflowBlockTypes | null): any {
    return value as any;
}

export function WorkflowBlockTypesToJSONTyped(value: any, ignoreDiscriminator: boolean): WorkflowBlockTypes {
    return value as WorkflowBlockTypes;
}

