import { EventStatus } from 'openapi/models/EventStatus'
import { LoadingState } from 'openapi/models/LoadingState'
import {
  WorkflowAnswerRenderBlockBlockParams,
  instanceOfWorkflowAnswerRenderBlockBlockParams,
} from 'openapi/models/WorkflowAnswerRenderBlockBlockParams'
import {
  WorkflowAntitrustFilingsRendererBlockParams,
  instanceOfWorkflowAntitrustFilingsRendererBlockParams,
} from 'openapi/models/WorkflowAntitrustFilingsRendererBlockParams'
import {
  WorkflowDraftRenderBlockBlockParams,
  instanceOfWorkflowDraftRenderBlockBlockParams,
} from 'openapi/models/WorkflowDraftRenderBlockBlockParams'
import { WorkflowEventStatusFeedback } from 'openapi/models/WorkflowEventStatusFeedback'
import { WorkflowEventStatusStepsInner } from 'openapi/models/WorkflowEventStatusStepsInner'
import {
  WorkflowFileUploadInputBlockBlockParams,
  instanceOfWorkflowFileUploadInputBlockBlockParams,
} from 'openapi/models/WorkflowFileUploadInputBlockBlockParams'
import {
  WorkflowFileUploadInputBlockOutput,
  instanceOfWorkflowFileUploadInputBlockOutput,
} from 'openapi/models/WorkflowFileUploadInputBlockOutput'
import {
  instanceOfWorkflowFilesRenderBlockBlockParams,
  WorkflowFilesRenderBlockBlockParams,
} from 'openapi/models/WorkflowFilesRenderBlockBlockParams'
import {
  WorkflowFollowUpsInputBlockBlockParams,
  instanceOfWorkflowFollowUpsInputBlockBlockParams,
} from 'openapi/models/WorkflowFollowUpsInputBlockBlockParams'
import { WorkflowFollowUpsInputBlockOutput } from 'openapi/models/WorkflowFollowUpsInputBlockOutput'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import {
  WorkflowSelectInputBlockBlockParams,
  instanceOfWorkflowSelectInputBlockBlockParams,
} from 'openapi/models/WorkflowSelectInputBlockBlockParams'
import {
  WorkflowSelectInputBlockOutput,
  instanceOfWorkflowSelectInputBlockOutput,
} from 'openapi/models/WorkflowSelectInputBlockOutput'
import {
  WorkflowTableSelectInputBlockBlockParams,
  instanceOfWorkflowTableSelectInputBlockBlockParams,
} from 'openapi/models/WorkflowTableSelectInputBlockBlockParams'
import {
  WorkflowTableSelectInputBlockOutput,
  instanceOfWorkflowTableSelectInputBlockOutput,
} from 'openapi/models/WorkflowTableSelectInputBlockOutput'
import {
  instanceOfWorkflowTextInputBlockBlockParams,
  WorkflowTextInputBlockBlockParams,
} from 'openapi/models/WorkflowTextInputBlockBlockParams'
import {
  instanceOfWorkflowTextInputBlockOutput,
  WorkflowTextInputBlockOutput,
} from 'openapi/models/WorkflowTextInputBlockOutput'

import { Source } from 'utils/task'

import {
  AssistantWorkflowTableRendererInput,
  AssistantWorkflowTableRendererThread,
  AssistantWorkflowTableRendererExportComponent,
  tableSourceExtractor,
} from 'components/assistant/workflows/blocks/assistant-workflow-table-renderer'

import {
  AssistantWorkflowAnswerRenderer,
  AssistantWorkflowAnswerRendererInputBox,
  AssistantWorkflowAnswerRendererExportComponent,
  answerSourceExtractor,
} from './blocks/assistant-workflow-answer-renderer'
import {
  AssistantWorkflowAntitrustFilingsAnswerRenderer,
  AssistantWorkflowAntitrustFilingsAnswerRendererExportComponent,
  AssistantWorkflowAntitrustFilingsAnswerRendererInputBox,
} from './blocks/assistant-workflow-antitrust-filings-answer-renderer'
import {
  AssistantWorkflowDraftRenderer,
  AssistantWorkflowDraftRendererExportComponent,
  AssistantWorkflowDraftRendererInputBox,
  draftSourceExtractor,
} from './blocks/assistant-workflow-draft-renderer'
import {
  AssistantWorkflowFilesRendererExportComponent,
  AssistantWorkflowFilesThread,
} from './blocks/assistant-workflow-file-renderer'
import { AssistantWorkflowFilesRendererInputBox } from './blocks/assistant-workflow-file-renderer'
import {
  AssistantWorkflowFileUploadExportComponent,
  AssistantWorkflowFileUploadInput,
  AssistantWorkflowFileUploadThread,
} from './blocks/assistant-workflow-file-upload-input'
import {
  AssistantWorkflowFollowUpsInput,
  AssistantWorkflowFollowUpsThread,
  AssistantWorkflowFollowUpsExportComponent,
} from './blocks/assistant-workflow-follow-ups-input'
import {
  AssistantWorkflowLoadingStateRenderer,
  AssistantWorkflowLoadingStateInputBox,
} from './blocks/assistant-workflow-loading-state-renderer'
import {
  AssistantWorkflowSelectExportComponent,
  AssistantWorkflowSelectInput,
  AssistantWorkflowSelectThread,
} from './blocks/assistant-workflow-select-input'
import {
  AssistantWorkflowTableSelectThread,
  AssistantWorkflowTableSelectInput,
  AssistantWorkflowTableSelectExportComponent,
} from './blocks/assistant-workflow-select-table-input'
import {
  AssistantWorkflowTextInput,
  AssistantWorkflowTextInputExportComponent,
  AssistantWorkflowTextThread,
} from './blocks/assistant-workflow-text-input'

export type AssistantWorkflowComponent<K extends keyof BlockTypeMap> = React.FC<
  AssistantWorkflowComponentParams<
    BlockTypeMap[K]['blockParams'],
    BlockTypeMap[K]['outputData']
  >
>

export type AssistantWorkflowExportComponent<K extends keyof BlockTypeMap> =
  React.FC<ExportComponentProps<K>>

export interface ExportComponentProps<K extends keyof BlockTypeMap> {
  stepIdx: number
  blockParams: BlockTypeMap[K]['blockParams']
  outputData: BlockTypeMap[K]['outputData']
}

export interface BlockValidators {
  blockParams: (params: any) => boolean
  outputData?: (data: any) => boolean
}

export interface AssistantWorkflowComponentParams<TBlockParams, TOutputData> {
  workflowName: string
  stepId: string | null
  stepIdx: number
  previousStep?: WorkflowEventStatusStepsInner
  onUpdated: (result: TOutputData) => void
  onCompleted: (result: TOutputData) => void
  onDeleted: () => void
  onRegenerate: () => void
  onCancel?: () => void
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  blockParams: TBlockParams
  outputData: TOutputData | null
  paramStatus: EventStatus
  completionStatus: EventStatus
  loadingStates: LoadingState[]
  onDownload?: () => void
  feedback: WorkflowEventStatusFeedback | undefined
  exportComponents: React.MutableRefObject<HTMLDivElement[]>
}

export interface BlockTypeMap {
  [WorkflowInputComponentBlocks.FILE_UPLOAD]: {
    blockParams: WorkflowFileUploadInputBlockBlockParams
    outputData: WorkflowFileUploadInputBlockOutput | null
  }
  [WorkflowInputComponentBlocks.SELECT]: {
    blockParams: WorkflowSelectInputBlockBlockParams
    outputData: WorkflowSelectInputBlockOutput | null
  }
  [WorkflowRenderComponentBlocks.ANSWER]: {
    blockParams: WorkflowAnswerRenderBlockBlockParams
    outputData: null
  }
  [WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER]: {
    blockParams: WorkflowAntitrustFilingsRendererBlockParams
    outputData: null
  }
  [WorkflowInputComponentBlocks.TABLE_SELECT]: {
    blockParams: WorkflowTableSelectInputBlockBlockParams
    outputData: WorkflowTableSelectInputBlockOutput | null
  }
  [WorkflowRenderComponentBlocks.TABLE]: {
    blockParams: WorkflowTableSelectInputBlockBlockParams
    outputData: null
  }
  [WorkflowInputComponentBlocks.TEXT]: {
    blockParams: WorkflowTextInputBlockBlockParams
    outputData: WorkflowTextInputBlockOutput | null
  }
  [WorkflowInputComponentBlocks.FOLLOW_UPS]: {
    blockParams: WorkflowFollowUpsInputBlockBlockParams
    outputData: WorkflowFollowUpsInputBlockOutput | null
  }
  [WorkflowRenderComponentBlocks.FILES]: {
    blockParams: WorkflowFilesRenderBlockBlockParams
    outputData: null
  }
  [WorkflowRenderComponentBlocks.DRAFT]: {
    blockParams: WorkflowDraftRenderBlockBlockParams
    outputData: null
  }
  [WorkflowRenderComponentBlocks.LOADING_STATE]: {
    blockParams: null
    outputData: null
  }
}

export const blockValidators: {
  [K in keyof BlockTypeMap]: BlockValidators
} = {
  [WorkflowInputComponentBlocks.FILE_UPLOAD]: {
    blockParams: instanceOfWorkflowFileUploadInputBlockBlockParams,
    outputData: instanceOfWorkflowFileUploadInputBlockOutput,
  },
  [WorkflowInputComponentBlocks.SELECT]: {
    blockParams: instanceOfWorkflowSelectInputBlockBlockParams,
    outputData: instanceOfWorkflowSelectInputBlockOutput,
  },
  [WorkflowRenderComponentBlocks.ANSWER]: {
    blockParams: instanceOfWorkflowAnswerRenderBlockBlockParams,
  },
  [WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER]: {
    blockParams: instanceOfWorkflowAntitrustFilingsRendererBlockParams,
  },
  [WorkflowInputComponentBlocks.TABLE_SELECT]: {
    blockParams: instanceOfWorkflowTableSelectInputBlockBlockParams,
    outputData: instanceOfWorkflowTableSelectInputBlockOutput,
  },
  [WorkflowRenderComponentBlocks.TABLE]: {
    blockParams: instanceOfWorkflowTableSelectInputBlockBlockParams,
  },
  [WorkflowInputComponentBlocks.TEXT]: {
    blockParams: instanceOfWorkflowTextInputBlockBlockParams,
    outputData: instanceOfWorkflowTextInputBlockOutput,
  },
  [WorkflowInputComponentBlocks.FOLLOW_UPS]: {
    blockParams: instanceOfWorkflowFollowUpsInputBlockBlockParams,
    outputData: instanceOfWorkflowTextInputBlockOutput,
  },
  [WorkflowRenderComponentBlocks.FILES]: {
    blockParams: instanceOfWorkflowFilesRenderBlockBlockParams,
  },
  [WorkflowRenderComponentBlocks.DRAFT]: {
    blockParams: instanceOfWorkflowDraftRenderBlockBlockParams,
  },
  [WorkflowRenderComponentBlocks.LOADING_STATE]: {
    blockParams: () => true,
  },
}

export type AssistantWorkflowSourceExtractor<K extends keyof BlockTypeMap> = (
  blockParams: BlockTypeMap[K]['blockParams']
) => Source[]

export interface WorkflowComponentEntry<K extends keyof BlockTypeMap> {
  InputComponent: AssistantWorkflowComponent<K>
  ThreadComponent: AssistantWorkflowComponent<K>
  validators: BlockValidators
  ExportComponent?: AssistantWorkflowExportComponent<K>
  sourceExtractor?: AssistantWorkflowSourceExtractor<K>
}

export const AssistantWorkflowStepNameToDefinition: {
  [K in keyof BlockTypeMap]: () => WorkflowComponentEntry<K>
} = {
  [WorkflowInputComponentBlocks.FILE_UPLOAD]: () => ({
    InputComponent: AssistantWorkflowFileUploadInput,
    ThreadComponent: AssistantWorkflowFileUploadThread,
    validators: blockValidators[WorkflowInputComponentBlocks.FILE_UPLOAD],
    ExportComponent: AssistantWorkflowFileUploadExportComponent,
  }),
  [WorkflowInputComponentBlocks.SELECT]: () => ({
    InputComponent: AssistantWorkflowSelectInput,
    ThreadComponent: AssistantWorkflowSelectThread,
    validators: blockValidators[WorkflowInputComponentBlocks.SELECT],
    ExportComponent: AssistantWorkflowSelectExportComponent,
  }),
  [WorkflowRenderComponentBlocks.ANSWER]: () => ({
    InputComponent: AssistantWorkflowAnswerRendererInputBox,
    ThreadComponent: AssistantWorkflowAnswerRenderer,
    validators: blockValidators[WorkflowRenderComponentBlocks.ANSWER],
    ExportComponent: AssistantWorkflowAnswerRendererExportComponent,
    sourceExtractor: answerSourceExtractor,
  }),
  [WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER]: () => ({
    InputComponent: AssistantWorkflowAntitrustFilingsAnswerRendererInputBox,
    ThreadComponent: AssistantWorkflowAntitrustFilingsAnswerRenderer,
    validators:
      blockValidators[WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER],
    ExportComponent:
      AssistantWorkflowAntitrustFilingsAnswerRendererExportComponent,
  }),
  [WorkflowInputComponentBlocks.TABLE_SELECT]: () => ({
    InputComponent: AssistantWorkflowTableSelectInput,
    ThreadComponent: AssistantWorkflowTableSelectThread,
    validators: blockValidators[WorkflowInputComponentBlocks.TABLE_SELECT],
    ExportComponent: AssistantWorkflowTableSelectExportComponent,
  }),
  [WorkflowRenderComponentBlocks.TABLE]: () => ({
    InputComponent: AssistantWorkflowTableRendererInput,
    ThreadComponent: AssistantWorkflowTableRendererThread,
    validators: blockValidators[WorkflowRenderComponentBlocks.TABLE],
    ExportComponent: AssistantWorkflowTableRendererExportComponent,
    sourceExtractor: tableSourceExtractor,
  }),
  [WorkflowInputComponentBlocks.TEXT]: () => ({
    InputComponent: AssistantWorkflowTextInput,
    ThreadComponent: AssistantWorkflowTextThread,
    validators: blockValidators[WorkflowInputComponentBlocks.TEXT],
    ExportComponent: AssistantWorkflowTextInputExportComponent,
  }),
  [WorkflowInputComponentBlocks.FOLLOW_UPS]: () => ({
    InputComponent: AssistantWorkflowFollowUpsInput,
    ThreadComponent: AssistantWorkflowFollowUpsThread,
    validators: blockValidators[WorkflowInputComponentBlocks.FOLLOW_UPS],
    ExportComponent: AssistantWorkflowFollowUpsExportComponent,
  }),
  [WorkflowRenderComponentBlocks.FILES]: () => ({
    InputComponent: AssistantWorkflowFilesRendererInputBox,
    ThreadComponent: AssistantWorkflowFilesThread,
    validators: blockValidators[WorkflowRenderComponentBlocks.FILES],
    ExportComponent: AssistantWorkflowFilesRendererExportComponent,
  }),
  [WorkflowRenderComponentBlocks.DRAFT]: () => ({
    InputComponent: AssistantWorkflowDraftRendererInputBox,
    ThreadComponent: AssistantWorkflowDraftRenderer,
    validators: blockValidators[WorkflowRenderComponentBlocks.DRAFT],
    ExportComponent: AssistantWorkflowDraftRendererExportComponent,
    sourceExtractor: draftSourceExtractor,
  }),
  [WorkflowRenderComponentBlocks.LOADING_STATE]: () => ({
    InputComponent: AssistantWorkflowLoadingStateInputBox,
    ThreadComponent: AssistantWorkflowLoadingStateRenderer,
    validators: blockValidators[WorkflowRenderComponentBlocks.LOADING_STATE],
  }),
}
