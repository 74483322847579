import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Maybe } from 'types'

import { BaseAppPath, SettingsPath } from 'components/base-app-path'

type RecentPage = {
  path: string
  label: string
  details: string
  timestamp: number
}

type RecentPagesContextType = {
  recentPages: RecentPage[]
  clearRecentPages: () => void
}

const MAX_RECENT_PAGES = 5
const STORAGE_KEY = 'harvey-recent-pages'

// Main path to label mapping
const pathToLabelMap: Record<string, string> = {
  [BaseAppPath.Assistant]: 'Assistant',
  [BaseAppPath.Vault]: 'Vault',
  [BaseAppPath.History]: 'History',
  [BaseAppPath.Library]: 'Library',
  [BaseAppPath.Settings]: 'Settings',
  [BaseAppPath.Workflows]: 'Workflows',
}

// Settings path to label mapping
const settingsPathToLabelMap: Record<string, string> = {
  [SettingsPath.Workspace]: 'Workspace Info',
  [SettingsPath.ClientMatters]: 'Client Matters',
  [SettingsPath.Sharing]: 'Sharing',
  [SettingsPath.Profile]: 'Profile',
  [SettingsPath.Users]: 'Users',
  [SettingsPath.Roles]: 'Roles',
  [SettingsPath.Usage]: 'Usage',
  [SettingsPath.WorkspaceHistory]: 'Workspace History',
  [SettingsPath.WorkspaceProjects]: 'Workspace Projects',
  [SettingsPath.Integrations]: 'Integrations',
}

// Helper functions for specific route types
const handleAssistantRoutes = (
  segments: string[],
  title: Maybe<string>
): { label: string; details: string } | null => {
  const mainLabel = pathToLabelMap[BaseAppPath.Assistant] || segments[0]

  if (segments[1] === 'workflows') {
    if (segments.length === 2) {
      return null // Skip top-level workflows page
    }

    if (segments.length === 3) {
      // Only include workflow template pages: /assistant/workflows/[workflow-id]
      const workflowId = segments[2]

      // Format workflow ID to be more readable
      const formattedWorkflowId = workflowId
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

      return {
        label: mainLabel,
        details: `Run Workflow: ${formattedWorkflowId}`,
      }
    }

    // Skip pages with event IDs: /assistant/workflows/[workflow-id]/[event-id]
    if (segments.length > 3) {
      return null
    }
  } else if (segments[1] === 'artifact' && segments.length >= 3) {
    return {
      label: mainLabel,
      details: `Artifact: ${title}`,
    }
  } else if (segments.length === 2) {
    // Include top-level mode pages like /assistant/chat
    const mode = segments[1]
    return {
      label: mainLabel,
      details: mode.charAt(0).toUpperCase() + mode.slice(1),
    }
  } else if (segments.length > 2) {
    // Skip pages with event IDs: /assistant/[mode]/[event-id]
    return null
  }

  return null
}

const handleVaultRoutes = (
  segments: string[],
  title: Maybe<string>
): { label: string; details: string } | null => {
  const mainLabel = pathToLabelMap[BaseAppPath.Vault] || segments[0]

  if (segments.length === 3) {
    // Include project pages: /vault/projects/[project-id]
    return {
      label: mainLabel,
      details: `Open project: ${title}`,
    }
  }

  return null
}

const handleSettingsRoutes = (
  segments: string[]
): { label: string; details: string } | null => {
  const mainLabel = pathToLabelMap[BaseAppPath.Settings] || segments[0]

  if (segments.length >= 2) {
    const settingsPath = `/${segments[1]}`
    const settingsLabel = settingsPathToLabelMap[settingsPath] || segments[1]

    if (segments.length === 2) {
      return {
        label: mainLabel,
        details: settingsLabel,
      }
    }

    // Handle deeper settings paths
    return {
      label: mainLabel,
      details: `${settingsLabel}: ${segments.slice(2).join(' > ')}`,
    }
  }

  return null
}

const handleWorkflowsRoutes = (
  segments: string[]
): { label: string; details: string } | null => {
  const mainLabel = pathToLabelMap[BaseAppPath.Workflows] || segments[0]

  if (segments.length === 2) {
    // Include workflow template pages: /workflows/[workflow-id]
    const workflowId = segments[1]
    return {
      label: mainLabel,
      details: workflowId,
    }
  }

  // Skip pages with IDs: /workflows/[workflow-id]/[id]
  if (segments.length > 2) {
    return null
  }

  return null
}

// Helper function to extract meaningful information from paths
const getPathDetails = (
  path: string,
  title: Maybe<string>
): { label: string; details: string } | null => {
  const segments = path.split('/').filter(Boolean)

  if (segments.length < 2) return null

  if (segments.length === 1) {
    const mainPath = `/${segments[0]}`
    return {
      label: pathToLabelMap[mainPath] || segments[0],
      details: '',
    }
  }

  const mainPath = `/${segments[0]}`
  const mainLabel = pathToLabelMap[mainPath] || segments[0]
  switch (segments[0]) {
    case 'assistant':
      return handleAssistantRoutes(segments, title)
    case 'vault':
      return handleVaultRoutes(segments, title)
    case 'settings':
      return handleSettingsRoutes(segments)
    case 'workflows':
      return handleWorkflowsRoutes(segments)
    default:
      return {
        label: mainLabel,
        details: segments.slice(1).join(' > '),
      }
  }
}

const RecentPagesContext = createContext<RecentPagesContextType | undefined>(
  undefined
)

export const RecentPagesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [recentPages, setRecentPages] = useState<RecentPage[]>([])
  const location = useLocation()

  // Load recent pages from localStorage on initial render
  useEffect(() => {
    const storedPages = localStorage.getItem(STORAGE_KEY)
    if (storedPages) {
      try {
        setRecentPages(JSON.parse(storedPages))
      } catch (error) {
        localStorage.removeItem(STORAGE_KEY)
      }
    }
  }, [])

  // Save to localStorage whenever recentPages changes
  useEffect(() => {
    if (recentPages.length > 0) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(recentPages))
    }
  }, [recentPages])

  // Track page visits when location.pathname changes
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      const pathName = location.pathname
      const vaultTitle = document.getElementById('vault-project-title')
        ?.textContent
      const assistantTitle = document.getElementById('assistant-header-title')
        ?.textContent

      const pathDetails = getPathDetails(pathName, vaultTitle || assistantTitle)

      // Skip tracking if getPathDetails returns null
      if (!pathDetails) {
        return
      }

      setRecentPages((prev) => {
        const exists = prev.some((p) => p.path === pathName)

        if (exists) {
          return prev
            .map((p) =>
              p.path === pathName ? { ...p, timestamp: Date.now() } : p
            )
            .sort((a, b) => b.timestamp - a.timestamp)
        }

        const newPages = [
          {
            path: pathName,
            label: pathDetails.label,
            details: pathDetails.details,
            timestamp: Date.now(),
          },
          ...prev.filter((p) => p.path !== pathName),
        ].slice(0, MAX_RECENT_PAGES)

        return newPages
      })
    })()
  }, [location.pathname])

  const clearRecentPages = () => {
    setRecentPages([])
    localStorage.removeItem(STORAGE_KEY)
  }

  return (
    <RecentPagesContext.Provider value={{ recentPages, clearRecentPages }}>
      {children}
    </RecentPagesContext.Provider>
  )
}

export const useRecentPages = () => {
  const context = useContext(RecentPagesContext)
  if (!context) {
    throw new Error('useRecentPages must be used within a RecentPagesProvider')
  }
  return context
}
