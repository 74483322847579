import React from 'react'

import { AntitrustFilingsAnalysisResult } from 'openapi/models/AntitrustFilingsAnalysisResult'

import { Source } from 'utils/task'

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Badge } from 'components/ui/badge'

import ExceptionComponent from './exception'
import RequirementComponent from './requirement'

const ThresholdComponent: React.FC<{
  thresholdName: string
  idx: number
  analysis: AntitrustFilingsAnalysisResult
  handleSourceClick: (source: Source) => void
}> = ({ thresholdName, idx, analysis, handleSourceClick }) => {
  if (!analysis.thresholds[idx]) return null

  const thresholdData = analysis.thresholds[idx]
  const formattedConclusion = thresholdData.overallConclusion
    .toLowerCase()
    .replace('likely to be ', '')
  const titledConclusion =
    formattedConclusion.charAt(0).toUpperCase() + formattedConclusion.slice(1)

  return (
    <AccordionItem value={thresholdName}>
      <AccordionTrigger className="group transition hover:no-underline">
        <div className="flex items-center space-x-2">
          <p className="text-left text-sm font-medium transition group-hover:underline">
            {thresholdName.replace('_', ' ').charAt(0).toUpperCase() +
              thresholdName.replace('_', ' ').slice(1)}
          </p>

          <Badge variant="secondary" className="font-normal">
            {titledConclusion}
          </Badge>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div className="space-y-4">
          {thresholdData.requirements.map((requirement, idx) => (
            <RequirementComponent
              key={idx}
              requirement={requirement}
              idx={idx}
              handleSourceClick={handleSourceClick}
            />
          ))}
          {thresholdData.exceptions.map((exception, idx) => (
            <ExceptionComponent
              key={idx}
              exception={exception}
              idx={idx}
              handleSourceClick={handleSourceClick}
            />
          ))}
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}

export default ThresholdComponent
