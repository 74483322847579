/* tslint:disable */
/* eslint-disable */
/**
 * Vault API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReviewEventRunType = {
    NEW: 'NEW',
    EXTRA_FILES: 'EXTRA_FILES',
    EXTRA_COLUMNS: 'EXTRA_COLUMNS',
    RETRY: 'RETRY',
    EXTRA_FILES_AND_COLUMNS: 'EXTRA_FILES_AND_COLUMNS',
    RETRY_EMPTY: 'RETRY_EMPTY',
    RETRY_ERROR: 'RETRY_ERROR',
    USER_INPUT: 'USER_INPUT'
} as const;
export type ReviewEventRunType = typeof ReviewEventRunType[keyof typeof ReviewEventRunType];


export function instanceOfReviewEventRunType(value: any): boolean {
    for (const key in ReviewEventRunType) {
        if (Object.prototype.hasOwnProperty.call(ReviewEventRunType, key)) {
            if (ReviewEventRunType[key as keyof typeof ReviewEventRunType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ReviewEventRunTypeFromJSON(json: any): ReviewEventRunType {
    return ReviewEventRunTypeFromJSONTyped(json, false);
}

export function ReviewEventRunTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewEventRunType {
    return json as ReviewEventRunType;
}

export function ReviewEventRunTypeToJSON(value?: ReviewEventRunType | null): any {
    return value as any;
}

export function ReviewEventRunTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): ReviewEventRunType {
    return value as ReviewEventRunType;
}

