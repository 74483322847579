import { useCallback } from 'react'

import * as Sentry from '@sentry/browser'
import { clsx, type ClassValue } from 'clsx'
import { format, formatDuration, intervalToDuration, parseISO } from 'date-fns'
import _ from 'lodash'
import { twMerge } from 'tailwind-merge'

import Services from 'services'

import { readableFormat } from './date-utils'

export const REFRESH_TIMEOUT = 60 * 60 * 1000
export const EM_DASH = '—'
export const HARVEY_START_DATE = new Date('2022-07-15') // rough estimate

const OFFSET_OR_Z_REGEX = /[+-]\d{2}:\d{2}|Z$/

export const SECONDS_TO_READABLE: { [key: number]: string } = {
  0: '0 days',
  604_800: '1 week',
  1_209_600: '2 weeks',
  2_592_000: '1 month',
  15_768_000: '6 months',
}

// Mask content with * except for alphanumeric characters
// This function might be expensive to run, should only use for error logging.
export function maskContent(text: string, maskChar: string = '*'): string {
  return text
    .split('')
    .map((c) => (/\p{L}|\p{N}/u.test(c) ? maskChar : c)) // \p{L} matches any kind of letter, \p{N} matches any kind of numeric character
    .join('')
}

export const ERROR_DATE = new Date(0)

/* NOTE: output should be in local timezone
 *  Additional info: can test locally by changing the default language within browser (and opening brand new tab)
 *  or browser's timezone & locale using dev tools.
 *  Changing the language will affect navigator.language and how we display readableFormat
 *  Changing the locale (e.g. en-US) will affect .toLocaleString() and how it displays time, military vs AM/PM
 *  Only the changing browser timezone will affect what timezone the date is converted to
 */
export function parseIsoString(stringDate: string): Date {
  if (!stringDate) {
    console.error('parseIsoString: input is empty or null')
    return ERROR_DATE // Returns January 1, 1970, 00:00:00 UTC
  }

  try {
    // XXX: Somehow backend might send lowercase date string or without timezone information for UTC,
    // e.g. 2009-05-06t00:00:00, we want to make it standardize to uppercase, e.g. 2009-05-06T00:00:00Z
    const stringDateUpperCase = stringDate.toUpperCase()
    const standardizedDate = OFFSET_OR_Z_REGEX.test(stringDateUpperCase)
      ? stringDateUpperCase
      : stringDateUpperCase + 'Z' // + 'Z' is to infer string as UTC

    const parsedDate = parseISO(standardizedDate)

    // The `parseISO` function from `date-fns` does not throw an exception for invalid date strings.
    // Instead, it returns an `Invalid Date` object. This is why the catch block might not be triggered,
    // and we need to explicitly check if the parsed date is valid using `isNaN(parsedDate.getTime())`.
    if (isNaN(parsedDate.getTime())) {
      // Return the error date to indicate that the date is invalid.
      return ERROR_DATE
    }

    return parsedDate
  } catch (error) {
    const maskedStringDate = maskContent(stringDate)
    console.info('Unable to parseIsoString:', maskedStringDate)
    console.error('Unable to parseIsoString:', error)
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
    return ERROR_DATE // Returns January 1, 1970, 00:00:00 UTC
  }
}

export function backendFormat(date: Date): string {
  return format(date, 'yyyy-MM-dd_HH-mm-ss')
}

export function backendToReadable(timestamp: string): string {
  if (isNaN(new Date(timestamp).getTime())) {
    return EM_DASH
  }
  return readableFormat(parseIsoString(timestamp))
}

export function backendToLocalFull(timestamp: string): string {
  if (isNaN(new Date(timestamp).getTime())) {
    return EM_DASH
  }
  return parseIsoString(timestamp).toLocaleString()
}

export function intervalToReadable(
  seconds: number,
  useDefaults = false
): string {
  if (useDefaults && seconds in SECONDS_TO_READABLE) {
    return SECONDS_TO_READABLE[seconds]
  }

  const interval = intervalToDuration({ start: 0, end: seconds * 1000 })
  return formatDuration(interval)
}

export function readableNumber(num: number): string {
  if (!isFinite(num)) {
    return num.toString()
  }

  const thresholds = [1e9, 1e6, 1e3]
  const suffixes = ['B', 'M', 'K']

  for (let i = 0; i < thresholds.length; i++) {
    if (num >= thresholds[i]) {
      const shortNum: number = num / thresholds[i]
      let numStr = shortNum.toFixed(0)

      if (numStr.length < 3) {
        numStr = shortNum.toFixed(i === 0 ? 2 : 1).replace(/\.?0+$/, '')
      }

      return numStr + suffixes[i]
    }
  }

  return num.toFixed(1).replace(/\.?0+$/, '')
}

export function isNumeric(str: string): boolean {
  return /^-?\d+(\.\d+)?$/.test(str)
}

export function snakeToCamel(str: string): string {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
}

export function camelToSnake(str: string): string {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase()
}

export type CamelToSnake<
  T extends string,
  P extends string = '',
> = string extends T
  ? string
  : T extends `${infer C0}${infer R}`
  ? CamelToSnake<
      R,
      `${P}${C0 extends Lowercase<C0> ? '' : '_'}${Lowercase<C0>}`
    >
  : P

export type SnakeToCamel<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamel<U>>}`
  : S

export function snakeToStart(str: string) {
  return _.startCase(str.replaceAll('_', ' '))
}

export function convertKeys(
  value: any,
  converter: (str: string) => string
): Record<string, any> {
  if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
    if (value instanceof Date) {
      return value
    }

    const newObj: Record<string, any> = {}
    for (const key of Object.keys(value)) {
      const newKey = converter(key)
      const newValue = convertKeys(value[key], converter)
      newObj[newKey] = newValue
    }
    return newObj
  } else if (Array.isArray(value)) {
    const newArr: any[] = []
    for (const element of value) {
      const newElement = convertKeys(element, converter)
      newArr.push(newElement)
    }
    return newArr
  } else {
    return value
  }
}

// Main function to convert all the keys recursively from snake_case to camelCase
export function ToFrontendKeys(value: any): any {
  return convertKeys(value, snakeToCamel)
}

// Main function to convert all the keys recursively from camelCase to snake_case
export function ToBackendKeys(value: any): any {
  return convertKeys(value, camelToSnake)
}

export function download(
  url: string,
  attributes: Record<string, string> = {}
): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      if (_.isEmpty(url)) {
        reject('No URL to download')
      }
      const link = document.createElement('a')
      link.style.display = 'none'
      for (const [key, value] of Object.entries(attributes)) {
        link.setAttribute(key, value)
      }
      document.body.appendChild(link)
      link.href = url

      if (!attributes.download) {
        link.setAttribute('download', '')
      }

      link.click()
      document.body.removeChild(link)

      // Resolve the promise to indicate success
      resolve(`File downloaded successfully.`)
    } catch (error: any) {
      // Reject the promise in case of an error
      reject(`Failed to download from the URL: ${error.message}`)
    }
  })
}

export function downloadString(text: string, name: string): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      if (!text) {
        reject('No text to download')
      }
      const blob = new Blob([text], { type: 'text/plain' })
      const link = document.createElement('a')
      link.style.display = 'none'
      link.download = name
      link.href = URL.createObjectURL(blob)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(link.href)

      // Resolve the promise to indicate success
      resolve(`File "${name}" downloaded successfully.`)
    } catch (error: any) {
      // Reject the promise in case of an error
      reject(`Failed to download the file: ${error.message}`)
    }
  })
}

export function isMarkdownTableRow(line: string): boolean {
  return /^\|(.+?)\|$/.test(line.trim())
}

export function isMarkdownTableHeaderSeparatorRow(line: string): boolean {
  return /^\|([|\- ]+)\|$/.test(line.trim())
}

export function ensureFreshData(
  loader: () => void,
  expiryMS: number = REFRESH_TIMEOUT
): () => void {
  loader()
  const interval = setInterval(() => {
    loader()
  }, expiryMS)
  return () => {
    clearInterval(interval)
  }
}

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs))
}

export async function retry<T>(
  func: () => Promise<T>,
  failed: (err: any) => T,
  options?: {
    maxRetries?: number
    retryDelayMs?: number
    shouldNotUseSentry?: boolean
  }
): Promise<{ result: T; retries: number }> {
  const { maxRetries = 3, retryDelayMs = 1000 } = options ?? {}

  let retries = 0
  while (retries < maxRetries) {
    try {
      const result = await func()
      return { result, retries }
    } catch (e) {
      if (!options?.shouldNotUseSentry) {
        Sentry.captureException(e)
      }
      retries++
      if (retries < maxRetries) {
        // don't throw error yet, wait then retry
        await new Promise((resolve) =>
          setTimeout(resolve, retryDelayMs * 2 ** (retries - 1))
        )
      } else {
        return { result: failed(e), retries }
      }
    }
  }
  return { result: failed(null), retries }
}

export const replaceLinks = (
  inputText: string,
  replacement: string
): string => {
  // Regular expression for matching URLs
  // This regex should match most common URL formats
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi

  // Replace all occurrences of URLs with the replacement string
  return inputText.replace(urlRegex, replacement)
}

export const getOperatingSystem = () => {
  const userAgent = window.navigator.userAgent
  let os = null

  if (/Mac OS/.test(userAgent)) {
    os = 'Mac'
  } else if (/iPhone/.test(userAgent) || /iPad/.test(userAgent)) {
    os = 'iOS'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(userAgent)) {
    os = 'Linux'
  } else if (/Windows/.test(userAgent)) {
    os = 'Windows'
  }

  return os
}

export const getCurrentRouteStringFromLocation = (path: string): string => {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^\/(assistant|research|workflows)\/?([^\/]*)/
  const match = path.match(pattern)

  if (match) {
    // If there's a match, check if there's a subcategory
    const category = match[1]
    let subcategory = match[2]

    // For the 'research' and 'workflow' categories, replace any additional slashes with underscores
    // and ensure the subcategory is not just a number (to exclude IDs)
    if (
      ['research', 'workflows'].includes(category) &&
      subcategory &&
      !/^\d+$/.test(subcategory)
    ) {
      subcategory = subcategory.replace(/\//g, '_')
      return `${category}/${subcategory}`
    }

    // If there's no valid subcategory or it's the 'assistant' category, return the category name
    return category
  }

  return 'unknown'
}

export const waitForElement = async (
  selector: string,
  maxRetries?: number
): Promise<Element | null> => {
  return new Promise<Element | null>((resolve) => {
    const checkForElement = (retries = 0) => {
      const element = document.querySelector(selector)
      if (element) resolve(element)
      else {
        if (retries > (maxRetries ?? 10)) resolve(null)
        else setTimeout(() => checkForElement(retries + 1), 100)
      }
    }
    checkForElement()
  })
}

export const isUserInputEmpty = (userInputs: unknown[]) => {
  return userInputs.every((input) => _.isNil(input) || _.isEmpty(input))
}

export const isAzureBlobStoragePath = (path: string): boolean => {
  return /https:\/\/(?<account_name>[^.]+)\.blob\.core\.windows\.net\/(?<container_name>[^/]+)/.test(
    path
  )
}

export const isAzureBlobPdf = (path: string | null): boolean => {
  if (!path) return false

  return (
    path.toLocaleLowerCase().includes('pdf') && isAzureBlobStoragePath(path)
  )
}

export const isAnchorOrButton = (el: Element | null): boolean => {
  if (!el || !(el instanceof Element)) return false
  if (el.tagName === 'A') return true
  if (el.tagName === 'BUTTON') return true
  return isAnchorOrButton(el.parentElement)
}

export const isInPopup = (el: Element | null): boolean => {
  if (!el || !(el instanceof Element)) return false
  if (el.getAttribute('data-state') === 'open') return true
  return isInPopup(el.parentElement)
}

export const findScrollingContainer = (
  el: HTMLElement | null
): HTMLElement | null => {
  if (!el || !(el instanceof HTMLElement)) return null
  const overflowY = window.getComputedStyle(el).overflowY
  if (overflowY === 'auto' || overflowY === 'scroll') {
    return el
  }
  return findScrollingContainer(el.parentElement)
}

export const isElementInView = (
  elementRect: DOMRect,
  containerRect: DOMRect,
  options?: {
    top?: number
    bottom?: number
    absolute?: boolean // Returns true if *any* part of the element is in view
  }
) => {
  const elementTop = Math.ceil(elementRect.top)
  const elementBottom = Math.floor(elementRect.bottom)
  const midY = (containerRect.bottom - containerRect.top) / 2
  if (options?.absolute) {
    if (
      elementBottom >= containerRect.top &&
      elementTop <= containerRect.bottom
    ) {
      return true
    }
  } else if (elementRect.height <= containerRect.height * 0.5) {
    // If the element height < half the container height, consider it
    // visible if it's within the scrolling container view
    if (
      elementTop >= containerRect.top &&
      elementBottom <= containerRect.bottom
    ) {
      return true
    }
  } else {
    // Otherwise, consider it visible if it's within 50% of the view
    // including the padding, which we add manually since
    // getBoundingClientRect does not consider padding/margins
    if (
      elementTop + (options?.top ?? 0) <= midY &&
      elementBottom + (options?.bottom ?? 0) > midY
    ) {
      return true
    }
  }
  return false
}

// can make this more generic in future
export const findMedianOfNumberArray = (numbers: number[]): number => {
  if (numbers.length === 0) {
    return NaN // similar to how lodash handles empty arrays when calling _.mean()
  }

  numbers.sort((a, b) => a - b)
  const length = numbers.length

  if (length % 2 == 0) {
    return (numbers[length / 2] + numbers[length / 2 - 1]) / 2
  } else {
    return numbers[Math.floor(length / 2)]
  }
}

export const roundDecimalToXPlaces = (num: number, places: number): number => {
  return Math.round(num * 10 ** places) / 10 ** places
}

export const useScrollToTop = () => {
  const scrollToTop = useCallback(
    (htmlDivElementRef: React.RefObject<HTMLDivElement>) => {
      htmlDivElementRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    []
  )

  return scrollToTop
}

export const downloadFileFromUrl = async (url: string, name: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const urlBlob = window.URL.createObjectURL(blob)
  const link = window.document.createElement('a')
  link.href = urlBlob
  link.setAttribute('download', name)
  window.document.body.appendChild(link)
  link.click()
  link.remove()
}

export const s2ab = (s: string): ArrayBuffer => {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff
  }
  return buf
}

export const isBooleanLike = (value: any): boolean | null => {
  if (typeof value === 'boolean') {
    return value
  }
  if (typeof value === 'string') {
    const trimmedLowerValue = value.toLowerCase().trim()
    if (['true', 't', '1'].includes(trimmedLowerValue)) {
      return true
    }
    if (['false', 'f', '0'].includes(trimmedLowerValue)) {
      return false
    }
  }
  return null
}

interface Duration {
  years?: number
  months?: number
  weeks?: number
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}

export function durationToSeconds(duration: Duration): number {
  return (
    (duration.years || 0) * 365 * 24 * 60 * 60 +
    (duration.months || 0) * 30 * 24 * 60 * 60 +
    (duration.weeks || 0) * 7 * 24 * 60 * 60 +
    (duration.days || 0) * 24 * 60 * 60 +
    (duration.hours || 0) * 60 * 60 +
    (duration.minutes || 0) * 60 +
    (duration.seconds || 0)
  )
}

export function parseIsoDurationString(durationString: string): Duration {
  if (!durationString) {
    console.error('parseIsoDurationString: input is empty or null')
    return {}
  }

  try {
    const regex =
      /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?/
    const matches = durationString.match(regex)

    if (!matches) {
      throw new Error('Invalid ISO duration format')
    }

    const [, years, months, weeks, days, hours, minutes, seconds] = matches

    const duration: Duration = {}

    if (years) duration.years = parseInt(years)
    if (months) duration.months = parseInt(months)
    if (weeks) duration.weeks = parseInt(weeks)
    if (days) duration.days = parseInt(days)
    if (hours) duration.hours = parseInt(hours)
    if (minutes) duration.minutes = parseInt(minutes)
    if (seconds) duration.seconds = parseInt(seconds)

    return duration
  } catch (error) {
    const maskedDurationString = maskContent(durationString)
    console.info('Unable to parseIsoDurationString:', maskedDurationString)
    console.error('Unable to parseIsoDurationString:', error)
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
    return {}
  }
}
