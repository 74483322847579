import { Users, Lock, Building2 } from 'lucide-react'

import { ProjectMetadata } from 'openapi/models/ProjectMetadata'
import { VaultFolderShareStatus } from 'openapi/models/VaultFolderShareStatus'

import { SafeRecord } from 'utils/safe-types'

export const getSharingStatusAndIconForVaultFolder = ({
  permissionsByProjectId,
  vaultProjectId,
  clientName,
  projectMetadata,
  isKnowledgeBaseProject,
}: {
  permissionsByProjectId: SafeRecord<string, VaultFolderShareStatus>
  vaultProjectId: string
  clientName: string
  projectMetadata?: ProjectMetadata
  isKnowledgeBaseProject?: boolean
}) => {
  const isSharedWithWorkspace =
    (permissionsByProjectId[vaultProjectId]?.permissionsByWorkspace?.length ??
      0) > 0

  const isSharedWithUser =
    (permissionsByProjectId[vaultProjectId]?.permissionsByUser?.length ?? 0) > 0

  const workspaceClientName = clientName
  const sharedByUserEmail = projectMetadata?.projectCreatorEmail ?? null

  if (isKnowledgeBaseProject && isSharedWithWorkspace) {
    return {
      sharingStatusIcon: Building2,
      sharingStatusTooltip: `Shared to everyone at ${workspaceClientName}`,
    }
  } else if (isSharedWithWorkspace || isSharedWithUser) {
    return {
      sharingStatusIcon: Users,
      sharingStatusTooltip: `Shared by ${sharedByUserEmail}`,
    }
  } else {
    return {
      sharingStatusIcon: Lock,
      sharingStatusTooltip: 'Private to you',
    }
  }
}
