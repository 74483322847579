import React, { useMemo, useRef } from 'react'

import { convertMillimetersToTwip } from 'docx'
import { DownloadIcon } from 'lucide-react'

import { WordSectionType } from 'utils/docx'
import { exportWordWithSections } from 'utils/markdown'
import { TaskType } from 'utils/task'
import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

import CountryCard from './country-card'
import CountryDetailCard from './country-detail-card'
import { generateAntitrustAnalysisHtml } from './export-utils'
import { RequestForInformationComponent } from './request-for-information'
import {
  getCountryCodesSortedByCountryName,
  getCountriesWithAllThresholdsMet,
  getCountriesWithAllThresholdsNotMet,
  getCountriesWithMoreInfoRequired,
  SummaryProps,
  createRequestForInformationTableRow,
} from './utils'

const Summary: React.FC<SummaryProps> = ({
  className,
  data,
  handleSourceClick,
}) => {
  const summaryRef = useRef<HTMLDivElement>(null)
  const [selectedCountry, setSelectedCountry] = React.useState<string | null>(
    null
  )

  const { countryCodeToAnalysis, worldwidePartyRevenues } = data

  const sortedCountryCodes = useMemo(
    () => getCountryCodesSortedByCountryName(countryCodeToAnalysis),
    [countryCodeToAnalysis]
  )

  const countriesWithMoreInfoRequired = useMemo(
    () =>
      getCountriesWithMoreInfoRequired(
        sortedCountryCodes,
        countryCodeToAnalysis
      ),
    [sortedCountryCodes, countryCodeToAnalysis]
  )

  const rfiRef = useRef<HTMLDivElement>(null)

  const handleRfiDownload = async () => {
    await exportWordWithSections({
      defaultStyleOverrides: {
        heading1: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(5),
            },
          },
        },
        heading2: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(2),
            },
          },
        },
        heading3: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(2),
            },
          },
        },
      },
      filePrefixOverride: 'ANTITRUST_RFI',
      includeAnnotation: false,
      queryId: '0',
      sections: [
        {
          content: rfiRef.current?.innerHTML ?? '',
          type: WordSectionType.HTML,
        },
      ],
      taskType: TaskType.WORD_DOCUMENT,
      title: 'Antitrust Filing Analysis - RFI',
    })
  }

  const handleCompleteReportDownload = async () => {
    await exportWordWithSections({
      defaultStyleOverrides: {
        heading1: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(10),
              after: convertMillimetersToTwip(5),
            },
          },
          run: {
            size: 32,
            bold: true,
          },
        },
        heading2: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(8),
              after: convertMillimetersToTwip(4),
            },
          },
          run: {
            size: 28,
            bold: true,
          },
        },
        heading3: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(6),
              after: convertMillimetersToTwip(3),
            },
          },
          run: {
            size: 24,
            bold: true,
          },
        },
        heading4: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(4),
              after: convertMillimetersToTwip(2),
            },
          },
          run: {
            size: 20,
            bold: true,
          },
        },
      },
      filePrefixOverride: 'ANTITRUST_ANALYSIS_REPORT',
      includeAnnotation: false,
      queryId: '0',
      sections: [
        {
          // Use the utility function to generate the HTML content
          content: generateAntitrustAnalysisHtml(data),
          type: WordSectionType.HTML,
        },
        ...(countriesWithMoreInfoRequired.length > 0
          ? [
              {
                content: '<h1>Requests for Information</h1>',
                type: WordSectionType.HTML,
              },
              {
                content: rfiRef.current?.innerHTML ?? '',
                type: WordSectionType.HTML,
              },
            ]
          : []),
      ],
      taskType: TaskType.WORD_DOCUMENT,
      title: 'Antitrust Filing Analysis - Complete Report',
    })
  }

  const requestForInformationRows = useMemo(() => {
    return createRequestForInformationTableRow(countryCodeToAnalysis)
  }, [countryCodeToAnalysis])

  return (
    <div>
      <div className="mt-2 space-y-1">
        {getCountriesWithAllThresholdsMet(
          sortedCountryCodes,
          countryCodeToAnalysis
        ).length > 0 && (
          <div>
            <p className="font-medium">Thresholds likely met</p>
            <p>
              {getCountriesWithAllThresholdsMet(
                sortedCountryCodes,
                countryCodeToAnalysis
              )
                .map(
                  (countryCode) =>
                    countryCodeToAnalysis[countryCode].countryName
                )
                .join(', ')}
            </p>
          </div>
        )}

        {getCountriesWithAllThresholdsNotMet(
          sortedCountryCodes,
          countryCodeToAnalysis
        ).length > 0 && (
          <div>
            <p className="font-medium">Thresholds likely not met</p>
            <p>
              {getCountriesWithAllThresholdsNotMet(
                sortedCountryCodes,
                countryCodeToAnalysis
              )
                .map(
                  (countryCode) =>
                    countryCodeToAnalysis[countryCode].countryName
                )
                .join(', ')}
            </p>
          </div>
        )}

        {countriesWithMoreInfoRequired.length > 0 && (
          <div>
            <p className="font-medium">More information required</p>
            <p>
              {countriesWithMoreInfoRequired
                .map(
                  (countryCode) =>
                    countryCodeToAnalysis[countryCode].countryName
                )
                .join(', ')}
            </p>
          </div>
        )}
      </div>
      <div className="mb-2 mt-4 flex justify-end">
        <Button
          variant="secondary"
          size="sm"
          onClick={handleCompleteReportDownload}
        >
          <Icon icon={DownloadIcon} className="mr-2" />
          Export Complete Report
        </Button>
      </div>
      <div
        className={cn('mt-4 w-full rounded border', className)}
        ref={summaryRef}
      >
        <div className="border-b px-4 py-3">
          <div className="flex items-center justify-between">
            <p>Country Breakdown</p>
            <Button
              variant="outline"
              size="sm"
              disabled={!selectedCountry}
              onClick={() => setSelectedCountry(null)}
            >
              View All
            </Button>
          </div>
        </div>
        {!selectedCountry && (
          <div className="grid grid-cols-2 gap-4 px-4 py-3">
            {sortedCountryCodes.map((countryCode) => (
              <CountryCard
                key={countryCode}
                countryName={countryCode}
                analysis={countryCodeToAnalysis[countryCode]}
                setSelectedCountry={setSelectedCountry}
                summaryRef={summaryRef}
              />
            ))}
          </div>
        )}

        {selectedCountry && (
          <div className="w-full px-4 py-3">
            <CountryDetailCard
              countryName={selectedCountry}
              analysis={countryCodeToAnalysis[selectedCountry]}
              handleSourceClick={handleSourceClick}
              worldwidePartyRevenues={worldwidePartyRevenues}
            />
          </div>
        )}
      </div>
      {countriesWithMoreInfoRequired.length > 0 && (
        <div className="mt-4 space-y-4" ref={rfiRef}>
          <div className="flex items-center justify-between">
            <h1 className="text-lg">Requests for information</h1>
            <Button
              variant="secondary"
              size="smIcon"
              onClick={handleRfiDownload}
            >
              <Icon icon={DownloadIcon} />
            </Button>
          </div>
          <RequestForInformationComponent
            requestForInformationRows={requestForInformationRows}
          />
        </div>
      )}
    </div>
  )
}

export default Summary
