import * as React from 'react'

import { type LucideIcon } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

type ToolbarAction = {
  icon?: LucideIcon
  component?: React.ReactElement
  tooltip: string
  disabled?: boolean
}

type ButtonAction = ToolbarAction & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

type LinkAction = ToolbarAction & {
  to: string
}

export type AnswerBlockToolbarAction = ButtonAction | LinkAction

interface AnswerBlockToolbarProps {
  title: string
  actions: AnswerBlockToolbarAction[]
}

const AnswerBlockToolbar: React.FC<AnswerBlockToolbarProps> = ({
  title,
  actions,
}) => {
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    action: AnswerBlockToolbarAction
  ) => {
    if (action.disabled) {
      e.stopPropagation()
      e.preventDefault()
      return
    }

    if ('onClick' in action) {
      e.stopPropagation()
      e.preventDefault()
      action.onClick(e)
    }
  }
  return (
    <div className="group flex min-h-12 items-center justify-between border-b border-primary py-2 pl-4 pr-1">
      <div className="line-clamp-1 pr-4 text-sm font-medium text-primary">
        {title}
      </div>
      <div className="flex shrink-0 items-center">
        {actions.map((action) => {
          return action.component ? (
            React.cloneElement(action.component, {
              key: action.tooltip,
            })
          ) : (
            <Button
              variant="secondary"
              size="smIcon"
              key={action.tooltip}
              onClick={(e) => handleClick(e, action)}
              to={'to' in action ? action.to : undefined}
              tooltip={action.tooltip}
              disabled={action.disabled}
              className={cn(
                'bg-primary hover:!bg-button-secondary-hover group-hover:bg-secondary ',
                action.disabled &&
                  'cursor-not-allowed opacity-50 hover:bg-button-secondary'
              )}
            >
              {action.icon && (
                <Icon className="text-muted" icon={action.icon} />
              )}
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default AnswerBlockToolbar
