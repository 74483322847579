import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/browser'
import { createWorkerFactory, terminate } from '@shopify/web-worker'
import _ from 'lodash'

import { Event } from 'models/event'
import { FileFailureCategory } from 'openapi/models/FileFailureCategory'
import { ProjectMetadata } from 'openapi/models/ProjectMetadata'
import { ReviewWorkflowCategory } from 'openapi/models/ReviewWorkflowCategory'
import { ReviewWorkflowCobrand } from 'openapi/models/ReviewWorkflowCobrand'
import { ReviewWorkflowKind } from 'openapi/models/ReviewWorkflowKind'
import { ReviewWorkflowVisibilityKind } from 'openapi/models/ReviewWorkflowVisibilityKind'
import { Tag } from 'openapi/models/Tag'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultDuplicateFilesApiResponseData } from 'openapi/models/VaultDuplicateFilesApiResponseData'
import { VaultExampleProjectsApiResponseData } from 'openapi/models/VaultExampleProjectsApiResponseData'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFileApiResponseData } from 'openapi/models/VaultFileApiResponseData'
import { VaultFilesApiResponseData } from 'openapi/models/VaultFilesApiResponseData'
import { VaultFilesDeleteApiResponseData } from 'openapi/models/VaultFilesDeleteApiResponseData'
import { VaultFilesRetryApiResponseData } from 'openapi/models/VaultFilesRetryApiResponseData'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderAccessPermission } from 'openapi/models/VaultFolderAccessPermission'
import { VaultFolderApiResponseData } from 'openapi/models/VaultFolderApiResponseData'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'
import { VaultFolderShareStatusApiResponseData } from 'openapi/models/VaultFolderShareStatusApiResponseData'
import { VaultFoldersApiResponseData } from 'openapi/models/VaultFoldersApiResponseData'
import { VaultProjectsMetadataApiResponseData } from 'openapi/models/VaultProjectsMetadataApiResponseData'
import { VaultSetupApiResponseData } from 'openapi/models/VaultSetupApiResponseData'
import Services from 'services'
import { RequestError } from 'services/backend/backend'

import { backendRestUrl } from 'utils/server-data'
import { sseStreamIterator, RequestMethod } from 'utils/sse-stream-iterator'
import { TaskType } from 'utils/task'

import { SetHistoryItemFromSSEProps } from 'components/vault/query-detail/vault-query-detail-store'
import {
  FileToUpload,
  VaultDeleteFolder,
  JobQueueEtaApiResponseData,
  GenerateNNRequestType,
  ReviewEvent,
  QueryQuestion,
  ReviewColumn,
  ColumnDataType,
  ReviewRow,
  ReviewRowResponse,
  ReviewCell,
} from 'components/vault/utils/vault'

const BATCH_FILE_SIZE_BYTES = 10_000_000 // 10MB
const BATCH_FILE_AMOUNT = 100

const uploadWorker = createWorkerFactory(
  () => import('services/workers/upload/upload-worker')
)

const FetchVaultSetup = async (): Promise<VaultSetupApiResponseData> => {
  const requestPath = 'vault/setup'
  const response = await Services.Backend.Get<VaultSetupApiResponseData>(
    requestPath,
    {
      throwOnError: true,
    }
  )
  return response
}

const FetchVaultProjects = async ({
  includeSharedFolders,
  includeSharedWithWorkspaceFolders,
  includeKnowledgeBaseFolders,
}: {
  includeSharedFolders?: boolean
  includeSharedWithWorkspaceFolders?: boolean
  includeKnowledgeBaseFolders?: boolean
}): Promise<VaultFoldersApiResponseData> => {
  const searchParams = new URLSearchParams()
  if (includeSharedFolders) {
    searchParams.append('include_shared_folders', 'true')
    // only add include_shared_with_workspace_folders param if also including shared folders
    if (includeSharedWithWorkspaceFolders) {
      searchParams.append('include_shared_with_workspace_folders', 'true')
    }
  }
  if (includeKnowledgeBaseFolders) {
    searchParams.append('include_knowledge_base_folders', 'true')
  }
  const requestPath =
    searchParams.size > 0
      ? `vault/user?${searchParams.toString()}`
      : 'vault/user'
  const response = await Services.Backend.Get<VaultFoldersApiResponseData>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

const FetchVaultExampleProjects =
  async (): Promise<VaultExampleProjectsApiResponseData> => {
    const requestPath = 'vault/example-projects'
    const response =
      await Services.Backend.Get<VaultExampleProjectsApiResponseData>(
        requestPath
      )
    return response
  }

const SetVaultExampleProject = async (projectId: string): Promise<void> => {
  const requestPath = `vault/set-example-project/${projectId}`
  await Services.Backend.Post<void>(requestPath, {}, { throwOnError: true })
}

const UnsetVaultExampleProject = async (projectId: string): Promise<void> => {
  const requestPath = `vault/unset-example-project/${projectId}`
  await Services.Backend.Post<void>(requestPath, {}, { throwOnError: true })
}

const FetchVaultFoldersMetadata = async (
  folderId: string
): Promise<VaultFolderMetadata[]> => {
  const requestPath = `vault/folders/${folderId}/project-metadata`
  const response =
    await Services.Backend.Get<VaultProjectsMetadataApiResponseData>(
      requestPath,
      {
        throwOnError: true,
      }
    )
  return response.metadata
}

const FetchVaultProjectMetadata = async (
  projectId: string
): Promise<ProjectMetadata> => {
  const requestPath = `vault/projects/${projectId}/metadata`
  const response = await Services.Backend.Get<ProjectMetadata>(requestPath, {
    throwOnError: true,
  })
  return response
}

const FetchVaultProjectsMetadata = async (
  projectIds: string[]
): Promise<ProjectMetadata[]> => {
  const requestPath = `vault/projects/metadata`
  const response = await Services.Backend.Post<ProjectMetadata[]>(
    requestPath,
    { project_ids: projectIds },
    { throwOnError: true }
  )
  return response
}

const FetchVaultFolder = async (
  folderId: string,
  skipUrlSigning = false
): Promise<VaultFolderApiResponseData> => {
  const requestPath = skipUrlSigning
    ? `vault/folder/${folderId}?skip_url_signing=true`
    : `vault/folder/${folderId}`
  const response = await Services.Backend.Get<VaultFolderApiResponseData>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

const SetVaultFolderLastOpened = async (folderId: string) => {
  const requestPath = `vault/folder/${folderId}/open`
  await Services.Backend.Post<void>(requestPath, {})
}

interface CreateVaultFolderParams {
  folderName: string
  parentId: string | null
  clientMatterId: string | null
  isKnowledgeBaseProject?: boolean
}

const CreateVaultFolder = async ({
  folderName,
  parentId,
  clientMatterId,
  isKnowledgeBaseProject,
}: CreateVaultFolderParams): Promise<VaultFolderApiResponseData> => {
  const requestPath = 'vault/folder'
  const response = await Services.Backend.Post<VaultFolderApiResponseData>(
    requestPath,
    {
      name: folderName,
      parentId: parentId,
      clientMatterId: clientMatterId,
      isKnowledgeBaseProject: isKnowledgeBaseProject ?? false,
    },
    // We want to throw an error if the folder is not created.
    { throwOnError: true }
  )
  return response
}

// we need the prefix here to update the file name when uploading zip folders
// for example, when uploading a file in a zip, the file name could be like: safes/discount/yc_safe.pdf
// in this example we need to remove the prefix 'safes/discount/' and rename the file to be just yc_safe.pdf
export const UploadVaultFiles = async ({
  accessToken,
  files,
  vaultFolderId,
  prefix,
  shouldSetUploadTimestamp,
  uploadedAt,
}: {
  accessToken: string
  files: FileToUpload[]
  vaultFolderId: string
  prefix: string
  shouldSetUploadTimestamp: boolean
  uploadedAt: string
}): Promise<VaultFilesApiResponseData> => {
  if (files.length === 0) {
    throw new Error('Cannot upload files, files is not set')
  }

  datadogRum.startDurationVital('vaultFileUpload')

  const results: VaultFilesApiResponseData[] = []
  const filesApiResponseData: VaultFilesApiResponseData = {
    files: [],
    failedFilesWithErrors: [],
  }
  let isFirstRequest = true
  let currFileIndex = 0
  let lastUploadedFileIndex = 0
  let currFormDataSize = 0
  let currNumFiles = 0
  while (currFileIndex < files.length) {
    const currFile = files[currFileIndex]
    currFile.name = currFile.name.replace(prefix, '')
    if (
      currFormDataSize < BATCH_FILE_SIZE_BYTES &&
      currNumFiles < BATCH_FILE_AMOUNT
    ) {
      currFormDataSize += currFile.file.size
      currNumFiles += 1
      currFileIndex += 1
    } else {
      const slicedFiles = files.slice(lastUploadedFileIndex, currFileIndex)
      try {
        const worker = uploadWorker()
        const res = await worker.uploadVaultFile({
          endpoint: `${backendRestUrl}/vault/folder/${vaultFolderId}/files`,
          accessToken,
          files: slicedFiles,
          shouldSetUploadTimestamp: shouldSetUploadTimestamp && isFirstRequest,
          uploadedAt,
        })
        terminate(worker)
        if (res.exception) console.error(res.exception)
        results.push(res)
        isFirstRequest = false
        lastUploadedFileIndex = currFileIndex
        currFormDataSize = 0
        currNumFiles = 0
      } catch (e) {
        console.error(e)
        const error = e as Error
        filesApiResponseData.failedFilesWithErrors = [
          ...(filesApiResponseData.failedFilesWithErrors || []),
          ...slicedFiles.map((file) => ({
            name: file.name,
            failureCategory: FileFailureCategory.OTHER_UPLOAD_ERROR,
            error: error.message,
          })),
        ]
      }
    }
  }

  // upload the last files
  if (lastUploadedFileIndex < files.length) {
    const remainingFiles = files.slice(lastUploadedFileIndex, files.length)
    try {
      const worker = uploadWorker()
      const res = await worker.uploadVaultFile({
        endpoint: `${backendRestUrl}/vault/folder/${vaultFolderId}/files`,
        accessToken,
        files: remainingFiles,
        shouldSetUploadTimestamp: shouldSetUploadTimestamp && isFirstRequest,
        uploadedAt,
      })
      terminate(worker)
      if (res.exception) console.error(res.exception)
      results.push(res)
    } catch (e) {
      console.error(e)
      const error = e as Error
      filesApiResponseData.failedFilesWithErrors = [
        ...(filesApiResponseData.failedFilesWithErrors || []),
        ...remainingFiles.map((file) => ({
          name: file.name,
          failureCategory: FileFailureCategory.OTHER_UPLOAD_ERROR,
          error: error.message,
        })),
      ]
    }
  }

  results.forEach((result) => {
    filesApiResponseData.files = [
      ...filesApiResponseData.files,
      ...result.files,
    ]
    if (result.failedFilesWithErrors) {
      filesApiResponseData.failedFilesWithErrors = [
        ...(filesApiResponseData.failedFilesWithErrors || []),
        ...result.failedFilesWithErrors,
      ]
    }
  })
  const totalFileSize = filesApiResponseData.files.reduce(
    (acc, file) => acc + (file.size || 0),
    0
  )
  datadogRum.stopDurationVital('vaultFileUpload', {
    context: {
      fileCount: filesApiResponseData.files.length,
      totalFileSizeInBytes: totalFileSize,
    },
  })
  return filesApiResponseData
}

export const DuplicateFilesToFolder = async (
  files: UploadedFile[],
  folderId?: string,
  projectName?: string
): Promise<VaultDuplicateFilesApiResponseData> => {
  const requestPath = `vault/duplicate_files`

  const fileIdsAndNames = files.map((file) => [file.id, file.name])

  const response =
    await Services.Backend.Post<VaultDuplicateFilesApiResponseData>(
      requestPath,
      {
        file_ids_and_names: fileIdsAndNames,
        project_id: folderId,
        project_name: projectName,
      }
    )
  return response
}

const FetchVaultFile = async (fileId: string) => {
  const requestPath = `vault/file/${fileId}`
  const response =
    await Services.Backend.Get<VaultFileApiResponseData>(requestPath)
  return response
}

const FetchVaultFiles = async (
  fileIds: string[]
): Promise<VaultFilesApiResponseData> => {
  const requestPath = `vault/files`
  const response = await Services.Backend.Post<VaultFilesApiResponseData>(
    requestPath,
    { file_ids: fileIds }
  )
  return response
}

const FetchJobQueueEta = async (
  projectId: string,
  lookbackWindowForJobs = 24,
  numRecentJobsToComputeAverageEta = 100
) => {
  try {
    const params = new URLSearchParams({
      project_id: projectId,
      lookback_window_for_jobs: lookbackWindowForJobs.toString(),
      num_recent_jobs_to_compute_average_eta:
        numRecentJobsToComputeAverageEta.toString(),
    })

    const response = await Services.Backend.Get<JobQueueEtaApiResponseData>(
      `vault/job_queue/eta?${params.toString()}`,
      { throwOnError: true, maxRetryCount: 0 }
    )
    return response
  } catch (e) {
    // We don't want to show an error message to the user if the job queue ETA request fails.
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return { etaInSeconds: null }
  }
}

const PatchFolder = async (
  folderId: string,
  data: Record<string, string>
): Promise<VaultFolderApiResponseData> => {
  const requestPath = `vault/folder/${folderId}`
  const response = await Services.Backend.Patch<
    VaultFolderApiResponseData | RequestError
  >(requestPath, data, { throwOnError: true })
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const BulkPatchFiles = async (data: Record<string, string>[]) => {
  const requestPath = `vault/files`
  const response = await Services.Backend.Patch<VaultFilesApiResponseData>(
    requestPath,
    { new_file_data: data }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const PatchFile = async (fileId: string, data: Record<string, string>) => {
  const requestPath = `vault/file/${fileId}`
  const response = await Services.Backend.Patch<
    VaultFileApiResponseData | RequestError
  >(requestPath, data, { throwOnError: true })
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const BulkDeleteVaultFiles = async (fileIds: string[]) => {
  const requestPath = `vault/files`
  const response =
    await Services.Backend.Delete<VaultFilesDeleteApiResponseData>(
      requestPath,
      {
        fileIds,
      }
    )
  return response
}

const DeleteVaultFolder = async (folderId: string) => {
  const requestPath = `vault/folder/${folderId}`
  const response = await Services.Backend.Delete<VaultDeleteFolder>(requestPath)
  const fileIds = response.deletedFilesMetadata
    ? response.deletedFilesMetadata.map((file) => file.id)
    : []
  const folderIds = response.deletedFoldersMetadata
    ? response.deletedFoldersMetadata.map((folder) => folder.id)
    : []

  return { fileIds, folderIds }
}

const RetryFiles = async (fileIds: string[]) => {
  const requestPath = 'vault/files/retry'
  const response = await Services.Backend.Post<VaultFilesRetryApiResponseData>(
    requestPath,
    { fileIds },
    { throwOnError: true }
  )
  return response
}

type ClearQueryErrorsApiResponseData = {
  clearedFileIds: string[]
}

const ClearQueryErrors = async (
  queryId: string,
  fileIds: string[]
): Promise<ClearQueryErrorsApiResponseData> => {
  const requestPath = `vault/query/${queryId}/clear_errors`
  const response = await Services.Backend.Post<ClearQueryErrorsApiResponseData>(
    requestPath,
    { file_ids: fileIds },
    { throwOnError: true }
  )
  return response
}

// Args needed to fetch history
interface FetchVaultHistoryArgs {
  currentPage: number
  pageSize: number
  vaultFolderId?: string
  threadOnly?: boolean
}

const FetchVaultHistoryByPage = async ({
  currentPage,
  pageSize,
  vaultFolderId,
  threadOnly,
}: FetchVaultHistoryArgs) => {
  try {
    const params = new URLSearchParams()
    if (!_.isNil(pageSize)) {
      params.append('page_size', pageSize.toString())
    }
    if (!_.isNil(currentPage)) {
      params.append('page_number', currentPage.toString())
    }
    if (!_.isNil(vaultFolderId)) {
      params.append('vault_folder_id', vaultFolderId)
    }
    if (!_.isNil(threadOnly)) {
      params.append('thread_only', threadOnly.toString())
    }
    const result = await Services.Backend.Get<{
      events: Event[]
      total: number
    }>(`vault/history?${params.toString()}`, { throwOnError: true })
    return result
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    throw e
  }
}

const FetchVaultHistoryByPageV2 = async ({
  currentPage,
  pageSize,
  vaultFolderId,
}: FetchVaultHistoryArgs) => {
  try {
    const params = `page_number=${currentPage}&page_size=${pageSize}&vault_folder_id=${vaultFolderId}`
    const response = await Services.Backend.Get<{
      events: ReviewEvent[]
      eventsCount: number
    }>(`vault/v2/history?${params}`, { throwOnError: true })
    return response
  } catch (e) {
    return { events: [], eventsCount: 0 }
  }
}

type CancelVaultHistoryItemResponseData = {
  eventCancelled: boolean
}

const CancelVaultHistoryItem = async (
  queryId: string
): Promise<CancelVaultHistoryItemResponseData> => {
  const requestPath = `vault/history/${queryId}/cancel`
  return await Services.Backend.Post<CancelVaultHistoryItemResponseData>(
    requestPath,
    { throwOnError: true }
  )
}

const ReorderVaultReviewQueryColumns = async (
  queryId: string,
  columnOrder: string[]
) => {
  const requestPath = `vault/history/${queryId}/reorder_columns`
  return await Services.Backend.Post(
    requestPath,
    { columnOrder },
    { throwOnError: true }
  )
}

interface RerunVaultReviewQueriesParams {
  requestType: GenerateNNRequestType
  queryIds: string[]
  fileIds: string[]
  projectId: string
}

const RerunVaultReviewQueries = async ({
  requestType,
  queryIds,
  fileIds,
  projectId,
}: RerunVaultReviewQueriesParams) => {
  const requestPath = `vault/rerun_review_queries`
  const body = {
    requestType,
    queryIds,
    fileIds,
    projectId,
  }

  try {
    const response = await Services.Backend.Post(requestPath, body, {
      throwOnError: true,
    })
    return response
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
  }
}

export type VaultFolderHistoryStatsApiResponseData = {
  totalCount: number
  inProgressCount: number
}

const FetchVaultFolderHistoryStats = async (folderId: string) => {
  const requestPath = `vault/folder/${folderId}/history_stats`
  try {
    const response =
      await Services.Backend.Get<VaultFolderHistoryStatsApiResponseData>(
        requestPath,
        { throwOnError: true }
      )
    return response
  } catch (e) {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return { totalCount: 0, inProgressCount: 0 }
  }
}

interface SemanticSearchApiResponseData {
  fileIds: string[]
}

const SemanticSearch = async (
  projectId: string,
  query: string,
  signal?: AbortSignal
): Promise<SemanticSearchApiResponseData> => {
  const requestPath = `vault/semantic_search`
  const response = await Services.Backend.Post<SemanticSearchApiResponseData>(
    requestPath,
    { projectId, query },
    { throwOnError: true, signal }
  )
  return response
}

/**
 * Sharing routes
 */

const FetchVaultFolderShareStatus = async (
  projectId: string
): Promise<VaultFolderShareStatusApiResponseData> => {
  const requestPath = `vault/folder/${projectId}/share`
  const response =
    await Services.Backend.Get<VaultFolderShareStatusApiResponseData>(
      requestPath,
      { throwOnError: true }
    )
  return response
}

export type WorkspaceAndUserSharingInfo = {
  shareWithWorkspaces?: Array<{
    workspaceId: number
    permissionLevel: VaultFolderAccessPermission
    alwaysShowOnHomepage?: boolean
  }>
  shareWithUsers?: Array<{
    userId: string
    permissionLevel: VaultFolderAccessPermission
  }>
}

export type ShareVaultFolderParams = {
  projectId: string
} & WorkspaceAndUserSharingInfo

const ShareVaultFolder = async ({
  projectId,
  shareWithWorkspaces,
  shareWithUsers,
}: ShareVaultFolderParams): Promise<VaultFolderShareStatusApiResponseData> => {
  const requestPath = `vault/folder/${projectId}/share`
  const response =
    await Services.Backend.Post<VaultFolderShareStatusApiResponseData>(
      requestPath,
      {
        shareWithWorkspaces,
        shareWithUsers,
      },
      { throwOnError: true }
    )

  if (response instanceof RequestError) {
    throw response
  }
  return response
}

export type UpdatedWorkspaceAndUserSharingInfo = {
  updateShareWithWorkspaces?: Array<{
    workspaceId: number
    permissionLevel: VaultFolderAccessPermission
    alwaysShowOnHomepage?: boolean
  }>
  removeShareWithWorkspaces?: number[]
  updateShareWithUsers?: Array<{
    userId: string
    permissionLevel: VaultFolderAccessPermission
  }>

  removeShareWithUsers?: string[]
}

export type UpdateVaultFolderShareParams = {
  projectId: string
} & UpdatedWorkspaceAndUserSharingInfo

const UpdateVaultFolderShare = async ({
  projectId,
  updateShareWithWorkspaces,
  removeShareWithWorkspaces,
  updateShareWithUsers,
  removeShareWithUsers,
}: UpdateVaultFolderShareParams): Promise<VaultFolderShareStatusApiResponseData> => {
  const requestPath = `vault/folder/${projectId}/share`
  const response =
    await Services.Backend.Patch<VaultFolderShareStatusApiResponseData>(
      requestPath,
      {
        updateShareWithWorkspaces,
        removeShareWithWorkspaces,
        updateShareWithUsers,
        removeShareWithUsers,
      },
      { throwOnError: true }
    )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

const UnshareVaultFolder = async (projectId: string) => {
  const requestPath = `vault/folder/${projectId}/share`
  const response = await Services.Backend.Delete(requestPath, {
    throwOnError: true,
  })
  return response
}

const UpdateVaultFileTags = async (
  fileId: string,
  oldTagIds: string[],
  newTagIds: string[]
) => {
  const requestPath = `vault/file/${fileId}/tags`
  const response = await Services.Backend.Patch(
    requestPath,
    { oldTagIds, newTagIds },
    { throwOnError: true }
  )
  return response
}

type BulkUpdateVaultFileTagsResponse = {
  successfulFileIds: string[]
  failedUpdates: {
    fileId: string
    reason: string
  }[]
}

const BulkUpdateVaultFileTags = async (fileIds: string[], newTagId: string) => {
  const requestPath = 'vault/files/tags'
  const response =
    await Services.Backend.Patch<BulkUpdateVaultFileTagsResponse>(
      requestPath,
      { file_ids: fileIds, new_tag_id: newTagId },
      { throwOnError: true }
    )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

export type VaultFolderUpdateSubscriptionResponseData = {
  hasContentUpdates: boolean
  hasEventsUpdates: boolean
  hasSharingUpdates: boolean
  updatedFolders?: VaultFolder[]
  updatedFiles?: VaultFile[]
  updatedProjectMetadata?: VaultFolderMetadata[]
  updatedSharing?: VaultFolderShareStatusApiResponseData
}

const SubscribeToVaultFolderUpdates = async (
  folderId: string,
  lastUpdatedAt: Date,
  skipContentUpdates: boolean
): Promise<VaultFolderUpdateSubscriptionResponseData> => {
  const searchParams = new URLSearchParams()
  searchParams.append('last_updated_at', lastUpdatedAt.toISOString())
  if (skipContentUpdates) {
    searchParams.append('skip_content_updates', 'true')
  }
  const requestPath = `vault/folder/${folderId}/subscribe_updates?${searchParams.toString()}`

  return await Services.Backend.Get<VaultFolderUpdateSubscriptionResponseData>(
    requestPath,
    {
      throwOnError: true,
    }
  )
}

export type SharingUpdateSubscriptionResponseData = {
  hasSharingUpdates: boolean
  updatedSharedProjects?: VaultFolder[]
}

const SubscribeToVaultSharingUpdates = async (
  lastUpdatedAt: Date
): Promise<SharingUpdateSubscriptionResponseData> => {
  const searchParams = new URLSearchParams()
  searchParams.append('last_updated_at', lastUpdatedAt.toISOString())
  const requestPath = `vault/shares/subscribe_updates?${searchParams.toString()}`

  return await Services.Backend.Get<SharingUpdateSubscriptionResponseData>(
    requestPath,
    {
      throwOnError: true,
    }
  )
}

export type ReviewWorkflow = {
  id: string
  name: string
  description: string
  kind: ReviewWorkflowKind
  strictDocumentType: boolean
  cobrand?: ReviewWorkflowCobrand
  visibility: ReviewWorkflowVisibilityKind
  createdAt: string
  updatedAt: string
  columns: ReviewColumn[]
  // TODO: Make it non-optional once it passes 7 days (TTL of useVaultWorkflows react query disk cache)
  // XXX: Because the cache in disk might not have tags, we need to make it optional
  tags?: Tag[]
  categories?: ReviewWorkflowCategory[]
  additionalVisibilitiesInfo?: {
    id: string
    isHiddenForWorkspace: boolean
    visibility: ReviewWorkflowVisibilityKind
    workspaceId: number
    userId: string
  }[]
}

export type VaultWorkflowApiResponseData = {
  workflows: ReviewWorkflow[]
}

const FetchVaultWorkflows = async () => {
  const requestPath = 'vault/workflows'
  const response = await Services.Backend.Get<VaultWorkflowApiResponseData>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

type FetchVaultReviewQueryParams = {
  queryId: string
  abortController: AbortController
  getAccessTokenSilently: () => Promise<string>
  callback: (props: SetHistoryItemFromSSEProps) => void
  errorCallback: (notReady?: boolean) => void
  getAbortController: (key: string) => AbortController | undefined
  removeAbortController: (key: string) => void
}

const FetchVaultReviewQuery = async ({
  getAccessTokenSilently,
  queryId,
  abortController,
  callback,
  errorCallback,
  getAbortController,
  removeAbortController,
}: FetchVaultReviewQueryParams) => {
  const authorizationToken = await getAccessTokenSilently()
  const requestMethod = RequestMethod.GET
  const requestUrl = `${backendRestUrl}/vault/review_query/${queryId}`
  const headers = {
    Authorization: `Bearer ${authorizationToken}`,
  }

  const sseStreamProps = {
    requestUrl,
    abortController,
    method: requestMethod,
    extraHeaders: headers,
  }
  try {
    for await (const event of sseStreamIterator(sseStreamProps)) {
      callback({
        event,
        errorCallback,
        getAbortController,
        removeAbortController,
      })
    }
  } catch (error) {
    console.error('Error in SSE stream:', error)
  }
}

type CreateVaultReviewQueryParams = {
  eventId: number | null
  query: string
  clientMatterId: string | undefined
  taskType: TaskType
  vaultFolderId: string
  fileIds: string[]
  questions: QueryQuestion[]
  requestType: GenerateNNRequestType
  workflowId: string | null
  selectedWorkflowColumnIds: string[] | null
  sourceQueryIdForDuplicatingQuestions?: string
  isNewQuery?: boolean
}

type CreateVaultReviewQueryResponseData = {
  reviewQueryJobEventId: string
}

const CreateVaultReviewQuery = async ({
  eventId,
  query,
  clientMatterId,
  taskType,
  vaultFolderId,
  fileIds,
  questions,
  requestType,
  workflowId,
  selectedWorkflowColumnIds,
  sourceQueryIdForDuplicatingQuestions,
  isNewQuery = false,
}: CreateVaultReviewQueryParams) => {
  const requestPath = isNewQuery
    ? 'vault/review_query_async'
    : 'vault/review_query'
  const response =
    await Services.Backend.Post<CreateVaultReviewQueryResponseData>(
      requestPath,
      {
        eventId,
        data: query,
        clientMatterId,
        taskType,
        vaultFolderId,
        fileIds,
        questions,
        requestType,
        workflowId,
        selectedWorkflowColumnIds,
        sourceQueryIdForDuplicatingQuestions,
      },
      { throwOnError: true }
    )
  return response
}

interface DraftWorkflowParams {
  name: string
  description: string
  columns?: Array<{ full_text: string; data_type: string; header: string }>
  csvFile?: File
  cobrand?: ReviewWorkflowCobrand
  documentClassificationTagsIds: string[]
  shouldBeStrictDocumentClassification: boolean
  categories?: ReviewWorkflowCategory[]
}

export type CreateDraftWorkflowApiResponseData = {
  workflow: ReviewWorkflow
}

const CreateDraftWorkflow = async ({
  name,
  description,
  columns,
  csvFile,
  cobrand,
  documentClassificationTagsIds,
  shouldBeStrictDocumentClassification,
  categories,
}: DraftWorkflowParams) => {
  const requestPath = `vault/workflows/draft`
  const formData = new FormData()
  formData.append('name', name)
  formData.append('description', description)
  formData.append(
    'should_be_strict_document_classification',
    shouldBeStrictDocumentClassification.toString().toLowerCase()
  )
  if (cobrand) {
    formData.append('cobrand', cobrand)
  }
  if (documentClassificationTagsIds) {
    formData.append('tag_ids', documentClassificationTagsIds.join(','))
  }
  if (columns) {
    formData.append('columns', JSON.stringify(columns))
  }
  if (categories) {
    formData.append('categories', categories.join(','))
  }
  if (csvFile) {
    formData.append('csv_file', csvFile)
  }

  try {
    return await Services.Backend.Post<CreateDraftWorkflowApiResponseData>(
      requestPath,
      formData,
      {
        throwOnError: true,
      }
    )
  } catch (error) {
    console.error('Error creating draft workflow:', error)
    throw error
  }
}

export interface UpdateDraftWorkflowParams {
  workflowId: string
  // reviewWorkflowTagIdsToDelete: the row id of the tag to delete
  reviewWorkflowTagIdsToDelete: string[]
  // reviewWorkflowTagsToAdd: the tag_id to add
  reviewWorkflowTagsToAdd: string[]
  reviewWorkflowCategoriesToDelete?: ReviewWorkflowCategory[]
  categoriesToAdd?: ReviewWorkflowCategory[]
  strictDocumentType?: boolean
  name?: string
  description?: string
}

type UpdateDraftWorkflowApiResponseData = {
  workflow: ReviewWorkflow
}

const UpdateDraftWorkflow = async ({
  workflowId,
  reviewWorkflowTagIdsToDelete,
  reviewWorkflowTagsToAdd,
  reviewWorkflowCategoriesToDelete,
  categoriesToAdd,
  strictDocumentType,
  name,
  description,
}: UpdateDraftWorkflowParams) => {
  const requestPath = `vault/workflows/draft/${workflowId}`
  try {
    const response =
      await Services.Backend.Patch<UpdateDraftWorkflowApiResponseData>(
        requestPath,
        {
          reviewWorkflowTagIdsToDelete,
          newTagIds: reviewWorkflowTagsToAdd,
          strictDocumentType,
          name,
          description,
          reviewWorkflowCategoriesToDelete,
          newCategories: categoriesToAdd,
        },
        { throwOnError: true }
      )
    return response
  } catch (error) {
    console.error('Error updating draft workflow:', error)
    throw error
  }
}

export type VaultWorkflowPublishApiResponseData = {
  workflow: ReviewWorkflow
}

type PublishWorkflowParams = {
  workflowId: string
  visibility: ReviewWorkflowVisibilityKind
  workspaceIds: number[]
  hiddenWorkspaceIds: number[]
  userIds: string[]
}

const PublishWorkflow = async ({
  workflowId,
  visibility,
  workspaceIds,
  hiddenWorkspaceIds,
  userIds,
}: PublishWorkflowParams) => {
  try {
    return await Services.Backend.Post<VaultWorkflowPublishApiResponseData>(
      `vault/workflows/draft/${workflowId}/publish`,
      {
        visibility: visibility,
        workspace_ids: workspaceIds,
        hidden_workspace_ids: hiddenWorkspaceIds,
        user_ids: userIds,
      },
      { throwOnError: true }
    )
  } catch (error) {
    console.error('Error publishing workflow:', error)
    throw error
  }
}

export type VaultWorkflowUnpublishApiResponseData = {
  workflow: ReviewWorkflow
}

const UnpublishWorkflow = async (workflowId: string) => {
  try {
    return await Services.Backend.Post<VaultWorkflowUnpublishApiResponseData>(
      `vault/workflows/${workflowId}/unpublish`,
      {},
      { throwOnError: true }
    )
  } catch (error) {
    console.error('Error unpublishing workflow:', error)
    throw error
  }
}

const DeleteDraftVaultWorkflow = async (workflowId: string): Promise<void> => {
  const requestPath = `vault/workflows/draft/${workflowId}`
  try {
    await Services.Backend.Delete<void>(requestPath, { throwOnError: true })
  } catch (error) {
    console.error(`Error deleting draft workflow with ID ${workflowId}:`, error)
    throw error
  }
}

export type UpdateReviewQueryParams = {
  queryId: string
  title: string
}

const UpdateReviewQuery = async ({
  queryId,
  title,
}: UpdateReviewQueryParams) => {
  const requestPath = `vault/review_query/${queryId}`
  const response = await Services.Backend.Patch(
    requestPath,
    { title },
    { throwOnError: true }
  )
  return response
}

export type UpdateReviewColumnParams = {
  eventId: number
  columnId: string
  header: string
  fullText: string
  dataType: ColumnDataType
  options: string[]
}

const UpdateReviewColumn = async ({
  eventId,
  columnId,
  header,
  fullText,
  dataType,
  options,
}: UpdateReviewColumnParams) => {
  const requestPath = `vault/events/${eventId}/columns/${columnId}`
  const response = await Services.Backend.Patch<ReviewColumn>(
    requestPath,
    { header, fullText, dataType, options },
    { throwOnError: true }
  )
  return response
}

const DeleteReviewColumn = async (eventId: number, columnId: string) => {
  const requestPath = `vault/events/${eventId}/columns/${columnId}`
  const response = await Services.Backend.Delete<ReviewColumn>(
    requestPath,
    {},
    {
      throwOnError: true,
      maxRetryCount: 0,
    }
  )
  return response
}

const DeleteReviewRow = async (eventId: number, rowId: string) => {
  const requestPath = `vault/events/${eventId}/rows/${rowId}`
  const response = await Services.Backend.Delete<ReviewRow>(
    requestPath,
    {},
    {
      throwOnError: true,
      maxRetryCount: 0,
    }
  )
  return response
}

const FetchReviewRow = async (eventId: string, rowId: string) => {
  const requestPath = `vault/events/${eventId}/rows/${rowId}`
  const response = await Services.Backend.Get<ReviewRowResponse>(requestPath, {
    throwOnError: true,
  })
  return response
}

export type ReviewRowsResponse = {
  rows: ReviewRowResponse[]
  total: number
}

const FetchReviewRows = async (
  fileId: string,
  pageSize: number,
  pageNumber: number
) => {
  const requestPath = `vault/file/${fileId}/review_rows?page_size=${pageSize}&page_number=${pageNumber}`
  const response = await Services.Backend.Get<ReviewRowsResponse>(requestPath, {
    throwOnError: true,
  })
  return response
}

const FetchSuggestedReviewColumn = async (
  question: string,
  signal?: AbortSignal
): Promise<SuggestedReviewColumn> => {
  const requestPath = `vault/columns/suggest`
  return await Services.Backend.Post<SuggestedReviewColumn>(
    requestPath,
    { question },
    {
      throwOnError: true,
      maxRetryCount: 1,
      signal,
    }
  )
}

// TEMP: eventually use OpenAPI type for this
export type SuggestedReviewColumn = {
  fullText: string
  header: string
  dataType: ColumnDataType
  options?: string[]
  displayId: string
}

const BuildReviewColumns = async (question: string) => {
  const requestPath = `vault/columns/build`
  const response = await Services.Backend.Post<SuggestedReviewColumn[]>(
    requestPath,
    { question },
    { throwOnError: true }
  )
  return response
}

interface EditReviewCellParams {
  eventId: number
  vaultFolderId: string
  fileId: string
  questionId: string
  text: string
  isLong?: boolean
}

async function EditReviewCell(
  params: EditReviewCellParams
): Promise<ReviewCell> {
  const { eventId, fileId, questionId, text } = params
  const url = `vault/event/${eventId}/edit_cell`

  const response = await Services.Backend.Patch<ReviewCell>(
    url,
    {
      text,
      column_id: questionId,
      file_id: fileId,
    },
    { throwOnError: true }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

async function VerifyReviewCell(eventId: number, cellId: string) {
  const requestPath = `vault/event/${eventId}/verify_cell`
  const response = await Services.Backend.Post<ReviewCell>(
    requestPath,
    { cellId },
    { throwOnError: true }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

async function ApproveReviewCell(eventId: number, cellId: string) {
  const requestPath = `vault/event/${eventId}/approve_cell`
  const response = await Services.Backend.Post<ReviewCell>(
    requestPath,
    { cellId },
    { throwOnError: true }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

export {
  FetchVaultSetup,
  FetchVaultProjects,
  FetchVaultExampleProjects,
  SetVaultExampleProject,
  UnsetVaultExampleProject,
  FetchVaultFolder,
  SetVaultFolderLastOpened,
  FetchVaultFoldersMetadata,
  FetchVaultProjectMetadata,
  FetchVaultProjectsMetadata,
  CreateVaultFolder,
  UpdateDraftWorkflow,
  FetchVaultFile,
  FetchVaultFiles,
  FetchJobQueueEta,
  PatchFolder,
  BulkPatchFiles,
  PatchFile,
  BulkDeleteVaultFiles,
  DeleteVaultFolder,
  RetryFiles,
  ClearQueryErrors,
  FetchVaultHistoryByPage,
  FetchVaultHistoryByPageV2,
  CancelVaultHistoryItem,
  ReorderVaultReviewQueryColumns,
  RerunVaultReviewQueries,
  FetchVaultFolderHistoryStats,
  SemanticSearch,
  FetchVaultFolderShareStatus,
  ShareVaultFolder,
  UpdateVaultFolderShare,
  UnshareVaultFolder,
  UpdateVaultFileTags,
  BulkUpdateVaultFileTags,
  SubscribeToVaultFolderUpdates,
  SubscribeToVaultSharingUpdates,
  FetchVaultWorkflows,
  FetchVaultReviewQuery,
  CreateVaultReviewQuery,
  CreateDraftWorkflow,
  PublishWorkflow,
  UnpublishWorkflow,
  DeleteDraftVaultWorkflow,
  UpdateReviewQuery,
  UpdateReviewColumn,
  DeleteReviewColumn,
  DeleteReviewRow,
  FetchSuggestedReviewColumn,
  BuildReviewColumns,
  FetchReviewRow,
  FetchReviewRows,
  EditReviewCell,
  VerifyReviewCell,
  ApproveReviewCell,
}
