import * as React from 'react'

import {
  FileType,
  FileTypeReadableName,
  removeSubsetDuplicates,
} from 'types/file'

import { useDropzoneWithNetdocsSupport } from 'hooks/use-dropzone-with-netdocs'
import { bytesToReadable } from 'utils/file-utils'

import VaultKnowledgeSourcePicker from 'components/assistant/features/composer/assistant-vault-ks-picker'
import { useAssistantKsOptions } from 'components/assistant/hooks/use-assistant-ks-options'
import { KnowledgeSourceItem } from 'components/assistant/utils/assistant-knowledge-sources'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import KsInputDropdown from 'components/common/ks-input-dropdown'
import { Button } from 'components/ui/button'
import { DropdownMenuSeparator } from 'components/ui/dropdown-menu'

interface FileDropzoneProps {
  isLoading: boolean
  isDisabled: boolean
  acceptedFileMimeTypes: string[]
  maxFileSizeBytes: number
  maxFileCount: number
  chooseFilesDropdownOptions: ReturnType<
    typeof useAssistantKsOptions
  >['chooseFilesDropdownOptions']
  dropdownOpen: boolean
  setDropdownOpen: (open: boolean) => void
  getRootProps: ReturnType<typeof useDropzoneWithNetdocsSupport>['getRootProps']
  getInputProps: ReturnType<
    typeof useDropzoneWithNetdocsSupport
  >['getInputProps']
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  showVaultDialog: boolean
  setShowVaultDialog: (showVaultDialog: boolean) => void
  knowledgeSource: KnowledgeSourceItem | null
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  isLoading,
  isDisabled,
  acceptedFileMimeTypes,
  maxFileSizeBytes,
  maxFileCount,
  chooseFilesDropdownOptions,
  dropdownOpen,
  setDropdownOpen,
  getRootProps,
  getInputProps,
  setKnowledgeSource,
  showVaultDialog,
  setShowVaultDialog,
  knowledgeSource,
}) => {
  return (
    <div>
      <Dropzone
        className="h-60 rounded-md bg-primary"
        isLoading={isLoading}
        isDisabled={isDisabled}
        description={
          <DropzoneDescription
            fileTypes={acceptedFileMimeTypes as FileType[]}
            maxSize={bytesToReadable(maxFileSizeBytes!)}
          >
            <KsInputDropdown
              triggerComponent={
                <Button
                  size="sm"
                  className="mt-2"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  id="file-upload-choose-file-button"
                >
                  Choose file
                </Button>
              }
              dropdownItems={chooseFilesDropdownOptions}
              dropdownOpen={dropdownOpen}
              setDropdownOpen={setDropdownOpen}
              positionSide="top"
              positionAlign="center"
            >
              <DropdownMenuSeparator className="-mx-2 my-2" />
              <p className="line-clamp-2 max-w-64 p-2 text-xs text-muted">
                Supported file types:{' '}
                {removeSubsetDuplicates(
                  (acceptedFileMimeTypes as FileType[]).map(
                    (fileType) => FileTypeReadableName[fileType]
                  )
                )
                  .sort((a, b) => a.localeCompare(b))
                  .join(', ')}
              </p>
            </KsInputDropdown>
          </DropzoneDescription>
        }
        dropzone={{ getRootProps, getInputProps }}
      />
      <VaultKnowledgeSourcePicker
        onClose={() => setKnowledgeSource(null)}
        showDialog={showVaultDialog}
        setShowDialog={setShowVaultDialog}
        knowledgeSource={knowledgeSource}
        setKnowledgeSource={setKnowledgeSource}
        numberOfFilesLimit={maxFileCount}
      />
    </div>
  )
}

export default FileDropzone
