import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { ArrowUpRight, DownloadIcon } from 'lucide-react'

import { EventStatus } from 'openapi/models/EventStatus'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'

import { Source } from 'utils/task'
import { cn } from 'utils/utils'

import { useFetchWorkflowDraft } from 'components/assistant/hooks/use-assistant-fetch-workflow-draft'
import { runMessageDiff } from 'components/assistant/utils/assistant-message-diff'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
  AssistantWorkflowSourceExtractor,
} from 'components/assistant/workflows'
import AnswerBlockToolbar, {
  AnswerBlockToolbarAction,
} from 'components/assistant/workflows/components/answer-block-toolbar/answer-block-toolbar'
import { AssistantWorkflowSidebar } from 'components/assistant/workflows/components/assistant-workflow-sidebar'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import RenderBlockToolbar from 'components/assistant/workflows/components/render-block-toolbar/render-block-toolbar'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import { useToolbarActions } from 'components/assistant/workflows/hooks/use-toolbar-actions'
import { useWorkflowAnalytics } from 'components/assistant/workflows/hooks/use-workflow-analytics'
import { inActiveCitationsLoadingState } from 'components/assistant/workflows/utils/utils'
import Markdown from 'components/common/markdown/markdown'
import { Button } from 'components/ui/button'
import { Switch } from 'components/ui/switch'

import {
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadBlock,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowDraftRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.DRAFT
> = ({
  blockParams,
  loadingStates,
  onDownload,
  workflowName,
  paramStatus,
  feedback,
  stepId,
  stepIdx,
  exportComponents,
  onRegenerate,
}) => {
  const { id: workflowId, eventId: eventIdParam } = useParams()
  const trackEvent = useWorkflowAnalytics()
  const draftRef = React.useRef<HTMLDivElement>(null)
  const [showEdits, setShowEdits] = React.useState(false)
  const { draftId, draftRevision } = blockParams

  const buttonDisabled = paramStatus !== EventStatus.COMPLETED
  const { data } = useFetchWorkflowDraft(buttonDisabled ? undefined : draftId)

  const currentRevision = draftRevision.response
  const firstRevision = useMemo(() => {
    const revisions = data?.revisions || []
    // always use the first revision
    return revisions[0]?.sensitiveMeta?.response ?? ''
  }, [data])

  // Get the revision ID for caching
  const currentRevisionId = data?.revisions.find(
    (revision) => revision.isCurrentRevision
  )?.id

  const content = useMemo(() => {
    if (showEdits && currentRevisionId) {
      return runMessageDiff(currentRevision, firstRevision, currentRevisionId)
    }
    return currentRevision
  }, [currentRevision, firstRevision, showEdits, currentRevisionId])

  const { getHrvyInfoMetadata, handleSourceClick } = useSourceUtils({
    sources: draftRevision.sources as Source[],
  })
  const { tryDownload } = useToolbarActions({
    onDownload,
    metadata: draftRevision.metadata,
    workflowName,
    content,
  })

  const draftUrl = `/assistant/workflows/draft/${workflowId}/${eventIdParam}/${draftId}`

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (buttonDisabled) {
      e.preventDefault()
    } else {
      trackEvent('Workflow Draft Viewed', {
        draft_id: draftId,
        step_idx: stepIdx,
        workflow_name: workflowName,
      })
    }
  }

  const toolbarActions: AnswerBlockToolbarAction[] = [
    {
      component: (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          className="mr-3 flex items-center gap-2"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <span className="text-sm text-muted">Show edits</span>
          <Switch
            checked={showEdits}
            onCheckedChange={setShowEdits}
            disabled={
              buttonDisabled || (data?.revisions && data.revisions.length < 2)
            }
            aria-label="Toggle edit visibility"
          />
        </div>
      ),
      tooltip: 'Toggle edit visibility',
      onClick: (e) => {
        e.stopPropagation()
        e.preventDefault()
      },
    },
    {
      icon: DownloadIcon,
      tooltip: 'Download',
      onClick: tryDownload,
      disabled: buttonDisabled,
    },
    {
      component: (
        <Button
          variant="secondary"
          className="flex items-center gap-1 bg-transparent pl-1.5 pr-0.5"
          tooltip="Edit draft"
          disabled={buttonDisabled}
          id="assistant-workflow-draft-edit-button"
        >
          <span className="text-sm text-muted">Edit</span>
          <ArrowUpRight className="size-4 text-muted" />
        </Button>
      ),
      tooltip: 'View Draft',
      to: draftUrl,
      disabled: buttonDisabled,
    },
  ]

  const isCompleted = paramStatus === EventStatus.COMPLETED

  const Sidebar = (inActiveCitationsLoadingState(loadingStates) ||
    draftRevision.sources.length > 0) && (
    <AssistantWorkflowSidebar
      sources={draftRevision.sources as Source[]}
      isStreaming={!isCompleted}
      onSetActiveFileId={handleSourceClick}
    />
  )

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <LoadingState
          isCompleted={isCompleted}
          paramStatus={paramStatus}
          states={loadingStates}
        />
        {content.length > 0 && (
          <Button
            variant="unstyled"
            className={cn(
              'group mt-5 h-auto w-full max-w-full p-0',
              buttonDisabled && 'cursor-not-allowed'
            )}
            to={draftUrl}
            onClick={handleClick}
            data-testid="assistant-workflow-draft-container"
          >
            <div
              ref={draftRef}
              className="relative rounded-lg border bg-primary transition group-hover:bg-secondary"
            >
              <AnswerBlockToolbar title="Draft" actions={toolbarActions} />
              <Markdown
                content={content}
                getHrvyInfoMetadata={getHrvyInfoMetadata}
                className="p-6"
                width="100%"
              />
            </div>
          </Button>
        )}
        {isCompleted && (
          <div className="mt-6">
            <RenderBlockToolbar
              content={content}
              feedback={feedback}
              workflowStepId={stepId ?? ''}
              exportComponents={exportComponents}
              onRegenerate={onRegenerate}
            />
          </div>
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowDraftRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.DRAFT
> = ({ blockParams, paramStatus, onCancel }) => {
  const headerText = blockParams.draftRevision.headerText
  const isStreaming = paramStatus !== 'COMPLETED'
  const renderedText = isStreaming ? 'Working…' : headerText

  return (
    <WorkflowInput>
      <WorkflowInputFooter
        footer={renderedText}
        onCancel={isStreaming ? onCancel : undefined}
      />
    </WorkflowInput>
  )
}

export const AssistantWorkflowDraftRendererExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowRenderComponentBlocks.DRAFT
> = ({ blockParams }) => {
  const content = blockParams.draftRevision.response
  return <Markdown content={content} />
}

export const draftSourceExtractor: AssistantWorkflowSourceExtractor<
  typeof WorkflowRenderComponentBlocks.DRAFT
> = (blockParams) => {
  return blockParams.draftRevision.sources as Source[]
}
