import { useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { VaultFolder } from 'openapi/models/VaultFolder'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage } from 'utils/toast'

import { BaseAppPath } from 'components/base-app-path'
import { projectsPath, REMOVE_PARAMS } from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'

import { createProject } from './create-project-helpers'

export const useCreateProject = ({
  shouldNavigateToProject,
  isKnowledgeBaseProject,
  onClick,
  userId,
}: {
  shouldNavigateToProject: boolean
  isKnowledgeBaseProject: boolean
  userId: string
  onClick?: (createdProject: VaultFolder | null) => void
}) => {
  const [isCreatingProject, setIsCreatingProject] = useState(false)
  const navigate = useNavigateWithQueryParams()
  const [upsertVaultFolders, setCurrentProject] = useVaultStore(
    useShallow((state) => [state.upsertVaultFolders, state.setCurrentProject])
  )

  const createProjectHandler = async () => {
    setIsCreatingProject(true)
    let hierarchyRootFolder: VaultFolder | null = null
    try {
      hierarchyRootFolder = await createProject({
        folderName: 'Untitled',
        parentId: null,
        clientMatterId: null,
        isKnowledgeBaseProject,
        upsertVaultFolders: upsertVaultFolders,
        userId,
      })
      if (shouldNavigateToProject) {
        setCurrentProject(hierarchyRootFolder, true)
        const newPath = `${BaseAppPath.Vault}${projectsPath}${hierarchyRootFolder.id}`
        navigate(newPath, {}, REMOVE_PARAMS)
      }
      onClick?.(hierarchyRootFolder)
    } catch (error) {
      displayErrorMessage('Failed to create project')
    }
    setIsCreatingProject(false)
  }

  return {
    isCreatingProject,
    createProjectHandler,
  }
}
