import React, { useEffect, useMemo, useState } from 'react'
import { useMount, useUnmount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { ProjectMetadata } from 'openapi/models/ProjectMetadata'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { PermissionLevel } from 'types/sharing'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { doesUserHavePermission } from 'utils/sharing-helpers'
import { displayErrorMessage } from 'utils/toast'

import {
  isVaultKnowledgeSource,
  KnowledgeSourceItem,
  VAULT_SOURCE_SELECT_FILES_DESCRIPTION,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { Spinner } from 'components/ui/spinner'
import { createProject } from 'components/vault/components/new-project/create-project-helpers'
import { DEFAULT_PROJECT_CARD_HEIGHT } from 'components/vault/components/new-project/create-project-helpers'
import { VaultCreateProjectCard } from 'components/vault/components/new-project/vault-create-project-card'
import VaultProjectCard from 'components/vault/components/vault-project-card'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { useVaultProjectsMetadata } from 'components/vault/utils/use-vault-project-metadata'
import { projectsPath } from 'components/vault/utils/vault'
import { REMOVE_PARAMS } from 'components/vault/utils/vault'
import { FetchVaultFolder } from 'components/vault/utils/vault-fetcher'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

import AssistantProjectFileSelector from './assistant-project-file-selector'

interface Props {
  knowledgeSource: KnowledgeSourceItem | null
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  showDialog: boolean
  setShowDialog: (showDialog: boolean) => void
  onClose: () => void
  numberOfFilesLimit?: number
  numberOfFilesWarningThreshold?: number
}

const VaultKnowledgeSourcePicker = ({
  knowledgeSource,
  setKnowledgeSource,
  showDialog,
  setShowDialog,
  onClose,
  numberOfFilesLimit,
  numberOfFilesWarningThreshold,
}: Props) => {
  const { allVaultProjects, isFetchingProjects, hasLoadedProjects } =
    useVaultProjects({ includeExampleProjects: false })
  const [vaultProjectId, setVaultProjectId, setFileUploadSource] =
    useAssistantWorkflowStore(
      useShallow((s) => [
        s.vaultProjectId,
        s.setVaultProjectId,
        s.setFileUploadSource,
      ])
    )
  const [currentProject, setCurrentProject] = useVaultStore(
    useShallow((s) => [s.currentProject, s.setCurrentProject])
  )

  const isVaultSource = isVaultKnowledgeSource(knowledgeSource)

  const activeFileIds = useMemo(
    () => new Set(isVaultSource ? knowledgeSource.fileIds : []),
    [knowledgeSource, isVaultSource]
  )

  const handleClose = () => {
    setKnowledgeSource(null)
    setCurrentProject(null)
    onClose()
  }

  useUnmount(() => {
    setCurrentProject(null)
  })

  const handleSelectProject = (project: VaultFolder) => {
    setCurrentProject(project)
  }

  useEffect(() => {
    if (vaultProjectId) {
      const fetchVaultFolder = async () => {
        const vaultFolder = await FetchVaultFolder(vaultProjectId)
        setCurrentProject(vaultFolder)
      }
      void fetchVaultFolder()
    } else {
      setCurrentProject(null)
    }
  }, [vaultProjectId, setCurrentProject])

  const handleResetFolderOverride = () => {
    const hasBeenReset = setVaultProjectId(null)
    if (!hasBeenReset) {
      return
    }
    setFileUploadSource(null)
    setCurrentProject(null)
  }

  const isEmpty = hasLoadedProjects && allVaultProjects.length === 0
  const dialogTitle = currentProject
    ? currentProject.isKnowledgeBaseProject
      ? `Add folders from ${currentProject.name}`
      : `Add files from ${currentProject.name}`
    : 'Choose project'
  const dialogDescription = currentProject
    ? currentProject.isKnowledgeBaseProject
      ? 'Select folders from this knowledge base to query'
      : VAULT_SOURCE_SELECT_FILES_DESCRIPTION
    : 'Select a Vault project to browse files'
  const isCurrentProjectKnowledgeBase = currentProject?.isKnowledgeBaseProject

  return (
    <AssistantProjectFileSelector
      showDialog={showDialog}
      setShowDialog={setShowDialog}
      onClose={handleClose}
      isEmpty={isEmpty}
      dialogTitle={dialogTitle}
      dialogDescription={dialogDescription}
      isFetching={isFetchingProjects}
      allVaultProjects={allVaultProjects}
      activeFileIds={activeFileIds}
      numberOfFilesLimit={numberOfFilesLimit}
      numberOfFilesWarningThreshold={numberOfFilesWarningThreshold}
      emptyState={<EmptyProjects />}
      projectChooser={
        <ProjectChooser
          isLoaded={hasLoadedProjects}
          onSelectProject={handleSelectProject}
          projects={allVaultProjects}
        />
      }
      setKnowledgeSource={setKnowledgeSource}
      isCurrentProjectKnowledgeBase={isCurrentProjectKnowledgeBase}
      handleResetFolderOverride={handleResetFolderOverride}
    />
  )
}

export default VaultKnowledgeSourcePicker

export const ProjectChooser = ({
  isLoaded,
  onSelectProject,
  projects,
  shouldShowCreateProjectCard = true,
  shouldAllowKnowledgeBaseProjects = true,
  currentProjectId,
}: {
  isLoaded: boolean
  onSelectProject: (project: VaultFolder) => void
  projects: VaultFolder[]
  shouldShowCreateProjectCard?: boolean
  shouldAllowKnowledgeBaseProjects?: boolean
  currentProjectId?: string
}) => {
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()
  const sharedProjectIds = useVaultStore((s) => s.sharedProjectIds)
  const currentUserPermissionByProjectId = useVaultSharingStore(
    useShallow((s) => s.currentUserPermissionByProjectId)
  )
  const isLoadingProjects = !isLoaded && !projects.length
  const { projectsMetadata, isLoadingProjectsMetadata } =
    useVaultProjectsMetadata(projects.map((project) => project.id))
  const currentProjectIndex = useMemo(
    () =>
      !currentProjectId
        ? -1
        : projects.findIndex((p) => p.id === currentProjectId),
    [projects, currentProjectId]
  )
  const currentProject = useMemo(() => {
    if (currentProjectIndex < 0) return null
    return projects[currentProjectIndex]
  }, [projects, currentProjectIndex])
  const otherProjects = useMemo(() => {
    const unsortedProjects =
      currentProjectIndex < 0
        ? projects
        : [
            ...projects.slice(0, currentProjectIndex),
            ...projects.slice(currentProjectIndex + 1),
          ]

    const sortedProjects = unsortedProjects.sort((a, b) => {
      const doesCurrentUserHaveEditPermissionA = doesUserHavePermission({
        currentPermissionLevel: currentUserPermissionByProjectId[a.id],
        requiredPermissionLevel: PermissionLevel.EDIT,
        isOwner: a.userId === userInfo.dbId,
      })
      const doesCurrentUserHaveEditPermissionB = doesUserHavePermission({
        currentPermissionLevel: currentUserPermissionByProjectId[b.id],
        requiredPermissionLevel: PermissionLevel.EDIT,
        isOwner: b.userId === userInfo.dbId,
      })
      const isADisabled =
        !doesCurrentUserHaveEditPermissionA ||
        (!shouldAllowKnowledgeBaseProjects && a.isKnowledgeBaseProject)
      const isBDisabled =
        !doesCurrentUserHaveEditPermissionB ||
        (!shouldAllowKnowledgeBaseProjects && b.isKnowledgeBaseProject)

      // If one is disabled and the other isn't, put the disabled one last
      if (isADisabled && !isBDisabled) return 1
      if (!isADisabled && isBDisabled) return -1

      // If both are enabled or both are disabled, sort by knowledge base status
      return a.isKnowledgeBaseProject !== b.isKnowledgeBaseProject
        ? a.isKnowledgeBaseProject
          ? -1
          : 1
        : new Date(b.projectUpdatedAt ?? b.updatedAt).getTime() -
            new Date(a.projectUpdatedAt ?? a.updatedAt).getTime()
    })
    return sortedProjects
  }, [
    projects,
    currentProjectIndex,
    shouldAllowKnowledgeBaseProjects,
    currentUserPermissionByProjectId,
    userInfo.dbId,
  ])

  const createProjectCard = (project: VaultFolder) => {
    return (
      <ProjectCard
        key={project.id}
        project={project}
        isLoadingMetadata={
          isLoadingProjectsMetadata || !projectsMetadata?.[project.id]
        }
        projectMetadata={projectsMetadata?.[project.id]}
        onClick={() => {
          onSelectProject(project)
          trackEvent('Vault File Selector Viewed', {
            source: 'Assistant',
          })
        }}
        isShared={sharedProjectIds.has(project.id)}
        shouldAllowKnowledgeBaseProjects={shouldAllowKnowledgeBaseProjects}
      />
    )
  }
  return (
    <div className="flex flex-col items-start">
      {currentProject && (
        <>
          <p className="px-6 pb-2 text-sm font-medium">Current project</p>
          <div className="grid w-full grid-cols-1 gap-3 p-6 pt-1 sm:grid-cols-3">
            {createProjectCard(currentProject)}
          </div>
          {otherProjects.length > 0 && (
            <p className="px-6 pb-2 text-sm font-medium">Other projects</p>
          )}
        </>
      )}
      <div className="grid w-full grid-cols-1 gap-3 p-6 pt-1 sm:grid-cols-3">
        {otherProjects.map((project) => createProjectCard(project))}
        {!isLoadingProjects && shouldShowCreateProjectCard && (
          <VaultCreateProjectCard height={DEFAULT_PROJECT_CARD_HEIGHT} />
        )}
        {isLoadingProjects &&
          [...new Array(4)].map((_, i) => (
            <ProjectCard
              key={i}
              project={{ id: `vault-placeholder-${i}` } as VaultFolder}
              isLoadingMetadata
              projectMetadata={undefined}
              isLoading
            />
          ))}
      </div>
    </div>
  )
}

export const EmptyProjects = () => {
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const [isCreatingProject, setIsCreatingProject] = useState(false)
  const [upsertVaultFolders, setCurrentProject] = useVaultStore(
    useShallow((state) => [state.upsertVaultFolders, state.setCurrentProject])
  )

  useMount(() => {
    trackEvent('No Vault Projects Dialog Shown')
  })

  const handleNewClick = async () => {
    trackEvent('Vault New Project Button Clicked', {
      source: 'use-vault-in-assistant',
    })
    setIsCreatingProject(true)
    try {
      const hierarchyRootFolder = await createProject({
        folderName: 'Untitled',
        parentId: null,
        clientMatterId: null,
        isKnowledgeBaseProject: false,
        userId: userInfo.dbId,
        upsertVaultFolders: upsertVaultFolders,
      })
      setCurrentProject(hierarchyRootFolder, true)
      const newPath = `${BaseAppPath.Vault}${projectsPath}${hierarchyRootFolder.id}`
      navigate(newPath, {}, REMOVE_PARAMS)
    } catch (error) {
      displayErrorMessage('Failed to create project')
    }
    setIsCreatingProject(false)
  }

  return (
    <div className="flex flex-col items-center px-6 py-12 text-sm">
      <FolderShieldIcon className="size-6 shrink-0" />
      <div className="my-2 text-lg font-semibold">
        Create a project to get started
      </div>
      <p className="mb-4 max-w-md text-center text-muted">
        Vault allows you to upload and store files and perform sophisticated
        analysis and search across them.
      </p>
      <Button isLoading={isCreatingProject} onClick={handleNewClick}>
        New project
      </Button>
    </div>
  )
}

const ProjectCard = ({
  project,
  isLoadingMetadata,
  projectMetadata,
  onClick,
  isLoading,
  isShared,
  shouldAllowKnowledgeBaseProjects,
}: {
  project: VaultFolder
  isLoadingMetadata: boolean
  projectMetadata?: ProjectMetadata
  onClick?: () => void
  isLoading?: boolean
  isShared?: boolean
  shouldAllowKnowledgeBaseProjects?: boolean
}) => {
  const userInfo = useAuthUser()

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: project.id,
  })

  const canUserUseProjectAsKnowledgeSource =
    doesCurrentUserHaveEditPermission ||
    (project.isKnowledgeBaseProject && userInfo.IsKnowledgeBaseProjectUser)
  const disabled =
    isLoading ||
    !canUserUseProjectAsKnowledgeSource ||
    (!shouldAllowKnowledgeBaseProjects && project.isKnowledgeBaseProject)
  const disabledTooltip = !canUserUseProjectAsKnowledgeSource
    ? 'You do not have permission to run queries on this project'
    : !shouldAllowKnowledgeBaseProjects && project.isKnowledgeBaseProject
    ? 'Cannot use a knowledge base to create Review tables. Save your desired files into a new Vault project.'
    : undefined

  return (
    <VaultProjectCard
      vaultProject={project}
      isShared={isShared}
      isLoading={isLoading}
      isLoadingMetadata={isLoadingMetadata}
      projectMetadata={projectMetadata}
      onClick={onClick}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
    />
  )
}

export const FetchingSpinner = ({ delayMs }: { delayMs: number }) => {
  const [isVisible, setIsVisible] = useState(!delayMs)

  useEffect(() => {
    if (!delayMs) return

    // Show spinner if request > 1 second
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, delayMs)

    return () => clearTimeout(timer)
  })

  if (!isVisible) return null

  return <Spinner size="xxs" className="ml-3" />
}
