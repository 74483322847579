import { DestinationLanguageCode } from 'openapi/models/DestinationLanguageCode'
import { Language } from 'openapi/models/Language'

export const TRANSLATION_OPTIONS: Record<string, Language> = {
  [DestinationLanguageCode.AR]: {
    code: DestinationLanguageCode.AR,
    value: 'Arabic',
    label: 'Arabic',
    isRtl: true,
  },
  [DestinationLanguageCode.YUE]: {
    code: DestinationLanguageCode.YUE,
    label: 'Cantonese',
    value: 'Cantonese',
  },
  [DestinationLanguageCode.DA]: {
    code: DestinationLanguageCode.DA,
    label: 'Danish',
    value: 'Danish',
  },
  [DestinationLanguageCode.NL]: {
    code: DestinationLanguageCode.NL,
    label: 'Dutch',
    value: 'Dutch',
  },
  [DestinationLanguageCode.EN]: {
    code: DestinationLanguageCode.EN,
    label: 'English',
    value: 'English',
  },
  [DestinationLanguageCode.FI]: {
    code: DestinationLanguageCode.FI,
    label: 'Finnish',
    value: 'Finnish',
  },
  [DestinationLanguageCode.FR]: {
    code: DestinationLanguageCode.FR,
    label: 'French',
    value: 'French',
  },
  [DestinationLanguageCode.DE]: {
    code: DestinationLanguageCode.DE,
    label: 'German',
    value: 'German',
  },
  [DestinationLanguageCode.GU]: {
    code: DestinationLanguageCode.GU,
    label: 'Gujarati',
    value: 'Gujarati',
  },
  [DestinationLanguageCode.HE]: {
    code: DestinationLanguageCode.HE,
    label: 'Hebrew',
    value: 'Hebrew',
    isRtl: true,
  },
  [DestinationLanguageCode.HI]: {
    code: DestinationLanguageCode.HI,
    label: 'Hindi',
    value: 'Hindi',
  },
  [DestinationLanguageCode.IT]: {
    code: DestinationLanguageCode.IT,
    label: 'Italian',
    value: 'Italian',
  },
  [DestinationLanguageCode.JA]: {
    code: DestinationLanguageCode.JA,
    label: 'Japanese',
    value: 'Japanese',
  },
  [DestinationLanguageCode.KO]: {
    code: DestinationLanguageCode.KO,
    label: 'Korean',
    value: 'Korean',
  },
  [DestinationLanguageCode.MS]: {
    code: DestinationLanguageCode.MS,
    label: 'Malay',
    value: 'Malay',
  },
  [DestinationLanguageCode.MR]: {
    code: DestinationLanguageCode.MR,
    label: 'Marathi',
    value: 'Marathi',
  },
  [DestinationLanguageCode.PL]: {
    code: DestinationLanguageCode.PL,
    label: 'Polish',
    value: 'Polish',
  },
  [DestinationLanguageCode.PT_BR]: {
    code: DestinationLanguageCode.PT_BR,
    label: 'Portuguese (Brazil)',
    value: 'Brazilian Portuguese',
  },
  [DestinationLanguageCode.PT_PT]: {
    code: DestinationLanguageCode.PT_PT,
    label: 'Portuguese (Portugal)',
    value: 'European Portuguese',
  },
  [DestinationLanguageCode.RO]: {
    code: DestinationLanguageCode.RO,
    label: 'Romanian',
    value: 'Romanian',
  },
  [DestinationLanguageCode.RU]: {
    code: DestinationLanguageCode.RU,
    label: 'Russian',
    value: 'Russian',
  },
  [DestinationLanguageCode.SR]: {
    code: DestinationLanguageCode.SR,
    label: 'Serbian',
    value: 'Serbian',
  },
  [DestinationLanguageCode.ZH_CN]: {
    code: DestinationLanguageCode.ZH_CN,
    label: 'Simplified Chinese',
    value: 'Simplified Chinese',
  },
  [DestinationLanguageCode.ES_ES]: {
    code: DestinationLanguageCode.ES_ES,
    label: 'Spanish (Spain)',
    value: 'Spanish (Spain)',
  },
  [DestinationLanguageCode.ES_MX]: {
    code: DestinationLanguageCode.ES_MX,
    label: 'Spanish (Latin America)',
    value: 'Spanish (Latin America)',
  },
  [DestinationLanguageCode.SV]: {
    code: DestinationLanguageCode.SV,
    label: 'Swedish',
    value: 'Swedish',
  },
  [DestinationLanguageCode.TR]: {
    code: DestinationLanguageCode.TR,
    label: 'Turkish',
    value: 'Turkish',
  },
  [DestinationLanguageCode.VI]: {
    code: DestinationLanguageCode.VI,
    label: 'Vietnamese',
    value: 'Vietnamese',
  },
}
export function labelFromCode(code: string): string {
  const language = TRANSLATION_OPTIONS[code]
  if (!language) {
    throw new Error(`No matching label found for code: ${code}`)
  }
  return language.label
}
