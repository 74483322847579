import React from 'react'

import { BookOpen } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { VaultFolder } from 'openapi/models/VaultFolder'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { Spinner } from 'components/ui/spinner'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

import { useCreateProject } from './use-create-project'

const CardIcon = ({
  isKnowledgeBaseProject,
  isCreatingProject,
}: {
  isKnowledgeBaseProject: boolean
  isCreatingProject: boolean
}) => {
  if (isCreatingProject) {
    return <Spinner size="sm" />
  }

  if (isKnowledgeBaseProject) {
    return <Icon icon={BookOpen} size="large" className="text-muted" />
  }
  return <FolderShieldIcon className="size-6 text-muted" />
}

interface Props {
  shouldOpenDialog?: boolean
  shouldNavigateToProject?: boolean
  id?: string
  isKnowledgeBaseProject?: boolean
  className?: string
  height?: number
  size?: 'small' | 'default'
  source?: string // Source for analytics
  disabled?: boolean
  disabledTooltip?: string
  isCreatingProjectFromProps?: boolean
  onClick?: (createdProject: VaultFolder | null) => void
  setIsCreatingProject?: (isCreatingProject: boolean) => void
}

export const VaultCreateProjectCard = ({
  id,
  shouldOpenDialog = false,
  shouldNavigateToProject = true,
  isKnowledgeBaseProject = false,
  className,
  height,
  source,
  disabled,
  disabledTooltip,
  isCreatingProjectFromProps = false,
  setIsCreatingProject,
  onClick,
}: Props) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const [setCreateProjectDialogOpen] = useVaultStore(
    useShallow((s) => [s.setIsCreateProjectDialogOpen])
  )
  const [setIsCreatingKnowledgeBaseProject] = useVaultCreateProjectStore(
    useShallow((s) => [s.setIsCreatingKnowledgeBaseProject])
  )

  const projectFilesCountLimitDisplayText = `Each project can contain up to ${userInfo.workspace
    .getVaultFilesCountLimit(userInfo.vaultFeature)
    .toLocaleString()} files`

  const {
    createProjectHandler,
    isCreatingProject: isCreatingProjectFromUseCreateProject,
  } = useCreateProject({
    shouldNavigateToProject,
    isKnowledgeBaseProject,
    userId: userInfo.dbId,
    onClick,
  })

  const isCreatingProject =
    isCreatingProjectFromProps || isCreatingProjectFromUseCreateProject

  const onClickHandler = async () => {
    trackEvent('Vault New Project Button Clicked', {
      source: source ?? 'new-project-card',
      is_knowledge_base_project: isKnowledgeBaseProject,
    })
    if (shouldOpenDialog) {
      setCreateProjectDialogOpen(true)
      setIsCreatingKnowledgeBaseProject(isKnowledgeBaseProject)
    } else {
      setIsCreatingProject?.(true)
      await createProjectHandler()
      setIsCreatingProject?.(false)
    }
  }

  const cardHeader = (() => {
    if (isKnowledgeBaseProject) {
      return 'Create knowledge base'
    } else if (userInfo.IsCreateKnowledgeBaseProjectUser) {
      return 'Create project'
    } else {
      return 'New project'
    }
  })()

  const cardDescription = (() => {
    if (isKnowledgeBaseProject) {
      return 'Distribute a repository of files to your organization'
    } else if (userInfo.IsCreateKnowledgeBaseProjectUser) {
      return 'Upload a new collection of files'
    } else {
      return projectFilesCountLimitDisplayText
    }
  })()

  return (
    <Button
      id={id}
      variant="outline"
      disabled={disabled || isCreatingProject}
      tooltip={disabledTooltip}
      onClick={onClickHandler}
      data-testid="vault-new-project-card-link"
      // eslint-disable-next-line react/forbid-component-props
      style={{ height }}
      className={cn(
        'relative flex h-36 w-full flex-col items-start justify-between rounded-lg border p-4',
        { 'hover:cursor-pointer hover:border-input-focused': !disabled },
        className
      )}
    >
      <CardIcon
        isKnowledgeBaseProject={isKnowledgeBaseProject}
        isCreatingProject={isCreatingProject}
      />
      <div className="text-start">
        <p className="text-sm font-medium">{cardHeader}</p>
        <p className="text-xs text-muted">{cardDescription}</p>
      </div>
    </Button>
  )
}
