import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { DmsFile } from 'openapi/models/DmsFile'
import { FileContainerType } from 'openapi/models/FileContainerType'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'

import { SynclySettingsOpenState } from 'components/settings/integrations/syncly-utils'
import { ConnectedIntegrationToken } from 'components/settings/integrations/utils'

interface SharepointOpenState {
  acceptedFileTypes: string[]
  onUploadFromSharepoint: (files: File[]) => Promise<void>
}

interface IntegrationFilePickerOpenState {
  acceptedFileTypes: string[]
  onUploadFromIntegration?: (files: File[]) => Promise<void>
  onFilesSelectionFromIntegration?: (
    dmsFiles: DmsFile[],
    localFiles: File[]
  ) => Promise<void>
  onFileUploadToServer?: (uploadedFiles: UploadedFile[]) => void
  integrationType: IntegrationType
  maxFileCount?: number
  containerType?: FileContainerType // required for Syncly
  containerId?: string // used only for Vault for now
}

interface IntegrationsState {
  sharepointPickerOpenState: Maybe<SharepointOpenState>
  integrationFilePickerOpenState: Maybe<IntegrationFilePickerOpenState>
  tokens: { [key in IntegrationType]?: ConnectedIntegrationToken }
  synclySettingsOpenState: Maybe<SynclySettingsOpenState>
}

interface IntegrationsAction {
  setSharepointPickerOpenState: (
    sharepointPickerOpenState: Maybe<SharepointOpenState>
  ) => void
  setIntegrationToken: (
    integrationType: IntegrationType,
    token: ConnectedIntegrationToken
  ) => void
  getIntegrationToken: (
    integrationType: IntegrationType
  ) => Maybe<ConnectedIntegrationToken>
  clearIntegrationToken: (integrationType: IntegrationType) => void
  setIntegrationFilePickerOpenState: (
    integrationFilePickerOpenState: Maybe<IntegrationFilePickerOpenState>
  ) => void
  setSynclySettingsOpenState: (
    synclySettingsOpenState: Maybe<SynclySettingsOpenState>
  ) => void
}

export const useIntegrationsStore = create(
  devtools(
    immer<IntegrationsState & IntegrationsAction>((set) => ({
      sharepointPickerOpenState: null,
      integrationFilePickerOpenState: null,
      tokens: {},
      synclySettingsOpenState: null,
      setIntegrationToken: (integrationType, token) =>
        set((state) => {
          state.tokens[integrationType] = token
        }),
      getIntegrationToken: (
        integrationType
      ): Maybe<ConnectedIntegrationToken> =>
        useIntegrationsStore.getState().tokens[integrationType],
      clearIntegrationToken: (integrationType) =>
        set((state) => {
          delete state.tokens[integrationType]
        }),
      setSharepointPickerOpenState: (sharepointPickerOpenState) =>
        set({ sharepointPickerOpenState }),
      setIntegrationFilePickerOpenState: (integrationFilePickerOpenState) =>
        set({ integrationFilePickerOpenState }),
      setSynclySettingsOpenState: (synclySettingsOpenState) =>
        set({ synclySettingsOpenState }),
    }))
  )
)
