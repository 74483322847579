import React, { memo } from 'react'

import { Trash } from 'lucide-react'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

export const RowActions = memo(function RowActionsRender({
  canAddRemoveRows,
  handleRemoveRow,
  rowIdx,
}: {
  canAddRemoveRows: boolean | undefined
  handleRemoveRow: (rowIdx: number) => void
  rowIdx: number
}) {
  return (
    <div className="flex items-center gap-2">
      {canAddRemoveRows && (
        <Button
          className="shrink-0 transition hover:bg-secondary-hover"
          size="xsIcon"
          variant="ghost"
          aria-label="Remove row"
          onClick={() => handleRemoveRow(rowIdx)}
        >
          <Icon icon={Trash} size="small" variant="secondary" />
        </Button>
      )}
    </div>
  )
})
