import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { VaultItemWithIndex } from './vault'

export interface VaultFileExplorerState {
  selectedRows: VaultItemWithIndex[]
  isSearching: boolean
  isRequestingSemanticSearch: boolean
  isSemanticSearching: boolean
  searchValue: string
  numRowsFound: number
  isNavigatingThroughBreadcrumb: boolean
}

export interface VaultFileExplorerActions {
  setSelectedRows: (selectedRows: VaultItemWithIndex[]) => void
  setSearchValue: (newSearchValue: string) => void
  setIsSearching: (isSearching: boolean) => void
  setIsRequestingSemanticSearch: (isRequestingSemanticSearch: boolean) => void
  setIsSemanticSearching: (isSemanticSearching: boolean) => void
  setNumRowsFound: (numFound: number) => void
  clearSearchHandler: () => void
  setIsNavigatingThroughBreadcrumb: (
    isNavigatingThroughBreadcrumb: boolean
  ) => void
}

export const useVaultFileExplorerStore = create(
  devtools(
    immer<VaultFileExplorerState & VaultFileExplorerActions>((set) => ({
      selectedRows: [],
      isSearching: false,
      isRequestingSemanticSearch: false,
      isSemanticSearching: false,
      searchValue: '',
      numRowsFound: 0,
      isNavigatingThroughBreadcrumb: false,
      setSelectedRows: (selectedRows) => set({ selectedRows }),
      setSearchValue: (newSearchValue) => set({ searchValue: newSearchValue }),
      setIsSearching: (isSearching) => set({ isSearching }),
      setIsRequestingSemanticSearch: (isRequestingSemanticSearch) =>
        set({ isRequestingSemanticSearch }),
      setIsSemanticSearching: (isSemanticSearching) =>
        set({ isSemanticSearching }),
      setNumRowsFound: (numRowsFound) => set({ numRowsFound }),
      clearSearchHandler: () =>
        set({
          isSearching: false,
          isRequestingSemanticSearch: false,
          isSemanticSearching: false,
          searchValue: '',
          numRowsFound: 0,
        }),
      setIsNavigatingThroughBreadcrumb: (isNavigatingThroughBreadcrumb) =>
        set({ isNavigatingThroughBreadcrumb }),
    }))
  )
)
