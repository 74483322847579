// dropzone.ts
import { FileRejection } from 'react-dropzone'

import JSZip from 'jszip'
import _ from 'lodash'
import pluralize from 'pluralize'

import { DmsFile } from 'openapi/models/DmsFile'
import { FileFailureCategory } from 'openapi/models/FileFailureCategory'
import { PstFile } from 'openapi/models/PstFile'
import { PstFileResponse } from 'openapi/models/PstFileResponse'
import Services from 'services'
import {
  FileType,
  FileTypeReadableName,
  removeSubsetDuplicates,
} from 'types/file'
import { FileTypeToExtension } from 'types/file'

import { ACCEPTED_GOOGLE_DRIVE_NATIVE_FILE_TYPES } from 'components/vault/utils/vault'

import {
  isPstFile,
  isSpreadsheetFile,
  isTooBig,
  isValidZipFileExtension,
  isZipFile,
  removeSlashesInFileName,
  bytesToReadable,
  isPDFFilePasswordProtected,
} from './file-utils'
import { joinWithAnd } from './string'
import { displayErrorMessage, displayWarningMessage } from './toast'

export const DISPLAY_FILE_ERROR_COUNT = 3

interface MaxTotalFileSizeProps {
  maxTotalFileSize: number
  currentTotalFileSize: number
}

interface FileDropProps {
  acceptedFiles: File[]
  acceptedDmsFiles?: DmsFile[]
  fileRejections: FileRejection[]
  currentFileCount: number
  maxFiles: number
  acceptedFileTypes: FileType[]
  maxFileSize: number
  maxExcelFileSize?: number
  maxZipFileSize?: number
  maxTotalFileSizeProps?: MaxTotalFileSizeProps
  shouldSkipPasswordProtectionCheck?: boolean
  shouldShowIndividualFileErrorInToast?: boolean
  isBackendDmsDownloadUser?: boolean
  handleAcceptedFiles: (files: File[], dmsFiles?: DmsFile[]) => Promise<void>
  handleRejectedFiles?: () => void
  handlePasswordProtectedFiles?: (files: File[]) => void // optional new handler for password protected files
  handleRejectedFilesInToast?: (
    files: File[],
    failureCategory: FileFailureCategory
  ) => void
}

// NOTE: if we make any changes to validation functions, we should also update the BE Syncly validation
export const onDrop = async (params: FileDropProps) => {
  const {
    acceptedFiles,
    acceptedDmsFiles,
    fileRejections,
    currentFileCount,
    maxFiles,
    acceptedFileTypes,
    maxFileSize,
    maxExcelFileSize,
    maxZipFileSize,
    maxTotalFileSizeProps,
    shouldSkipPasswordProtectionCheck,
    shouldShowIndividualFileErrorInToast,
    isBackendDmsDownloadUser,
    handleAcceptedFiles,
    handleRejectedFiles = () => {},
    handlePasswordProtectedFiles,
    handleRejectedFilesInToast,
  } = params
  const isBackendDmsDownload = isBackendDmsDownloadUser ?? false
  const { maxTotalFileSize, currentTotalFileSize } = maxTotalFileSizeProps || {}

  // 1. Validate projected file count under project limit.
  const projectedNewFiles = isBackendDmsDownload
    ? fileRejections.length +
      acceptedFiles.length +
      (acceptedDmsFiles?.length || 0)
    : fileRejections.length + acceptedFiles.length
  const projectedTotalFiles = projectedNewFiles + currentFileCount
  if (projectedTotalFiles > maxFiles) {
    displayErrorMessage(
      `You can’t upload more than ${maxFiles} ${pluralize('files', maxFiles)}.`
    )
    handleRejectedFiles()
    return
  }

  // 2. Setup tracking vars...
  const files: File[] = []
  const dmsFiles: DmsFile[] = []
  const allAdditionalFiles: File[] = []
  const pstFiles: File[] = []
  const excelFilesToBig: string[] = []
  const filesTooBig: string[] = []
  const passwordProtectedFiles: File[] = []
  const invalidFileTypes: string[] = []
  const emptyFiles: string[] = []

  // 3. Extract zip/pst files, validate file sizes, sanitize names.
  // 3a. From local files
  await Promise.all(
    acceptedFiles.map(async (file) => {
      if (isZipFile(file)) {
        if (isTooBig(file, maxZipFileSize)) {
          filesTooBig.push(file.name)
        } else {
          let filesFromZip: { files: File[]; invalidFiles: File[] } = {
            files: [],
            invalidFiles: [],
          }
          try {
            filesFromZip = await getFilesFromZip(file)
          } catch (error) {
            displayErrorMessage(`${error}`)
            return
          }
          filesFromZip.files.forEach((f) => {
            allAdditionalFiles.push(f)
            if (isSpreadsheetFile(f) && isTooBig(f, maxExcelFileSize)) {
              excelFilesToBig.push(f.name)
            } else if (isPstFile(f)) {
              pstFiles.push(f) // TODO (george 3/31/25): Is this a bug? We do not check if .pst file is too big.
            } else if (isTooBig(f, maxFileSize)) {
              filesTooBig.push(f.name)
            } else {
              files.push(f)
            }
          })
          filesFromZip.invalidFiles.forEach((f) => {
            allAdditionalFiles.push(f)
            invalidFileTypes.push(f.name)
          })
        }
      } else if (isPstFile(file)) {
        if (isTooBig(file, maxZipFileSize)) {
          filesTooBig.push(file.name)
        } else {
          const filesFromPst = await getFilesFromPst(file)
          filesFromPst.files.forEach((f) => {
            allAdditionalFiles.push(f)
            if (isTooBig(f, maxFileSize)) {
              filesTooBig.push(f.name)
            } else {
              const sanitizedFileName = removeSlashesInFileName(f.name)
              files.push(new File([f], sanitizedFileName, { type: f.type }))
            }
          })
          filesFromPst.invalidFiles.forEach((f) => {
            allAdditionalFiles.push(f)
            invalidFileTypes.push(f.name)
          })
        }
      } else if (isSpreadsheetFile(file) && isTooBig(file, maxExcelFileSize)) {
        excelFilesToBig.push(file.name)
      } else if (isTooBig(file, maxFileSize)) {
        filesTooBig.push(file.name)
      } else {
        // For files uploaded from the file system, we should use the path as the file name
        const filePath = (file as any).path
        // For files uploaded from the web, we should remove slashes in the file name
        const sanitizedFileName = filePath || removeSlashesInFileName(file.name)
        files.push(new File([file], sanitizedFileName, { type: file.type }))
      }
    })
  )

  // 3b. From DMS files
  // TODO (george 3/31/25): Code duplication. Need unit tests to safely refactor.
  // NOTE: We know that there are no zip/pst files in acceptedDmsFiles by design.
  if (isBackendDmsDownload) {
    acceptedDmsFiles?.map((dmsFile) => {
      if (isSpreadsheetFile(dmsFile) && isTooBig(dmsFile, maxExcelFileSize)) {
        excelFilesToBig.push(dmsFile.name)
      } else if (isTooBig(dmsFile, maxFileSize)) {
        filesTooBig.push(dmsFile.name)
      } else {
        dmsFile.name = removeSlashesInFileName(dmsFile.name)
        dmsFiles.push(dmsFile)
      }
    })
  }

  // 4. Process pst files
  // if any of the files have a pst extension, we need to unpack them
  if (pstFiles.length > 0) {
    await Promise.all(
      pstFiles.map(async (file) => {
        const filesFromPst = await getFilesFromPst(file)

        filesFromPst.files.forEach((f) => {
          allAdditionalFiles.push(f)
          if (f.size > maxFileSize) {
            filesTooBig.push(f.name)
          } else {
            files.push(f)
          }
        })

        filesFromPst.invalidFiles.forEach((f) => {
          allAdditionalFiles.push(f)
          invalidFileTypes.push(f.name)
        })
      })
    )
  }

  // 5. Process incoming files rejections
  if (fileRejections.length > 0) {
    fileRejections.forEach((rejection) => {
      const file = rejection.file
      allAdditionalFiles.push(file)
      rejection.errors.forEach((error) => {
        if (error.code === 'file-too-large') {
          filesTooBig.push(file.name)
        } else if (error.code === 'file-invalid-type') {
          invalidFileTypes.push(file.name)
        }
      })
    })
  }

  // 6. Process individual files
  // 6a. From local files
  let totalFileSize = 0

  for (const file of files) {
    if (!shouldSkipPasswordProtectionCheck) {
      const isPasswordProtected = await isPDFFilePasswordProtected(file)
      if (isPasswordProtected) {
        passwordProtectedFiles.push(file)
      }
    }

    const isFileTypeAccepted = file.type
      ? (acceptedFileTypes as string[]).includes(file.type)
      : isValidZipFileExtension(file.name, acceptedFileTypes)

    if (!isFileTypeAccepted) {
      invalidFileTypes.push(file.name)
    }

    if (file.size === 0) {
      emptyFiles.push(file.name)
    }

    totalFileSize += file.size
  }

  // 6b. From DMS files
  if (isBackendDmsDownload) {
    const acceptedBeDownloadFileTypes: string[] = [
      ...acceptedFileTypes,
      ...ACCEPTED_GOOGLE_DRIVE_NATIVE_FILE_TYPES,
    ]
    for (const dmsFile of dmsFiles) {
      const isFileTypeAccepted = acceptedBeDownloadFileTypes.includes(
        dmsFile.type ?? ''
      )

      if (!isFileTypeAccepted) {
        invalidFileTypes.push(dmsFile.name)
      }

      if (dmsFile.size === 0) {
        emptyFiles.push(dmsFile.name)
      } else if (dmsFile.size) {
        totalFileSize += dmsFile.size
      }
    }
  }

  const passwordProtectedFileNames = passwordProtectedFiles.map(
    (file) => file.name
  )

  // 7. Validate total file count under project limit.
  const numAcceptedFiles = isBackendDmsDownload
    ? files.length + dmsFiles.length
    : files.length
  if (numAcceptedFiles + currentFileCount > maxFiles) {
    displayErrorMessage(
      `Upload failed. You can’t upload more than ${maxFiles} ${pluralize(
        'files',
        maxFiles
      )} at a time.`
    )
    handleRejectedFiles()
    return
  }

  // 8. Validate cumulative file size under project limit.
  if (
    !_.isNil(maxTotalFileSize) &&
    !_.isNil(currentTotalFileSize) &&
    totalFileSize + currentTotalFileSize >= maxTotalFileSize
  ) {
    displayErrorMessage(
      `Upload failed. You can’t upload more than a total of ${bytesToReadable(
        maxTotalFileSize,
        0
      )} at a time.`
    )
    handleRejectedFiles()
    return
  }

  // 9. Display errors
  if (!_.isNil(maxExcelFileSize) && excelFilesToBig.length > 0) {
    if (!shouldShowIndividualFileErrorInToast) {
      displayFileUploadError(
        excelFilesToBig,
        `Upload failed, max allowed Excel file size is ${bytesToReadable(
          maxExcelFileSize,
          0
        )}. The following files are too large: `,
        files.length > 0
      )
    }
  }

  if (filesTooBig.length > 0) {
    if (!shouldShowIndividualFileErrorInToast) {
      displayFileUploadError(
        filesTooBig,
        `Upload failed, max allowed file size is ${bytesToReadable(
          maxFileSize,
          0
        )}. The following files are too large: `,
        files.length > 0
      )
    }
  }

  if (passwordProtectedFiles.length > 0) {
    if (
      !_.isNil(handlePasswordProtectedFiles) &&
      _.isFunction(handlePasswordProtectedFiles)
    ) {
      handlePasswordProtectedFiles(passwordProtectedFiles)
    } else {
      if (!shouldShowIndividualFileErrorInToast) {
        displayFileUploadError(
          passwordProtectedFileNames,
          'Upload failed, the following files are password protected: ',
          files.length > 0
        )
      }
    }
  }

  if (invalidFileTypes.length > 0) {
    if (!shouldShowIndividualFileErrorInToast) {
      displayFileUploadError(
        invalidFileTypes,
        `One or more files you tried to upload failed because of an unsupported file type. Supported file types are ${joinWithAnd(
          removeSubsetDuplicates(
            acceptedFileTypes.map((fileType) => FileTypeReadableName[fileType])
          )
        )}. The following files have invalid file types: `,
        files.length > 0
      )
    }
  }

  if (emptyFiles.length > 0) {
    if (!shouldShowIndividualFileErrorInToast) {
      displayFileUploadError(
        emptyFiles,
        `Upload failed, one or more files you have uploaded are empty: `,
        files.length > 0
      )
    }
  }

  if (shouldShowIndividualFileErrorInToast && handleRejectedFilesInToast) {
    const allFiles = [...acceptedFiles, ...allAdditionalFiles]
    handleRejectedFilesInToast(
      allFiles.filter((f) => emptyFiles.includes(f.name)),
      FileFailureCategory.EMPTY_DOCUMENT
    )
    handleRejectedFilesInToast(
      allFiles.filter((f) => invalidFileTypes.includes(f.name)),
      FileFailureCategory.UPLOAD_INVALID_FILE_TYPE
    )
    handleRejectedFilesInToast(
      allFiles.filter((f) => filesTooBig.includes(f.name)),
      FileFailureCategory.UPLOAD_FILE_TOO_LARGE
    )
    handleRejectedFilesInToast(
      allFiles.filter((f) => excelFilesToBig.includes(f.name)),
      FileFailureCategory.UPLOAD_FILE_TOO_LARGE
    )
  }

  // 10. Filter out files that have errors.
  const unfilteredFiles = new Set([
    ...emptyFiles,
    ...passwordProtectedFileNames,
    ...invalidFileTypes,
    ...filesTooBig,
    ...excelFilesToBig,
  ])

  const filteredFiles = files
    .filter((file) => !unfilteredFiles.has(file.name))
    .map(
      (file) =>
        // VLT-3077: Convert filenames to NFC to match BE response format. This prevents duplicate file entries that occur
        // when FE stores names as NFD but BE returns them as NFC during optimistic updates
        new File([file], file.name.normalize('NFC'), {
          type: file.type,
          lastModified: file.lastModified,
        })
    )

  if (isBackendDmsDownload) {
    const filteredDmsFiles = dmsFiles.filter(
      (file) => !unfilteredFiles.has(file.name)
    )
    await handleAcceptedFiles(filteredFiles, filteredDmsFiles)
  } else {
    await handleAcceptedFiles(filteredFiles)
  }
}

const FILE_UPLOAD_ERROR_DURATION = 20

export const displayFileUploadError = (
  filenames: string[],
  error: string,
  acceptedSomeFiles: boolean
) => {
  let fileNames = filenames.slice(0, DISPLAY_FILE_ERROR_COUNT).join(', ')

  if (filenames.length > DISPLAY_FILE_ERROR_COUNT) {
    fileNames = fileNames.concat(
      ` and ${filenames.length - DISPLAY_FILE_ERROR_COUNT} more`
    )
  }

  if (acceptedSomeFiles) {
    displayWarningMessage(`${error} ${fileNames}`, FILE_UPLOAD_ERROR_DURATION)
  } else {
    displayErrorMessage(`${error} ${fileNames}`, FILE_UPLOAD_ERROR_DURATION)
  }
}

const IGNORE_FILE_PREFIXES = ['._', '__MACOSX']
const IGNORE_FILE_SUFFIXES = ['.DS_Store']

export const getFilesFromZip = async (
  zipFile: File,
  parentPath: string = ''
): Promise<{ files: File[]; invalidFiles: File[] }> => {
  const jszip = new JSZip()
  const data = await zipFile.arrayBuffer()
  const zip = await jszip.loadAsync(data)
  const invalidFiles: File[] = []
  const filePromises: Array<Promise<File | File[]>> = []
  const processedFiles = new Set<string>()

  // Only create basePath from zip filename if it's a nested zip (has a parentPath)
  const zipFileName = zipFile.name.split('.').slice(0, -1).join('.')
  const basePath = parentPath ? `${parentPath}/${zipFileName}` : ''

  zip.forEach((relativePath: string, zipObject: JSZip.JSZipObject) => {
    const fullPath = `${basePath}/${relativePath}`

    if (
      IGNORE_FILE_PREFIXES.some((prefix) => relativePath.startsWith(prefix)) ||
      IGNORE_FILE_SUFFIXES.some((suffix) => relativePath.endsWith(suffix))
    ) {
      return
    }

    if (zipObject.dir) {
      return
    }

    if (processedFiles.has(fullPath)) {
      return
    }
    processedFiles.add(fullPath)

    const filePromise = zipObject
      .async('blob')
      .then(async (content: BlobPart) => {
        const fileExtension = '.' + relativePath.toLowerCase().split('.').pop()

        if (['.zip', '.jar'].includes(fileExtension)) {
          const nestedZipFile = new File([content], relativePath, {
            type: 'application/zip',
          })

          const nestedResult = await getFilesFromZip(nestedZipFile, basePath)

          invalidFiles.push(...nestedResult.invalidFiles)

          return nestedResult.files
        } else {
          const match = Object.entries(FileTypeToExtension).find(
            ([, extensions]) => extensions.includes(fileExtension)
          )
          const type = (match?.at(0) || '') as string
          return new File([content], fullPath, { type })
        }
      })

    filePromises.push(filePromise)
  })

  // Resolve all promises and flatten the results
  const resolvedFiles = await Promise.all(filePromises)
  const files = resolvedFiles.flat()

  return { files, invalidFiles }
}

export const getFilesFromPst = async (
  pstFile: File
): Promise<{ files: File[]; invalidFiles: File[] }> => {
  const formData = new FormData()
  formData.append('file', pstFile)

  try {
    const response = await Services.Backend.Post<PstFileResponse>(
      'parse_pst',
      formData
    )
    // Convert the files unpacked from the PST into File objects
    const pstFileName = pstFile.name.replace('.pst', '')
    const files: File[] = (response.files ?? []).map((fileData: PstFile) => {
      const byteString = atob(fileData.content)

      // Create a typed array from the byte string
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // Create a blob from the typed array
      const blob = new Blob([ia], { type: fileData.mimeType })

      // Create and return a File object
      const relativePath = `${pstFileName}/${fileData.name}`
      return new File([blob], relativePath, { type: fileData.mimeType })
    })

    return { files, invalidFiles: [] }
  } catch (error) {
    return { files: [], invalidFiles: [] }
  }
}
