import React, { useState } from 'react'
import { useEffect } from 'react'
import { FileRejection } from 'react-dropzone'
import { useSearchParams } from 'react-router-dom'

import _ from 'lodash'
import pluralize from 'pluralize'
import { v4 as uuidv4 } from 'uuid'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'

import { uploadFile } from 'api'
import { DocumentKnowledgeSource } from 'openapi/models/DocumentKnowledgeSource'
import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { KnowledgeSource } from 'openapi/models/KnowledgeSource'
import { KnowledgeSourceType } from 'openapi/models/KnowledgeSourceType'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { WorkflowFileUploadInputBlockBlockParams } from 'openapi/models/WorkflowFileUploadInputBlockBlockParams'
import { WorkflowFileUploadInputBlockOutput } from 'openapi/models/WorkflowFileUploadInputBlockOutput'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { useFileCache } from 'stores/file-cache'
import { FileType, FileTypeReadableName } from 'types/file'

import { useDropzoneWithNetdocsSupport } from 'hooks/use-dropzone-with-netdocs'
import { onDrop } from 'utils/dropzone'
import { bytesToMb, bytesToReadable } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAssistantKsOptions } from 'components/assistant/hooks/use-assistant-ks-options'
import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import {
  FileSource,
  KnowledgeSourceItem,
  VaultKnowledgeSource,
} from 'components/assistant/utils/assistant-knowledge-sources'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import { AssistantWorkflowFilesComponent } from 'components/assistant/workflows/components/assistant-workflow-files-component'
import FileDropzone from 'components/assistant/workflows/components/file-upload-input/file-dropzone'
import FileList from 'components/assistant/workflows/components/file-upload-input/file-list'
import TextInput from 'components/assistant/workflows/components/text-input/text-input'
import { useSetViewingFile } from 'components/assistant/workflows/components/workflow-file-popover'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useWorkflowAnalytics } from 'components/assistant/workflows/hooks/use-workflow-analytics'
import {
  useAssistantWorkflowStore,
  WorkflowFileUploadSource,
} from 'components/assistant/workflows/stores/assistant-workflow-store'
import { createTextFile } from 'components/assistant/workflows/utils/utils'
import { Button } from 'components/ui/button'
import Divider from 'components/ui/divider/divider'
import { useVaultStore } from 'components/vault/utils/vault-store'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

export const DEFAULT_BLOCK_PARAMS = {
  headerText: 'Upload a file',
  maxFileSizeBytes: 1024 * 1024 * 10, // 10MB
  maxTotalFileSizeBytes: 1024 * 1024 * 10, // 10MB
  minFileCount: 1,
  maxFileCount: 1,
  acceptedFileMimeTypes: ['application/pdf'],
} as const satisfies WorkflowFileUploadInputBlockBlockParams

interface BlockStoreState {
  uploadedFiles: UploadedFile[] | null
  knowledgeSource: KnowledgeSourceItem | null
  showVaultDialog: boolean
  isLoading: boolean
}

interface BlockStoreActions {
  setUploadedFiles: (file: UploadedFile[] | null) => void
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  setShowVaultDialog: (showVaultDialog: boolean) => void
  setIsLoading: (loading: boolean) => void
  removeFileIds: (fileIds: string[]) => void
}

const initialState: BlockStoreState = {
  uploadedFiles: null,
  knowledgeSource: null,
  showVaultDialog: false,
  isLoading: false,
}

// TODO: Need to somehow reset the store at certain points
const createBlockStore = () =>
  create(
    devtools(
      immer<BlockStoreState & BlockStoreActions>((set) => ({
        ...initialState,

        reset: () => set(() => initialState),
        setUploadedFiles: (files) =>
          set((state) => {
            state.uploadedFiles = files
          }),
        setKnowledgeSource: (knowledgeSource) =>
          set((state) => {
            state.knowledgeSource = knowledgeSource
          }),
        setShowVaultDialog: (showVaultDialog) =>
          set((state) => {
            state.showVaultDialog = showVaultDialog
          }),
        setIsLoading: (loading) =>
          set((state) => {
            state.isLoading = loading
          }),
        removeFileIds: (fileIds: string[]) => {
          set((state) => {
            if (
              !state.knowledgeSource ||
              !('fileIds' in state.knowledgeSource) ||
              !state.knowledgeSource.fileIds
            )
              return
            const newIds = state.knowledgeSource.fileIds.filter(
              (id) => !fileIds.includes(id)
            )
            if (newIds.length) {
              state.knowledgeSource.fileIds = newIds
            } else {
              state.knowledgeSource = null
            }
          })
        },
      }))
    )
  )

// TODO: Can we make this zustand stuff more generic for other blocks?
export const useBlockStore = (
  stepIdx: number
): ReturnType<typeof createBlockStore> => {
  const [blockStores, addBlockStore] = useAssistantWorkflowStore(
    useShallow((state) => [state.blockStores, state.addBlockStore])
  )

  if (!blockStores[stepIdx]) {
    const blockStore = createBlockStore()
    addBlockStore(stepIdx, blockStore)
    return blockStore
  }

  return blockStores[stepIdx]
}

export type FileToShow = UploadedFile & {
  fileType?: FileType
  description: string
}

const getFileIdsFromOutputData = (
  outputData: WorkflowFileUploadInputBlockOutput | null
) => {
  if (!outputData?.knowledgeSources) return []
  const knowledgeSources =
    outputData.knowledgeSources as DocumentKnowledgeSource[]

  const fileIds = knowledgeSources.reduce<string[]>((acc, ks) => {
    if ([FileSource.FILES, FileSource.VAULT].includes(ks.type as FileSource)) {
      acc.push(...ks.fileIds)
    }
    return acc
  }, [])
  return fileIds
}

const useFetchOutputFiles = () => {
  const getFile = useFileCache((state) => state.getFile)

  const fetchFiles = async (
    outputData: WorkflowFileUploadInputBlockOutput | null
  ) => {
    const fileIds = getFileIdsFromOutputData(outputData)
    const files = await Promise.all(fileIds.map(getFile))
    return files.filter((f): f is UploadedFile => f !== undefined)
  }

  return fetchFiles
}

export const AssistantWorkflowFileUploadThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({
  blockParams: _blockParams,
  outputData,
  stepIdx,
  completionStatus,
  isEditing,
  setIsEditing,
}) => {
  const streamInProgress = useAssistantWorkflowStore(
    useShallow((state) => state.streamInProgress)
  )
  const blockParams = { ...DEFAULT_BLOCK_PARAMS, ..._blockParams }
  const { headerText } = blockParams
  const [uploadedFiles, setUploadedFiles, knowledgeSource] = useBlockStore(
    stepIdx
  )(
    useShallow((state) => [
      state.uploadedFiles,
      state.setUploadedFiles,
      state.knowledgeSource,
    ])
  )
  const [tempUploadedFiles, setTempUploadedFiles] = useState<
    UploadedFile[] | null
  >(null)
  const [tempKnowledgeSource, setTempKnowledgeSource] =
    useState<KnowledgeSourceItem | null>(null)

  const [fileIdToVaultFile] = useVaultStore(
    useShallow((s) => [s.currentProjectFileIdToVaultFile])
  )
  const setViewingFile = useSetViewingFile()
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)

  const fetchFiles = useFetchOutputFiles()

  useEffect(() => {
    if (
      knowledgeSource === null &&
      uploadedFiles === null &&
      outputData !== null &&
      !isEditing
    ) {
      fetchFiles(outputData)
        .then(setUploadedFiles)
        .catch((e) => {
          console.error(e)
        })
    }
  }, [
    uploadedFiles,
    outputData,
    setUploadedFiles,
    knowledgeSource,
    fetchFiles,
    isEditing,
  ])

  const onFileClick = (file: UploadedFile) => {
    setViewingFile(file)
  }

  const onSetIsEditing = (isEditing: boolean) => {
    if (isEditing) {
      setTempUploadedFiles(uploadedFiles ?? [])
      setTempKnowledgeSource(knowledgeSource ?? null)
    } else {
      setTempUploadedFiles(null)
      setTempKnowledgeSource(null)
    }
    setIsEditing(isEditing)
  }

  const allFiles = React.useMemo(() => {
    const files = (isEditing ? tempUploadedFiles : uploadedFiles) ?? []
    const ks = isEditing ? tempKnowledgeSource : knowledgeSource
    const vaultFileIds = ks && 'fileIds' in ks && ks.fileIds ? ks.fileIds : []
    const activeFiles = vaultFileIds
      .map((fileId) => fileIdToVaultFile[fileId])
      .filter(Boolean) as VaultFile[]

    return [...files, ...activeFiles]
  }, [
    tempUploadedFiles,
    tempKnowledgeSource,
    uploadedFiles,
    knowledgeSource,
    fileIdToVaultFile,
    isEditing,
  ])

  const filesToShow = allFiles.reduce((acc: FileToShow[], file) => {
    const ksFileIds = getFileIdsFromOutputData(outputData)
    if (ksFileIds.includes(file.id)) {
      const fileType = file.contentType as FileType | null | undefined
      let description = ''
      if (fileType) {
        description = `${FileTypeReadableName[fileType]} document • ${
          file.size ? bytesToReadable(file.size) : ''
        }`
      } else {
        description = `Document • ${
          file.size ? bytesToReadable(file.size) : ''
        }`
      }
      acc.push({ ...file, fileType: fileType || undefined, description })
    }
    return acc
  }, [])

  return (
    <AssistantWorkflowThreadBlock stepIdx={stepIdx}>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText
          completionStatus={completionStatus}
          text={headerText}
        />
      </AssistantWorkflowHarveyComponent>
      {!!outputData && (
        <AssistantWorkflowYouComponent
          onEditToggle={
            streamInProgress ? undefined : () => onSetIsEditing(!isEditing)
          }
        >
          {filesToShow.length > 0 ? (
            <AssistantWorkflowFilesComponent
              files={filesToShow}
              onFileClick={onFileClick}
              fileId={fileId}
            />
          ) : (
            <AssistantWorkflowThreadText
              completionStatus={completionStatus}
              text="Skipped"
            />
          )}
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowFileUploadInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({
  blockParams: _blockParams,
  onCompleted,
  stepIdx,
  outputData,
  workflowName,
  isEditing,
  setIsEditing,
  onUpdated,
}) => {
  const [textInputValue, setTextInputValue] = React.useState('')
  const [textInputLoading, setTextInputLoading] = React.useState(false)
  const trackEvent = useWorkflowAnalytics()
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [isFileListOpen, setIsFileListOpen] = React.useState(true)
  const blockParams = { ...DEFAULT_BLOCK_PARAMS, ..._blockParams }
  const [
    getOrCreateEventId,
    pendingMessage,
    fileUploadSource,
    setFileUploadSource,
    setVaultProjectId,
  ] = useAssistantWorkflowStore(
    useShallow((state) => [
      state.getOrCreateEventId,
      state.pendingMessage,
      state.fileUploadSource,
      state.setFileUploadSource,
      state.setVaultProjectId,
    ])
  )
  const [
    isLoading,
    uploadedFiles,
    setUploadedFiles,
    setIsLoading,
    knowledgeSource,
    setKnowledgeSource,
    showVaultDialog,
    setShowVaultDialog,
    removeFileIds,
  ] = useBlockStore(stepIdx)(
    useShallow((state) => [
      state.isLoading,
      state.uploadedFiles,
      state.setUploadedFiles,
      state.setIsLoading,
      state.knowledgeSource,
      state.setKnowledgeSource,
      state.showVaultDialog,
      state.setShowVaultDialog,
      state.removeFileIds,
    ])
  )
  const setViewingFile = useSetViewingFile()

  const addFile = useFileCache((state) => state.addFile)

  const fetchFiles = useFetchOutputFiles()

  const onFileClick = (file: UploadedFile) => {
    setViewingFile(file)
  }

  const onSubmit = async () => {
    const knowledgeSources: KnowledgeSource[] = []

    if (textInputValue) {
      setTextInputLoading(true)
      try {
        const eventId = await getOrCreateEventId()
        const uploaded = await createTextFile({
          text: textInputValue,
          filename: `${workflowName} - ${uuidv4()}.txt`,
          eventId,
        })
        addFile(uploaded)
        trackEvent('Workflow File Uploaded', {
          step_idx: stepIdx,
          workflow_name: workflowName,
          file_size: uploaded.size,
          file_type: uploaded.contentType,
        })
        setUploadedFiles([uploaded, ...(uploadedFiles ?? [])])
        knowledgeSources.push({
          type: KnowledgeSourceType.FILES,
          fileIds: [uploaded.id],
        })
      } catch (e) {
        displayErrorMessage('Failed to upload file')
        setTextInputLoading(false)
        console.error(e)
        return
      }
    }

    if (uploadedFiles?.length) {
      const docKS: DocumentKnowledgeSource = {
        type: KnowledgeSourceType.FILES,
        fileIds: uploadedFiles.map((file) => file.id),
      }
      knowledgeSources.push(docKS as any)
      setFileUploadSource(WorkflowFileUploadSource.FILES)
    }
    if (
      knowledgeSource &&
      'fileIds' in knowledgeSource &&
      knowledgeSource.fileIds &&
      knowledgeSource.type === FileSource.VAULT
    ) {
      const vaultKS: VaultKnowledgeSource = {
        type: FileSource.VAULT,
        fileIds: knowledgeSource.fileIds,
        folderId: knowledgeSource.folderId,
      }
      knowledgeSources.push(vaultKS as any)
      setFileUploadSource(WorkflowFileUploadSource.VAULT)
      setVaultProjectId(knowledgeSource.folderId ?? null)
    }

    if (isEditing) {
      onUpdated({
        knowledgeSources,
      })
      setIsEditing(false)
    } else {
      onCompleted({
        knowledgeSources,
      })
    }
    setTextInputLoading(false)
  }

  const handleRemoveFile = (file: UploadedFile | any) => {
    setUploadedFiles(
      uploadedFiles?.filter((f) => f.id !== file.id) ?? uploadedFiles
    )
    removeFileIds([file.id])
  }

  const handleCancelEdit = () => {
    fetchFiles(outputData)
      .then(setUploadedFiles)
      .catch((e) => {
        console.error(e)
      })
    setIsEditing(false)
  }

  const handleRemoveAll = () => {
    setUploadedFiles(null)
  }

  const handleAcceptedFiles = async (
    files: File[],
    fileSource: FileUploadSource
  ) => {
    if (!files.length) {
      displayErrorMessage('No files were accepted')
      setIsLoading(false)
      return
    }

    const eventId = await getOrCreateEventId()

    // TODO: Do we have a batch upload API?
    // TODO: Error handling
    Promise.all(
      files.map((file) =>
        uploadFile(file, false, {
          eventId: eventId ? String(eventId) : undefined,
          fileUploadSource: fileSource,
        }).then((uploaded) => {
          addFile(uploaded) // Add to file cache for quick retrieval
          trackEvent('Workflow File Uploaded', {
            step_idx: stepIdx,
            workflow_name: workflowName,
            file_size: uploaded.size,
            file_type: uploaded.contentType,
          })
          return uploaded
        })
      )
    )
      .then((newUploadedFiles) => {
        setUploadedFiles([...newUploadedFiles, ...(uploadedFiles ?? [])])
      })
      .catch((e) => {
        if (e.known && !_.isNil(e.message)) {
          // This error is informative, so persist the toast.
          displayErrorMessage(e.message, 4, undefined, false)
        } else {
          displayErrorMessage('Failed to upload file')
        }
        console.error(e)
      })
      // TODO: It's weird to set isLoading to false here and true in onFileDrop
      .finally(() => setIsLoading(false))
  }

  const onFileDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    fileSource: FileUploadSource
  ) => {
    // TODO: Handle file rejections
    setIsLoading(true)
    return onDrop({
      acceptedFiles,
      fileRejections,
      currentFileCount: uploadedFiles?.length ?? 0,
      maxFiles: blockParams.maxFileCount!,
      acceptedFileTypes: blockParams.acceptedFileMimeTypes! as FileType[],
      maxFileSize: blockParams.maxFileSizeBytes!,
      maxTotalFileSizeProps: {
        maxTotalFileSize: blockParams.maxTotalFileSizeBytes!,
        currentTotalFileSize:
          uploadedFiles?.reduce((total, file) => total + (file.size ?? 0), 0) ??
          0,
      },
      handleAcceptedFiles: (files) => handleAcceptedFiles(files, fileSource),
      handleRejectedFiles: () => setIsLoading(false),
    })
  }

  const componentDisabled =
    !!isLoading || (!isEditing && !!outputData) || pendingMessage

  const { getRootProps, getInputProps, open } = useDropzoneWithNetdocsSupport({
    onDrop: async (params) =>
      await onFileDrop(
        params.acceptedFiles,
        params.fileRejections,
        params.fileSource
      ),
    maxFiles: blockParams.maxFileCount,
    maxSize: blockParams.maxTotalFileSizeBytes,
    disabled: componentDisabled,
  })

  const onUploadFromComputer = () => {
    open()
  }

  const { chooseFilesDropdownOptions } = useAssistantKsOptions({
    onUploadFromComputer,
    setShowDialog: setShowVaultDialog,
    onDrop: onFileDrop,
    maxFileCount: blockParams.maxFileCount!,
    maxFileSize: Number(bytesToMb(blockParams.maxFileSizeBytes!)),
    includeVaultOption: true,
  })

  const filteredChooseFilesDropdownOptions = React.useMemo(() => {
    if (fileUploadSource === WorkflowFileUploadSource.VAULT) {
      // When Vault is selected, only show Vault option
      return chooseFilesDropdownOptions.filter(
        (option) => typeof option !== 'string' && option.title === 'Vault'
      )
    } else if (fileUploadSource === WorkflowFileUploadSource.FILES) {
      // When Files is selected, filter out Vault option
      return chooseFilesDropdownOptions.filter(
        (option) => typeof option === 'string' || option.title !== 'Vault'
      )
    } else {
      // When no source is selected, show all options
      return chooseFilesDropdownOptions
    }
  }, [chooseFilesDropdownOptions, fileUploadSource])

  const [fileIdToVaultFile] = useVaultStore(
    useShallow((s) => [s.currentProjectFileIdToVaultFile])
  )

  const allFiles = React.useMemo(() => {
    const files = uploadedFiles ?? []
    const vaultFileIds =
      knowledgeSource && 'fileIds' in knowledgeSource && knowledgeSource.fileIds
        ? knowledgeSource.fileIds
        : []
    const activeFiles = vaultFileIds
      .map((fileId) => fileIdToVaultFile[fileId])
      .filter(Boolean) as VaultFile[]

    return [...files, ...activeFiles]
  }, [knowledgeSource, uploadedFiles, fileIdToVaultFile])

  const footer = allFiles.length
    ? `${allFiles.length} ${pluralize('file', allFiles.length)} attached`
    : blockParams.maxFileCount === 1
    ? 'Upload a file'
    : `Upload up to ${blockParams.maxFileCount} ${pluralize(
        'file',
        blockParams.maxFileCount
      )}`

  const isSkippable = blockParams.minFileCount === 0
  const requiresFile = !allFiles.length && !isSkippable
  const allowsMultipleFiles = blockParams.maxFileCount! > 1
  const allowsText = blockParams.canFreeformText
  const hasFiles = allFiles.length > 0

  if (allowsText && !hasFiles) {
    return (
      <>
        {!textInputValue && (
          <FileDropzone
            isLoading={isLoading}
            isDisabled={componentDisabled}
            acceptedFileMimeTypes={blockParams.acceptedFileMimeTypes}
            maxFileSizeBytes={blockParams.maxFileSizeBytes}
            maxFileCount={blockParams.maxFileCount}
            chooseFilesDropdownOptions={filteredChooseFilesDropdownOptions}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            setKnowledgeSource={setKnowledgeSource}
            showVaultDialog={showVaultDialog}
            setShowVaultDialog={setShowVaultDialog}
            knowledgeSource={knowledgeSource}
          />
        )}
        <Divider
          text="OR"
          className={cn('my-4', {
            'opacity-0': isLoading || textInputValue,
          })}
        />
        {!isLoading && (
          <WorkflowInput>
            <TextInput
              id="assistant-workflow-file-upload-text-input"
              onSubmit={onSubmit}
              value={textInputValue}
              onChange={setTextInputValue}
              placeholder="Paste your text in here"
              rows={2}
              showLoadPromptButton={false}
              isLoading={textInputLoading}
              optional={isSkippable}
            />
          </WorkflowInput>
        )}
      </>
    )
  }

  return allFiles.length ? (
    <WorkflowInput className={cn(!isFileListOpen && '[&>*]:space-y-0')}>
      <FileList
        isFileListOpen={isFileListOpen}
        allFiles={allFiles}
        allowsMultipleFiles={allowsMultipleFiles}
        componentDisabled={componentDisabled}
        handleRemoveFile={handleRemoveFile}
        handleRemoveAll={handleRemoveAll}
        handleOpenFilePicker={onUploadFromComputer}
        onFileClick={onFileClick}
      />
      <WorkflowInputFooter
        className={cn(!isFileListOpen && 'mt-0')}
        footer={footer}
      >
        {allFiles.length > 1 && (
          <>
            <span className="-ml-6 -mr-2.5 text-muted">・</span>
            {isFileListOpen ? (
              <Button
                variant="text"
                onClick={() => setIsFileListOpen(false)}
                className="mr-auto"
              >
                Hide
              </Button>
            ) : (
              <Button
                variant="text"
                onClick={() => setIsFileListOpen(true)}
                className="mr-auto"
              >
                Show
              </Button>
            )}
          </>
        )}
        <div className="space-x-2">
          {isEditing && (
            <Button variant="outline" onClick={handleCancelEdit}>
              Cancel
            </Button>
          )}
          <Button
            onClick={onSubmit}
            isLoading={isLoading}
            disabled={requiresFile}
          >
            Send
          </Button>
        </div>
      </WorkflowInputFooter>
    </WorkflowInput>
  ) : (
    <WorkflowInput className={cn(!isFileListOpen && '[&>*]:space-y-0')}>
      <FileDropzone
        isLoading={isLoading}
        isDisabled={componentDisabled}
        acceptedFileMimeTypes={blockParams.acceptedFileMimeTypes}
        maxFileSizeBytes={blockParams.maxFileSizeBytes}
        maxFileCount={blockParams.maxFileCount}
        chooseFilesDropdownOptions={filteredChooseFilesDropdownOptions}
        dropdownOpen={dropdownOpen}
        setDropdownOpen={setDropdownOpen}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        setKnowledgeSource={setKnowledgeSource}
        showVaultDialog={showVaultDialog}
        setShowVaultDialog={setShowVaultDialog}
        knowledgeSource={knowledgeSource}
      />
      <WorkflowInputFooter footer={footer}>
        <div className="space-x-2">
          {isEditing && (
            <Button variant="outline" onClick={handleCancelEdit}>
              Cancel
            </Button>
          )}
          <Button
            onClick={onSubmit}
            isLoading={isLoading || pendingMessage}
            disabled={requiresFile}
          >
            {!requiresFile ? 'Skip' : 'Send'}
          </Button>
        </div>
      </WorkflowInputFooter>
    </WorkflowInput>
  )
}

export const AssistantWorkflowFileUploadExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({ blockParams, stepIdx }) => {
  const { headerText } = blockParams

  // TODO: There's a delay in uploadedFiles being available, during which the export will not include the files
  const uploadedFiles = useBlockStore(stepIdx)(
    useShallow((state) => state.uploadedFiles)
  )

  return (
    <>
      <div>{headerText}</div>
      {(uploadedFiles ?? []).map((file) => (
        <div key={file.id}>
          <p>{file.name}</p>
        </div>
      ))}
    </>
  )
}
