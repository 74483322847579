import * as React from 'react'

import { cn } from 'utils/utils'

interface DividerProps {
  text: string
  className?: string
  lineColorClassName?: string
  textColorClassName?: string
}

const Divider: React.FC<DividerProps> = ({
  text,
  className,
  lineColorClassName = 'border-input',
  textColorClassName = 'bg-primary text-inactive',
}) => {
  return (
    <div className={cn('relative', className)}>
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className={cn('w-full border-t', lineColorClassName)} />
      </div>
      <div className="relative flex justify-center">
        <span className={cn('px-4 text-2xs', textColorClassName)}>{text}</span>
      </div>
    </div>
  )
}

export default Divider
