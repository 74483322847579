/* tslint:disable */
/* eslint-disable */
/**
 * Vault API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReviewWorkflowCategory = {
    GENERAL: 'GENERAL',
    TRANSACTIONAL: 'TRANSACTIONAL',
    LITIGATION: 'LITIGATION',
    FINANCIAL_SERVICES: 'FINANCIAL_SERVICES'
} as const;
export type ReviewWorkflowCategory = typeof ReviewWorkflowCategory[keyof typeof ReviewWorkflowCategory];


export function instanceOfReviewWorkflowCategory(value: any): boolean {
    for (const key in ReviewWorkflowCategory) {
        if (Object.prototype.hasOwnProperty.call(ReviewWorkflowCategory, key)) {
            if (ReviewWorkflowCategory[key as keyof typeof ReviewWorkflowCategory] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ReviewWorkflowCategoryFromJSON(json: any): ReviewWorkflowCategory {
    return ReviewWorkflowCategoryFromJSONTyped(json, false);
}

export function ReviewWorkflowCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewWorkflowCategory {
    return json as ReviewWorkflowCategory;
}

export function ReviewWorkflowCategoryToJSON(value?: ReviewWorkflowCategory | null): any {
    return value as any;
}

export function ReviewWorkflowCategoryToJSONTyped(value: any, ignoreDiscriminator: boolean): ReviewWorkflowCategory {
    return value as ReviewWorkflowCategory;
}

