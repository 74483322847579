import React from 'react'

import { capitalize } from 'lodash'

import { AntitrustFilingsAnalysisResult } from 'openapi/models/AntitrustFilingsAnalysisResult'
import { AntitrustFilingsPartyRevenues } from 'openapi/models/AntitrustFilingsPartyRevenues'

import { Source } from 'utils/task'
import { cn } from 'utils/utils'

import Markdown from 'components/common/markdown/markdown'
import { Accordion } from 'components/ui/accordion'

import { Citations } from './citations'
import StatusBadge from './status-badge'
import ThresholdComponent from './threshold'
import { computeStatus } from './utils'

interface CountryCardProps {
  className?: string
  countryName: string
  analysis: AntitrustFilingsAnalysisResult
  handleSourceClick: (source: Source) => void
  worldwidePartyRevenues: AntitrustFilingsPartyRevenues[]
}

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(value)
}

const TurnoverData: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex items-center justify-between">
      <p className="text-xs text-muted">{label}</p>
      <p className="text-xs">{value}</p>
    </div>
  )
}

export const insertPartyRevenueCombinations = (
  partyRevenues: AntitrustFilingsPartyRevenues[],
  isWorldwide: boolean
): { label: string; value: string }[] => {
  // Group parties by type
  const groupedParties = partyRevenues.reduce(
    (acc, party) => {
      if (!(party.partyType in acc)) {
        acc[party.partyType] = []
      }
      acc[party.partyType].push(party)
      return acc
    },
    {} as Record<string, AntitrustFilingsPartyRevenues[]>
  )

  const result: { label: string; value: string }[] = []

  // Add individual parties first
  Object.entries(groupedParties)
    .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
    .forEach(([type, parties]) => {
      const cleanType = type.split('_').map(capitalize).join(' ')
      parties.forEach((party) => {
        result.push({
          label: `${party.partyName} (${cleanType})`,
          value: formatCurrency(party.revenue),
        })
      })
    })

  // Add combined revenues based on number of parties
  // Add total revenue for all parties
  const combinedLabel = isWorldwide ? 'CWTO' : 'CDTO'
  const totalRevenue = partyRevenues.reduce(
    (sum, party) => sum + party.revenue,
    0
  )
  result.push({
    label: combinedLabel,
    value: formatCurrency(totalRevenue),
  })
  // }
  return result
}

const CountryCard: React.FC<CountryCardProps> = ({
  className,
  analysis,
  handleSourceClick,
  worldwidePartyRevenues,
}) => {
  if (analysis.partyRevenues.length === 0) return null

  return (
    <div
      className={cn(
        'h-full w-full transform rounded bg-secondary px-4 py-3 text-left',
        className
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex">
          <p className="text-base">{analysis.countryName}</p>
          <Citations
            handleSourceClick={handleSourceClick}
            sources={analysis.sources as Source[]}
          />
        </div>
        <div className="flex items-center gap-2">
          <StatusBadge label={computeStatus(analysis)} />
        </div>
      </div>
      <div className="mt-8 w-full">
        <Accordion
          type="multiple"
          defaultValue={analysis.thresholds.map((threshold) => threshold.name)}
        >
          {analysis.thresholds.map((threshold, idx) => (
            <ThresholdComponent
              key={threshold.name}
              idx={idx}
              thresholdName={threshold.name}
              analysis={analysis}
              handleSourceClick={handleSourceClick}
            />
          ))}
        </Accordion>
      </div>
      <div className="mt-4 flex space-x-4">
        <Markdown className="text-xs" content={analysis.additionalInsights} />
        <div className="flex h-fit w-1/3 shrink-0 flex-col gap-4">
          <div className="rounded bg-accent px-4 py-3">
            <p className="mb-1 text-xs">Local Turnover</p>
            <div className="space-y-1">
              {insertPartyRevenueCombinations(
                analysis.partyRevenues,
                false
              ).map((partyRevenue) => (
                <TurnoverData
                  key={partyRevenue.label}
                  label={partyRevenue.label}
                  value={partyRevenue.value}
                />
              ))}
            </div>
          </div>
          <div className="rounded bg-accent px-4 py-3">
            <p className="mb-1 text-xs">Worldwide Turnover</p>
            <div className="space-y-1">
              {insertPartyRevenueCombinations(worldwidePartyRevenues, true).map(
                (partyRevenue) => (
                  <TurnoverData
                    key={partyRevenue.label}
                    label={partyRevenue.label}
                    value={partyRevenue.value}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CountryCard
