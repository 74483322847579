import { enableMapSet } from 'immer'
import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { DocumentClassificationTag } from 'openapi/models/DocumentClassificationTag'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'

import { SafeRecord } from 'utils/safe-types'
import { AnnotationById, Source, TaskType } from 'utils/task'
import { HarveySocketTask } from 'utils/use-harvey-socket'

import { ErrorPageTitle } from 'components/common/error/error'

import { VaultItem, QueryQuestion, ReviewAnswer, ColumnDataType } from './vault'
import {
  generateEmptyMetadata,
  generateEmptyVaultCurrentStreamingState,
  generateEmptyVaultReviewSocketState,
  updateProjectMetadata,
} from './vault-helpers'

// Enable the MapSet plugin for Immer
enableMapSet()

export interface VaultState {
  isAgGridEnterpriseLicenseRegistered: boolean
  isFetchingProjects: boolean
  hasLoadedProjects: boolean
  hasLoadedProjectsMetadata: boolean
  isLayoutLoading: boolean
  isProjectLayoutLoading: boolean
  currentFolderId: string | null
  currentProject: VaultFolder | null

  // stores the file and folder info of the current project
  currentProjectFolderIdToVaultFolder: SafeRecord<string, VaultFolder>
  currentProjectParentIdToVaultFolderIds: SafeRecord<string, string[]>
  currentProjectFileIdToVaultFile: SafeRecord<string, VaultFile>
  currentProjectFolderIdToVaultFileIds: SafeRecord<string, string[]>

  // stores the file and folder info of all projects
  allFolderIdToVaultFolder: SafeRecord<string, VaultFolder>
  allParentIdToVaultFolderIds: SafeRecord<string, string[]>
  allFileIdToVaultFile: SafeRecord<string, VaultFile>
  allFolderIdToVaultFileIds: SafeRecord<string, string[]>

  // stores the file and folder info of the current project
  projectIdToFileIds: SafeRecord<string, string[]>
  projectIdToFolderIds: SafeRecord<string, string[]>

  rootVaultFolderIds: Set<string>
  localFileIds: Set<string>
  currentProjectMetadata: VaultFolderMetadata

  // stores the metadata only of the current project and its descendant folders
  foldersMetadata: SafeRecord<string, VaultFolderMetadata>

  // stores the metadata of all projects and their descendant folders
  allFoldersMetadata: SafeRecord<string, VaultFolderMetadata>

  queryId: string
  isNewVaultReviewQuery: boolean
  showProcessingProgress: SafeRecord<string, boolean>
  showRecentQueries: boolean
  recentlyUploadedFileIds: string[]
  requiresProjectDataRefetch: boolean
  exampleProjectIds: Set<string>
  areExampleProjectsLoaded: boolean
  sharedProjectIds: Set<string>
}

interface VaultAction {
  setAgGridEnterpriseLicenseRegistered: (
    agGridEnterpriseLicenseRegistered: boolean
  ) => void
  setIsFetchingProjects: (isFetchingProjects: boolean) => void
  setHasLoadedProjects: (hasLoadedProjects: boolean) => void
  setHasLoadedProjectsMetadata: (hasLoadedProjectsMetadata: boolean) => void
  setIsLayoutLoading: (isLayoutLoading: boolean) => void
  setIsProjectLayoutLoading: (isProjectLayoutLoading: boolean) => void
  setCurrentFolderId: (id: string | null) => void
  setCurrentProject: (
    project: VaultFolder | null,
    shouldUpdateProjectMetadata?: boolean
  ) => void
  // updateVaultFile: updates a single file in the store with new data
  updateVaultFile: (
    fileId: string,
    newData: Partial<VaultFile>,
    projectId: string | undefined
  ) => void
  bulkUpdateVaultFileTags: (
    fileIds: string[],
    newDocumentClassificationTag: DocumentClassificationTag,
    projectId: string | undefined
  ) => void
  // upsertVaultFile: determines if the file needs to be updated and merges the new data with the existing data
  upsertVaultFiles: (files: VaultFile[], projectId: string | undefined) => void
  upsertVaultFolders: (
    folders: VaultFolder[],
    currentUserId: string,
    isExampleProject?: boolean,
    projectId?: string
  ) => void
  deleteVaultFiles: (fileIds: string[], projectId: string | undefined) => void
  deleteVaultFolders: (
    folderIds: string[],
    projectId: string | undefined
  ) => void
  addToProjectsMetadata: (
    projectMetadata: Record<string, VaultFolderMetadata>
  ) => void
  updateProjectMetadata: () => void
  updateProjectMetadataLastFileUploadedAt: (lastFileUploadedAt: string) => void
  updateProjectMetadataClientMatterId: (clientMatterId: string) => void
  setQueryId: (queryId: string) => void
  setIsNewVaultReviewQuery: (isNewVaultReviewQuery: boolean) => void
  setShowProcessingProgress: (
    projectId: string,
    showProcessingProgress: boolean
  ) => void
  setShowRecentQueries: (showRecentQueries: boolean) => void
  setRecentlyUploadedFileIds: (fileIds: string[]) => void
  setRequiresProjectDataRefetch: (requiresProjectDataRefetch: boolean) => void
  setExampleProjectIds: (exampleProjectIds: Set<string>) => void
  setAreExampleProjectsLoaded: (areExampleProjectsLoaded: boolean) => void
  markInProgressReviewTask: (queryId: string) => void
  updateQueryIdToStateTitle: (queryId: string, title: string) => void
  resetVaultQueryState: () => void
}

interface VaultQueryBoxState {
  pendingQuery: string
}

interface VaultQueryBoxAction {
  setPendingQuery: (pendingQuery: string) => void
}

interface VaultSheetState {
  activeDocument: UploadedFile | null
}

interface VaultSheetAction {
  setActiveDocument: (activeDocument: UploadedFile | null) => void
}

interface VaultDialogState {
  areUploadButtonsDisabled: boolean
  isCreateProjectDialogOpen: boolean
  isCreateFolderDialogOpen: boolean
  currentCreateFolderFolderId: string | null
  isUploadFilesDialogOpen: boolean
  currentUploadFilesFolderId: string | null
  isDeleteDialogOpen: boolean
  deleteRecords: VaultItem[]
  isRenameDialogOpen: boolean
  renameRecord: VaultItem | null
  isMoveDialogOpen: boolean
  moveRecords: VaultItem[]
  isReviewQuerySelectionDialogOpen: boolean
  isExportDialogOpen: boolean
  isAddFilesDialogOpen: boolean
  isVaultAssistantModalOpen: boolean
  isEditClientMatterDialogOpen: boolean
  isDuplicateModalOpen: boolean
  isColumnBuilderDialogOpen: boolean
  isEditQueryTitleDialogOpen: boolean
  editQueryTaskType: TaskType | null
  isDuplicateTableDialogOpen: boolean
}

interface VaultDialogAction {
  setAreUploadButtonsDisabled: (areDisabled: boolean) => void
  setIsCreateProjectDialogOpen: (isOpen: boolean) => void
  setIsCreateFolderDialogOpen: (isOpen: boolean) => void
  setCurrentCreateFolderFolderId: (folderId: string | null) => void
  setIsUploadFilesDialogOpen: (isOpen: boolean) => void
  setCurrentUploadFilesFolderId: (folderId: string | null) => void
  setIsDeleteDialogOpen: (isOpen: boolean) => void
  setDeleteRecords: (records: VaultItem[]) => void
  setIsRenameDialogOpen: (isOpen: boolean) => void
  setRenameRecord: (record: VaultItem | null) => void
  setIsMoveDialogOpen: (isOpen: boolean) => void
  setMoveRecords: (records: VaultItem[]) => void
  setIsReviewQuerySelectionDialogOpen: (isOpen: boolean) => void
  setIsExportDialogOpen: (isOpen: boolean) => void
  setIsAddFilesDialogOpen: (isOpen: boolean) => void
  setIsVaultAssistantModalOpen: (isOpen: boolean) => void
  setIsEditClientMatterDialogOpen: (isOpen: boolean) => void
  setIsDuplicateModalOpen: (isOpen: boolean) => void
  setIsColumnBuilderDialogOpen: (isOpen: boolean) => void
  setIsEditQueryTitleDialogOpen: (
    isOpen: boolean,
    taskType?: TaskType | null
  ) => void
  setIsDuplicateTableDialogOpen: (isOpen: boolean) => void
}

export type VaultExtraSocketState = {
  isFromHistory: boolean
  title: string
  taskType: TaskType | null
  vaultFolderId: string | null
  numFiles: number
  numQuestions: number
  fileIds: string[]
  sourcedFileIds: string[]
  // When the query is first created
  createdAt: Date | null
  // When the query is started recently (could be a retry)
  startedAt: Date | null
  completedAt: Date | null
  pausedAt: Date | null
  failedAt: Date | null
  maxTokenLimitReached: boolean
  creatorUserEmail: string | null
}

export type VaultReviewSocketState = {
  followUpQueries: { query: string; questionIds: string[] }[]
  questions: QueryQuestion[]
  questionsNotAnswered: QueryQuestion[]
  maxQuestionCharacterLength: number
  minQuestionCharacterLength: number
  columnHeaders: { id: string; text: string; columnDataType?: ColumnDataType }[]
  answers: SafeRecord<string, ReviewAnswer[]>
  originalAnswers: SafeRecord<string, ReviewAnswer[]>
  errors: SafeRecord<string, { columnId: string; text: string }[]>
  fileIdToSources: SafeRecord<string, Source[]>
  processedFileIds: string[]
  suppressedFileIds: string[]
  eta: string | null
  columnOrder: string[]
  isWorkflowRepsWarranties: boolean
}

// This state is for either the query that VaultQueryDetail is serving right
// now, or the one that is being generated by the VaultQueryBox.
export type VaultCurrentStreamingState = VaultExtraSocketState & {
  query: string
  isLoading: boolean
  headerText: string
  response: string
  queryId: string
  progress: number
  sources: Source[]
  annotations: AnnotationById
}

// This state is for all the queries that are pending, or the cached result
// of the queries that have been completed and fetched.
interface VaultStreamingState {
  queryIdToState: SafeRecord<string, VaultCurrentStreamingState>
  queryIdToReviewState: SafeRecord<string, VaultReviewSocketState>
}

export type VaultSocketTask = HarveySocketTask &
  VaultExtraSocketState &
  VaultReviewSocketState

export type VaultSocketSetter = (task: Partial<VaultSocketTask>) => void

interface VaultStreamingAction {
  setTask: VaultSocketSetter
  setReviewTask: VaultSocketSetter
}

export interface VaultError {
  title?: ErrorPageTitle
  message: string
  cta: {
    message: string
    redirectUri: string
  }
}

interface VaultErrorState {
  error: VaultError | null
}

interface VaultErrorAction {
  setError: (error: VaultError | null) => void
}

export interface AddColumnPopoverPosition {
  left: number
  top: number
  question: string
  header: string
  columnDataType: ColumnDataType | undefined
  colId?: string
}

interface VaultStartFromScratchStore {
  addColumnPopoverPosition: AddColumnPopoverPosition | null
}

interface VaultStartFromScratchActions {
  setAddColumnPopoverPosition: (
    popoverPosition: AddColumnPopoverPosition | null
  ) => void
}

function fileEquals(file1: VaultFile, file2: VaultFile): boolean {
  return (
    file1.id === file2.id ||
    (file1.name === file2.name && file1.vaultFolderId === file2.vaultFolderId)
  )
}

function shouldUpdateFile(
  existingFile: VaultFile,
  newFile: VaultFile
): boolean {
  if (!fileEquals(existingFile, newFile)) {
    return false
  }
  // If the file has a newer updatedAt time, update it
  return (
    newFile.updatedAt > existingFile.updatedAt ||
    // If the file has a new URL, update it
    (!_.isEmpty(newFile.url) && newFile.url !== existingFile.url) ||
    (!_.isEmpty(newFile.docAsPdfUrl) &&
      newFile.docAsPdfUrl !== existingFile.docAsPdfUrl)
  )
}

function shouldUpdateFolder(
  existingFolder: VaultFolder,
  newFolder: VaultFolder
): boolean {
  // Update folder if it has been 1) updated or 2) recently opened
  return (
    newFolder.updatedAt > existingFolder.updatedAt ||
    (!!newFolder.lastOpenedAt &&
      !!existingFolder.lastOpenedAt &&
      newFolder.lastOpenedAt > existingFolder.lastOpenedAt) ||
    (!!newFolder.lastOpenedAt && !existingFolder.lastOpenedAt)
  )
}

function updatedFile(newFile: VaultFile, existingFile: VaultFile): VaultFile {
  if (!shouldUpdateFile(existingFile, newFile)) {
    return existingFile
  }
  let updatedFile = { ...existingFile }
  if (newFile.updatedAt > existingFile.updatedAt) {
    updatedFile = { ...newFile }
  }
  updatedFile.url = !_.isEmpty(newFile.url) ? newFile.url : existingFile.url
  updatedFile.docAsPdfUrl = !_.isEmpty(newFile.docAsPdfUrl)
    ? newFile.docAsPdfUrl
    : existingFile.docAsPdfUrl
  return updatedFile
}

/**
 * Creates an updated folder by merging properties from a new folder into an existing folder
 */
function updatedFolder(
  newFolder: VaultFolder,
  existingFolder: VaultFolder
): VaultFolder {
  if (!shouldUpdateFolder(existingFolder, newFolder)) {
    return existingFolder
  }
  let updatedFolder = { ...existingFolder }
  if (newFolder.updatedAt > existingFolder.updatedAt) {
    updatedFolder = {
      ...existingFolder,
      ...newFolder,
    }
  }
  return updatedFolder
}

/**
 * Removes a file from all collections in the state
 * @param state The current state
 * @param fileToRemove The file to remove
 * @param isCurrentProject Whether the file is in the current project
 */
function removeFileFromCollections(
  state: VaultState,
  fileToRemove: VaultFile,
  isCurrentProject: boolean
): void {
  // Remove from main dictionary
  delete state.allFileIdToVaultFile[fileToRemove.id]

  // Remove from folder-to-files mapping
  if (state.allFolderIdToVaultFileIds[fileToRemove.vaultFolderId]) {
    state.allFolderIdToVaultFileIds[fileToRemove.vaultFolderId] =
      state.allFolderIdToVaultFileIds[fileToRemove.vaultFolderId]!.filter(
        (id: string) => id !== fileToRemove.id
      )
  }

  // Remove from project-to-files mapping
  if (state.projectIdToFileIds[fileToRemove.vaultProjectId]) {
    state.projectIdToFileIds[fileToRemove.vaultProjectId] =
      state.projectIdToFileIds[fileToRemove.vaultProjectId]!.filter(
        (id: string) => id !== fileToRemove.id
      )
  }

  // Remove from current project mappings if applicable
  if (isCurrentProject) {
    delete state.currentProjectFileIdToVaultFile[fileToRemove.id]

    if (
      state.currentProjectFolderIdToVaultFileIds[fileToRemove.vaultFolderId]
    ) {
      state.currentProjectFolderIdToVaultFileIds[fileToRemove.vaultFolderId] =
        state.currentProjectFolderIdToVaultFileIds[
          fileToRemove.vaultFolderId
        ]!.filter((id: string) => id !== fileToRemove.id)
    }
  }
}

/**
 * Adds a file to all collections in the state
 * @param state The current state
 * @param fileToAdd The file to add
 * @param isCurrentProject Whether the file is in the current project
 */
function addFileToCollections(
  state: VaultState,
  fileToAdd: VaultFile,
  isCurrentProject: boolean
): void {
  // Add to main dictionary
  state.allFileIdToVaultFile[fileToAdd.id] = fileToAdd

  // Add to folder-to-files mapping
  state.allFolderIdToVaultFileIds[fileToAdd.vaultFolderId] ??= []
  state.allFolderIdToVaultFileIds[fileToAdd.vaultFolderId]!.push(fileToAdd.id)

  // Add to project-to-files mapping
  state.projectIdToFileIds[fileToAdd.vaultProjectId] ??= []
  state.projectIdToFileIds[fileToAdd.vaultProjectId]!.push(fileToAdd.id)

  // Add to current project mappings if applicable
  if (isCurrentProject) {
    state.currentProjectFileIdToVaultFile[fileToAdd.id] = fileToAdd
    state.currentProjectFolderIdToVaultFileIds[fileToAdd.vaultFolderId] ??= []
    state.currentProjectFolderIdToVaultFileIds[fileToAdd.vaultFolderId]!.push(
      fileToAdd.id
    )
  }
}

/**
 * Updates a file's location in all collections in the state
 * @param params Object containing parameters for updating file location
 * @param params.state The current state
 * @param params.oldFile The original file
 * @param params.newFile The updated file
 * @param params.isCurrentProject Whether the file is in the current project
 */
function updateFileLocation(params: {
  state: VaultState
  oldFile: VaultFile
  newFile: VaultFile
  isCurrentProject: boolean
}): void {
  const { state, oldFile, newFile, isCurrentProject } = params

  if (
    oldFile.vaultFolderId === newFile.vaultFolderId &&
    oldFile.id === newFile.id
  ) {
    return
  }

  // Remove from old folder
  state.allFolderIdToVaultFileIds[oldFile.vaultFolderId] =
    state.allFolderIdToVaultFileIds[oldFile.vaultFolderId]!.filter(
      (id: string) => id !== oldFile.id
    )

  // Add to new folder
  state.allFolderIdToVaultFileIds[newFile.vaultFolderId] ??= []
  state.allFolderIdToVaultFileIds[newFile.vaultFolderId]!.push(newFile.id)

  // Update project mapping if ID changed
  if (newFile.id !== oldFile.id) {
    state.projectIdToFileIds[newFile.vaultProjectId] ??= []
    state.projectIdToFileIds[newFile.vaultProjectId]!.push(newFile.id)
  }

  // Update current project mappings if applicable
  if (isCurrentProject) {
    state.currentProjectFolderIdToVaultFileIds[oldFile.vaultFolderId] =
      state.currentProjectFolderIdToVaultFileIds[oldFile.vaultFolderId]!.filter(
        (id: string) => id !== oldFile.id
      )

    state.currentProjectFolderIdToVaultFileIds[newFile.vaultFolderId] ??= []
    state.currentProjectFolderIdToVaultFileIds[newFile.vaultFolderId]!.push(
      newFile.id
    )
  }
}

type StoreWideState = VaultState &
  VaultQueryBoxState &
  VaultDialogState &
  VaultSheetState &
  VaultStreamingState &
  VaultErrorState &
  VaultStartFromScratchStore

type StoreWideActions = VaultAction &
  VaultQueryBoxAction &
  VaultDialogAction &
  VaultSheetAction &
  VaultStreamingAction &
  VaultErrorAction &
  VaultStartFromScratchActions

const initialVaultQueryState = {
  currentFolderId: null,
  currentProject: null,
  currentProjectMetadata: generateEmptyMetadata(''),
}

const initialVaultState: VaultState = {
  ...initialVaultQueryState,
  isAgGridEnterpriseLicenseRegistered: false,
  isFetchingProjects: true,
  hasLoadedProjects: false,
  areExampleProjectsLoaded: false,
  hasLoadedProjectsMetadata: false,
  isLayoutLoading: true,
  isProjectLayoutLoading: false,
  currentProjectFolderIdToVaultFolder: {},
  currentProjectParentIdToVaultFolderIds: {},
  rootVaultFolderIds: new Set(),
  currentProjectFileIdToVaultFile: {},
  currentProjectFolderIdToVaultFileIds: {},
  allFolderIdToVaultFolder: {},
  allParentIdToVaultFolderIds: {},
  allFileIdToVaultFile: {},
  allFolderIdToVaultFileIds: {},
  projectIdToFileIds: {},
  projectIdToFolderIds: {},
  localFileIds: new Set(),
  foldersMetadata: {},
  allFoldersMetadata: {},
  queryId: '',
  isNewVaultReviewQuery: false,
  showProcessingProgress: {},
  showRecentQueries: true,
  recentlyUploadedFileIds: [],
  requiresProjectDataRefetch: false,
  exampleProjectIds: new Set(),
  sharedProjectIds: new Set(),
}

const initialState: StoreWideState = {
  ...initialVaultState,
  // VaultQueryBoxState
  pendingQuery: '',
  // VaultDialogState
  areUploadButtonsDisabled: true,
  isCreateProjectDialogOpen: false,
  isCreateFolderDialogOpen: false,
  currentCreateFolderFolderId: null,
  isUploadFilesDialogOpen: false,
  currentUploadFilesFolderId: null,
  isDeleteDialogOpen: false,
  deleteRecords: [],
  isRenameDialogOpen: false,
  renameRecord: null,
  isMoveDialogOpen: false,
  moveRecords: [],
  isReviewQuerySelectionDialogOpen: false,
  isExportDialogOpen: false,
  isAddFilesDialogOpen: false,
  isVaultAssistantModalOpen: false,
  isEditClientMatterDialogOpen: false,
  isDuplicateModalOpen: false,
  isColumnBuilderDialogOpen: false,
  isEditQueryTitleDialogOpen: false,
  editQueryTaskType: null,
  isDuplicateTableDialogOpen: false,
  // VaultSheetState
  activeDocument: null,
  // VaultStreamingState
  queryIdToState: {},
  queryIdToReviewState: {},
  // VaultErrorState
  error: null,
  // VaultStartFromScratchStore
  addColumnPopoverPosition: null,
}

export const useVaultStore = create(
  devtools(
    immer<StoreWideState & StoreWideActions>((set) => ({
      ...initialState,
      // VaultAction
      setAgGridEnterpriseLicenseRegistered: (
        isAgGridEnterpriseLicenseRegistered: boolean
      ) => set({ isAgGridEnterpriseLicenseRegistered }),
      setIsFetchingProjects: (isFetchingProjects: boolean) =>
        set({ isFetchingProjects }),
      setHasLoadedProjects: (hasLoadedProjects: boolean) =>
        set({ hasLoadedProjects }),
      setHasLoadedProjectsMetadata: (hasLoadedProjectsMetadata: boolean) =>
        set({ hasLoadedProjectsMetadata }),
      setIsLayoutLoading: (isLayoutLoading: boolean) =>
        set({ isLayoutLoading }),
      setIsProjectLayoutLoading: (isProjectLayoutLoading: boolean) =>
        set({ isProjectLayoutLoading }),
      setCurrentFolderId: (folderId) => set({ currentFolderId: folderId }),
      setCurrentProject: (
        project,
        shouldUpdateProjectMetadataAndUserId = false
      ) =>
        set((state) => {
          if (!project) {
            return {
              currentProject: null,
              currentProjectMetadata: generateEmptyMetadata(''),
            }
          }

          const projectMetadata =
            state.allFoldersMetadata[project.id] ||
            generateEmptyMetadata(project.id)

          if (shouldUpdateProjectMetadataAndUserId && !projectMetadata.userId) {
            // Read the userId from the project object directly if it doesn't already
            // exist in the project metadata (e.g. when creating a new project)
            projectMetadata.userId = project.userId
            projectMetadata.userEmail = project.userEmail
          }

          state.currentProject = project
          state.currentProjectMetadata = projectMetadata

          if (shouldUpdateProjectMetadataAndUserId) {
            state.allFoldersMetadata[project.id] = projectMetadata
          }

          // update files and folders
          const currentProjectFileIdToVaultFile: SafeRecord<string, VaultFile> =
            {}
          const currentProjectFolderIdToVaultFolder: SafeRecord<
            string,
            VaultFolder
          > = {}
          const currentProjectFolderIdToVaultFileIds: SafeRecord<
            string,
            string[]
          > = {}
          const currentProjectParentIdToVaultFolderIds: SafeRecord<
            string,
            string[]
          > = {}

          const descendantFileIds =
            state.projectIdToFileIds[state.currentProject.id] ?? []
          const descendantFolderIds =
            state.projectIdToFolderIds[state.currentProject.id] ?? []

          descendantFileIds
            .filter((fileId) => !!state.allFileIdToVaultFile[fileId])
            .forEach((fileId) => {
              const file = state.allFileIdToVaultFile[fileId]!
              currentProjectFileIdToVaultFile[fileId] = file
              currentProjectFolderIdToVaultFileIds[file.vaultFolderId] ??= []
              currentProjectFolderIdToVaultFileIds[file.vaultFolderId]!.push(
                fileId
              )
            })

          descendantFolderIds
            .filter((folderId) => !!state.allFolderIdToVaultFolder[folderId])
            .forEach((folderId) => {
              const folder = state.allFolderIdToVaultFolder[folderId]!
              currentProjectFolderIdToVaultFolder[folderId] = folder
              if (folder.parentId) {
                currentProjectParentIdToVaultFolderIds[folder.parentId] ??= []
                currentProjectParentIdToVaultFolderIds[folder.parentId]!.push(
                  folder.id
                )
              }
            })
          currentProjectFolderIdToVaultFolder[project.id] = project

          state.currentProjectFileIdToVaultFile =
            currentProjectFileIdToVaultFile
          state.currentProjectFolderIdToVaultFolder =
            currentProjectFolderIdToVaultFolder
          state.currentProjectFolderIdToVaultFileIds =
            currentProjectFolderIdToVaultFileIds
          state.currentProjectParentIdToVaultFolderIds =
            currentProjectParentIdToVaultFolderIds

          // update foldersMetadata
          state.foldersMetadata = Object.fromEntries(
            descendantFolderIds.map((folderId) => [
              folderId,
              state.allFoldersMetadata[folderId] ||
                generateEmptyMetadata(folderId),
            ])
          )
          state.foldersMetadata[project.id] = projectMetadata
        }),
      updateVaultFile: (
        fileId: string,
        newData: Partial<VaultFile>,
        projectId: string | undefined
      ) =>
        set((state) => {
          const file = state.allFileIdToVaultFile[fileId]
          if (!file) return
          state.allFileIdToVaultFile[fileId] = { ...file, ...newData }

          if (projectId === state.currentProject?.id) {
            state.currentProjectFileIdToVaultFile[fileId] = {
              ...file,
              ...newData,
            }
          }
        }),
      bulkUpdateVaultFileTags: (
        fileIds: string[],
        newDocumentClassificationTag: DocumentClassificationTag,
        projectId: string | undefined
      ) =>
        set((state) => {
          const isCurrentProject = projectId === state.currentProject?.id
          const updatedFiles = fileIds.reduce(
            (acc, fileId) => {
              const file = state.allFileIdToVaultFile[fileId]
              if (!file) return acc

              acc[fileId] = {
                ...file,
                tags: [newDocumentClassificationTag],
              }
              return acc
            },
            {} as SafeRecord<string, VaultFile>
          )

          return {
            allFileIdToVaultFile: {
              ...state.allFileIdToVaultFile,
              ...updatedFiles,
            },
            ...(isCurrentProject && {
              currentProjectFileIdToVaultFile: {
                ...state.currentProjectFileIdToVaultFile,
                ...updatedFiles,
              },
            }),
          }
        }),
      upsertVaultFiles: (files, projectId) =>
        set((state) => {
          if (projectId) {
            const project = state.currentProjectFolderIdToVaultFolder[projectId]
            if (project && !!project.parentId) {
              throw new Error(
                `${projectId} is not a root folder. Please fix the logic.`
              )
            }
          }
          const isCurrentProject = projectId === state.currentProject?.id
          const updatedLocalFileIds = state.localFileIds

          // Deduplicate files by folder and name, keeping the most up-to-date version
          const dedupedFiles = Array.from(
            files
              .reduce((map, file) => {
                const key = `${file.vaultFolderId}-${file.name}`
                const existing = map.get(key)
                if (!existing || shouldUpdateFile(existing, file)) {
                  map.set(key, file)
                }
                return map
              }, new Map<string, VaultFile>())
              .values()
          )

          dedupedFiles.forEach((file) => {
            const localFileId = `${file.vaultFolderId}-${file.name}`
            const existingFile =
              state.allFileIdToVaultFile[file.id] ??
              state.allFileIdToVaultFile[localFileId]

            // Find any file with the same name in the same folder
            const existingFileWithSameName = (
              state.allFolderIdToVaultFileIds[file.vaultFolderId] || []
            )
              .map((id) => state.allFileIdToVaultFile[id])
              .find(
                (f) =>
                  f &&
                  f.name === file.name &&
                  f.id !== file.id &&
                  f.id !== localFileId
              )

            // Clean up local file ID if present
            if (localFileId in state.allFileIdToVaultFile) {
              delete state.allFileIdToVaultFile[localFileId]
              if (isCurrentProject) {
                delete state.currentProjectFileIdToVaultFile[localFileId]
              }
            }

            // Remove any duplicate file with the same name
            if (existingFileWithSameName) {
              removeFileFromCollections(
                state,
                existingFileWithSameName,
                isCurrentProject
              )
            }

            // Update or add the file
            if (existingFile && shouldUpdateFile(existingFile, file)) {
              const updatedFileObj = updatedFile(file, existingFile)
              state.allFileIdToVaultFile[file.id] = updatedFileObj

              if (isCurrentProject) {
                state.currentProjectFileIdToVaultFile[file.id] = updatedFileObj
              }

              updateFileLocation({
                state,
                oldFile: existingFile,
                newFile: file,
                isCurrentProject,
              })
            } else if (
              !existingFile ||
              !(file.id in state.allFileIdToVaultFile)
            ) {
              addFileToCollections(state, file, isCurrentProject)
            }

            // Update local file tracking
            if (file.id === localFileId) {
              updatedLocalFileIds.add(localFileId)
            } else {
              updatedLocalFileIds.delete(localFileId)
            }
          })

          state.localFileIds = updatedLocalFileIds
        }),
      // eslint-disable-next-line max-params
      upsertVaultFolders: (
        folders,
        currentUserId,
        isExampleProject,
        projectId
      ) =>
        set((state) => {
          if (projectId) {
            const project = state.currentProjectFolderIdToVaultFolder[projectId]
            if (project && !!project.parentId) {
              throw new Error(
                `${projectId} is not a root folder. Please fix the logic.`
              )
            }
          }
          const isCurrentProject = projectId === state.currentProject?.id
          const updatedRootVaultFolderIds = state.rootVaultFolderIds
          const updatedSharedProjectIds = state.sharedProjectIds
          folders.forEach((folder) => {
            const existingFolder = state.allFolderIdToVaultFolder[folder.id]
            if (existingFolder && shouldUpdateFolder(existingFolder, folder)) {
              const updatedFolderObject = updatedFolder(folder, existingFolder)
              state.allFolderIdToVaultFolder[folder.id] = updatedFolderObject
              // update for current project
              state.currentProjectFolderIdToVaultFolder[folder.id] =
                updatedFolderObject
              const isRootFolder = folder.id === state.currentProject?.id
              if (isCurrentProject && isRootFolder) {
                state.currentProject = updatedFolderObject
                state.currentProjectMetadata = {
                  ...state.currentProjectMetadata,
                  name: updatedFolderObject.name,
                }
              }
              if (folder.parentId !== existingFolder.parentId) {
                if (existingFolder.parentId) {
                  state.allParentIdToVaultFolderIds[existingFolder.parentId] =
                    state.allParentIdToVaultFolderIds[
                      existingFolder.parentId
                    ]!.filter((id) => id !== existingFolder.id)
                  if (isCurrentProject) {
                    state.currentProjectParentIdToVaultFolderIds[
                      existingFolder.parentId
                    ] = state.currentProjectParentIdToVaultFolderIds[
                      existingFolder.parentId
                    ]!.filter((id) => id !== existingFolder.id)
                  }
                  if (
                    state.currentProjectFolderIdToVaultFolder[
                      existingFolder.parentId
                    ]
                  ) {
                    state.currentProjectParentIdToVaultFolderIds[
                      existingFolder.parentId
                    ]!.filter((id) => id !== existingFolder.id)
                    if (isCurrentProject) {
                      state.currentProjectParentIdToVaultFolderIds[
                        existingFolder.parentId
                      ]!.filter((id) => id !== existingFolder.id)
                    }
                  }
                } else {
                  updatedRootVaultFolderIds.delete(existingFolder.id)
                }
                if (folder.parentId) {
                  state.allParentIdToVaultFolderIds[folder.parentId] ??= []
                  state.allParentIdToVaultFolderIds[folder.parentId]!.push(
                    folder.id
                  )
                  // update for current project
                  if (isCurrentProject) {
                    state.currentProjectParentIdToVaultFolderIds[
                      folder.parentId
                    ] ??= []
                    state.currentProjectParentIdToVaultFolderIds[
                      folder.parentId
                    ]!.push(folder.id)
                  }
                } else {
                  updatedRootVaultFolderIds.add(folder.id)
                }
              }
            } else if (!existingFolder) {
              state.allFolderIdToVaultFolder[folder.id] = folder
              state.projectIdToFolderIds[folder.vaultProjectId] ??= []
              state.projectIdToFolderIds[folder.vaultProjectId]!.push(folder.id)
              if (isCurrentProject) {
                state.currentProjectFolderIdToVaultFolder[folder.id] = folder
              }
              if (folder.parentId) {
                state.allParentIdToVaultFolderIds[folder.parentId] ??= []
                state.allParentIdToVaultFolderIds[folder.parentId]!.push(
                  folder.id
                )
                // update for current project
                if (isCurrentProject) {
                  state.currentProjectParentIdToVaultFolderIds[
                    folder.parentId
                  ] ??= []
                  state.currentProjectParentIdToVaultFolderIds[
                    folder.parentId
                  ]!.push(folder.id)
                }
              } else {
                updatedRootVaultFolderIds.add(folder.id)
              }
            }

            // mark as shared project if user id is not the current user id
            if (folder.userId !== currentUserId && !isExampleProject) {
              updatedSharedProjectIds.add(folder.id)
            }
          })

          state.rootVaultFolderIds = updatedRootVaultFolderIds
          state.sharedProjectIds = updatedSharedProjectIds
        }),
      deleteVaultFiles: (fileIds, projectId) =>
        set((state) => {
          const isCurrentProject = projectId === state.currentProject?.id
          const updatedAllFileIdToVaultFile = { ...state.allFileIdToVaultFile }
          fileIds.forEach((id) => {
            delete updatedAllFileIdToVaultFile[id]
          })
          const updatedFileIdToVaultFile = {
            ...state.currentProjectFileIdToVaultFile,
          }
          if (isCurrentProject) {
            fileIds.forEach((id) => {
              delete updatedFileIdToVaultFile[id]
            })
          }
          return {
            allFileIdToVaultFile: updatedAllFileIdToVaultFile,
            currentProjectFileIdToVaultFile: updatedFileIdToVaultFile,
          }
        }),
      deleteVaultFolders: (folderIds, projectId) =>
        set((state) => {
          const isCurrentProject = projectId === state.currentProject?.id
          const updatedAllFolderIdToVaultFolder = {
            ...state.allFolderIdToVaultFolder,
          }
          folderIds.forEach((id) => {
            delete updatedAllFolderIdToVaultFolder[id]
          })
          const updatedFolderIdToVaultFolder = {
            ...state.currentProjectFolderIdToVaultFolder,
          }
          if (isCurrentProject) {
            folderIds.forEach((id) => {
              delete updatedFolderIdToVaultFolder[id]
            })
          }
          return {
            allFolderIdToVaultFolder: updatedAllFolderIdToVaultFolder,
            currentProjectFolderIdToVaultFolder: updatedFolderIdToVaultFolder,
          }
        }),
      addToProjectsMetadata: (projectsMetadata) => {
        set((state) => {
          state.allFoldersMetadata = {
            ...state.allFoldersMetadata,
            ...projectsMetadata,
          }
          if (state.currentProject) {
            state.projectIdToFolderIds[state.currentProject.id]?.forEach(
              (folderId) => {
                if (projectsMetadata[folderId]) {
                  state.foldersMetadata[folderId] = projectsMetadata[folderId]
                }
              }
            )
          }
          updateProjectMetadata(state)
        })
      },
      updateProjectMetadata: () => {
        set((state) => updateProjectMetadata(state))
      },
      updateProjectMetadataLastFileUploadedAt: (lastFileUploadedAt: string) => {
        set((state) => ({
          currentProjectMetadata: {
            ...state.currentProjectMetadata,
            lastFileUploadedAt: lastFileUploadedAt,
          },
        }))
      },
      updateProjectMetadataClientMatterId: (clientMatterId: string) => {
        set((state) => ({
          currentProjectMetadata: {
            ...state.currentProjectMetadata,
            clientMatterId: clientMatterId,
          },
        }))
      },
      setQueryId: (queryId) => set({ queryId }),
      setIsNewVaultReviewQuery: (isNewVaultReviewQuery) =>
        set({ isNewVaultReviewQuery }),
      setShowProcessingProgress: (projectId, showProcessingProgress) =>
        set((state) => ({
          showProcessingProgress: {
            ...state.showProcessingProgress,
            [projectId]: showProcessingProgress,
          },
        })),
      setShowRecentQueries: (showRecentQueries: boolean) =>
        set({ showRecentQueries }),
      setRecentlyUploadedFileIds: (fileIds: string[]) =>
        set({ recentlyUploadedFileIds: fileIds }),
      setRequiresProjectDataRefetch: (requiresProjectDataRefetch) =>
        set({ requiresProjectDataRefetch }),
      setExampleProjectIds: (exampleProjectIds: Set<string>) =>
        set({ exampleProjectIds }),
      setAreExampleProjectsLoaded: (areExampleProjectsLoaded: boolean) =>
        set({ areExampleProjectsLoaded }),
      markInProgressReviewTask: (queryId: string) =>
        set((state) => {
          if (state.queryIdToState[queryId]) {
            state.queryIdToState[queryId]!.isLoading = true
          }
        }),
      updateQueryIdToStateTitle: (queryId: string, title: string) =>
        set((state) => {
          if (state.queryIdToState[queryId]) {
            state.queryIdToState[queryId]!.title = title
          }
        }),
      // VaultQueryBoxAction
      setPendingQuery: (pendingQuery: string) => set({ pendingQuery }),
      // VaultSheetAction
      setActiveDocument: (activeDocument) => set({ activeDocument }),
      // VaultDialogAction
      setAreUploadButtonsDisabled: (areDisabled) =>
        set({ areUploadButtonsDisabled: areDisabled }),
      setIsCreateProjectDialogOpen: (isOpen) =>
        set({ isCreateProjectDialogOpen: isOpen }),
      setIsCreateFolderDialogOpen: (isOpen) =>
        set({ isCreateFolderDialogOpen: isOpen }),
      setCurrentCreateFolderFolderId: (folderId) =>
        set({ currentCreateFolderFolderId: folderId }),
      setIsUploadFilesDialogOpen: (isOpen) =>
        set({ isUploadFilesDialogOpen: isOpen }),
      setCurrentUploadFilesFolderId: (folderId) =>
        set({ currentUploadFilesFolderId: folderId }),
      setIsDeleteDialogOpen: (isOpen) => set({ isDeleteDialogOpen: isOpen }),
      setDeleteRecords: (records) => set({ deleteRecords: records }),
      setIsRenameDialogOpen: (isOpen) => set({ isRenameDialogOpen: isOpen }),
      setRenameRecord: (record) => set({ renameRecord: record }),
      setIsMoveDialogOpen: (isOpen) => set({ isMoveDialogOpen: isOpen }),
      setMoveRecords: (records) => set({ moveRecords: records }),
      setIsReviewQuerySelectionDialogOpen: (isOpen) =>
        set({ isReviewQuerySelectionDialogOpen: isOpen }),
      setIsExportDialogOpen: (isOpen) => set({ isExportDialogOpen: isOpen }),
      setIsAddFilesDialogOpen: (isOpen) =>
        set({ isAddFilesDialogOpen: isOpen }),
      setIsVaultAssistantModalOpen: (isOpen) =>
        set({ isVaultAssistantModalOpen: isOpen }),
      setIsEditClientMatterDialogOpen: (isOpen) =>
        set({ isEditClientMatterDialogOpen: isOpen }),
      setIsDuplicateModalOpen: (isOpen) =>
        set({ isDuplicateModalOpen: isOpen }),
      setIsColumnBuilderDialogOpen: (isOpen) =>
        set({ isColumnBuilderDialogOpen: isOpen }),
      setIsEditQueryTitleDialogOpen: (isOpen, taskType = null) =>
        set({
          isEditQueryTitleDialogOpen: isOpen,
          editQueryTaskType: taskType,
        }),
      setIsDuplicateTableDialogOpen: (isOpen) =>
        set({ isDuplicateTableDialogOpen: isOpen }),
      // VaultStreamingAction
      setTask: (socketState: Partial<VaultSocketTask>) =>
        set((state) => {
          const queryId = socketState.queryId ?? state.queryId
          if (
            state.queryIdToState[queryId] &&
            (socketState.isFromHistory ?? false) !==
              state.queryIdToState[queryId]!.isFromHistory
          ) {
            // If the new state data source is not the same as the existing state, we don't
            // want to override the existing state with the new state.
            return
          }

          state.queryIdToState[queryId] ??=
            generateEmptyVaultCurrentStreamingState(queryId)

          if (
            _.isNil(socketState.metadata) ||
            !('type' in socketState.metadata)
          ) {
            // When we have metadata in the socket state but don't have type, it means
            // it's end of the streaming and we are getting the final response, or we
            // are getting the history event of a N:1 query.
            state.queryIdToState[queryId] = {
              ...state.queryIdToState[queryId]!,
              ...socketState,
              ...socketState.metadata,
            } as VaultCurrentStreamingState // TODO: Fix and remove this assertion. The backend OpenAPI spec changed but was not updated on the frontend.
            return
          }

          console.error('setTask: socketState does not have metadata')
        }),
      setReviewTask: (socketState: Partial<VaultSocketTask>) =>
        set((state) => {
          const queryId = socketState.queryId ?? state.queryId
          if (
            state.queryIdToState[queryId] &&
            socketState.isFromHistory !== undefined &&
            socketState.isFromHistory !==
              state.queryIdToState[queryId]!.isFromHistory
          ) {
            // If the new state data source is not the same as the existing state, we don't
            // want to override the existing state with the new state.
            return
          }

          state.queryIdToState[queryId] ??=
            generateEmptyVaultCurrentStreamingState(queryId)
          state.queryIdToReviewState[queryId] ??=
            generateEmptyVaultReviewSocketState()

          if (
            _.isNil(socketState.metadata) ||
            !('type' in socketState.metadata)
          ) {
            // When we have metadata in the socket state but don't have type, it means
            // it's end of the streaming and we are getting the final response, or we
            // are getting the history event of a review query.
            state.queryIdToState[queryId] = {
              ...state.queryIdToState[queryId]!,
              ...socketState,
              ...socketState.metadata,
            } as VaultCurrentStreamingState // TODO: Fix and remove this assertion. The backend OpenAPI spec changed but was not updated on the frontend.
            state.queryIdToReviewState[queryId] = {
              ...state.queryIdToReviewState[queryId]!,
              ...socketState,
              ...socketState.metadata,
              fileIdToSources: {
                ...state.queryIdToReviewState[queryId]!.fileIdToSources,
                ..._.groupBy(
                  socketState.sources,
                  (source) => source.documentId
                ),
              },
            }
            return
          }

          console.error('setReviewTask: socketState does not have metadata')
        }),
      // VaultErrorAction
      setError: (error) => set({ error }),
      // VaultStartFromScratchActions
      setAddColumnPopoverPosition: (
        popoverPosition: AddColumnPopoverPosition | null
      ) => set({ addColumnPopoverPosition: popoverPosition }),
      resetVaultQueryState: () =>
        set((state) => ({
          ...state,
          ...initialVaultQueryState,
        })),
    }))
  )
)
