/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SensitiveCustomerDataPermission = {
    READ_ALL_SENSITIVE: 'read:all_sensitive',
    READ_SOME_SENSITIVE_DATA: 'read:some_sensitive_data',
    VIEW_CAN_USER_REDIRECT: 'view:can_user_redirect'
} as const;
export type SensitiveCustomerDataPermission = typeof SensitiveCustomerDataPermission[keyof typeof SensitiveCustomerDataPermission];


export function instanceOfSensitiveCustomerDataPermission(value: any): boolean {
    for (const key in SensitiveCustomerDataPermission) {
        if (Object.prototype.hasOwnProperty.call(SensitiveCustomerDataPermission, key)) {
            if (SensitiveCustomerDataPermission[key as keyof typeof SensitiveCustomerDataPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SensitiveCustomerDataPermissionFromJSON(json: any): SensitiveCustomerDataPermission {
    return SensitiveCustomerDataPermissionFromJSONTyped(json, false);
}

export function SensitiveCustomerDataPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensitiveCustomerDataPermission {
    return json as SensitiveCustomerDataPermission;
}

export function SensitiveCustomerDataPermissionToJSON(value?: SensitiveCustomerDataPermission | null): any {
    return value as any;
}

export function SensitiveCustomerDataPermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): SensitiveCustomerDataPermission {
    return value as SensitiveCustomerDataPermission;
}

