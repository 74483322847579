// This is a markdown file, exported as a single javascript string

const markdownContent = `
## Assistant agentic workflows are now available

Discover Harvey’s streamlined Workflows to quickly accomplish your tasks, without the need to prompt. These workflows proactively gather the 
necessary context and generate high-quality, task-specific outputs. They provide incremental progress updates, offering transparency into Harvey’s process.


`

export default markdownContent
