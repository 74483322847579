import React from 'react'
import {
  useLocation,
  useNavigationType,
  matchRoutes,
  createRoutesFromChildren,
} from 'react-router-dom'

import * as SentryBrowser from '@sentry/browser'
import * as SentryReact from '@sentry/react'

import { UserInfo } from 'models/user-info'
import { HarveyTeam } from 'openapi/models/HarveyTeam'

import {
  backendRestUrl,
  backendWebsocketUrl,
  environment,
  site,
} from './server-data'

export enum SentryEventType {
  USER_ACTION = 'user_action',
  EVENT = 'ui_event',
}

export const SentryDsns = {
  FRONTEND:
    'https://bd9aca7e931261471a71511d9f83aa9c@o4504924022505472.ingest.sentry.io/4506159071232000',
  HARVEY_FOR_WORD:
    'https://3bb2a42e9aef18035feb6873cc7219af@o4504924022505472.ingest.us.sentry.io/4508009277358080',
}

export const SentryAssignees: Record<string, HarveyTeam> = {
  /**
   * (If you need to assign to team) Add team in:
   * https://harvey-ai.sentry.io/settings/teams/
   *
   * Add ownership rule in the following to auto-assign issues to the team:
   * https://harvey-ai.sentry.io/settings/projects/backend/ownership/
   * https://harvey-ai.sentry.io/settings/projects/frontend/ownership/
   */
  ASSISTANT_V2: HarveyTeam.ASSISTANT_V2,
  INFRA: HarveyTeam.INFRA,
  PLATFORM: HarveyTeam.PLATFORM,
  TAX: HarveyTeam.TAX,
  VAULT: HarveyTeam.VAULT,
}
export type SentryAssignees =
  (typeof SentryAssignees)[keyof typeof SentryAssignees]

export const sentryEvent = (
  type: SentryEventType,
  subType: string,
  extra?: Record<string, unknown | undefined | null>
): void => {
  // Just to enforce our events being clean and organized
  const isSnakeCase = /^[a-z]+(_[a-z]+)*$/.test(subType)
  if (!isSnakeCase) {
    throw new Error('Message must be in snake_case')
  }

  SentryBrowser.addBreadcrumb({
    message: `${type}.${subType}__${JSON.stringify(extra)}`,
  })
}

export const initSentry = (dsn: string) => {
  SentryReact.init({
    dsn: dsn,
    beforeSend(event) {
      event.fingerprint = ['{{ default }}'] // salt the stack trace based error grouping criteria
      const errorType = event?.exception?.values?.[0]?.type || '<no error type>'
      event.fingerprint = [...event.fingerprint, `errorType: ${errorType}`]
      return event
    },
    tunnel: `${backendRestUrl}/sentry_tunnel`,
    integrations: [
      new SentryReact.BrowserTracing({
        routingInstrumentation: SentryReact.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      backendRestUrl ?? 'http://localhost:8000',
      backendWebsocketUrl ?? 'ws://localhost:8000',
    ],
    environment: environment,
    maxValueLength: 8192,
  })

  SentryReact.setTag('service', process.env.REACT_APP_SERVICE)
  SentryReact.setTag('revision', process.env.REACT_APP_REVISION)
  SentryReact.setTag('environment', environment)
  SentryReact.setTag('locale', navigator.language)
  SentryReact.setTag('site', site)
}

export const setOrClearSentryAssignee = (
  assignee: SentryAssignees | undefined
) => SentryReact.setTag('assignee', assignee)

export const setSentryWorkspace = (userInfo: UserInfo) => {
  SentryReact.setTag('workspaceId', userInfo.workspace.id.toString())
  SentryReact.setTag('workspaceSlug', userInfo.workspace.slug)
}
