/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SensitiveDataPermGranterEmail = {
    BKHARVEY_AI: 'bk@harvey.ai',
    DANAEHARVEY_AI: 'danae@harvey.ai'
} as const;
export type SensitiveDataPermGranterEmail = typeof SensitiveDataPermGranterEmail[keyof typeof SensitiveDataPermGranterEmail];


export function instanceOfSensitiveDataPermGranterEmail(value: any): boolean {
    for (const key in SensitiveDataPermGranterEmail) {
        if (Object.prototype.hasOwnProperty.call(SensitiveDataPermGranterEmail, key)) {
            if (SensitiveDataPermGranterEmail[key as keyof typeof SensitiveDataPermGranterEmail] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SensitiveDataPermGranterEmailFromJSON(json: any): SensitiveDataPermGranterEmail {
    return SensitiveDataPermGranterEmailFromJSONTyped(json, false);
}

export function SensitiveDataPermGranterEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensitiveDataPermGranterEmail {
    return json as SensitiveDataPermGranterEmail;
}

export function SensitiveDataPermGranterEmailToJSON(value?: SensitiveDataPermGranterEmail | null): any {
    return value as any;
}

export function SensitiveDataPermGranterEmailToJSONTyped(value: any, ignoreDiscriminator: boolean): SensitiveDataPermGranterEmail {
    return value as SensitiveDataPermGranterEmail;
}

