import { ReviewWorkflowCategory } from 'openapi/models/ReviewWorkflowCategory'
import { ReviewWorkflowCobrand } from 'openapi/models/ReviewWorkflowCobrand'
import { Tag } from 'openapi/models/Tag'
import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'
import { WorkflowOutputType } from 'openapi/models/WorkflowOutputType'

// To be deprecated after Workflows GA
export enum ProductCategory {
  ASSISTANT = 'assistant',
  VAULT = 'vault',
  LEGACY = 'legacy',
}

export enum WorkflowCategory {
  GENERAL = 'general',
  TRANSACTIONAL = 'transactional',
  IN_HOUSE = 'in_house',
  LITIGATION = 'litigation',
  FINANCIAL_SERVICES = 'financial_services',
  OTHER = 'other',
}

type BaseWorkflowInfo = {
  id: string
  name: string
  description: string
  // To be deprecated after Workflows GA
  productCategory: ProductCategory
  categories: WorkflowCategory[]
}

export type VaultWorkflowInfo = BaseWorkflowInfo & {
  // To be deprecated after Workflows GA
  productCategory: ProductCategory.VAULT
  numColumns: number
  documentTag?: Tag
  cobrand?: ReviewWorkflowCobrand
}

export type LegacyWorkflowInfo = BaseWorkflowInfo & {
  // To be deprecated after Workflows GA
  productCategory: ProductCategory.LEGACY
  path: string
}

export type AssistantWorkflowInfo = BaseWorkflowInfo & {
  slug: string
  tags: string[]
  userInputStepCount: number
  // To be deprecated after Workflows GA
  productCategory: ProductCategory.ASSISTANT
  path: string
  outputType: WorkflowOutputType
  docParamsNeededToStart?: WorkflowDefinitionListItem['docParamsNeededToStart']
  sampleOutput?: WorkflowDefinitionListItem['sampleOutput']
}

export type WorkflowInfo =
  | VaultWorkflowInfo
  | LegacyWorkflowInfo
  | AssistantWorkflowInfo

export const ASSISTANT_CATEGORY_TO_WORKFLOW_CATEGORY = {
  allen_and_overy: WorkflowCategory.GENERAL,
  general: WorkflowCategory.GENERAL,
  transactional: WorkflowCategory.TRANSACTIONAL,
  litigation: WorkflowCategory.LITIGATION,
  financial_services: WorkflowCategory.FINANCIAL_SERVICES,
}

export const REVIEW_WORKFLOW_CATEGORY_TO_WORKFLOW_CATEGORY = {
  [ReviewWorkflowCategory.GENERAL]: WorkflowCategory.GENERAL,
  [ReviewWorkflowCategory.TRANSACTIONAL]: WorkflowCategory.TRANSACTIONAL,
  [ReviewWorkflowCategory.LITIGATION]: WorkflowCategory.LITIGATION,
  [ReviewWorkflowCategory.FINANCIAL_SERVICES]:
    WorkflowCategory.FINANCIAL_SERVICES,
}

export type WorkflowCategoryTypes =
  | 'A&O Shearman'
  | 'Paul, Weiss'
  | 'For General Work'
  | 'For Transactional Work'
  | 'For In-House Work'
  | 'For Litigation Work'
  | 'For Financial Services'
