import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { EventKind } from 'openapi/models/EventKind'
import { EventStatus } from 'openapi/models/EventStatus'
import { KnowledgeSourceType } from 'openapi/models/KnowledgeSourceType'
import { WorkflowDefinition } from 'openapi/models/WorkflowDefinition'
import { WorkflowEventStatus } from 'openapi/models/WorkflowEventStatus'
import Services from 'services'

import { HarveySocketTask } from 'utils/use-harvey-socket'
import { ToFrontendKeys } from 'utils/utils'

import { mergeSteps } from 'components/assistant/workflows/utils/utils'

export enum WorkflowStatus {
  IN_PROGRESS = 'in_progress',
  PENDING_USER_INPUT = 'pending_user_input',
  COMPLETED = 'completed',
}

export enum WorkflowFileUploadSource {
  VAULT = 'vault',
  FILES = 'files',
}

interface AssistantWorkflowState {
  workflowDefinition: WorkflowDefinition | null
  currentEvent: (WorkflowEventStatus & { isPending?: boolean }) | null
  workflowEventBeforeRequest:
    | (WorkflowEventStatus & { isPending?: boolean })
    | null
  blockStores: Record<number, any>
  pendingMessage: boolean
  streamInProgress: boolean
  workflowHasSources: boolean
  isCurrentEventLibraryEvent: boolean
  vaultProjectId: string | null
  fileUploadSource: WorkflowFileUploadSource | null
}

interface AssistantWorkflowAction {
  setCurrentWorkflow: (
    definition: WorkflowDefinition,
    event: WorkflowEventStatus | null,
    optimistic?: boolean,
    isLibraryEvent?: boolean
  ) => void
  workflowSetter: (socketState: Partial<HarveySocketTask>) => void
  reset: () => void
  addBlockStore: (stepIdx: number, blockStore: any) => void
  setPendingEventId: (eventId: number) => void
  getOrCreateEventId: () => Promise<number>
  setPendingMessage: (pending: boolean) => void
  setStreamInProgress: (inProgress: boolean) => void
  saveEventBeforeRequest: () => void
  restoreEventBeforeRequest: () => void
  resetBlockStore: (stepIdx: number) => void
  resetBlockStoresAfterStepId: (stepId: string) => void
  setCurrentEventCaption: (caption: string | undefined) => void
  setWorkflowHasSources: (hasSources: boolean) => void
  getWorkflowStatus: () => WorkflowStatus
  getCurrentStepIdx: () => number
  getCurrentEventId: () => string | undefined
  setVaultProjectId: (projectId: string | null) => boolean
  setFileUploadSource: (source: WorkflowFileUploadSource | null) => void
}

const initialState: AssistantWorkflowState = {
  workflowDefinition: null,
  currentEvent: null,
  workflowEventBeforeRequest: null,
  blockStores: {},
  // Whether a socket message is getting sent
  pendingMessage: false,
  // Whether the socket is currently streaming
  streamInProgress: false,
  workflowHasSources: false,
  isCurrentEventLibraryEvent: false,
  vaultProjectId: null,
  fileUploadSource: null,
}

export type AssistantWorkflowStore = AssistantWorkflowState &
  AssistantWorkflowAction

export const useAssistantWorkflowStore = create(
  devtools(
    immer<AssistantWorkflowStore>((set, get) => ({
      ...initialState,

      reset: () => {
        for (const blockStoreIdx of Object.keys(get().blockStores)) {
          get().resetBlockStore(parseInt(blockStoreIdx))
        }
        set(() => initialState)
      },

      getCurrentEventId: () => {
        return get().currentEvent?.eventId?.toString()
      },

      getOrCreateEventId: async (): Promise<number> => {
        const currEventId = get().currentEvent?.eventId
        if (!currEventId) {
          const { id } = await Services.Backend.Post<{ id: number }>('event', {
            kind: EventKind.ASSISTANT,
          })
          get().setPendingEventId(id)
          return id
        }
        return currEventId
      },

      setPendingEventId: (eventId: number) => {
        set((state) => {
          state.currentEvent = {
            allowsFollowUps: state.currentEvent?.allowsFollowUps ?? false,
            eventId,
            isPending: true,
            isCompleted: false,
            steps: state.currentEvent?.steps || [],
          }
        })
      },

      addBlockStore: (stepIdx, blockStore) => {
        set((state) => {
          state.blockStores[stepIdx] = blockStore
        })
      },

      // eslint-disable-next-line max-params
      setCurrentWorkflow: (
        definition: WorkflowDefinition,
        event: WorkflowEventStatus | null,
        optimistic: boolean = false,
        isLibraryEvent?: boolean
      ) => {
        set((state) => {
          state.workflowDefinition = definition

          if (
            !state.currentEvent ||
            (event && state.currentEvent.eventId !== event.eventId) ||
            optimistic
          ) {
            state.currentEvent = event
          }

          // find the first knowledge source. we need to record this
          // because vault cannot mix with other files
          for (const step of event?.steps ?? []) {
            if (
              step.outputData &&
              'knowledgeSources' in step.outputData &&
              Array.isArray(step.outputData.knowledgeSources) &&
              step.outputData.knowledgeSources.length > 0
            ) {
              if (
                step.outputData.knowledgeSources[0].type ===
                KnowledgeSourceType.VAULT
              ) {
                state.vaultProjectId =
                  step.outputData.knowledgeSources[0].folderId
                state.fileUploadSource = WorkflowFileUploadSource.VAULT
                break
              } else if (
                step.outputData.knowledgeSources[0].type ===
                KnowledgeSourceType.FILES
              ) {
                state.fileUploadSource = WorkflowFileUploadSource.FILES
                break
              }
            }
          }
          // isLibraryEvent is not passed in on some code paths, e.g., when canceling a step and refreshing the state of
          // the current workflow. In this case, we default to the existing state.
          state.isCurrentEventLibraryEvent =
            isLibraryEvent ?? state.isCurrentEventLibraryEvent
        })
      },

      workflowSetter: (socketState: Partial<HarveySocketTask>) => {
        const newWorkflow = socketState.workflow
          ? ToFrontendKeys(socketState.workflow)
          : null
        if (!newWorkflow) return

        set((state) => {
          // If it's a full workflow update, just set it
          // If there's no existing workflow, just set it
          if (!newWorkflow.isPartialUpdate || !state.currentEvent) {
            state.currentEvent = newWorkflow
            return
          }

          // Otherwise merge top-level fields + steps
          const mergedSteps = mergeSteps(
            get().currentEvent?.steps ?? [],
            newWorkflow.steps ?? []
          )

          // Merge everything else (isCompleted, eventId, etc.)
          state.currentEvent = {
            ...newWorkflow,
            steps: mergedSteps,
          }
        })
      },

      setPendingMessage: (pending: boolean) => {
        set((state) => ({
          ...state,
          pendingMessage: pending,
        }))
      },

      setStreamInProgress: (inProgress: boolean) => {
        set((state) => {
          state.streamInProgress = inProgress
        })
      },

      saveEventBeforeRequest: () => {
        set((state) => {
          if (state.currentEvent) {
            state.workflowEventBeforeRequest = state.currentEvent
          }
        })
      },

      restoreEventBeforeRequest: () => {
        set((state) => {
          if (state.workflowEventBeforeRequest) {
            state.currentEvent = state.workflowEventBeforeRequest
          }
        })
      },

      resetBlockStore(stepIdx: number) {
        get().blockStores[stepIdx].getState().reset()
      },

      resetBlockStoresAfterStepId: (stepId: string) => {
        const stepIdx = get().currentEvent?.steps.findIndex(
          (step) => step.stepId === stepId
        )
        if (stepIdx === undefined || stepIdx === -1) return
        for (const blockStoreIdx of Object.keys(get().blockStores)) {
          if (parseInt(blockStoreIdx) > stepIdx) {
            get().resetBlockStore(parseInt(blockStoreIdx))
          }
        }
      },

      setCurrentEventCaption: (caption: string | undefined) => {
        set((state) => {
          if (state.currentEvent) {
            state.currentEvent.caption = caption
          }
        })
      },

      setWorkflowHasSources: (hasSources: boolean) => {
        set((state) => {
          state.workflowHasSources = hasSources
        })
      },

      getWorkflowStatus: () => {
        const { currentEvent } = get()
        const steps = currentEvent?.steps
        if (!steps) return WorkflowStatus.COMPLETED
        if (!steps.every((step) => step.paramStatus === EventStatus.COMPLETED))
          return WorkflowStatus.IN_PROGRESS
        if (
          !steps.every(
            (step) => step.completionStatus === EventStatus.COMPLETED
          )
        )
          return WorkflowStatus.PENDING_USER_INPUT
        return WorkflowStatus.COMPLETED
      },

      getCurrentStepIdx: () => {
        const { currentEvent } = get()
        return (currentEvent?.steps ?? []).length - 1
      },

      setFileUploadSource: (source: WorkflowFileUploadSource | null) => {
        set((state) => {
          state.fileUploadSource = source
        })
      },

      setVaultProjectId: (projectId: string | null) => {
        const { currentEvent } = get()
        // Only allow projectId to be set to null if we're at the first step and not completed
        if (projectId === null) {
          const isFirstStep = (currentEvent?.steps ?? []).length === 1
          const isNotCompleted = !currentEvent?.isCompleted

          if (!isFirstStep || !isNotCompleted) {
            return false
          }
        }

        set((state) => {
          state.vaultProjectId = projectId
        })
        return true
      },
    }))
  )
)

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('assistant-workflow-store', useAssistantWorkflowStore)
}
