import { convertMillimetersToTwip } from 'docx'
import saveAs from 'file-saver'

import { WordSection } from './docx'
import { exportWordWithSections, logExport } from './markdown'
import { TaskType } from './task'
import { backendFormat } from './utils'

export const createExportFilename = (base: string) =>
  `${base}_${backendFormat(new Date())}.docx`

export const exportTaskDocumentFromBackend = async (
  url: string,
  taskType: TaskType,
  queryId: string,
  file_name: string | undefined = undefined
  // eslint-disable-next-line max-params
) => {
  const response = await fetch(url)
  const blob = await response.blob()
  saveAs(blob, file_name ?? createExportFilename(taskType))
  void logExport('docx', taskType, queryId)
}

export function exportAsFormattedWordDocx({
  title,
  sections,
  includeAnnotation = true,
  onSuccess,
  onError,
}: {
  title: string
  sections: WordSection[]
  includeAnnotation?: boolean
  onSuccess?: () => void
  onError?: (message: string) => void
}): Promise<void> {
  return exportWordWithSections({
    defaultStyleOverrides: {
      heading1: {
        paragraph: {
          spacing: {
            before: convertMillimetersToTwip(5),
          },
        },
      },
      heading2: {
        paragraph: {
          spacing: {
            before: convertMillimetersToTwip(2),
          },
        },
      },
      heading3: {
        paragraph: {
          spacing: {
            before: convertMillimetersToTwip(2),
          },
        },
      },
    },
    filePrefixOverride: title,
    includeAnnotation,
    queryId: '0',
    sections,
    taskType: TaskType.ASSISTANT,
    title,
  })
    .then(() => onSuccess?.())
    .catch((error) => onError?.(error.message))
}
