import { AntitrustFilingsAnalysisPayload } from 'openapi/models/AntitrustFilingsAnalysisPayload'

import { modelMarkdownToHtmlRemark } from 'utils/markdown'

import { computeStatus } from './utils'
import { getCountryCodesSortedByCountryName } from './utils'
import { getCountriesWithAllThresholdsMet } from './utils'
import { getCountriesWithAllThresholdsNotMet } from './utils'
import { getCountriesWithMoreInfoRequired } from './utils'
import { calculateCombinedDomesticTurnover } from './utils'
import { formatConclusion } from './utils'

/**
 * Generate HTML content for antitrust analysis report that matches the UI format
 * @param data The antitrust filings analysis metadata
 * @returns HTML string for the report
 */
export const generateAntitrustAnalysisHtml = (
  data: AntitrustFilingsAnalysisPayload
): string => {
  const { countryCodeToAnalysis } = data
  const sortedCountryCodes = getCountryCodesSortedByCountryName(
    countryCodeToAnalysis
  )

  const thresholdsMet = getCountriesWithAllThresholdsMet(
    sortedCountryCodes,
    countryCodeToAnalysis
  )
  const thresholdsNotMet = getCountriesWithAllThresholdsNotMet(
    sortedCountryCodes,
    countryCodeToAnalysis
  )
  const moreInfoRequired = getCountriesWithMoreInfoRequired(
    sortedCountryCodes,
    countryCodeToAnalysis
  )

  // Add basic styles for the document
  let content = `
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
            margin-bottom: 20px;
          }
          th, td {
            border: 1px solid #000;
            padding: 6px;
            vertical-align: top;
            font-size: 10pt;
          }
          th {
            background-color: #f2f2f2;
            font-weight: bold;
          }
          h1, h2, h3 {
            page-break-after: avoid;
          }
          ol {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 20px;
          }
          ul {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 20px;
          }
          p {
            margin-top: 3px;
            margin-bottom: 3px;
          }
        </style>
      `

  content += '<h1>Antitrust Filing Analysis</h1>'

  // Add summary section
  content += '<h2>Executive Summary</h2>'

  if (thresholdsMet.length > 0) {
    content += '<h3>Jurisdictions Where Thresholds Are Met</h3>'
    content +=
      '<p>Based on the information provided, filing requirements appear to be triggered in the following jurisdictions:</p>'
    content += '<ul>'
    thresholdsMet.forEach((countryCode) => {
      content += `<li><strong>${countryCodeToAnalysis[countryCode].countryName}</strong></li>`
    })
    content += '</ul>'
  }

  if (thresholdsNotMet.length > 0) {
    content += '<h3>Jurisdictions Where Thresholds Are Not Met</h3>'
    content +=
      '<p>Based on the information provided, filing requirements appear not to be triggered in the following jurisdictions:</p>'
    content += '<ul>'
    thresholdsNotMet.forEach((countryCode) => {
      content += `<li><strong>${countryCodeToAnalysis[countryCode].countryName}</strong></li>`
    })
    content += '</ul>'
  }

  if (moreInfoRequired.length > 0) {
    content += '<h3>Jurisdictions Requiring Additional Information</h3>'
    content +=
      '<p>The following jurisdictions require additional information to determine filing requirements:</p>'
    content += '<ul>'
    moreInfoRequired.forEach((countryCode) => {
      content += `<li><strong>${countryCodeToAnalysis[countryCode].countryName}</strong></li>`
    })
    content += '</ul>'
  }

  // Add detailed analysis section
  content += '<h2>Detailed Jurisdictional Analysis</h2>'

  // Create the export table header
  content += `
        <h3>Export Table</h3>
        <table>
          <tr>
            <th>Jurisdiction</th>
            <th>Turnover</th>
            <th>Thresholds</th>
            <th>Analysis</th>
            <th>Additional Information</th>
          </tr>
      `

  // Process each country
  sortedCountryCodes.forEach((countryCode) => {
    const country = countryCodeToAnalysis[countryCode]
    const status = computeStatus(country)

    // Start row for this country
    content += '<tr>'

    // COLUMN 1: Country with status in parentheses
    content += '<td>'
    content += `<strong>${country.countryName}</strong>`
    content += ` (${status})` // Simply add status in parentheses
    content += '</td>'

    // COLUMN 2: Revenues
    content += '<td>'
    content += '<strong>Local Turnover</strong><br/>'

    if (country.partyRevenues.length > 0) {
      // Find acquirer and target domestic revenues
      const acquirerDomestic = country.partyRevenues.find(
        (p) => p.partyType === 'acquirer'
      )
      const targetDomestic = country.partyRevenues.find(
        (p) => p.partyType === 'target'
      )

      const acquirerPartyName = acquirerDomestic?.partyName
      const targetPartyName = targetDomestic?.partyName
      // Format with thousands separators
      const acquirerValue = acquirerDomestic
        ? Math.round(acquirerDomestic.revenue).toLocaleString()
        : '0'
      const targetValue = targetDomestic
        ? Math.round(targetDomestic.revenue).toLocaleString()
        : '0'

      content += `${acquirerPartyName} (Acquirer): $${acquirerValue}<br/>`
      content += `${targetPartyName} (Target): $${targetValue}<br/>`

      // Calculate CDTO more simply
      const cdto = calculateCombinedDomesticTurnover(country.partyRevenues)
      content += `CDTO: $${Math.round(cdto).toLocaleString()}<br/><br/>`

      // Add worldwide revenues
      content += '<strong>Worldwide Turnover</strong><br/>'

      if (data.worldwidePartyRevenues.length > 0) {
        const acquirerWorldwide = data.worldwidePartyRevenues.find(
          (p) => p.partyType === 'acquirer'
        )
        const targetWorldwide = data.worldwidePartyRevenues.find(
          (p) => p.partyType === 'target'
        )

        const acquirerPartyNameWW = acquirerWorldwide?.partyName
        const targetPartyNameWW = targetWorldwide?.partyName

        // Format with thousands separators
        const acquirerWWValue = acquirerWorldwide
          ? Math.round(acquirerWorldwide.revenue).toLocaleString()
          : '0'
        const targetWWValue = targetWorldwide
          ? Math.round(targetWorldwide.revenue).toLocaleString()
          : '0'

        content += `${acquirerPartyNameWW} (Acquirer): $${acquirerWWValue}<br/>`
        content += `${targetPartyNameWW} (Target): $${targetWWValue}<br/>`

        // Calculate CWTO directly from worldwide revenues
        const cwto = data.worldwidePartyRevenues.reduce(
          (sum, party) => sum + party.revenue,
          0
        )
        content += `CWTO: $${Math.round(cwto).toLocaleString()}`
      } else {
        content += 'No worldwide turnover data available'
      }
    } else {
      content += 'No turnover data available'
    }
    content += '</td>'

    // COLUMN 3: Thresholds Analysis
    content += '<td>'
    if (country.thresholds.length > 0) {
      // Process each threshold with better spacing and structure
      country.thresholds.forEach((threshold, thresholdIndex) => {
        if (thresholdIndex > 0) content += '<br/>' // Add divider between thresholds

        content += `<strong>${threshold.name}</strong><br/>`

        if (threshold.requirements.length > 0) {
          // Use divs with manual numbering instead of ordered list
          content += `<div class=“threshold-requirements” style=“margin-top: 5px; margin-bottom: 5px;”>`

          threshold.requirements.forEach((req, reqIndex) => {
            const reqText =
              modelMarkdownToHtmlRemark(req.requirement) ||
              `Requirement ${reqIndex + 1}`

            // Add the requirement with manual numbering
            content += `<div style="margin-bottom: 4px;"><em>Requirement ${
              reqIndex + 1
            }.</em> ${reqText}</div>`
            // Add AND between requirements except after the last one
            if (reqIndex < threshold.requirements.length - 1) {
              content += `<br/><div style=“margin-left: 20px; margin-bottom: 4px; font-weight: bold;”>AND</div>`
            }
          })
          content += '</div>'
        } else {
          content += 'No detailed requirements available'
        }
      })
    } else if (computeStatus(country) === 'No merger control regime') {
      // Using computeStatus instead of a non-existent property
      content += '<p>No merger control regime</p>'
    } else {
      content += 'No threshold analysis available'
    }
    content += '</td>'

    // COLUMN 4: Conclusion
    content += '<td>'
    if (country.thresholds.length > 0) {
      // Process each threshold
      country.thresholds.forEach((threshold, thresholdIndex) => {
        if (thresholdIndex > 0) content += '<br/>' // Add divider between thresholds

        // Display the threshold conclusion as is, wrapped in brackets
        content += `<strong>[${formatConclusion(
          threshold.overallConclusion
        )}]</strong> ${threshold.name}<br/>`

        // Add conclusions for each requirement
        if (threshold.requirements.length > 0) {
          // Don't use an ordered list - use divs with manual numbering
          content += `<div class=“requirement-conclusions”>`

          threshold.requirements.forEach((req, reqIndex) => {
            // Display the requirement conclusion as is, wrapped in brackets
            content += `<div style="margin-bottom: 4px;"><em>Requirement ${
              reqIndex + 1
            }.</em> [${formatConclusion(req.requirementConclusion)}] ${
              req.requirementAnalysis || 'Requirement ${reqIndex + 1}'
            }</div><br/>`
          })

          content += '</div>'
        }
      })
    } else {
      content += 'No conclusions available'
    }
    content += '</td>'

    // COLUMN 5: Additional Information
    content += '<td>'

    // Use the additionalInsights field for country-specific information
    if (country.additionalInsights) {
      // Parse or use the additionalInsights field as needed
      const asHtml = modelMarkdownToHtmlRemark(country.additionalInsights)

      content += asHtml
    } else {
      // Standard filing information if no specific insights available
      content +=
        '<p>No additional information available for this jurisdiction.</p>'
    }

    content += '</td>'
  })

  // Close the table
  content += '</table>'

  return content
}
