/* tslint:disable */
/* eslint-disable */
/**
 * File Upload Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FileFailureCategory = {
    UNSUPPORTED_FILE_TYPE: 'UNSUPPORTED_FILE_TYPE',
    EMPTY_DOCUMENT: 'EMPTY_DOCUMENT',
    FILE_TYPE_MISMATCH: 'FILE_TYPE_MISMATCH',
    PASSWORD_PROTECTED: 'PASSWORD_PROTECTED',
    OCR_ERROR: 'OCR_ERROR',
    DOCUMENT_TOO_LARGE: 'DOCUMENT_TOO_LARGE',
    UPLOAD_NO_FILENAME: 'UPLOAD_NO_FILENAME',
    UPLOAD_FILE_TOO_LARGE: 'UPLOAD_FILE_TOO_LARGE',
    UPLOAD_INVALID_FILE_TYPE: 'UPLOAD_INVALID_FILE_TYPE',
    OTHER_UPLOAD_ERROR: 'OTHER_UPLOAD_ERROR',
    RECOVERABLE_ERROR: 'RECOVERABLE_ERROR',
    INTERNAL_ERROR: 'INTERNAL_ERROR',
    UNKNOWN: 'UNKNOWN'
} as const;
export type FileFailureCategory = typeof FileFailureCategory[keyof typeof FileFailureCategory];


export function instanceOfFileFailureCategory(value: any): boolean {
    for (const key in FileFailureCategory) {
        if (Object.prototype.hasOwnProperty.call(FileFailureCategory, key)) {
            if (FileFailureCategory[key as keyof typeof FileFailureCategory] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FileFailureCategoryFromJSON(json: any): FileFailureCategory {
    return FileFailureCategoryFromJSONTyped(json, false);
}

export function FileFailureCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileFailureCategory {
    return json as FileFailureCategory;
}

export function FileFailureCategoryToJSON(value?: FileFailureCategory | null): any {
    return value as any;
}

export function FileFailureCategoryToJSONTyped(value: any, ignoreDiscriminator: boolean): FileFailureCategory {
    return value as FileFailureCategory;
}

