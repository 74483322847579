import React from 'react'

import { PlusIcon } from 'lucide-react'

import { Button } from 'components/ui/button'
import { DropdownMenuSeparator } from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Spinner } from 'components/ui/spinner'

export const AssistantCreateNewVaultProjectDropdownButton = ({
  onClick,
  isLoading,
}: {
  onClick: () => void
  isLoading: boolean
}) => {
  return (
    <div className="w-full px-2">
      <DropdownMenuSeparator className="-mx-2 my-2" />
      <Button
        variant="link"
        className="flex w-full items-center justify-between gap-2 bg-primary px-2 transition-colors hover:bg-secondary-hover hover:text-primary hover:disabled:bg-primary"
        data-testid="create-new-vault-project-button"
        onClick={onClick}
        disabled={isLoading}
      >
        <div className="flex-1 truncate text-left text-sm font-normal leading-5">
          Create new project
        </div>
        {isLoading ? (
          <Spinner size="xs" className="mr-0" />
        ) : (
          <Icon icon={PlusIcon} />
        )}
      </Button>
    </div>
  )
}
