import React from 'react'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Switch } from 'components/ui/switch'

export const DownloadDialog = ({
  onOpenChange,
  isOpen,
  additionalSourcesCount,
  includeAdditionalSources,
  setIncludeAdditionalSources,
  selectedDocumentsCount,
  isDownloading,
  handleDownload,
  handleCancel,
}: {
  onOpenChange: (open: boolean) => void
  isOpen: boolean
  additionalSourcesCount: number
  selectedDocumentsCount: number
  includeAdditionalSources: boolean
  setIncludeAdditionalSources: (include: boolean) => void
  isDownloading: boolean
  handleDownload: (e: React.FormEvent) => void
  handleCancel: () => void
}) => {
  const handleOpenChange = (open: boolean) => {
    onOpenChange(open)
    if (!open) {
      handleCancel()
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent
        closeIconClassName="right-1 top-1"
        className="max-w-80"
        showCloseIcon
        innerClassName="p-0"
      >
        <DialogHeader className="border-b p-3">
          <DialogTitle className="text-base">Download sources</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleDownload}>
          <div className="px-5 pb-5 pt-2">
            <label className="flex justify-between text-sm">
              Include {additionalSourcesCount} additional sources
              <Switch
                checked={includeAdditionalSources}
                onCheckedChange={() =>
                  setIncludeAdditionalSources(!includeAdditionalSources)
                }
              />
            </label>
          </div>
          <div className="flex items-center justify-between border-t p-3">
            <span className="text-xs leading-4 text-muted">
              {selectedDocumentsCount} files selected
            </span>
            <div className="flex gap-2">
              <DialogClose asChild>
                <Button variant="outline" onClick={handleCancel}>
                  Cancel
                </Button>
              </DialogClose>
              <Button
                type="submit"
                isLoading={isDownloading}
                disabled={isDownloading || selectedDocumentsCount === 0}
              >
                Download
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
