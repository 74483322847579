import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { DmsFile } from 'openapi/models/DmsFile'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { useIntegrationsStore } from 'stores/integrations-store'
import { FileType } from 'types/file'

import GoogleDriveLogo from 'components/common/integrations/google-drive-logo'
import { Button } from 'components/ui/button'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface Props {
  onUploadFromGoogleDrive: (files: File[]) => Promise<void>
  onFilesSelectionFromGoogleDrive?: (
    dmsFiles: DmsFile[],
    localFiles: File[]
  ) => Promise<void>
  acceptedFileTypes: FileType[]
  maxFileCount?: number
  size?: 'sm' | 'lg'
}

const GoogleDriveButton: React.FC<Props> = ({
  onUploadFromGoogleDrive,
  onFilesSelectionFromGoogleDrive,
  acceptedFileTypes,
  maxFileCount,
  size = 'sm',
}) => {
  const [setIntegrationFilePickerOpenState] = useIntegrationsStore(
    useShallow((state) => [state.setIntegrationFilePickerOpenState])
  )

  const currentUploadFilesFolderId = useVaultStore(
    (s) => s.currentUploadFilesFolderId
  )

  const handleGoogleDriveClick = () => {
    setIntegrationFilePickerOpenState({
      acceptedFileTypes: acceptedFileTypes,
      onUploadFromIntegration: onUploadFromGoogleDrive,
      onFilesSelectionFromIntegration:
        onFilesSelectionFromGoogleDrive ?? undefined,
      integrationType: IntegrationType.GOOGLE_DRIVE,
      maxFileCount,
      containerId: currentUploadFilesFolderId ?? undefined,
    })
  }

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        handleGoogleDriveClick()
      }}
      onKeyDown={(e) => {
        e.stopPropagation()
        handleGoogleDriveClick()
      }}
      size={size}
      variant="outline"
      className="pointer-events-auto w-full px-3"
    >
      <GoogleDriveLogo />
    </Button>
  )
}

export default GoogleDriveButton
