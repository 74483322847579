import React from 'react'

import { UserInfo } from 'models/user-info'
import Services from 'services'

import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import { TextLink } from 'components/ui/text-link'

interface NotAuthorizedStrings {
  title: ErrorPageTitle
  description: React.ReactNode
}

interface NotAuthorizedScreenProps {
  logoutHandler: () => void
  userInfo: UserInfo
}

const PWC_STRINGS: NotAuthorizedStrings = {
  title: ErrorPageTitle.NOT_AUTHORIZED,
  description: (
    <p>
      We apologize for the inconvenience, but it seems you are unable to access
      the Harvey Platform at the moment.
      <br />
      <br />
      For assistance, please visit the{' '}
      <TextLink
        label="PwC Harvey Programme SharePoint page"
        href="https://pwc.sharepoint.com/sites/GBL-xLoS-PwC-HarveyAI"
      />
      , where you will find the Help Centre.
      <br />
      This page will provide you with the necessary support and resources.
    </p>
  ),
}

const GENERIC_STRINGS: NotAuthorizedStrings = {
  title: ErrorPageTitle.NOT_AUTHORIZED,
  description:
    'This is likely caused by your account not having proper access to the Harvey platform.\nPlease contact your internal Harvey administrator for assistance.\nIf this issue persists and more help is needed, contact support@harvey.ai.',
}

const NotAuthorizedScreen: React.FC<NotAuthorizedScreenProps> = ({
  logoutHandler,
  userInfo,
}) => {
  let currentStrings = userInfo.IsPwcUser ? PWC_STRINGS : GENERIC_STRINGS

  if (
    userInfo.fetchErr?.message &&
    userInfo.fetchErr?.message.includes('disallowed on this network')
  ) {
    currentStrings = {
      title: ErrorPageTitle.INCORRECT_NETWORK,
      description: userInfo.fetchErr?.message,
    }
  }

  Services.HoneyComb.Record({
    metric: 'ui.show_not_authorized_screen',
    user_id: userInfo.id,
    is_pwc: userInfo.IsPwcUser,
    perms: userInfo.permissions,
    perms_with_source: userInfo.permsWithSource,
    workspace_id: userInfo.workspace.id,
  })

  return (
    <ErrorPage
      title={currentStrings.title}
      description={currentStrings.description}
      primaryCTA={{
        text: 'Retry',
        onClick: () => (window.location.pathname = '/'),
      }}
      secondaryCTA={{
        text: 'Logout',
        onClick: logoutHandler,
      }}
    />
  )
}

export default NotAuthorizedScreen
