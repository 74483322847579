import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReactTyped } from 'react-typed'

import { PencilIcon, User } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { EventStatus } from 'openapi/models/EventStatus'

import { cn } from 'utils/utils'

import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import {
  useAssistantWorkflowStore,
  WorkflowStatus,
} from 'components/assistant/workflows/stores/assistant-workflow-store'
import HarveyLogo from 'components/common/harvey-logo'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'

export const AssistantWorkflowLayoutContainer: React.FC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  return (
    <div className={cn('mr-9 flex items-start space-x-3', className)}>
      {children}
    </div>
  )
}

export const AssistantWorkflowLayoutIconComponent: React.FC<{
  children?: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'flex size-6 shrink-0 items-center justify-center rounded-full',
        className
      )}
    >
      {children}
    </div>
  )
}

export const AssistantWorkflowYouComponent: React.FC<{
  onEditToggle?: () => void
  children: React.ReactNode
}> = ({ children, onEditToggle }) => {
  const [getWorkflowStatus, isLibraryEvent] = useAssistantWorkflowStore(
    useShallow((state) => [
      state.getWorkflowStatus,
      state.isCurrentEventLibraryEvent,
    ])
  )

  const workflowStatus = getWorkflowStatus()
  const showEditButton =
    onEditToggle &&
    !isLibraryEvent &&
    workflowStatus !== WorkflowStatus.IN_PROGRESS

  return (
    <AssistantWorkflowLayoutContainer
      data-testid="assistant-workflow-you"
      className="group relative"
    >
      <AssistantWorkflowLayoutIconComponent className="border">
        <Icon icon={User} className="size-4 text-inactive" />
      </AssistantWorkflowLayoutIconComponent>
      <div
        className={cn('relative flex-1 items-center', { flex: !!onEditToggle })}
      >
        {children}
        {showEditButton && (
          <div className="relative ml-2 self-stretch">
            <Button
              onClick={onEditToggle}
              variant="ghost"
              size="smIcon"
              className="absolute inset-y-0 m-auto opacity-0 transition group-hover:opacity-100"
              data-testid="assistant-workflow-edit-button"
              tooltip="Edit"
            >
              <Icon icon={PencilIcon} size="small" />
            </Button>
          </div>
        )}
      </div>
    </AssistantWorkflowLayoutContainer>
  )
}

export const AssistantWorkflowHarveyComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <AssistantWorkflowLayoutContainer>
      <AssistantWorkflowLayoutIconComponent className="bg-accent">
        <HarveyLogo className="size-3" />
      </AssistantWorkflowLayoutIconComponent>
      <div className="flex-1">{children}</div>
    </AssistantWorkflowLayoutContainer>
  )
}

export const AssistantWorkflowThreadText: React.FC<{
  text: string
  completionStatus: EventStatus
  skipTypewriter?: boolean
}> = ({ text, completionStatus, skipTypewriter }) => {
  const [hasDoneInitialTypewriter, setHasDoneInitialTypewriter] =
    useState(false)

  // TODO: PENDING isn't in EventStatus?
  const doTypewriter =
    ['PENDING', EventStatus.IN_PROGRESS].includes(completionStatus) &&
    !hasDoneInitialTypewriter &&
    !skipTypewriter

  return doTypewriter ? (
    <ReactTyped
      className="text-sm text-primary *:text-sm"
      strings={[text]}
      typeSpeed={2}
      showCursor={!hasDoneInitialTypewriter}
      onComplete={() => setHasDoneInitialTypewriter(true)}
    />
  ) : (
    <span className="text-sm text-primary">{text}</span>
  )
}

const AssistantWorkflowThreadBlockSidebar: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  return (
    <div className="box-border w-40 min-w-[auto] grow basis-0 sm:w-80">
      {children}
    </div>
  )
}

export const AssistantWorkflowThreadBlock: React.FC<{
  children: React.ReactNode
  sidebar?: React.ReactNode
  stepIdx?: number
}> = ({ children, sidebar, stepIdx }) => {
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)
  const [workflowHasSources, setWorkflowHasSources] = useAssistantWorkflowStore(
    useShallow((s) => [s.workflowHasSources, s.setWorkflowHasSources])
  )

  // If the sidebar is present, we need to set the workflowHasSources to true
  // This allows us to ensure all of the workflow blocks look the same
  // Once a workflow has sources we don't need to worry about setting it back to false
  if (sidebar && !workflowHasSources) {
    setWorkflowHasSources(true)
  }

  return (
    <div className="mx-auto flex w-full px-6">
      {workflowHasSources && !fileId && <AssistantWorkflowThreadBlockSidebar />}
      <div
        className="z-10 mx-auto w-full max-w-[900px] grow space-y-4"
        id={
          stepIdx !== undefined
            ? `assistant-workflow-block-${stepIdx}`
            : undefined
        }
      >
        {children}
      </div>

      {workflowHasSources && !fileId && (
        <AssistantWorkflowThreadBlockSidebar>
          {sidebar || (
            <div className="invisible m-0 ml-4 h-0 w-36 space-y-2 rounded-md border px-2 py-4 sm:ml-4 sm:w-72" />
          )}
        </AssistantWorkflowThreadBlockSidebar>
      )}
    </div>
  )
}
