import React, { useEffect, useMemo, useState } from 'react'
import { useMount, useUnmount } from 'react-use'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import AssistantFiles from 'components/assistant/components/assistant-files'
import AssistantInputSourceHeader from 'components/assistant/components/assistant-input-source-header'
import { FileUploadingState } from 'components/assistant/stores/assistant-store'
import {
  FileSource,
  isVaultKnowledgeSource,
  VAULT_SOURCE_SELECT_FILES_DESCRIPTION,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { KnowledgeSourceItem } from 'components/assistant/utils/assistant-knowledge-sources'
import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'
import VaultFileExplorerInModal from 'components/vault/components/file-explorer/vault-file-explorer-in-modal'
import { createProject } from 'components/vault/components/new-project/create-project-helpers'
import { DEFAULT_PROJECT_CARD_HEIGHT } from 'components/vault/components/new-project/create-project-helpers'
import { VaultCreateProjectCard } from 'components/vault/components/new-project/vault-create-project-card'
import VaultProjectCard from 'components/vault/components/vault-project-card'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { useVaultProjectsMetadata } from 'components/vault/utils/use-vault-project-metadata'
import { projectsPath, REMOVE_PARAMS } from 'components/vault/utils/vault'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getSelectedFiles,
  getSortedFilesBasedOnReviewQueryOrder,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

import { useAuthUser } from './auth-context'

interface Props {
  knowledgeSource: KnowledgeSourceItem | null
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  onClose: () => void
  numberOfFilesLimit?: number
  numberOfFilesWarningThreshold?: number
}

const VaultKnowledgeSourcePicker = ({
  knowledgeSource,
  setKnowledgeSource,
  onClose,
  numberOfFilesLimit,
  numberOfFilesWarningThreshold,
}: Props) => {
  const { allVaultProjects, isFetchingProjects, hasLoadedProjects } =
    useVaultProjects({ includeExampleProjects: false })
  const [fileIdToVaultFile, folderIdToVaultFolder, setCurrentProject] =
    useVaultStore(
      useShallow((s) => [
        s.currentProjectFileIdToVaultFile,
        s.currentProjectFolderIdToVaultFolder,
        s.setCurrentProject,
      ])
    )

  const isVaultSource = isVaultKnowledgeSource(knowledgeSource)

  const activeFileIds = useMemo(
    () => new Set(isVaultSource ? knowledgeSource.fileIds : []),
    [knowledgeSource, isVaultSource]
  )
  const activeFiles = Array.from(activeFileIds)
    .map((fileId) => fileIdToVaultFile[fileId])
    .filter(Boolean) as VaultFile[]

  const [showDialog, setShowDialog] = useState(!activeFileIds.size)

  const activeProject = isVaultSource
    ? allVaultProjects.find(
        (project) => project.id === knowledgeSource.folderId
      )
    : null

  const [selectedRows, setSelectedRows] = useVaultFileExplorerStore(
    useShallow((s) => [s.selectedRows, s.setSelectedRows])
  )
  const selectedFiles = getSelectedFiles(selectedRows)

  const handleClose = () => {
    setKnowledgeSource(null)
    setCurrentProject(null)
    onClose()
  }

  useUnmount(() => {
    setCurrentProject(null)
  })

  const handleOpenChange = (isOpen: boolean) => {
    setShowDialog(isOpen)
    if (!isOpen && !activeFiles.length) handleClose()
  }
  const handleSelectMore = () => {
    setShowDialog(true)
  }

  const handleSelectProject = (project: VaultFolder) => {
    setKnowledgeSource({
      type: FileSource.VAULT,
      folderId: project.id,
      fileIds: [],
    })
    setCurrentProject(project)
  }
  const handleResetFolder = () => {
    setKnowledgeSource({
      type: FileSource.VAULT,
      folderId: '',
      fileIds: [],
    })
    setCurrentProject(null)
  }

  const handleAddFiles = () => {
    if (!isVaultSource) return
    const sortedFiles = getSortedFilesBasedOnReviewQueryOrder(
      selectedFiles,
      folderIdToVaultFolder
    )
    setKnowledgeSource({
      ...knowledgeSource,
      fileIds: sortedFiles.map((file) => file.id),
    })
    setShowDialog(false)
  }

  const handleRemoveFile = (file: UploadedFile | FileUploadingState) => {
    if (!isVaultSource) return
    const newFiles = activeFiles.filter((f) => f && f.name !== file.name)
    setKnowledgeSource({
      ...knowledgeSource,
      fileIds: newFiles.map((file) => file.id),
    })
  }

  if (!showDialog) {
    const separator = <span className="mx-1.5 text-xs text-muted/50">•</span>
    return (
      <div className="flex h-full grow flex-col">
        <AssistantInputSourceHeader
          actions={
            <>
              <Button size="sm" variant="ghost" onClick={handleClose}>
                Cancel
              </Button>
              <Button size="sm" variant="outline" onClick={handleSelectMore}>
                Select more files
              </Button>
            </>
          }
        >
          Vault
          {separator}
          {activeProject?.name ?? 'Project'}
          {separator}
          {pluralizeFiles(activeFiles.length)}
        </AssistantInputSourceHeader>
        <ScrollArea className="min-h-0 grow">
          <AssistantFiles
            files={activeFiles as UploadedFile[]}
            handleRemoveFile={handleRemoveFile}
          />
        </ScrollArea>
      </div>
    )
  }

  const tooManyFilesSelected =
    !_.isNil(numberOfFilesLimit) && selectedFiles.length > numberOfFilesLimit
  const isEmpty = hasLoadedProjects && allVaultProjects.length === 0
  const dialogTitle = activeProject
    ? `Add files from ${activeProject.name}`
    : 'Choose project'
  const dialogDescription = activeProject
    ? VAULT_SOURCE_SELECT_FILES_DESCRIPTION
    : 'Select a Vault project to browse files'

  return (
    <div className="flex grow items-center justify-center">
      <p className="text-muted">Selecting files from Vault…</p>
      <Dialog open={showDialog} onOpenChange={handleOpenChange}>
        <DialogContent
          className={cn('max-w-[800px]', {
            'h-[65vh]': !isEmpty,
          })}
          hasContainer={!activeProject}
          innerClassName="flex flex-col p-0"
        >
          {!isEmpty && (
            <DialogHeader className="px-6 pt-6">
              <DialogTitle className="flex items-center">
                {dialogTitle}{' '}
                {isFetchingProjects && (
                  <FetchingSpinner
                    delayMs={allVaultProjects.length > 0 ? 1000 : 0}
                  />
                )}
              </DialogTitle>
              <DialogDescription>{dialogDescription}</DialogDescription>
            </DialogHeader>
          )}
          {activeProject ? (
            <div className="flex min-h-0 grow flex-col space-y-4">
              <VaultFileExplorerInModal
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                className="flex min-h-0 grow flex-col"
                preselectFileIds={activeFileIds}
                projectId={activeProject.id}
                numberOfFilesLimit={numberOfFilesLimit}
                numberOfFilesWarningThreshold={numberOfFilesWarningThreshold}
              />
              <div className="flex items-center justify-between space-x-2 px-6 pb-6">
                {selectedFiles.length > 0 ? (
                  <p>{pluralizeFiles(selectedFiles.length)}</p>
                ) : (
                  <div />
                )}
                <div className="flex items-center space-x-2">
                  {!activeFiles.length && (
                    <Button variant="outline" onClick={handleResetFolder}>
                      Back
                    </Button>
                  )}
                  <Button
                    disabled={!selectedFiles.length || tooManyFilesSelected}
                    onClick={handleAddFiles}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          ) : isEmpty ? (
            <EmptyProjects />
          ) : (
            <ProjectChooser
              isLoaded={hasLoadedProjects}
              onSelectProject={handleSelectProject}
              projects={allVaultProjects}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default VaultKnowledgeSourcePicker

export const ProjectChooser = ({
  isLoaded,
  onSelectProject,
  projects,
  shouldShowCreateProjectCard = true,
}: {
  isLoaded: boolean
  onSelectProject: (project: VaultFolder) => void
  projects: VaultFolder[]
  shouldShowCreateProjectCard?: boolean
}) => {
  const { trackEvent } = useAnalytics()
  const sharedProjectIds = useVaultStore((s) => s.sharedProjectIds)
  const isLoadingProjects = !isLoaded && !projects.length
  const { projectsMetadata, isLoadingProjectsMetadata } =
    useVaultProjectsMetadata(projects.map((project) => project.id))

  return (
    <div className="grid w-full grid-cols-1 gap-3 p-6 pt-1 sm:grid-cols-3">
      {projects.map((project) => (
        <VaultProjectCard
          key={project.id}
          vaultProject={project}
          isLoadingMetadata={
            isLoadingProjectsMetadata || !projectsMetadata?.[project.id]
          }
          projectMetadata={projectsMetadata?.[project.id]}
          onClick={() => {
            onSelectProject(project)
            trackEvent('Vault File Selector Viewed', {
              source: 'Assistant',
            })
          }}
          isShared={sharedProjectIds.has(project.id)}
        />
      ))}
      {!isLoadingProjects && shouldShowCreateProjectCard && (
        <VaultCreateProjectCard height={DEFAULT_PROJECT_CARD_HEIGHT} />
      )}
      {isLoadingProjects &&
        [...new Array(4)].map((_, i) => (
          <VaultProjectCard
            key={i}
            vaultProject={{ id: `vault-placeholder-${i}` } as VaultFolder}
            isLoadingMetadata
            projectMetadata={undefined}
            isLoading
          />
        ))}
    </div>
  )
}

export const EmptyProjects = () => {
  const { trackEvent } = useAnalytics()

  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()

  const [upsertVaultFolders, setCurrentProject] = useVaultStore(
    useShallow((state) => [state.upsertVaultFolders, state.setCurrentProject])
  )
  const [isCreatingProject, setIsCreatingProject] = useState(false)

  useMount(() => {
    trackEvent('No Vault Projects Dialog Shown')
  })

  const handleNewClick = async () => {
    trackEvent('Vault New Project Button Clicked', {
      source: 'use-vault-in-assistant',
    })
    setIsCreatingProject(true)
    try {
      const hierarchyRootFolder = await createProject({
        folderName: 'Untitled',
        parentId: null,
        clientMatterId: null,
        isKnowledgeBaseProject: false,
        userId: userInfo.dbId,
        upsertVaultFolders: upsertVaultFolders,
      })
      setCurrentProject(hierarchyRootFolder, true)
      const newPath = `${BaseAppPath.Vault}${projectsPath}${hierarchyRootFolder.id}`
      navigate(newPath, {}, REMOVE_PARAMS)
    } catch (error) {
      displayErrorMessage('Failed to create project')
    }
    setIsCreatingProject(false)
  }

  return (
    <div className="flex flex-col items-center px-6 py-12 text-sm">
      <FolderShieldIcon className="h-6 w-6 shrink-0" />
      <div className="my-2 text-lg font-semibold">
        Create a project to get started
      </div>
      <p className="mb-4 max-w-md text-center text-muted">
        Vault allows you to upload and store files and perform sophisticated
        analysis and search across them.
      </p>
      <Button isLoading={isCreatingProject} onClick={handleNewClick}>
        New project
      </Button>
    </div>
  )
}

export const FetchingSpinner = ({ delayMs }: { delayMs: number }) => {
  const [isVisible, setIsVisible] = useState(!delayMs)

  useEffect(() => {
    if (!delayMs) return

    // Show spinner if request > 1 second
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, delayMs)

    return () => clearTimeout(timer)
  })

  if (!isVisible) return null

  return <Spinner size="xxs" className="ml-3" />
}
