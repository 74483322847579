import React from 'react'

import { cn } from 'utils/utils'

import { SkeletonBlock } from 'components/ui/skeleton'

type TextSize = 'xs' | 'sm' | 'base' | 'lg'

type LoadingTextProps = {
  text?: string
  isLoading: boolean
  size?: TextSize
  className?: string
}

const sizeConfig: Record<TextSize, { textStyles: string; skeleton: string }> = {
  xs: {
    textStyles: 'text-xs',
    skeleton: 'my-0.5 h-3 w-16',
  },
  sm: {
    textStyles: 'text-sm',
    skeleton: 'my-[3px] h-3.5 w-20',
  },
  base: {
    textStyles: 'text-base',
    skeleton: 'my-1 h-4 w-24',
  },
  lg: {
    textStyles: 'text-lg',
    skeleton: 'my-[5px] h-[18px] w-32',
  },
}

/**
 * LoadingText provides a loading state for text content that is being fetched.
 * The component matches the loading skeleton dimensions to the text's line height
 * to prevent layout shifts when transitioning from loading to loaded states.
 *
 * The skeleton height is calculated as:
 * - Total height = margin-y + font-size
 * - Example for 'xs': my-0.5 (2px on each side so 4px total) + h-3 (12px) = 16px line height
 */

export const LoadingText = ({
  text,
  isLoading,
  size = 'xs',
  className,
}: LoadingTextProps) => {
  const { textStyles, skeleton: skeletonClasses } = sizeConfig[size]

  if (isLoading) {
    return <SkeletonBlock className={cn(skeletonClasses)} />
  }

  return text && <p className={cn(textStyles, className)}>{text}</p>
}
