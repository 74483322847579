import React from 'react'

import { cn } from 'utils/utils'

interface ErrorIconProps {
  className?: string
}

const ErrorIcon: React.FC<ErrorIconProps> = ({ className = '' }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.00098C0 1.79184 1.79086 0.000976562 4 0.000976562C6.20912 0.000976562 8 1.79184 8 4.00098C8 6.2101 6.20912 8.00098 4 8.00098C1.79086 8.00098 0 6.2101 0 4.00098ZM3.08284 2.51813C2.92663 2.36192 2.67337 2.36192 2.51716 2.51813C2.36095 2.67434 2.36095 2.92761 2.51716 3.08382L3.43432 4.00098L2.51716 4.91814C2.36095 5.07434 2.36095 5.32762 2.51716 5.48382C2.67337 5.64002 2.92663 5.64002 3.08284 5.48382L4 4.56666L4.91716 5.48382C5.07336 5.64002 5.32664 5.64002 5.48284 5.48382C5.63904 5.32762 5.63904 5.07434 5.48284 4.91814L4.56568 4.00098L5.48284 3.08382C5.63904 2.92761 5.63904 2.67434 5.48284 2.51813C5.32664 2.36192 5.07336 2.36192 4.91716 2.51813L4 3.4353L3.08284 2.51813Z"
        fill="#EF4444"
      />
    </svg>
  )
}

export default ErrorIcon
