import React from 'react'

import { BookOpenIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { KnowledgeSource } from 'openapi/models/KnowledgeSource'
import { KnowledgeSourceType } from 'openapi/models/KnowledgeSourceType'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { SafeRecord } from 'utils/safe-types'

import { NEW_KNOWLEDGE_SOURCE_CONFIG } from 'components/assistant/features/composer/config'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  DatabaseSource,
  KnowledgeSourceItem,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { LoadingText } from 'components/ui/loading-text'
import { useVaultStore } from 'components/vault/utils/vault-store'

const getInfoForType = (
  ks: KnowledgeSource,
  allFolderIdToVaultFolder: SafeRecord<string, VaultFolder>
): {
  icon: React.ReactNode
  title: string
  subtitle?: string
  description?: string
} | null => {
  if (ks.type === KnowledgeSourceType.VAULT) {
    const folder = allFolderIdToVaultFolder[ks.folderId]
    return {
      icon: folder?.isKnowledgeBaseProject ? (
        <Icon icon={BookOpenIcon} />
      ) : (
        <FolderShieldIcon className="size-4" />
      ),
      title: folder?.name ?? 'Vault',
    }
  }

  if (!Object.values(DatabaseSource).includes(ks.type as DatabaseSource)) {
    return null
  }

  const config = NEW_KNOWLEDGE_SOURCE_CONFIG[ks.type as DatabaseSource]
  return {
    ...config,
    icon: <span className="size-4">{config.icon}</span>,
  }
}
export const SuggestionTag = ({
  knowledgeSource,
}: {
  knowledgeSource: KnowledgeSource
}) => {
  const [allFolderIdToVaultFolder, setCurrentProject] = useVaultStore(
    useShallow((s) => [s.allFolderIdToVaultFolder, s.setCurrentProject])
  )
  const [
    setKnowledgeSource,
    setShowResearchDialog,
    setShowVaultDialog,
    setResearchDialogArea,
  ] = useAssistantStore(
    useShallow((s) => [
      s.setKnowledgeSource,
      s.setShowResearchDialog,
      s.setShowVaultDialog,
      s.setResearchDialogArea,
    ])
  )

  const info = getInfoForType(knowledgeSource, allFolderIdToVaultFolder)
  if (!info) {
    return null
  }
  const { icon, title, subtitle, description } = info
  return (
    <Button
      variant="secondary"
      className="border border-dashed border-input"
      tooltip={description}
      onClick={() => {
        if (
          knowledgeSource.type === KnowledgeSourceType.VAULT &&
          allFolderIdToVaultFolder[knowledgeSource.folderId]
        ) {
          setCurrentProject(
            allFolderIdToVaultFolder[knowledgeSource.folderId] ?? null
          )
          setShowVaultDialog(true)
        } else {
          setResearchDialogArea(knowledgeSource.type as DatabaseSource)
          setShowResearchDialog(true)
          setKnowledgeSource(knowledgeSource as KnowledgeSourceItem)
        }
      }}
    >
      <div className="flex items-center gap-0.5">
        {icon}
        <div className="flex items-center gap-1">
          <LoadingText
            isLoading={false}
            className="text-sm font-normal"
            text={title}
          />
          {subtitle && (
            <>
              <span className="text-xs text-muted">›</span>
              <span className="text-xs text-muted">{subtitle}</span>
            </>
          )}
        </div>
      </div>
    </Button>
  )
}
