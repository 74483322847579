/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Research
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the research area.
 * @export
 */
export const ResearchArea = {
    TAX: 'tax',
    EDGAR: 'edgar',
    MEMOS: 'memos',
    FRANCECASELAW: 'francecaselaw',
    SWEDENCASELAW: 'swedencaselaw',
    SINGAPORECASELAW: 'singaporecaselaw',
    EURLEX: 'eurlex',
    AUSBREACHREPORTING: 'ausbreachreporting',
    CUATRECASAS: 'cuatrecasas',
    FROMCOUNSEL: 'fromcounsel',
    MACFARLANES: 'macfarlanes',
    LEFEBVRE: 'lefebvre',
    BREDIN_PRAT: 'bredin_prat',
    WEB: 'web'
} as const;
export type ResearchArea = typeof ResearchArea[keyof typeof ResearchArea];


export function instanceOfResearchArea(value: any): boolean {
    for (const key in ResearchArea) {
        if (Object.prototype.hasOwnProperty.call(ResearchArea, key)) {
            if (ResearchArea[key as keyof typeof ResearchArea] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ResearchAreaFromJSON(json: any): ResearchArea {
    return ResearchAreaFromJSONTyped(json, false);
}

export function ResearchAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResearchArea {
    return json as ResearchArea;
}

export function ResearchAreaToJSON(value?: ResearchArea | null): any {
    return value as any;
}

export function ResearchAreaToJSONTyped(value: any, ignoreDiscriminator: boolean): ResearchArea {
    return value as ResearchArea;
}

