import * as React from 'react'

import { FileTypeToIcon } from 'types/file'

import { cn } from 'utils/utils'

import { FileToShow } from 'components/assistant/workflows/blocks/assistant-workflow-file-upload-input'
import { Button } from 'components/ui/button'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'

type FileToShowWithLoading = FileToShow & {
  isLoading?: boolean
}

interface MultipleDocumentsCardProps {
  files: FileToShowWithLoading[]
  headerText?: string
  // map of file id to onClick function
  onClickDict?: { [id: string]: (file: FileToShowWithLoading) => void }
}

const MultipleDocumentsCard: React.FC<MultipleDocumentsCardProps> = ({
  files,
  headerText,
  onClickDict,
}) => {
  return (
    <div className="max-w-[550px] rounded-lg border bg-primary p-[6px]">
      <div className="flex items-center justify-between pb-2 pl-[6px] pr-[10px] pt-3">
        <div className="text-sm font-medium">Files</div>
        <div className="text-xs text-muted">{headerText}</div>
      </div>
      <ScrollArea maxHeight="max-h-[200px]">
        {files.map((file) => {
          let FileIcon = FileTypeToIcon.Unknown
          if (
            file.fileType &&
            Object.keys(FileTypeToIcon).includes(file.fileType)
          ) {
            FileIcon =
              FileTypeToIcon[file.fileType as keyof typeof FileTypeToIcon]
          }

          const onClick = onClickDict?.[file.id]
          const disableClick = !onClick

          return (
            <Button
              className={cn(
                'group h-8 w-full bg-primary pl-[6px] pr-[10px] transition',
                !disableClick && 'hover:bg-secondary disabled:hover:bg-primary',
                disableClick && 'cursor-default'
              )}
              onClick={!disableClick ? () => onClick(file) : undefined}
              variant="unstyled"
              disabled={file.isLoading}
              key={file.name}
              title={file.name}
            >
              <div
                className={cn(
                  'flex h-full items-center gap-[10px] transition duration-75',
                  !file.isLoading && 'group-active:opacity-70'
                )}
              >
                <div className="flex size-4 shrink-0 items-center justify-center">
                  {file.isLoading ? (
                    <Spinner size="xxs" className="mr-0 fill-primary" />
                  ) : (
                    <FileIcon className="size-4" />
                  )}
                </div>
                <div className="flex h-full flex-1 items-center border-b group-last:border-b-0">
                  <p className="line-clamp-1 text-left text-xs">{file.name}</p>
                </div>
              </div>
            </Button>
          )
        })}
      </ScrollArea>
    </div>
  )
}

export default MultipleDocumentsCard
