import React from 'react'

import _ from 'lodash'

import { UserInfo } from 'models/user-info'

import { site } from 'utils/server-data'
import { getSiteName } from 'utils/site'
import { replaceSpacesWithDashes } from 'utils/string'
import { cn } from 'utils/utils'

import { SettingsPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { Separator } from 'components/ui/separator'

import { IntegrationDefinitions } from './integrations/integration-definitions'
import SettingsNavItem, { SettingsNavItemProps } from './settings-nav-item'
import { useSettingsState } from './settings-store'

export const getUserSettingsNavItems = (
  settingsUser: UserInfo | null
): SettingsNavItemProps[] => {
  const hasAnyIntegrationsAvailable = settingsUser
    ? _.some(settingsUser.workspace.integrations, (integration) =>
        IntegrationDefinitions[integration].available(settingsUser)
      )
    : false

  return [
    {
      label: 'Profile',
      href: SettingsPath.Profile,
      available: settingsUser?.isCreateUserProfilesUser ?? false,
    },
    {
      label: 'Workspace',
      href: SettingsPath.Workspace,
      available:
        (settingsUser?.IsWorkspaceInfoViewer ||
          settingsUser?.isWorkspacePersonalizationUser) ??
        false,
    },
    {
      label: 'Client matters',
      href: SettingsPath.ClientMatters,
      available: settingsUser?.isClientMattersReadUser ?? false,
    },
    {
      label: 'Sharing',
      href: SettingsPath.Sharing,
      available: settingsUser?.IsSharingManagementUser ?? false,
    },
    {
      label: 'Integrations',
      href: SettingsPath.Integrations,
      available:
        (settingsUser?.isIntegrationAdmin &&
          settingsUser.isAnyIntegrationUser) ||
        hasAnyIntegrationsAvailable,
    },
    {
      label: 'Playbooks',
      href: SettingsPath.Playbooks,
      available: settingsUser?.isPlaybookAdminUser ?? false,
    },
  ]
}

export const getAdminSettingsNavItems = (
  settingsUser: UserInfo | null
): SettingsNavItemProps[] => {
  return [
    {
      label: 'Usage',
      href: SettingsPath.Usage,
      available:
        (settingsUser?.IsUsageDashboardViewer ||
          settingsUser?.IsUsageDashboardV2Viewer) ??
        false,
    },
    {
      label: 'Roles',
      href: SettingsPath.Roles,
      available: settingsUser?.IsClientAdminSelfServePermsViewer ?? false,
    },
    {
      label: 'Users',
      href: SettingsPath.Users,
      available:
        (settingsUser?.IsClientAdminViewUsers ||
          settingsUser?.IsClientAdminSelfServePermsViewer) ??
        false,
    },
    {
      label: 'Workspace history',
      href: SettingsPath.WorkspaceHistory,
      available:
        (settingsUser?.IsWorkspaceHistoryReader &&
          settingsUser.IsHistoryUser) ??
        false,
    },
    {
      label: 'Workspace projects',
      href: SettingsPath.WorkspaceProjects,
      available: settingsUser?.isVaultWorkspaceProjectsViewer ?? false,
    },
  ]
}

const getInternalAdminSettingsNavItems = (
  userInfo: UserInfo
): SettingsNavItemProps[] => {
  return [
    {
      label: 'Workspaces',
      href: SettingsPath.InternalAdminWorkspaces,
      available: userInfo.IsInternalAdminReader,
    },
    {
      label: 'Permissions',
      href: SettingsPath.InternalAdminPermissions,
      available: userInfo.IsInternalAdminReader,
    },
    {
      label: 'User management',
      href: SettingsPath.InternalAdminUserManagement,
      available: userInfo.isUserManagementTabVisible,
    },
    {
      label: 'Experiment management',
      href: SettingsPath.InternalAdminExperimentManagement,
      available: userInfo.IsResponseComparisonAdmin,
    },
    {
      label: 'PWC tax feedback export',
      href: SettingsPath.InternalAdminPwcTaxExport,
      available: userInfo.IsPWCTaxFeedbackExportUser,
    },
    {
      label: 'Library items manager',
      href: SettingsPath.InternalAdminLibraryEventsManager,
      available: userInfo.IsLibraryCreateHarveyItemsUser,
    },
    {
      label: 'Incident management',
      href: SettingsPath.InternalAdminIncidentManagement,
      available: userInfo.IsInternalAdminWriter,
    },
    {
      label: 'Workflow permissions',
      href: SettingsPath.InternalAdminWorkflowPermissions,
      available: userInfo.isUserManagement,
    },
  ]
}
export const hasInternalAdminSettings = (userInfo: UserInfo) => {
  return getInternalAdminSettingsNavItems(userInfo).some(
    (item) => item.available === true
  )
}

const SettingsSidebar: React.FC = () => {
  const userInfo = useAuthUser()
  const settingsUser = useSettingsState((s) => s.settingsUser)

  const userSettingsNavItems = getUserSettingsNavItems(settingsUser)
  const hasUserSettings = userSettingsNavItems.some(
    (item) => item.available === true
  )

  const adminSettingsNavItems = getAdminSettingsNavItems(settingsUser)
  const hasAdminSettings = adminSettingsNavItems.some(
    (item) => item.available === true
  )

  const internalAdminSettingsNavItems =
    getInternalAdminSettingsNavItems(userInfo)
  const hasInternalAdminSettings = internalAdminSettingsNavItems.some(
    (item) => item.available === true
  )

  return (
    <div className="flex w-48 flex-col">
      <div>
        {hasUserSettings && (
          <div>
            <h2 className="mb-1.5 text-sm font-semibold ">Settings</h2>
            <div className="space-y-1">
              {userSettingsNavItems
                .filter((item) => item.available)
                .map((item) => (
                  <SettingsNavItem
                    key={item.label}
                    id={`user-settings-nav-item-${replaceSpacesWithDashes(
                      item.label
                    )}`}
                    label={item.label}
                    icon={item.icon}
                    href={item.href}
                    comingSoon={item.comingSoon}
                  />
                ))}
            </div>
          </div>
        )}
        {hasAdminSettings && (
          <div className={cn({ 'mt-6': hasUserSettings })}>
            <h2 className="mb-1.5 text-sm font-semibold ">Admin</h2>
            <div className="space-y-1">
              {adminSettingsNavItems
                .filter((item) => item.available)
                .map((item) => (
                  <SettingsNavItem
                    key={item.label}
                    id={`admin-settings-nav-item-${replaceSpacesWithDashes(
                      item.label
                    )}`}
                    label={item.label}
                    icon={item.icon}
                    href={item.href}
                    search={item.search}
                    comingSoon={item.comingSoon}
                  />
                ))}
            </div>
          </div>
        )}
        {hasInternalAdminSettings && (
          <div
            className={cn('space-y-4', {
              'mt-4': hasAdminSettings || hasUserSettings,
            })}
          >
            {(hasAdminSettings || hasUserSettings) && (
              <Separator className="-ml-3" />
            )}
            <div>
              <h2 className="mb-1.5 items-center text-sm font-semibold">{`Internal admin (${getSiteName(
                site
              )})`}</h2>
              <div className="space-y-1">
                {internalAdminSettingsNavItems
                  .filter((item) => item.available)
                  .map((item) => (
                    <SettingsNavItem
                      key={item.label}
                      label={item.label}
                      icon={item.icon}
                      href={item.href}
                      comingSoon={item.comingSoon}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SettingsSidebar
