import React, { useEffect, lazy, Suspense } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { RecentPagesProvider } from 'contexts/recent-pages-context'

import { useClientMattersPrefetch } from 'models/queries/use-client-matters-query'
import { UserInfo } from 'models/user-info'
import useTabFocusTracking from 'services/honey-comb/use-tab-focus-tracking'

import { isSentryLoggingEnabled } from 'utils/env'
import { createRouteComponent } from 'utils/routing'
import { SentryAssignees } from 'utils/sentry'

import AppLayout from 'components/app-layout'
import { BaseAppPath, SettingsPath } from 'components/base-app-path'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import RedirectWithId from 'components/common/redirects/redirect-with-id'
import { hasInternalAdminSettings } from 'components/settings/settings-sidebar'

import AuthenticationGuard from './auth/auth-guard'
import { AppMain } from './common/app-main'
import { AuthContext } from './common/auth-context'
import NotAuthorizedScreen from './common/error/not-authorized-screen'
import FullscreenLoading from './common/fullscreen-loading'
import RedirectWithQuery from './common/redirects/redirect-with-query'
import { useStatusBarFetcher } from './use-status-bar-refech'
import { getWorkflowSpecificRouting } from './workflows/workflow-definitions'

const AssistantIndex = lazy(() => import('./assistant/assistant-index'))
const Compare = lazy(() => import('components/compare/compare-page'))
const UserEvaluation = lazy(
  () =>
    import(
      'components/settings/experiment/user-evaluation/user-evaluation-page'
    )
)
const History = lazy(() => import('components/history/history-page'))
const Library = lazy(() => import('components/library/library-page'))
const Prompts = lazy(() => import('components/library/prompts-page'))
const LibraryExamples = lazy(() => import('components/library/examples-page'))
const VaultIndex = lazy(() => import('components/vault/vault-index'))
const Artifact = lazy(
  () => import('components/research/artifact/artifact-page')
)
const WorkflowsPage = lazy(() => import('components/workflows/workflows-page'))
const WorkflowContainer = lazy(
  () => import('components/workflows/workflow/workflow-container')
)
const SettingsIndex = lazy(() => import('components/settings/settings-index'))
const WorkspaceInfo = lazy(
  () =>
    import('components/settings/workspace/workspace-info/workspace-info-page')
)
const SettingsClientMatters = lazy(
  () =>
    import('components/settings/client-matters/settings-client-matters-page')
)
const SettingsSharing = lazy(
  () => import('components/settings/sharing/settings-sharing-page')
)
const PlaybooksPage = lazy(
  () => import('components/settings/playbooks/playbooks-page')
)
const ProfilePage = lazy(
  () => import('components/settings/profile/profile-page')
)
const WorkspaceUsers = lazy(
  () => import('components/settings/workspace/workspace-users-page')
)
const WorkspaceUsersPageV2 = lazy(
  () => import('components/settings/workspace/workspace-users-page-v2')
)
const WorkspaceRolesPage = lazy(
  () => import('components/settings/workspace/workspace-roles-page')
)
const Dashboard = lazy(() => import('components/dashboard/dashboard-page'))
const WorkspaceHistory = lazy(
  () => import('components/history/workspace-history-page')
)
const WorkspaceProjects = lazy(
  () => import('components/settings/workspace/workspace-projects-page')
)
const IntegrationsPage = lazy(
  () => import('components/settings/integrations/integrations-page')
)
const InternalAdminLayout = lazy(
  () => import('components/settings/internal-admin/internal-admin-layout')
)
const Workspaces = lazy(
  () => import('components/settings/workspace/workspaces-page')
)
const AddEditWorkspace = lazy(
  () =>
    import(
      'components/settings/workspace/workspace-details/add-edit-workspace-page'
    )
)
const WorkspaceDetails = lazy(
  () =>
    import(
      'components/settings/workspace/workspace-details/workspace-details-page'
    )
)
const Permissions = lazy(
  () => import('components/settings/permissions/permissions-page')
)
const SettingPermissionUsers = lazy(
  () => import('components/settings/permissions/settings-permission-users-page')
)
const SettingsUserManagement = lazy(
  () =>
    import('components/settings/user-management/settings-user-management-page')
)
const SettingsExperimentManager = lazy(
  () =>
    import('components/settings/experiment/settings-experiment-management-page')
)
const NewExperiment = lazy(
  () => import('components/settings/experiment/new-experiment-page')
)
const ExperimentManagement = lazy(
  () => import('components/settings/experiment/experiment-management-page')
)
const UserInspector = lazy(
  () => import('components/settings/permissions/user-inspector')
)
const WorkspaceRolesInspector = lazy(
  () => import('components/settings/roles/workspace-roles-inspector-page')
)
const PWCTaxFeedbackExport = lazy(
  () => import('components/settings/pwc/pwc-tax-feedback-export-page')
)
const SettingsLibraryEventsManager = lazy(
  () => import('components/settings/library/settings-library-events-manager')
)
const SettingsIncidentManagement = lazy(
  () =>
    import(
      'components/settings/incident-management/settings-incident-management'
    )
)
const SettingsWorkflowPermissions = lazy(
  () =>
    import(
      'components/settings/workflow-permissions/settings-workflow-permissions'
    )
)

export interface AppRouterProps {
  userInfo: UserInfo
  logoutHandler: () => void
}

const LoginCompletionRedirect = () => {
  const navigate = useNavigate()
  useEffect(() => {
    // We set replace so browser history remembers Harvey, not the login URL.
    navigate('/', { replace: true })
  }, [navigate])

  return null
}

const Fallback = () => {
  return (
    <AppMain>
      <FullscreenLoading isLoading />
    </AppMain>
  )
}

const SuspenseWrapper = (
  LazyComponent: React.LazyExoticComponent<React.ComponentType<any>>
) => {
  return (
    <Suspense fallback={<Fallback />}>
      <LazyComponent />
    </Suspense>
  )
}

// Component
const AppRouter: React.FC<AppRouterProps> = ({ userInfo, logoutHandler }) => {
  let baseComponent = null
  let basePath = '/'
  if (userInfo.IsAssistantV2User) {
    baseComponent = <AssistantIndex />
    basePath = BaseAppPath.Assistant
  } else if (userInfo.IsVaultUser) {
    baseComponent = <VaultIndex />
    basePath = BaseAppPath.Vault
  } else if (userInfo.IsHarveyV1WorkflowUser) {
    baseComponent = <WorkflowsPage />
    basePath = BaseAppPath.Workflows
  } else if (userInfo.IsHistoryUser) {
    baseComponent = <History />
    basePath = BaseAppPath.History
  } else if (userInfo.IsLibraryUser) {
    baseComponent = <Library />
    basePath = BaseAppPath.Library
  } else if (userInfo.isSettingsTabVisible) {
    baseComponent = <SettingsIndex />
    basePath = BaseAppPath.Settings
  } else if (!userInfo.IsAuthorized) {
    ;<NotAuthorizedScreen logoutHandler={logoutHandler} userInfo={userInfo} />
  } else {
    baseComponent = <ErrorPage />
  }

  // status bar fetcher
  useStatusBarFetcher(userInfo.workspace.id)

  // prefetch the client matters for sidebar combobox
  useClientMattersPrefetch(userInfo.isClientMattersReadUser)

  // track user activity when tab is focused
  useTabFocusTracking()

  const SentryRoutes = isSentryLoggingEnabled
    ? Sentry.withSentryReactRouterV6Routing(Routes)
    : Routes

  const routes: React.ReactNode[] = [
    // Catch a user who successfully logged in via a login slug, and redirect
    // them to the main app.
    createRouteComponent({
      path: `${BaseAppPath.Login}/*`,
      hasPerms: true,
      component: LoginCompletionRedirect,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Assistant}/*`,
      hasPerms: userInfo.IsAssistantUser,
      component: () => SuspenseWrapper(AssistantIndex),
      sentryAssignee: userInfo.IsAssistantV2User
        ? SentryAssignees.ASSISTANT_V2
        : undefined,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Compare}/:id?`,
      hasPerms: userInfo.IsResponseComparisonUser,
      component: () => SuspenseWrapper(Compare),
    }),
    createRouteComponent({
      path: `${BaseAppPath.Evaluations}/experiment/:slug?`,
      hasPerms: userInfo.IsResponseComparisonUser,
      component: () => SuspenseWrapper(UserEvaluation),
    }),
    createRouteComponent({
      path: BaseAppPath.History,
      hasPerms: userInfo.IsHistoryUser,
      component: () => SuspenseWrapper(History),
    }),
    createRouteComponent({
      path: BaseAppPath.Library,
      hasPerms: userInfo.IsLibraryUser,
      component: () => SuspenseWrapper(Library),
    }),
    createRouteComponent({
      path: `${BaseAppPath.Library}/prompts`,
      hasPerms: userInfo.IsLibraryPromptsUser,
      component: () => SuspenseWrapper(Prompts),
    }),
    createRouteComponent({
      path: `${BaseAppPath.Library}/examples`,
      hasPerms: userInfo.IsLibraryExamplesUser,
      component: () => SuspenseWrapper(LibraryExamples),
    }),
    createRouteComponent({
      path: `${BaseAppPath.Vault}/*`,
      hasPerms: userInfo.IsVaultUser,
      component: () => SuspenseWrapper(VaultIndex),
      sentryAssignee: SentryAssignees.VAULT,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Research}/:area?/:id?`,
      hasPerms: userInfo.IsAssistantV2User,
      component: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { area, id } = useParams()
        if (!area || !id) {
          return <Navigate to={BaseAppPath.Assistant} />
        }
        return <RedirectWithId path={BaseAppPath.Assistant} />
      },
      sentryAssignee: userInfo.IsAssistantV2User
        ? SentryAssignees.ASSISTANT_V2
        : undefined,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Research}/artifact/:id`,
      hasPerms: userInfo.IsAssistantV2User,
      component: () => SuspenseWrapper(Artifact),
      sentryAssignee: userInfo.IsAssistantV2User
        ? SentryAssignees.ASSISTANT_V2
        : undefined,
    }),
    createRouteComponent({
      path: `${BaseAppPath.Research}/sec-edgar/:id`,
      hasPerms: userInfo.IsAssistantV2User,
      component: () => <RedirectWithId path={BaseAppPath.Assistant} />,
      sentryAssignee: userInfo.IsAssistantV2User
        ? SentryAssignees.ASSISTANT_V2
        : undefined,
    }),
    createRouteComponent({
      path: BaseAppPath.Workflows,
      hasPerms: userInfo.IsHarveyV1WorkflowUser || userInfo.IsVaultUser,
      component: () => SuspenseWrapper(WorkflowsPage),
    }),
    createRouteComponent({
      path: `${BaseAppPath.Workflows}/:workflow_id/:id?`,
      hasPerms: userInfo.IsHarveyV1WorkflowUser,
      component: () => SuspenseWrapper(WorkflowContainer),
    }),
    ...getWorkflowSpecificRouting(userInfo),
    createRouteComponent({
      path: BaseAppPath.Settings,
      hasPerms: userInfo.isSettingsTabVisible,
      component: () => SuspenseWrapper(SettingsIndex),
      sentryAssignee: SentryAssignees.PLATFORM,
      children: [
        createRouteComponent({
          path: SettingsPath.Workspace,
          hasPerms: userInfo.IsWorkspaceInfoViewer,
          component: () => SuspenseWrapper(WorkspaceInfo),
        }),
        createRouteComponent({
          path: SettingsPath.ClientMatters,
          component: () => SuspenseWrapper(SettingsClientMatters),
          hasPerms: userInfo.isClientMattersReadUser,
        }),
        createRouteComponent({
          path: SettingsPath.Sharing,
          component: () => SuspenseWrapper(SettingsSharing),
          hasPerms: userInfo.IsSharingManagementUser,
        }),
        createRouteComponent({
          path: SettingsPath.Playbooks,
          component: () => SuspenseWrapper(PlaybooksPage),
          hasPerms: userInfo.isPlaybookAdminUser,
        }),
        createRouteComponent({
          path: SettingsPath.Profile,
          component: () => SuspenseWrapper(ProfilePage),
          hasPerms: true,
          sentryAssignee: SentryAssignees.ASSISTANT_V2,
        }),
        createRouteComponent({
          path: SettingsPath.Users,
          hasPerms:
            userInfo.IsClientAdminViewUsers ||
            userInfo.IsClientAdminSelfServePermsViewer,
          component: userInfo.IsClientAdminSelfServePermsViewer
            ? () => SuspenseWrapper(WorkspaceUsersPageV2)
            : () => SuspenseWrapper(WorkspaceUsers),
        }),
        createRouteComponent({
          path: SettingsPath.Roles,
          hasPerms: userInfo.IsClientAdminSelfServePermsViewer,
          component: () => SuspenseWrapper(WorkspaceRolesPage),
        }),
        createRouteComponent({
          path: SettingsPath.Usage,
          hasPerms:
            userInfo.IsUsageDashboardViewer ||
            userInfo.IsUsageDashboardV2Viewer,
          component: () => SuspenseWrapper(Dashboard),
        }),
        createRouteComponent({
          path: SettingsPath.WorkspaceHistory,
          hasPerms: userInfo.IsWorkspaceHistoryReader && userInfo.IsHistoryUser,
          component: () => SuspenseWrapper(WorkspaceHistory),
        }),
        createRouteComponent({
          path: SettingsPath.WorkspaceProjects,
          hasPerms: userInfo.isVaultWorkspaceProjectsViewer,
          component: () => SuspenseWrapper(WorkspaceProjects),
        }),
        createRouteComponent({
          path: SettingsPath.Integrations,
          hasPerms: userInfo.isAnyIntegrationUser,
          component: () => SuspenseWrapper(IntegrationsPage),
        }),
        createRouteComponent({
          path: SettingsPath.InternalAdmin,
          hasPerms: hasInternalAdminSettings(userInfo),
          component: () => SuspenseWrapper(InternalAdminLayout),
          children: [
            createRouteComponent({
              path: SettingsPath.InternalAdminWorkspaces,
              hasPerms: userInfo.IsInternalAdminReader,
              component: () => SuspenseWrapper(Workspaces),
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminWorkspaces}/new`,
              hasPerms: userInfo.IsInternalAdminWriter,
              component: () => SuspenseWrapper(AddEditWorkspace),
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminWorkspaces}/:id/edit`,
              hasPerms: userInfo.IsInternalAdminWriter,
              component: () => SuspenseWrapper(AddEditWorkspace),
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminWorkspaces}/:id`,
              hasPerms: userInfo.IsInternalAdminReader,
              component: () => SuspenseWrapper(WorkspaceDetails),
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminPermissions,
              hasPerms: userInfo.IsInternalAdminReader,
              component: () => SuspenseWrapper(Permissions),
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminPermissions}/users`,
              hasPerms: userInfo.IsInternalAdminReader,
              component: () => SuspenseWrapper(SettingPermissionUsers),
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminUserManagement,
              hasPerms: userInfo.isUserManagementTabVisible,
              component: () => SuspenseWrapper(SettingsUserManagement),
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminExperimentManagement,
              hasPerms: userInfo.IsResponseComparisonAdmin,
              component: () => SuspenseWrapper(SettingsExperimentManager),
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminExperimentManagement}/new`,
              hasPerms: userInfo.IsResponseComparisonAdmin,
              component: () => SuspenseWrapper(NewExperiment),
            }),
            createRouteComponent({
              path: `${SettingsPath.InternalAdminExperimentManagement}/:slug`,
              hasPerms: userInfo.IsResponseComparisonAdmin,
              component: () => SuspenseWrapper(ExperimentManagement),
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminUserInspector,
              hasPerms: userInfo.isUserManagementTabVisible,
              component: () => SuspenseWrapper(UserInspector),
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminRoleInspector,
              hasPerms: userInfo.IsInternalAdminReader,
              component: () => SuspenseWrapper(WorkspaceRolesInspector),
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminPwcTaxExport,
              hasPerms: userInfo.IsPWCTaxFeedbackExportUser,
              component: () => SuspenseWrapper(PWCTaxFeedbackExport),
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminLibraryEventsManager,
              hasPerms: userInfo.IsLibraryCreateHarveyItemsUser,
              component: () => SuspenseWrapper(SettingsLibraryEventsManager),
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminIncidentManagement,
              hasPerms: userInfo.IsInternalAdminWriter,
              component: () => SuspenseWrapper(SettingsIncidentManagement),
            }),
            createRouteComponent({
              path: SettingsPath.InternalAdminWorkflowPermissions,
              hasPerms: userInfo.isUserManagement,
              component: () => SuspenseWrapper(SettingsWorkflowPermissions),
            }),
          ],
        }),
      ],
    }),
  ]

  return (
    <AuthContext.Provider value={{ userInfo }}>
      <RecentPagesProvider>
        <SentryRoutes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<RedirectWithQuery to={basePath} />} />
            <Route
              index
              element={
                <AuthenticationGuard hasPerms component={() => baseComponent} />
              }
            />
            {routes.map((r) => r)}
            <Route
              path="*"
              element={<ErrorPage title={ErrorPageTitle.PAGE_NOT_FOUND} />}
            />
          </Route>
        </SentryRoutes>
      </RecentPagesProvider>
    </AuthContext.Provider>
  )
}

// Export default
export default AppRouter
