import React, { useMemo } from 'react'

import { isNil } from 'lodash'

import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import { getFoldersOnPath } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultLocationBreadcrumbs = ({ id }: { id: string }) => {
  const folderIdToVaultFolder = useVaultStore(
    (s) => s.currentProjectFolderIdToVaultFolder
  )
  const fileIdToVaultFile = useVaultStore(
    (s) => s.currentProjectFileIdToVaultFile
  )
  const currentItem = fileIdToVaultFile[id] || folderIdToVaultFolder[id]

  const filePath = useMemo(() => {
    if (isNil(currentItem)) return ''
    const currentId =
      'vaultFolderId' in currentItem
        ? currentItem.vaultFolderId
        : currentItem.parentId
    if (isNil(currentId)) return ''
    const foldersOnPath = getFoldersOnPath(currentId, folderIdToVaultFolder)
    return foldersOnPath?.map((f) => f.name).join('/') ?? ''
  }, [currentItem, folderIdToVaultFolder]) // Note: dependency should be currentItem, not file

  if (!currentItem) return null

  return (
    <RouterBreadcrumbs
      overridePath={filePath}
      className="mb-0"
      breadcrumbItemClassName="font-normal text-muted text-sm"
      keepCaseStartingFromIndex={0}
      checkHistoryIdInPath={false}
      keepLastItem
    />
  )
}

export default VaultLocationBreadcrumbs
