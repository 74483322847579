import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useInterval, useMount } from 'react-use'
import { useUnmount } from 'react-use'

import {
  Row,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
  Table,
} from '@tanstack/react-table'
import _ from 'lodash'
import { ListFilter, Search, XCircle } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { VaultFile } from 'openapi/models/VaultFile'
import { FileTypeReadableName } from 'types/file'

import { SafeRecord } from 'utils/safe-types'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { Icon } from 'components/ui/icon/icon'
import { MultiSelect, MultiSelectEntry } from 'components/ui/multi-select'
import SearchInput from 'components/ui/search-input'
import {
  createGetPathForItem,
  createProjectDataPoller,
  createRowSelectionHandler,
  createShiftSelectionHandler,
  createVaultTableColumns,
  getHiddenColumns,
  handleVaultRowClick,
  prepareVaultExplorerData,
  renderWarningMessages,
} from 'components/vault/components/file-explorer/vault-file-explorer-utils'
import { SimpleDocumentClassificationPill } from 'components/vault/components/vault-document-classification'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING,
  useDocumentClassificationStore,
} from 'components/vault/utils/use-document-classification-store'
import {
  VaultItem,
  VaultItemType,
  VaultItemWithIndex,
} from 'components/vault/utils/vault'
import { isProjectShared } from 'components/vault/utils/vault-sharing-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

import { DEFAULT_FILES_WARNING_THRESHOLD } from './vault-file-explorer-warning-message'

const VaultFileExplorerInModal = ({
  projectId,
  selectedRows,
  setSelectedRows,
  existingSelectedFileIds,
  preselectFileIds,
  numberOfFilesLimit,
  numberOfFilesWarningThreshold = DEFAULT_FILES_WARNING_THRESHOLD,
  showDocumentClassificationMismatchWarning = false,
  isDisplayingFilesProgress = false,
  shouldHideAllFiles = false,
  useModalStyling = false,
  isAddingFilesToReviewQuery = false,
  className,
}: {
  projectId: string | undefined
  selectedRows: VaultItemWithIndex[]
  setSelectedRows: (rows: VaultItemWithIndex[]) => void
  existingSelectedFileIds?: Set<string>
  preselectFileIds?: Set<string>
  numberOfFilesLimit?: number
  numberOfFilesWarningThreshold?: number
  showDocumentClassificationMismatchWarning?: boolean
  isDisplayingFilesProgress?: boolean
  shouldHideAllFiles?: boolean
  useModalStyling?: boolean
  isAddingFilesToReviewQuery?: boolean
  className?: string
}) => {
  const [
    allFolderIdToVaultFolder,
    allParentIdToVaultFolderIds,
    allFileIdToVaultFile,
    allFolderIdToVaultFileIds,
    projectIdToFileIds,
    foldersMetadata,
    requiresProjectDataRefetch,
    exampleProjectIds,
    sharedProjectIds,
    projectIdToFolderIds,
    currentProject,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    setRequiresProjectDataRefetch,
    deleteVaultFiles,
    deleteVaultFolders,
    setError,
  ] = useVaultStore(
    useShallow((state) => [
      state.allFolderIdToVaultFolder,
      state.allParentIdToVaultFolderIds,
      state.allFileIdToVaultFile,
      state.allFolderIdToVaultFileIds,
      state.projectIdToFileIds,
      state.foldersMetadata,
      state.requiresProjectDataRefetch,
      state.exampleProjectIds,
      state.sharedProjectIds,
      state.projectIdToFolderIds,
      state.currentProject,
      state.upsertVaultFolders,
      state.upsertVaultFiles,
      state.addToProjectsMetadata,
      state.setRequiresProjectDataRefetch,
      state.deleteVaultFiles,
      state.deleteVaultFolders,
      state.setError,
    ])
  )

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const permissionsByProjectId = useVaultSharingStore(
    useShallow((state) => state.permissionsByProjectId)
  )

  const setOpenFileId = useDocumentClassificationStore(
    useShallow((s) => s.setOpenFileId)
  )

  const [lastSelectedRow, setLastSelectedRow] =
    useState<VaultItemWithIndex | null>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedContentTypes, setSelectedContentTypes] = useState<string[]>([])
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<string[]>(
    []
  )

  const allCurrentProjectFiles: VaultFile[] = useMemo(() => {
    if (!projectId) return []
    const fileIds = projectIdToFileIds[projectId]
    if (!fileIds || fileIds.length === 0) return []
    return fileIds
      .map((fileId) => allFileIdToVaultFile[fileId])
      .filter(Boolean) as VaultFile[]
  }, [projectId, projectIdToFileIds, allFileIdToVaultFile])
  const allContentTypes = useMemo(() => {
    return new Set(
      allCurrentProjectFiles
        .map((file) => file.contentType)
        .filter(Boolean) as string[]
    )
  }, [allCurrentProjectFiles])
  const sortedContentTypes = useMemo(() => {
    return Array.from(allContentTypes).map((contentType) => ({
      text: FileTypeReadableName[
        contentType as keyof typeof FileTypeReadableName
      ],
      value: contentType,
    }))
  }, [allContentTypes])
  const allTags = useMemo(() => {
    return allCurrentProjectFiles.flatMap((file) => file.tags)
  }, [allCurrentProjectFiles])
  const tagIdToTagName = useMemo(() => {
    return allTags.reduce(
      (acc, tag) => {
        acc[tag.id] = tag.name
        return acc
      },
      {} as SafeRecord<string, string>
    )
  }, [allTags])
  const documentTypeEntries = useMemo(() => {
    return Object.entries(tagIdToTagName).map(([id, name]) => ({
      text: name!,
      value: id,
    }))
  }, [tagIdToTagName])
  const sortedDocumentTypeGroups = useMemo(() => {
    const groupEntriesMap = documentTypeEntries.reduce(
      (acc, documentType) => {
        const parentTag =
          DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING[
            documentType.text as keyof typeof DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING
          ]
        if (!parentTag) return acc
        acc[parentTag] ??= []
        acc[parentTag]!.push(documentType)
        return acc
      },
      {} as SafeRecord<string, MultiSelectEntry[]>
    )
    return Object.entries(groupEntriesMap)
      .map(([label, entriesMap]) => ({
        label,
        entries: Object.values(entriesMap!),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) // Sort by label alphabetically
  }, [documentTypeEntries])

  const filterCountInDropdown = useMemo(() => {
    let count = 0
    if (selectedContentTypes.length > 0) {
      count += 1
    }
    if (selectedDocumentTypes.length > 0) {
      count += 1
    }
    return count
  }, [selectedContentTypes, selectedDocumentTypes])

  const filterData = useCallback(
    (items: VaultItem[]): VaultItem[] => {
      if (
        !searchTerm.trim() &&
        selectedContentTypes.length === 0 &&
        selectedDocumentTypes.length === 0
      )
        return items

      const normalizedTerm = searchTerm.toLowerCase().trim()
      const hasOnlySearchTerm =
        normalizedTerm.length > 0 &&
        selectedContentTypes.length === 0 &&
        selectedDocumentTypes.length === 0

      // Function to check if an item matches the search term
      const itemMatches = (item: VaultItem): boolean => {
        if (item.type === VaultItemType.file) {
          return (
            (normalizedTerm.length === 0 ||
              item.name.toLowerCase().includes(normalizedTerm)) &&
            (selectedContentTypes.length === 0 ||
              selectedContentTypes.includes(item.contentType ?? '')) &&
            (selectedDocumentTypes.length === 0 ||
              (!!item.tags &&
                item.tags.some((tag) =>
                  selectedDocumentTypes.includes(tag.id)
                )))
          )
        } else if (item.type === VaultItemType.folder) {
          // If user only filters by search term and the folder name matches the search term, it considers as matched
          if (
            hasOnlySearchTerm &&
            item.name.toLowerCase().includes(normalizedTerm)
          ) {
            return true
          }

          // Check if any children match
          const folderItem = item as { children?: VaultItem[] }
          const hasMatchingChild = folderItem.children?.some((child) =>
            itemMatches(child)
          )

          return hasMatchingChild || false
        }
        return false
      }

      // Create deep copies of items to avoid mutating the original array
      const cloneItems = (items: VaultItem[]): VaultItem[] => {
        return items.map((item) => {
          if (item.type === VaultItemType.folder) {
            const folderItem = item as { children?: VaultItem[] }
            return {
              ...item,
              children: folderItem.children
                ? cloneItems(folderItem.children)
                : undefined,
            }
          }
          return { ...item }
        })
      }

      // Filter items and their children recursively
      const filterItems = (items: VaultItem[]): VaultItem[] => {
        return items.filter((item) => {
          const matches = itemMatches(item)

          // For folders, if the folder name does not match the search term or the user is not only filtering by search term, we'd also filter their children
          if (
            item.type === VaultItemType.folder &&
            matches &&
            (!item.name.toLowerCase().includes(normalizedTerm) ||
              !hasOnlySearchTerm)
          ) {
            const folderItem = item as { children?: VaultItem[] }
            if (folderItem.children) {
              folderItem.children = filterItems(folderItem.children)
            }
          }

          return matches
        })
      }

      // Clone first, then filter
      const itemsCopy = cloneItems(items)
      const filteredItems = filterItems(itemsCopy)

      return filteredItems
    },
    [searchTerm, selectedContentTypes, selectedDocumentTypes]
  )

  const isSharedProject = userInfo.IsVaultViewSharesUser
    ? isProjectShared(sharedProjectIds, permissionsByProjectId, projectId)
    : false

  const data = useMemo(() => {
    if (!projectId) return []

    const baseData = prepareVaultExplorerData({
      projectId,
      currentFolderId: projectId,
      folderIdToVaultFolder: allFolderIdToVaultFolder,
      parentIdToVaultFolderIds: allParentIdToVaultFolderIds,
      fileIdToVaultFile: allFileIdToVaultFile,
      folderIdToVaultFileIds: allFolderIdToVaultFileIds,
      foldersMetadata,
      userId: userInfo.dbId,
      exampleProjectIds,
      existingSelectedFileIds,
      isAddingFilesToQuery: true,
      isSharedProject,
      canUserAccessKnowledgeBaseProject:
        userInfo.IsKnowledgeBaseProjectUser &&
        !!currentProject?.isKnowledgeBaseProject,
      shouldHideAllFiles,
      isVaultWorkspaceProjectsViewer: userInfo.isVaultWorkspaceProjectsViewer,
    })

    return filterData(baseData)
  }, [
    projectId,
    allFolderIdToVaultFolder,
    allParentIdToVaultFolderIds,
    allFileIdToVaultFile,
    allFolderIdToVaultFileIds,
    foldersMetadata,
    userInfo.dbId,
    exampleProjectIds,
    existingSelectedFileIds,
    isSharedProject,
    userInfo.IsKnowledgeBaseProjectUser,
    shouldHideAllFiles,
    filterData,
    currentProject?.isKnowledgeBaseProject,
    userInfo.isVaultWorkspaceProjectsViewer,
  ])

  const getPathForItem = useMemo(() => {
    return createGetPathForItem({
      data,
      foldersMetadata,
      folderIdToVaultFolder: allFolderIdToVaultFolder,
      currentFolderId: projectId || null,
      projectId,
    })
  }, [data, foldersMetadata, allFolderIdToVaultFolder, projectId])

  const tableRef = useRef<Table<VaultItem> | null>(null)

  const createShiftSelectionHandlerFn = useCallback(
    (row: Row<VaultItem>) => {
      if (!tableRef.current) return
      return createShiftSelectionHandler({
        table: tableRef.current,
        selectedRows,
        lastSelectedRow,
        getPathForItem,
        setSelectedRows,
      })(row)
    },
    [selectedRows, lastSelectedRow, getPathForItem, setSelectedRows]
  )

  const columns = useMemo(() => {
    return createVaultTableColumns({
      trackEvent,
      isAddingFilesToQuery: true,
      setLastSelectedRow,
      handleShiftSelection: createShiftSelectionHandlerFn,
      fileIdToVaultFile: allFileIdToVaultFile,
      shouldHideAllFiles,
    })
  }, [
    trackEvent,
    setLastSelectedRow,
    createShiftSelectionHandlerFn,
    allFileIdToVaultFile,
    shouldHideAllFiles,
  ])

  const isExampleProject = useMemo(
    () => !!projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )

  const sharingPermissionsOptions = useMemo(
    () => ({
      projectId: projectId,
    }),
    [projectId]
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions(
    sharingPermissionsOptions
  )

  const canCurrentUserSelectFiles =
    doesCurrentUserHaveEditPermission || userInfo.IsKnowledgeBaseProjectUser

  const hideColumns = useMemo(() => {
    return getHiddenColumns({
      isExampleProject,
      canCurrentUserSelectFiles,
      isAddingFilesToQuery: true,
      isDisplayingFilesProgress,
      shouldHideAllFiles,
    })
  }, [
    isExampleProject,
    canCurrentUserSelectFiles,
    isDisplayingFilesProgress,
    shouldHideAllFiles,
  ])

  const [sorting, setSorting] = useState<SortingState>([])

  const rowSelection = useMemo(() => {
    return selectedRows.reduce(
      (acc, row) => {
        const path = getPathForItem(row)

        if (path && path.length > 0) {
          acc[path] = true
        }
        return acc
      },
      {} as Record<string, boolean>
    )
  }, [selectedRows, getPathForItem])

  const table = useReactTable<VaultItem>({
    columns,
    data,
    autoResetPageIndex: false,
    getSubRows: (row) =>
      row.type !== VaultItemType.file ? row.children : undefined,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: (
      updaterOrValue:
        | Record<string, boolean>
        | ((old: Record<string, boolean>) => Record<string, boolean>)
    ) => {
      if (!tableRef.current) return
      return createRowSelectionHandler({
        table: tableRef.current,
        rowSelection,
        setSelectedRows,
      })(updaterOrValue)
    },
    state: {
      sorting,
      rowSelection,
      columnVisibility: _.mapValues(_.keyBy(hideColumns), () => false),
    },
    enableSorting: true,
    enableSortingRemoval: true,
  })

  useEffect(() => {
    tableRef.current = table
  }, [table])

  // Auto-expand all folders when search term is present
  useEffect(() => {
    if (
      searchTerm.trim() ||
      selectedContentTypes.length > 0 ||
      selectedDocumentTypes.length > 0
    ) {
      table.toggleAllRowsExpanded(true)
    }
    // Select all rows when new search term or new filters are applied
    table.toggleAllRowsSelected(true)
  }, [searchTerm, selectedContentTypes, selectedDocumentTypes, table])

  useMount(() => {
    if (preselectFileIds && preselectFileIds.size > 0) {
      const preselectedRows: VaultItemWithIndex[] = []
      const selectedFileIds = new Set<string>()

      const processFileRow = (row: Row<VaultItem>) => {
        if (
          row.original.type === VaultItemType.file &&
          preselectFileIds.has(row.original.id)
        ) {
          preselectedRows.push({
            ...row.original,
            index: getPathForItem(row.original),
          })
          selectedFileIds.add(row.original.id)
        }
      }

      const processFolderRow = (row: Row<VaultItem>) => {
        if (row.original.type === VaultItemType.folder) {
          // Get all leaf nodes (files) under this folder
          const leafRows = row.getLeafRows()
          const fileRows = leafRows.filter(
            (leafRow) => leafRow.original.type === VaultItemType.file
          )

          // If folder has files and all of them are selected, mark the folder as selected
          if (
            fileRows.length > 0 &&
            fileRows.every((fileRow) =>
              selectedFileIds.has(fileRow.original.id)
            )
          ) {
            preselectedRows.push({
              ...row.original,
              index: getPathForItem(row.original),
              isAllDescendantsSelected: true,
            })
          }
        }
      }

      // Process all rows
      table.getExpandedRowModel().flatRows.forEach(processFileRow)
      table.getExpandedRowModel().flatRows.forEach(processFolderRow)

      setSelectedRows(preselectedRows)
    }
  })

  const onRowClick = useCallback(
    async (row: Row<VaultItem>, e: React.MouseEvent | React.KeyboardEvent) => {
      handleVaultRowClick(row, e, {
        selectedRows,
        setSelectedRows,
        getPathForItem,
        lastSelectedRow,
        setLastSelectedRow,
        handleShiftSelection: createShiftSelectionHandlerFn,
        isAddingFilesToQuery: true,
        existingSelectedFileIds,
        shouldHideAllFiles,
      })
    },
    [
      selectedRows,
      setSelectedRows,
      getPathForItem,
      lastSelectedRow,
      setLastSelectedRow,
      createShiftSelectionHandlerFn,
      existingSelectedFileIds,
      shouldHideAllFiles,
    ]
  )

  const entity = 'project'
  const emptyStateText =
    searchTerm.trim() || filterCountInDropdown > 0
      ? `No files match your filters`
      : shouldHideAllFiles
      ? `No folders have been created in this ${entity} yet`
      : isSharedProject
      ? `No files have been uploaded to this ${entity} yet`
      : `You haven’t uploaded any files to this ${entity} yet`

  const projectMetadata = useMemo(
    () => (projectId ? foldersMetadata[projectId] : undefined),
    [projectId, foldersMetadata]
  )
  const isFilesProcessing = projectMetadata
    ? projectMetadata.completedFiles !== projectMetadata.totalFiles
    : false

  const pollProjectData = useCallback(async () => {
    return await createProjectDataPoller({
      projectId,
      upsertVaultFolders,
      upsertVaultFiles,
      addToProjectsMetadata,
      userId: userInfo.dbId,
      exampleProjectIds,
      deleteVaultFiles,
      deleteVaultFolders,
      setError,
      projectIdToFolderIds,
      folderIdToVaultFolder: allFolderIdToVaultFolder,
    })
  }, [
    projectId,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    userInfo.dbId,
    exampleProjectIds,
    deleteVaultFiles,
    deleteVaultFolders,
    setError,
    projectIdToFolderIds,
    allFolderIdToVaultFolder,
  ])

  useInterval(pollProjectData, isFilesProcessing ? 10_000 : null)

  useEffect(() => {
    const refetchProjectData = async () => {
      await pollProjectData()
      setRequiresProjectDataRefetch(false)
    }
    if (requiresProjectDataRefetch) {
      void refetchProjectData()
    }
  }, [
    requiresProjectDataRefetch,
    pollProjectData,
    setRequiresProjectDataRefetch,
  ])

  useUnmount(() => {
    setSelectedRows([])
    setOpenFileId(null)
  })

  return (
    <div className={cn('flex flex-col gap-2 px-6 pt-4', className)}>
      {userInfo.IsVaultInternalOnlyUser && (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <DropdownMenu
              onOpenChange={(open) => {
                if (open) {
                  trackEvent('Vault File Selector Filters Dropdown Opened', {
                    number_of_filters: filterCountInDropdown,
                  })
                }
              }}
            >
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className={cn('justify-between text-xs', {
                    'text-muted': filterCountInDropdown === 0,
                  })}
                >
                  <Icon icon={ListFilter} size="small" className="mr-1" />
                  Filters
                  {filterCountInDropdown > 0 && (
                    <Badge
                      variant="secondary"
                      className="ml-1 h-4 min-w-4 justify-center px-1 text-xs"
                    >
                      {filterCountInDropdown}
                    </Badge>
                  )}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="flex w-[300px] flex-col gap-4 p-4"
                align="start"
              >
                <p className="text-sm font-medium">Filters</p>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-between gap-1">
                    <p className="text-xs text-muted">Document type</p>
                    <MultiSelect
                      placeholder="Select"
                      ariaLabel="Filter by document type"
                      selectedValues={selectedDocumentTypes}
                      setSelectedValues={(values) => {
                        setSelectedDocumentTypes(values)
                        trackEvent(
                          'Vault File Selector Document Type Filter Changed',
                          {
                            number_of_document_types: values.length,
                          }
                        )
                      }}
                      sortedEntries={[]}
                      sortedGroups={sortedDocumentTypeGroups}
                      className={cn('w-[160px]', {
                        'text-muted': selectedDocumentTypes.length === 0,
                      })}
                      customEntryRenderer={(entry) => (
                        <SimpleDocumentClassificationPill
                          tagName={entry.text}
                          tagId={entry.value}
                          isPill
                          pillClassName="cursor-pointer"
                        />
                      )}
                    />
                  </div>
                  <div className="flex items-center justify-between gap-1">
                    <p className="text-xs text-muted">File type</p>
                    <MultiSelect
                      placeholder="Select"
                      ariaLabel="Filter by file type"
                      selectedValues={selectedContentTypes}
                      setSelectedValues={(values) => {
                        setSelectedContentTypes(values)
                        trackEvent(
                          'Vault File Selector File Type Filter Changed',
                          {
                            number_of_file_types: values.length,
                          }
                        )
                      }}
                      sortedEntries={sortedContentTypes}
                      className={cn('w-[160px]', {
                        'text-muted': selectedContentTypes.length === 0,
                      })}
                    />
                  </div>
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
            {filterCountInDropdown > 0 && (
              <Button
                variant="dashed"
                size="sm"
                className="text-muted"
                onClick={() => {
                  trackEvent('Vault File Selector Filters Reset', {
                    number_of_document_types: selectedDocumentTypes.length,
                    number_of_file_types: selectedContentTypes.length,
                  })
                  setSelectedDocumentTypes([])
                  setSelectedContentTypes([])
                }}
              >
                <Icon icon={XCircle} size="small" className="mr-1" />
                Reset
              </Button>
            )}
          </div>
          <div className="relative">
            <div className="absolute flex h-full items-center px-3">
              <Icon icon={Search} size="small" className="text-muted" />
            </div>
            <SearchInput
              placeholder="Search files…"
              inputClassName="h-6 w-[200px] text-xs placeholder:text-xs"
              value={searchTerm}
              setValue={(value) => {
                setSearchTerm(value)
                trackEvent('Vault File Selector Search Term Changed', {
                  length_of_search_term: value.length,
                })
              }}
            />
          </div>
        </div>
      )}
      <DataTable
        caption={`${entity} files`}
        table={table}
        onRowClick={onRowClick}
        className={cn('rounded-none', {
          'max-h-[308px] rounded-lg': isDisplayingFilesProgress,
        })}
        hideTableBorder={!isDisplayingFilesProgress}
        tableCellClassName={cn(
          'px-1 py-2.5 first:px-0 first:py-1 last:px-0 last:py-1 font-normal select-none bg-primary'
        )}
        emptyStateText={emptyStateText}
        useVirtual
        virtualEstimateSize={41}
        marginTop={256}
        hideHeaders={isDisplayingFilesProgress}
        useModalStyling={useModalStyling}
        rowFilter={
          shouldHideAllFiles
            ? (row) => row.original.type !== VaultItemType.file
            : undefined
        }
      />
      {renderWarningMessages(selectedRows, {
        numberOfFilesLimit,
        numberOfFilesWarningThreshold,
        showDocumentClassificationMismatchWarning,
        isAddingFilesToQuery: true,
        isAddingFilesToReviewQuery,
      })}
    </div>
  )
}

export default VaultFileExplorerInModal
