import React, { useState } from 'react'

import { Download } from 'lucide-react'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { fetchAndDownloadDocuments } from 'utils/download'
import { displayErrorMessage } from 'utils/toast'
import { displayInfoMessage } from 'utils/toast'

import { DownloadDialog } from 'components/common/download-dialog/download-dialog'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'

export const DownloadSection = ({
  selectedDocuments,
  title,
  exportTitle,
  eventId,
  getDocument,
  additionalSourcesCount,
  includeAdditionalSources,
  setIncludeAdditionalSources,
  onClick,
  onConfirmFiles,
}: {
  selectedDocuments: UploadedFile[]
  title?: string
  eventId: string | null
  exportTitle: string | null
  getDocument: (
    eventId: string | null,
    documentId: string
  ) => Promise<UploadedFile | undefined>
  additionalSourcesCount: number
  includeAdditionalSources: boolean
  setIncludeAdditionalSources: (include: boolean) => void
  onClick: (location: 'Vault' | 'local') => void
  onConfirmFiles: (location: 'Vault' | 'local') => void
}) => {
  const [showDownloadDialog, setShowDownloadDialog] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownload = async (e?: React.FormEvent) => {
    setIsDownloading(true)
    e?.preventDefault()
    try {
      requestIdleCallback(async () => {
        await fetchAndDownloadDocuments({
          documents: selectedDocuments,
          exportTitle,
          eventId,
          getDocument,
          title: title,
        })
        displayInfoMessage('Your documents have been downloaded')
      })
      onConfirmFiles('local')
    } catch (e) {
      displayErrorMessage('Failed to download documents')
    }
    handleCancel()
  }

  const handleCancel = () => {
    setShowDownloadDialog(false)
    setIsDownloading(false)
    setIncludeAdditionalSources(true)
  }

  const handleOnClick = async () => {
    if (additionalSourcesCount > 0) {
      setShowDownloadDialog(true)
    } else {
      await handleDownload()
    }
    onClick('local')
  }

  return (
    <>
      <Button
        variant="outline"
        size="sm"
        className="w-full"
        onClick={handleOnClick}
      >
        <Icon icon={Download} className="mr-2" />
        Download sources
      </Button>
      <DownloadDialog
        onOpenChange={setShowDownloadDialog}
        isOpen={showDownloadDialog}
        additionalSourcesCount={additionalSourcesCount}
        selectedDocumentsCount={selectedDocuments.length}
        includeAdditionalSources={includeAdditionalSources}
        setIncludeAdditionalSources={setIncludeAdditionalSources}
        isDownloading={isDownloading}
        handleDownload={handleDownload}
        handleCancel={handleCancel}
      />
    </>
  )
}
