/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowTextInputBlockBlockParams
 */
export interface WorkflowTextInputBlockBlockParams {
    /**
     * 
     * @type {string}
     * @memberof WorkflowTextInputBlockBlockParams
     */
    agentText?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowTextInputBlockBlockParams
     */
    placeholder?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowTextInputBlockBlockParams
     */
    optional?: boolean | null;
}

/**
 * Check if a given object implements the WorkflowTextInputBlockBlockParams interface.
 */
export function instanceOfWorkflowTextInputBlockBlockParams(value: object): value is WorkflowTextInputBlockBlockParams {
    return true;
}

export function WorkflowTextInputBlockBlockParamsFromJSON(json: any): WorkflowTextInputBlockBlockParams {
    return WorkflowTextInputBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowTextInputBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowTextInputBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'agentText': json['agent_text'] == null ? undefined : json['agent_text'],
        'placeholder': json['placeholder'] == null ? undefined : json['placeholder'],
        'optional': json['optional'] == null ? undefined : json['optional'],
    };
}

export function WorkflowTextInputBlockBlockParamsToJSON(json: any): WorkflowTextInputBlockBlockParams {
    return WorkflowTextInputBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowTextInputBlockBlockParamsToJSONTyped(value?: WorkflowTextInputBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'agent_text': value['agentText'],
        'placeholder': value['placeholder'],
        'optional': value['optional'],
    };
}

