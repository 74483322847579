import * as React from 'react'
import { useMemo } from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { cn } from 'utils/utils'

import {
  FileSource,
  KnowledgeSourceItem,
} from 'components/assistant/utils/assistant-knowledge-sources'
import LooseFilesWarningBanner from 'components/common/loose-files-warning-banner'
import { FetchingSpinner } from 'components/common/vault-knowledge-source-picker'
import { Button } from 'components/ui/button'
import {
  DialogContent,
  DialogTitle,
  DialogHeader,
  DialogFooter,
} from 'components/ui/dialog'
import { Dialog } from 'components/ui/dialog'
import VaultFileExplorerInModal from 'components/vault/components/file-explorer/vault-file-explorer-in-modal'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getSelectedFiles,
  getSortedFilesBasedOnReviewQueryOrder,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

const AssistantProjectFileSelector = ({
  showDialog,
  setShowDialog,
  onClose,
  setKnowledgeSource,
  isEmpty,
  dialogTitle,
  isFetching,
  allVaultProjects,
  activeFileIds,
  numberOfFilesLimit,
  numberOfFilesWarningThreshold,
  emptyState,
  projectChooser,
  isCurrentProjectKnowledgeBase = false,
  backButtonText = 'Back',
  handleResetFolderOverride,
}: {
  showDialog: boolean
  setShowDialog: (showDialog: boolean) => void
  onClose: () => void
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  isEmpty: boolean
  dialogTitle: string
  dialogDescription: string
  isFetching: boolean
  allVaultProjects: VaultFolder[]
  activeFileIds: Set<string>
  numberOfFilesLimit?: number
  numberOfFilesWarningThreshold?: number
  emptyState?: React.ReactNode
  projectChooser?: React.ReactNode
  isCurrentProjectKnowledgeBase?: boolean
  backButtonText?: string
  handleResetFolderOverride?: () => void
}) => {
  const [
    fileIdToVaultFile,
    folderIdToVaultFolder,
    folderIdToVaultFileIds,
    currentProject,
    setCurrentProject,
  ] = useVaultStore(
    useShallow((s) => [
      s.currentProjectFileIdToVaultFile,
      s.currentProjectFolderIdToVaultFolder,
      s.currentProjectFolderIdToVaultFileIds,
      s.currentProject,
      s.setCurrentProject,
    ])
  )
  const [selectedRows, setSelectedRows] = useVaultFileExplorerStore(
    useShallow((s) => [s.selectedRows, s.setSelectedRows])
  )
  const activeFiles = Array.from(activeFileIds)
    .map((fileId) => fileIdToVaultFile[fileId])
    .filter(Boolean) as VaultFile[]
  const handleOpenChange = (isOpen: boolean) => {
    setShowDialog(isOpen)
    if (!isOpen && !activeFiles.length) handleClose()
  }
  const handleClose = () => {
    setKnowledgeSource(null)
    setCurrentProject(null)
    onClose()
  }

  const selectedFiles = React.useMemo(() => {
    return getSelectedFiles(selectedRows)
  }, [selectedRows])

  const handleResetFolder = () => {
    setCurrentProject(null)
  }

  const handleAddFiles = () => {
    const sortedFiles = getSortedFilesBasedOnReviewQueryOrder(
      selectedFiles,
      folderIdToVaultFolder
    )
    setKnowledgeSource({
      folderId: currentProject?.id,
      type: FileSource.VAULT,
      fileIds: sortedFiles.map((file) => file.id),
    })
    setShowDialog(false)
  }

  const tooManyFilesSelected =
    !_.isNil(numberOfFilesLimit) && selectedFiles.length > numberOfFilesLimit

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  const shouldShowLooseFilesWarningBanner = useMemo(() => {
    if (
      !isCurrentProjectKnowledgeBase ||
      !doesCurrentUserHaveEditPermission ||
      !currentProject?.id
    ) {
      return false
    }

    // Check if the project (root folder) has any files directly in it
    const fileIdsInRootFolder = folderIdToVaultFileIds[currentProject.id] || []
    const nonDeletedFilesInRootFolder = fileIdsInRootFolder.filter(
      (fileId) => !fileIdToVaultFile[fileId]?.deletedAt
    )
    return nonDeletedFilesInRootFolder.length > 0
  }, [
    isCurrentProjectKnowledgeBase,
    folderIdToVaultFileIds,
    currentProject?.id,
    fileIdToVaultFile,
    doesCurrentUserHaveEditPermission,
  ])

  return (
    <Dialog open={showDialog} onOpenChange={handleOpenChange}>
      <DialogContent
        className={cn('max-w-[960px]', {
          'h-[600px]': !isEmpty,
        })}
        hasContainer={!currentProject}
        innerClassName="flex flex-col p-0 py-5 space-y-0 h-full"
      >
        {!isEmpty && (
          <DialogHeader className="h-9 shrink-0 border-b border-b-primary px-6">
            <DialogTitle className="flex items-center">
              {dialogTitle}{' '}
              {isFetching && (
                <FetchingSpinner
                  delayMs={allVaultProjects.length > 0 ? 1000 : 0}
                />
              )}
            </DialogTitle>
          </DialogHeader>
        )}
        {shouldShowLooseFilesWarningBanner && (
          <div className="px-6">
            <LooseFilesWarningBanner />
          </div>
        )}
        {currentProject ? (
          <VaultFileExplorerInModal
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            preselectFileIds={activeFileIds}
            projectId={currentProject.id}
            numberOfFilesLimit={numberOfFilesLimit}
            numberOfFilesWarningThreshold={numberOfFilesWarningThreshold}
            shouldHideAllFiles={isCurrentProjectKnowledgeBase}
            useModalStyling
            className="flex min-h-0 grow flex-col rounded-none pb-4"
          />
        ) : isEmpty ? (
          emptyState
        ) : (
          projectChooser
        )}
        <DialogFooter className="flex w-full flex-row items-center justify-between border-t px-6 pt-4 sm:justify-between">
          {selectedFiles.length > 0 && !isCurrentProjectKnowledgeBase ? (
            <p className="text-xs text-muted">
              {pluralizeFiles(selectedFiles.length)} selected
            </p>
          ) : (
            <div />
          )}
          <div className="flex flex-row gap-2">
            {!activeFiles.length && (
              <Button
                variant="outline"
                onClick={handleResetFolderOverride ?? handleResetFolder}
              >
                {backButtonText}
              </Button>
            )}
            <Button
              disabled={!selectedFiles.length || tooManyFilesSelected}
              onClick={handleAddFiles}
            >
              Add
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AssistantProjectFileSelector
