import React from 'react'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { createFileName, parsedName } from 'utils/file-utils'

import { Button } from 'components/ui/button'
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog'
import { VaultDialogHeader } from 'components/vault/dialogs/vault-dialog-header'

export const FilesNameCollisionModal = ({
  selectedDocuments,
  setSelectedDocuments,
  isOpen,
  setIsOpen,
  existingNamesSet,
  duplicateFiles,
}: {
  selectedDocuments: UploadedFile[]
  setSelectedDocuments: (documents: UploadedFile[]) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  existingNamesSet: Set<string>
  duplicateFiles: UploadedFile[]
}) => {
  const handleCancel = () => {
    setIsOpen(false)
  }

  const getNonDuplicateFiles = () => {
    const duplicateFileIds = new Set(duplicateFiles.map((file) => file.id))
    return selectedDocuments.filter((file) => !duplicateFileIds.has(file.id))
  }

  const handleSkipDuplicates = () => {
    const nonDuplicateFiles = getNonDuplicateFiles()
    setSelectedDocuments(nonDuplicateFiles)
    setIsOpen(false)
  }

  const handleKeepDuplicates = () => {
    const nonDuplicateFiles = getNonDuplicateFiles()
    const projectFilesNamesWithNewDuplicates = new Set<string>(existingNamesSet)
    const renamedDuplicateFiles: UploadedFile[] = duplicateFiles.map((file) => {
      const parsedFileName = parsedName(file.name)
      const newFileName = createFileName(
        parsedFileName,
        projectFilesNamesWithNewDuplicates
      )
      projectFilesNamesWithNewDuplicates.add(newFileName)
      return {
        ...file,
        name: newFileName,
      }
    })

    setSelectedDocuments([...nonDuplicateFiles, ...renamedDuplicateFiles])
    setIsOpen(false)
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent showCloseIcon={false} className="max-w-screen-sm">
        <VaultDialogHeader
          dialogTitle="Duplicate Files Detected"
          dialogDescription="Files already exist in the project"
          currentFolder={null}
        />
        <div className="flex flex-col space-y-2">
          <p className="mt-4">What would you like to do?</p>
        </div>
        <DialogFooter className="mt-6 flex justify-end space-x-2">
          <Button variant="ghost" onClick={handleCancel}>
            Cancel
          </Button>

          <Button variant="destructive" onClick={handleSkipDuplicates}>
            Skip duplicates
          </Button>
          <Button
            onClick={handleKeepDuplicates}
            data-testid="vault-duplicate-files-modal--keep-button"
          >
            Keep duplicates
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
