import React, { useCallback, useMemo } from 'react'
import { useEffect } from 'react'

import {
  ColumnDef,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table'
import _ from 'lodash'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { EventStatus } from 'openapi/models/EventStatus'
import { TableSelect } from 'openapi/models/TableSelect'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { WorkflowTableSelectInputBlockBlockParams } from 'openapi/models/WorkflowTableSelectInputBlockBlockParams'
import { WorkflowTableSelectInputBlockOutput } from 'openapi/models/WorkflowTableSelectInputBlockOutput'
import { Maybe } from 'types'

import { getHrvyInfoMetadata } from 'utils/source'
import { Source } from 'utils/task'

import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import { AssistantWorkflowSidebar } from 'components/assistant/workflows/components/assistant-workflow-sidebar'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import TableSelectTable, {
  generateColumns,
} from 'components/assistant/workflows/components/table-select/table-select'
import { RowActions } from 'components/assistant/workflows/components/table-select/table-select-row-actions'
import { validateTable } from 'components/assistant/workflows/components/table-select/table-select-validation'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useCalcTableMargin } from 'components/assistant/workflows/hooks/use-calc-table-margin'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import { useTableSelectActions } from 'components/assistant/workflows/hooks/use-table-select-actions'
import { useTableSelectStore } from 'components/assistant/workflows/stores/table-select-store'
import { inActiveCitationsLoadingState } from 'components/assistant/workflows/utils/utils'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

type RowData = TableSelect['rows'][number]
const columnHelper = createColumnHelper<RowData>()

// TODO: Ideally use Pick<>
const useRestoreTableData = (params: {
  stepIdx: number
  blockParams: WorkflowTableSelectInputBlockBlockParams
  outputData: WorkflowTableSelectInputBlockOutput | null
}) => {
  const { stepIdx, blockParams, outputData } = params
  const [setTableData, setTempTableData, setSelectedRows] = useTableSelectStore(
    stepIdx
  )(
    useShallow((state) => [
      state.setTableData,
      state.setTempTableData,
      state.setSelectedRows,
    ])
  )

  const restoreTableData = useCallback(() => {
    if (!blockParams.table) return

    const table = outputData?.table ?? blockParams.table

    setTableData({ ...table, rows: table.rows })
    setTempTableData({ ...table, rows: table.rows })
    setSelectedRows(
      table.rows.reduce((acc, row, idx) => {
        acc[idx] = typeof row.isSelected === 'boolean' ? row.isSelected : true
        return acc
      }, {} as RowSelectionState)
    )
  }, [blockParams, outputData, setSelectedRows, setTableData, setTempTableData])

  return restoreTableData
}

export const AssistantWorkflowTableSelectThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TABLE_SELECT
> = ({
  blockParams,
  loadingStates,
  stepIdx,
  outputData,
  paramStatus,
  completionStatus,
  isEditing,
  setIsEditing,
}) => {
  const { headerText } = blockParams
  const { handleSourceClick, getHrvyInfoMetadata } = useSourceUtils({
    sources: (blockParams.sources || []) as Source[],
  })
  const [tableData, selectedRows, setSelectedRows, setAllSelectedRows] =
    useTableSelectStore(stepIdx)(
      useShallow((state) => [
        state.tableData,
        state.selectedRows,
        state.setSelectedRows,
        state.setAllSelectedRows,
      ])
    )

  const { handleRemoveRow, handleRowChange, handleSaveRow, handleAddRow } =
    useTableSelectActions(useTableSelectStore(stepIdx))

  const restoreTableData = useRestoreTableData({
    stepIdx,
    blockParams,
    outputData,
  })

  useEffect(() => {
    restoreTableData()
  }, [restoreTableData])

  const negativeMargin = useCalcTableMargin()

  const isCompleted = paramStatus === EventStatus.COMPLETED
  const isSubmitted = !!outputData && !isEditing

  const Sidebar = useMemo(() => {
    if (
      !inActiveCitationsLoadingState(loadingStates) &&
      !blockParams.sources?.length
    ) {
      return null
    }
    return (
      <AssistantWorkflowSidebar
        sources={blockParams.sources as Maybe<Source[]>}
        isStreaming={!isCompleted}
        onSetActiveFileId={handleSourceClick}
        className="top-[42px] w-72 sm:w-72"
        // eslint-disable-next-line react/forbid-component-props
        style={{ marginLeft: negativeMargin }}
      />
    )
  }, [
    blockParams.sources,
    handleSourceClick,
    isCompleted,
    loadingStates,
    negativeMargin,
  ])

  const canAddRemoveRows = blockParams.table?.canAddRemoveRows

  const showActions = !isSubmitted && canAddRemoveRows

  const columns: ColumnDef<RowData>[] = useMemo(
    () =>
      !tableData
        ? []
        : [
            ...(!canAddRemoveRows
              ? [
                  columnHelper.display({
                    id: 'select',
                    header: ({ table }) => (
                      <div className="flex items-center gap-2">
                        <Checkbox
                          checked={
                            table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() &&
                              'indeterminate')
                          }
                          onCheckedChange={(value) => {
                            table.toggleAllPageRowsSelected(!!value)
                            setAllSelectedRows(!!value)
                          }}
                          isIndeterminate={table.getIsSomePageRowsSelected()}
                          disabled={isSubmitted}
                          aria-label="Select all"
                        />
                      </div>
                    ),
                    cell: ({ row }) => (
                      <div className="flex items-center gap-2">
                        <Checkbox
                          checked={row.getIsSelected()}
                          onCheckedChange={(value) => {
                            row.toggleSelected(!!value)
                            setSelectedRows({ [row.index]: !!value })
                          }}
                          disabled={isSubmitted}
                          aria-label="Select row"
                        />
                      </div>
                    ),
                    enableSorting: false,
                    enableHiding: false,
                    size: 36,
                  }),
                ]
              : []),
            ...(generateColumns(
              tableData,
              getHrvyInfoMetadata,
              handleRowChange,
              handleSaveRow
            ) as []),
            ...(showActions
              ? [
                  columnHelper.display({
                    id: 'actions',
                    cell: ({ row }) => (
                      <RowActions
                        canAddRemoveRows={canAddRemoveRows}
                        handleRemoveRow={handleRemoveRow}
                        rowIdx={row.index}
                      />
                    ),
                    size: 50,
                  }),
                ]
              : []),
          ],
    [
      tableData,
      canAddRemoveRows,
      getHrvyInfoMetadata,
      handleRowChange,
      showActions,
      isSubmitted,
      setAllSelectedRows,
      setSelectedRows,
      handleRemoveRow,
      handleSaveRow,
    ]
  )

  const Table = isCompleted ? (
    <TableSelectTable
      title={tableData?.title || ''}
      data={tableData?.rows || []}
      columns={columns}
      selectedRows={selectedRows}
      canAddRemoveRows={!outputData && canAddRemoveRows}
      onAddRow={handleAddRow}
    />
  ) : null

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <LoadingState
          isCompleted={paramStatus === 'COMPLETED'}
          paramStatus={paramStatus}
          states={loadingStates}
        />
        <>
          <AssistantWorkflowThreadText
            completionStatus={completionStatus}
            text={headerText}
          />
          <div
            className="mt-5"
            style={{
              marginLeft: `-${negativeMargin}px`,
              marginRight: `-${negativeMargin}px`,
            }}
          >
            {!isSubmitted || isEditing ? (
              Table
            ) : (
              <Button
                className="w-full text-left transition"
                onClick={() => setIsEditing(true)}
                variant="unstyled"
                data-testid="table-select-edit-button"
              >
                {Table}
              </Button>
            )}
          </div>
        </>
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTableSelectInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TABLE_SELECT
> = ({
  blockParams,
  onCompleted,
  stepIdx,
  outputData,
  paramStatus,
  isEditing,
  setIsEditing,
  onUpdated,
}) => {
  const [selectedRows, tableData, isLoading, setIsLoading] =
    useTableSelectStore(stepIdx)(
      useShallow((state) => [
        state.selectedRows,
        state.tableData,
        state.isLoading,
        state.setIsLoading,
      ])
    )

  const numSelected = Object.values(selectedRows).filter(Boolean).length

  const restoreTableData = useRestoreTableData({
    stepIdx,
    blockParams,
    outputData,
  })

  const handleSubmit = () => {
    if (!tableData) return

    setIsLoading(true)
    const newTableData = {
      ...tableData,
      rows: tableData.rows.map((row, idx) => {
        return {
          ...row,
          isSelected: selectedRows[idx],
        }
      }),
    }
    if (isEditing) {
      onUpdated({ table: newTableData })
      setIsEditing(false)
    } else {
      onCompleted({ table: newTableData })
    }
    setIsLoading(false)
  }

  const handleCancelEdit = () => {
    restoreTableData()
    setIsEditing(false)
  }

  // if no rows, also disable
  const _disabled =
    isLoading ||
    (!!outputData && !isEditing) ||
    tableData?.rows.some((row) => row.isEditing) ||
    paramStatus !== 'COMPLETED' ||
    (tableData && !validateTable({ tableData })) ||
    (tableData && tableData.rows.length === 0) ||
    !tableData

  const disabled = _.isNull(_disabled) ? true : _disabled

  return (
    <div className="flex w-full">
      <div className="size-6" />
      <div className="flex grow flex-col gap-2 pl-4">
        <WorkflowInput>
          <div className="flex items-center justify-between gap-2 bg-transparent">
            <p className="pl-[10px] text-xs text-inactive">
              {paramStatus == EventStatus.COMPLETED &&
              !blockParams.table?.canAddRemoveRows
                ? `${numSelected} ${pluralize('row', numSelected)} selected`
                : ''}
            </p>
            <div className="space-x-2">
              {isEditing && (
                <Button
                  onClick={handleCancelEdit}
                  tooltip="Cancel edit"
                  variant="outline"
                >
                  Cancel
                </Button>
              )}
              <Button
                isLoading={isLoading}
                onClick={handleSubmit}
                disabled={disabled}
                tooltip={
                  disabled ? 'Please fill out all required columns' : undefined
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </WorkflowInput>
      </div>
    </div>
  )
}

export const AssistantWorkflowTableSelectExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowInputComponentBlocks.TABLE_SELECT
> = ({ blockParams, outputData }) => {
  const { headerText, table } = blockParams

  const canAddRemoveRows = table?.canAddRemoveRows

  const columns: ColumnDef<RowData>[] = table
    ? [
        ...(!canAddRemoveRows
          ? [
              columnHelper.display({
                id: 'select',
                header: ({ table }) => (
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={
                        table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && 'indeterminate')
                      }
                      onCheckedChange={() => null}
                    />
                  </div>
                ),
                cell: ({ row }) => (
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={row.getIsSelected()}
                      onCheckedChange={() => null}
                    />
                  </div>
                ),
                enableSorting: false,
                enableHiding: false,
                size: 36,
              }),
            ]
          : []),
        ...(generateColumns(
          table,
          {} as getHrvyInfoMetadata,
          () => null
        ) as []),
      ]
    : []

  const selectedRowData = table?.rows ?? []
  const selectedRows = selectedRowData.reduce((acc, row, idx) => {
    acc[idx] = typeof row.isSelected === 'boolean' ? row.isSelected : true
    return acc
  }, {} as RowSelectionState)

  return (
    <>
      <div>{headerText}</div>
      <div className="mt-5">
        <TableSelectTable
          data={outputData?.table.rows ?? []}
          columns={columns}
          selectedRows={selectedRows}
        />
      </div>
    </>
  )
}
