import { useCallback, useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { FetchSharingWorkspaceUsers } from 'models/fetchers/sharing-fetcher'
import { DocumentClassificationTag } from 'openapi/models/DocumentClassificationTag'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'
import { useSharingStore } from 'stores/sharing-store'

import { SafeRecord } from 'utils/safe-types'

import { useAuthUser } from 'components/common/auth-context'
import { useDocumentClassificationStore } from 'components/vault/utils/use-document-classification-store'
import {
  FetchVaultExampleProjects,
  FetchVaultSetup,
  FetchVaultProjects,
  FetchVaultFolder,
} from 'components/vault/utils/vault-fetcher'
import { fetchFoldersMetadata } from 'components/vault/utils/vault-helpers'
import { getVaultProjects } from 'components/vault/utils/vault-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

export const useFetchVaultProjects = () => {
  const userInfo = useAuthUser()
  const isSharingEnabled = userInfo.IsVaultViewSharesUser

  const [
    setIsFetchingProjects,
    setHasLoadedProjects,
    setHasLoadedProjectsMetadata,
    setExampleProjectIds,
    setAreExampleProjectsLoaded,
    upsertVaultFolders,
    upsertVaultFiles,
    addToProjectsMetadata,
    setShowProcessingProgress,
  ] = useVaultStore(
    useShallow((s) => [
      s.setIsFetchingProjects,
      s.setHasLoadedProjects,
      s.setHasLoadedProjectsMetadata,
      s.setExampleProjectIds,
      s.setAreExampleProjectsLoaded,
      s.upsertVaultFolders,
      s.upsertVaultFiles,
      s.addToProjectsMetadata,
      s.setShowProcessingProgress,
    ])
  )

  const [setSharingUsersForWorkspace, setDidFetchSharingUsersForWorkspaceFail] =
    useSharingStore(
      useShallow((s) => [
        s.setSharingUsersForWorkspace,
        s.setDidFetchSharingUsersForWorkspaceFail,
      ])
    )

  const [
    setKnowledgeBaseUsersForWorkspace,
    setDidFetchKnowledgeBaseUsersForWorkspaceFail,
    setIsFetchingKnowledgeBaseUsersForWorkspace,
  ] = useVaultSharingStore(
    useShallow((s) => [
      s.setKnowledgeBaseUsersForWorkspace,
      s.setDidFetchKnowledgeBaseUsersForWorkspaceFail,
      s.setIsFetchingKnowledgeBaseUsersForWorkspace,
    ])
  )
  const [setPermissionsForProjectId] = useVaultSharingStore(
    useShallow((s) => [s.setPermissionsForProjectId])
  )

  const setDocumentClassificationTags = useDocumentClassificationStore(
    useShallow((s) => s.setDocumentClassificationTags)
  )

  const fetchAndSaveProjectMetadata = useCallback(
    async (
      projects: VaultFolder[],
      isExampleProject: boolean
    ): Promise<SafeRecord<string, VaultFolderMetadata>> => {
      const responses = await Promise.all(
        projects.map(async (project) => {
          try {
            const projectsMetadata = await fetchFoldersMetadata(project)
            addToProjectsMetadata(projectsMetadata)
            Object.values(projectsMetadata).forEach((projectMetadata) => {
              upsertVaultFiles(
                projectMetadata.descendantFiles ?? [],
                project.id
              )
              upsertVaultFolders(
                projectMetadata.descendantFolders ?? [],
                userInfo.dbId,
                isExampleProject,
                project.id
              )
              const shouldShowProcessingProgress =
                projectMetadata.failedFiles !== 0 ||
                projectMetadata.completedFiles !== projectMetadata.totalFiles
              if (shouldShowProcessingProgress) {
                setShowProcessingProgress(project.id, true)
              }
            })
            return projectsMetadata
          } catch (e) {
            console.error('Error fetching project metadata', e)
            return {}
          }
        })
      )
      return responses.reduce((acc, response) => {
        return { ...acc, ...response }
      }, {})
    },
    [
      userInfo.dbId,
      addToProjectsMetadata,
      upsertVaultFiles,
      upsertVaultFolders,
      setShowProcessingProgress,
    ]
  )

  const fetchProject = useCallback(
    async (projectId: string, throwError = false) => {
      if (!userInfo.IsVaultUser) {
        // Only fetch projects if user is a vault user
        return
      }

      let project = undefined
      try {
        const projectResponse = await FetchVaultFolder(projectId, true)
        project = projectResponse
      } catch (e) {
        console.error('Error fetching project', e)
        if (throwError) {
          throw e
        }
      }
      if (project) {
        upsertVaultFolders([project], userInfo.dbId, false, project.id)
        if (project.shareStatus) {
          setPermissionsForProjectId({
            projectId: project.id,
            userId: userInfo.dbId,
            workspaceId: userInfo.workspace.id,
            permissions: project.shareStatus,
          })
        }
        const currentProjectMetadataResponse =
          await fetchAndSaveProjectMetadata([project], false)
        const currentProjectMetadata =
          currentProjectMetadataResponse[project.id]
        if (currentProjectMetadata) {
          const shouldShowProcessingProgress =
            currentProjectMetadata.failedFiles !== 0 ||
            currentProjectMetadata.completedFiles !==
              currentProjectMetadata.totalFiles

          if (shouldShowProcessingProgress) {
            setShowProcessingProgress(project.id, true)
          }
        }
      }
      return project
    },
    [
      userInfo.IsVaultUser,
      userInfo.dbId,
      userInfo.workspace.id,
      upsertVaultFolders,
      setPermissionsForProjectId,
      setShowProcessingProgress,
      fetchAndSaveProjectMetadata,
    ]
  )

  const fetchProjects = useCallback(
    async (potentialProjectId?: string) => {
      if (!userInfo.IsVaultUser) {
        // Only fetch projects if user is a vault user
        return
      }

      // 1. Fetch the document classification tags
      try {
        const vaultSetupResponse = await FetchVaultSetup()
        const documentClassificationTags =
          vaultSetupResponse.documentClassificationTags as DocumentClassificationTag[]
        setDocumentClassificationTags(documentClassificationTags)
      } catch (e) {
        console.error('Error fetching vault setup', e)
      }

      // 2. Fetch the sharing and knowledge base users for workspace.
      // We do this before fetching remaining projects to enable sharing sooner on the current project
      if (userInfo.IsVaultCreateSharesUser) {
        try {
          const response = await FetchSharingWorkspaceUsers(
            userInfo.workspace.id,
            'vault'
          )
          const users = response.users
          setSharingUsersForWorkspace(users)
          setDidFetchSharingUsersForWorkspaceFail(false)
        } catch (e) {
          console.error('Error fetching sharing workspace users', e)
          setDidFetchSharingUsersForWorkspaceFail(true)
        }
      }
      if (userInfo.IsCreateKnowledgeBaseProjectUser) {
        try {
          setIsFetchingKnowledgeBaseUsersForWorkspace(true)
          const response = await FetchSharingWorkspaceUsers(
            userInfo.workspace.id,
            'knowledge_base'
          )
          const users = response.users
          setKnowledgeBaseUsersForWorkspace(users)
          setDidFetchKnowledgeBaseUsersForWorkspaceFail(false)
        } catch (e) {
          console.error('Error fetching sharing workspace users', e)
          setDidFetchKnowledgeBaseUsersForWorkspaceFail(true)
        } finally {
          setIsFetchingKnowledgeBaseUsersForWorkspace(false)
        }
      }

      // 3. Fetch the project if it exists
      // This is an optimization for vault
      if (potentialProjectId) {
        await fetchProject(potentialProjectId)
      }

      // Step 4: Fetch the remaining projects
      let remainingProjects: VaultFolder[] = []
      try {
        const vaultProjectsResponse = await FetchVaultProjects({
          includeSharedFolders: isSharingEnabled,
          includeSharedWithWorkspaceFolders: isSharingEnabled,
          includeKnowledgeBaseFolders: userInfo.IsKnowledgeBaseProjectUser,
        })
        const vaultProjects = vaultProjectsResponse.folders
        for (const project of vaultProjects) {
          upsertVaultFolders([project], userInfo.dbId, false, project.id)
          if (project.shareStatus) {
            setPermissionsForProjectId({
              projectId: project.id,
              userId: userInfo.dbId,
              workspaceId: userInfo.workspace.id,
              permissions: project.shareStatus,
            })
          }
        }
        remainingProjects = vaultProjects
      } catch (e) {
        console.error('Error fetching vault projects', e)
      }
      setHasLoadedProjects(true)

      // Step 5: Fetch the example projects
      let exampleProjects: VaultFolder[] = []
      try {
        const exampleProjectsResponse = await FetchVaultExampleProjects()
        exampleProjects = exampleProjectsResponse.folders ?? []
        if (exampleProjects.length > 0) {
          setExampleProjectIds(
            new Set(exampleProjects.map((project) => project.id))
          )
          // Do not upsert the example projects here. We will do that after
          // we have fetched the remaining projects.
        }
        // Upsert the example projects after we have fetched the remaining projects
        // This is to ensure on the UI render, we don't show the example projects first
        // and then add the remaining projects.
        for (const project of exampleProjects) {
          upsertVaultFolders([project], userInfo.dbId, true, project.id)
        }
      } catch (e) {
        console.error('Error fetching example project', e)
      }
      setAreExampleProjectsLoaded(true)

      // Step 6: Fetch and save the project metadata
      // TODO(stella): try and not do this separately
      await fetchAndSaveProjectMetadata(
        remainingProjects.filter((p) => p.id !== potentialProjectId),
        false
      )
      await fetchAndSaveProjectMetadata(exampleProjects, true)
      setHasLoadedProjectsMetadata(true)
      setIsFetchingProjects(false)
    },
    [
      userInfo.IsVaultUser,
      userInfo.dbId,
      userInfo.workspace.id,
      userInfo.IsVaultCreateSharesUser,
      userInfo.IsCreateKnowledgeBaseProjectUser,
      userInfo.IsKnowledgeBaseProjectUser,
      isSharingEnabled,
      fetchProject,
      fetchAndSaveProjectMetadata,
      setExampleProjectIds,
      setAreExampleProjectsLoaded,
      setHasLoadedProjectsMetadata,
      upsertVaultFolders,
      setDocumentClassificationTags,
      setHasLoadedProjects,
      setIsFetchingProjects,
      setPermissionsForProjectId,
      setSharingUsersForWorkspace,
      setDidFetchSharingUsersForWorkspaceFail,
      setKnowledgeBaseUsersForWorkspace,
      setDidFetchKnowledgeBaseUsersForWorkspaceFail,
      setIsFetchingKnowledgeBaseUsersForWorkspace,
    ]
  )

  return { fetchProject, fetchProjects }
}

export const useVaultProjects = ({
  includeExampleProjects = true,
}: {
  includeExampleProjects?: boolean
} = {}) => {
  const userInfo = useAuthUser()

  const [
    isFetchingProjects,
    hasLoadedProjects,
    hasLoadedProjectsMetadata,
    exampleProjectIds,
    allFolderIdToVaultFolder,
    rootVaultFolderIds,
    sharedProjectIds,
  ] = useVaultStore(
    useShallow((s) => [
      s.isFetchingProjects,
      s.hasLoadedProjects,
      s.hasLoadedProjectsMetadata,
      s.exampleProjectIds,
      s.allFolderIdToVaultFolder,
      s.rootVaultFolderIds,
      s.sharedProjectIds,
    ])
  )

  const allVaultProjects = useMemo(() => {
    return getVaultProjects({
      allFolderIdToVaultFolder,
      rootVaultFolderIds,
      userId: userInfo.dbId,
      exampleProjectIds: includeExampleProjects ? exampleProjectIds : undefined,
      sharedProjectIds,
      isKnowledgeBaseProjectUser: userInfo.IsKnowledgeBaseProjectUser,
    })
  }, [
    allFolderIdToVaultFolder,
    rootVaultFolderIds,
    sharedProjectIds,
    exampleProjectIds,
    includeExampleProjects,
    userInfo.dbId,
    userInfo.IsKnowledgeBaseProjectUser,
  ])

  return {
    allVaultProjects,
    isFetchingProjects,
    hasLoadedProjects,
    hasLoadedProjectsMetadata,
  }
}
