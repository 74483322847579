/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileUploadSource } from './FileUploadSource';
import {
    FileUploadSourceFromJSON,
    FileUploadSourceFromJSONTyped,
    FileUploadSourceToJSON,
    FileUploadSourceToJSONTyped,
} from './FileUploadSource';

/**
 * Result object after file is uploaded which includes
 * @export
 * @interface UploadedFile
 */
export interface UploadedFile {
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    pdfkitId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UploadedFile
     */
    size?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UploadedFile
     */
    isRedlinesDocument?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UploadedFile
     */
    isNotRedlinesDocument?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UploadedFile
     */
    languageNotSupported?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    docAsPdfPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    docAsPdfUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    docAsStructuredJsonPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    docAsStructuredUrl?: string | null;
    /**
     * 
     * @type {FileUploadSource}
     * @memberof UploadedFile
     */
    source?: FileUploadSource;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFile
     */
    fullText?: string | null;
}



/**
 * Check if a given object implements the UploadedFile interface.
 */
export function instanceOfUploadedFile(value: object): value is UploadedFile {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('path' in value) || value['path'] === undefined) return false;
    return true;
}

export function UploadedFileFromJSON(json: any): UploadedFile {
    return UploadedFileFromJSONTyped(json, false);
}

export function UploadedFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadedFile {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'path': json['path'],
        'url': json['url'] == null ? undefined : json['url'],
        'pdfkitId': json['pdfkit_id'] == null ? undefined : json['pdfkit_id'],
        'size': json['size'] == null ? undefined : json['size'],
        'isRedlinesDocument': json['is_redlines_document'] == null ? undefined : json['is_redlines_document'],
        'isNotRedlinesDocument': json['is_not_redlines_document'] == null ? undefined : json['is_not_redlines_document'],
        'languageNotSupported': json['language_not_supported'] == null ? undefined : json['language_not_supported'],
        'docAsPdfPath': json['doc_as_pdf_path'] == null ? undefined : json['doc_as_pdf_path'],
        'docAsPdfUrl': json['doc_as_pdf_url'] == null ? undefined : json['doc_as_pdf_url'],
        'docAsStructuredJsonPath': json['doc_as_structured_json_path'] == null ? undefined : json['doc_as_structured_json_path'],
        'docAsStructuredUrl': json['doc_as_structured_url'] == null ? undefined : json['doc_as_structured_url'],
        'source': json['source'] == null ? undefined : FileUploadSourceFromJSON(json['source']),
        'contentType': json['content_type'] == null ? undefined : json['content_type'],
        'fullText': json['full_text'] == null ? undefined : json['full_text'],
    };
}

export function UploadedFileToJSON(json: any): UploadedFile {
    return UploadedFileToJSONTyped(json, false);
}

export function UploadedFileToJSONTyped(value?: UploadedFile | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'path': value['path'],
        'url': value['url'],
        'pdfkit_id': value['pdfkitId'],
        'size': value['size'],
        'is_redlines_document': value['isRedlinesDocument'],
        'is_not_redlines_document': value['isNotRedlinesDocument'],
        'language_not_supported': value['languageNotSupported'],
        'doc_as_pdf_path': value['docAsPdfPath'],
        'doc_as_pdf_url': value['docAsPdfUrl'],
        'doc_as_structured_json_path': value['docAsStructuredJsonPath'],
        'doc_as_structured_url': value['docAsStructuredUrl'],
        'source': FileUploadSourceToJSON(value['source']),
        'content_type': value['contentType'],
        'full_text': value['fullText'],
    };
}

