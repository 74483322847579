import { getFileMetadata, handlePotentialRedirect } from 'error/handler'
import { captureError } from 'error/handler'
import ky from 'ky'

import Services from 'services'

import { ToBackendKeys, ToFrontendKeys } from 'utils/utils'

const MAX_RETRY_COUNT = 3

export default class EvalData {
  private restURL: string
  getToken: any
  logout: () => void

  constructor(restURL?: string) {
    this.restURL = restURL ?? 'http://localhost:5000'
    this.logout = () => {}
  }

  attachGetToken(getToken: any): void {
    this.getToken = getToken
  }

  attachLogout(logout: () => void): void {
    this.logout = logout
  }

  private async Client(maxRetryCount: number = MAX_RETRY_COUNT) {
    let retryCount = 0
    const client = ky.create({
      prefixUrl: this.restURL,
      retry: {
        limit: maxRetryCount,
        methods: ['get', 'post', 'put', 'delete', 'patch'],
        statusCodes: [408, 413, 429, 500, 502, 503, 504],
        afterStatusCodes: [413, 429, 503],
      },
      hooks: {
        beforeRetry: [
          async () => {
            retryCount++
          },
        ],
      },
      headers: {
        Authorization: this.getToken
          ? `Bearer ${await this.getToken()}`
          : undefined,
      },
    })

    return {
      client,
      getRetryCount: () => retryCount,
    }
  }

  async Get<T>(path: string, options?: ClientOptions): Promise<T> {
    const { maxRetryCount = MAX_RETRY_COUNT, throwOnError } = options ?? {}
    path = path.startsWith('/') ? path.slice(1) : path
    path = `api/v1/${path}`
    const telEvent = Services.HoneyComb.Start({
      metric: 'frontend.request.get',
      host: this.restURL,
      path,
      method: 'GET',
      endpoint_name: path,
    })

    const { client, getRetryCount } = await this.Client(maxRetryCount)
    return client
      .get(path)
      .json()
      .then((r: any) => ToFrontendKeys(r.data))
      .catch(async (e) => {
        await handlePotentialRedirect(e)
        await captureError({ e, telEvent, throwOnError, logout: this.logout })
        return {} as unknown as T
      })
      .finally(() => {
        telEvent.Finish({ retry_count: getRetryCount() })
      })
  }

  async Post<T>(
    path: string,
    body: FormData | Record<string, any>,
    options?: ClientOptions
  ): Promise<T> {
    const { maxRetryCount = MAX_RETRY_COUNT, throwOnError } = options ?? {}

    path = path.startsWith('/') ? path.slice(1) : path
    path = `api/v1/${path}`

    const telEvent = Services.HoneyComb.Start({
      metric: 'frontend.request.post',
      host: this.restURL,
      path,
      method: 'POST',
      endpoint_name: path,
    })

    const { client, getRetryCount } = await this.Client(maxRetryCount)
    return client
      .post(path, {
        ...(body instanceof FormData
          ? { body }
          : { json: ToBackendKeys(body) }),
      })
      .json()
      .then((r: any) => ToFrontendKeys(r.data))
      .catch(async (e) => {
        await handlePotentialRedirect(e)

        let err = {}
        try {
          err = {
            message: e instanceof Error ? e.message : e,
            is_online: navigator.onLine,
          }
          if (body instanceof FormData) {
            Array.from(body.keys()).forEach((key) => {
              const value = body.get(key)
              err = {
                ...err,
                ...getFileMetadata(value),
              }
            })
          }
        } finally {
          await captureError({
            e,
            telEvent,
            throwOnError,
            additionalErrorFields: err,
            logout: this.logout,
          })
        }
        return {} as unknown as T
      })
      .finally(() => {
        telEvent.Finish({ retry_count: getRetryCount() })
      })
  }
}

export interface ClientOptions {
  maxRetryCount?: number
  throwOnError?: boolean
}
