/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying user permissions.
 * @export
 */
export const Permission = {
    ADMIN_ORGANIZATION: 'admin:organization',
    ADMIN_TERRITORY: 'admin:territory',
    AUS_BREACH_REPORTING: 'aus_breach_reporting',
    CLIENT_ADMIN_ADD_USERS: 'client_admin:add_users',
    CLIENT_ADMIN_API_TOKEN_MGMT: 'client_admin:api_token_mgmt',
    CLIENT_ADMIN_INTEGRATIONS: 'client_admin:integrations',
    CLIENT_ADMIN_MANAGE_ASSISTANT_WORKFLOWS: 'client_admin:manage_assistant_workflows',
    CLIENT_ADMIN_MANAGE_SHARING: 'client_admin:manage_sharing',
    CLIENT_ADMIN_PLAYBOOKS: 'client_admin:playbooks',
    CLIENT_ADMIN_REMOVE_USERS: 'client_admin:remove_users',
    CLIENT_ADMIN_SELF_SERVE_PERMS_READONLY: 'client_admin:self_serve_perms_readonly',
    CLIENT_ADMIN_SELF_SERVE_PERMS_WRITE: 'client_admin:self_serve_perms_write',
    CLIENT_ADMIN_VAULT_ADD_USERS: 'client_admin:vault_add_users',
    CLIENT_ADMIN_VAULT_REMOVE_USERS: 'client_admin:vault_remove_users',
    CLIENT_ADMIN_VIEW_USERS: 'client_admin:view_users',
    CREATE_ADVANCED_DOC_QA: 'create:advanced_doc_qa',
    CREATE_ASSISTANT_DRAFT: 'create:assistant_draft',
    CREATE_ASSISTANT_V2_SURVEY_FEEDBACK: 'create:assistant_v2_survey_feedback',
    CREATE_ASYNC_FIELD_EXTRACTION_API: 'create:async_field_extraction_api',
    CREATE_AUSTRALIA_TAX_QA: 'create:australia_tax_qa',
    CREATE_CALL_SUMMARIZATION: 'create:call_summarization',
    CREATE_COMPANY_PROFILE: 'create:company_profile',
    CREATE_COMPLETION: 'create:completion',
    CREATE_CONTRACTS: 'create:contracts',
    CREATE_CORPUS_QA: 'create:corpus_qa',
    CREATE_CUATRECASAS: 'create:cuatrecasas',
    CREATE_DILIGENCE_TRANSCRIPTS: 'create:diligence_transcripts',
    CREATE_DILIGENCE_UK_TAX: 'create:diligence_uk_tax',
    CREATE_DOCUMENT_QA: 'create:document_qa',
    CREATE_DRAFTING: 'create:drafting',
    CREATE_EDGAR_ALL_FILINGS: 'create:edgar_all_filings',
    CREATE_EURLEX_QA: 'create:eurlex_qa',
    CREATE_EVENT_SHARES: 'create:event_shares',
    CREATE_FEEDBACK: 'create:feedback',
    CREATE_FRANCE_CASE_LAW: 'create:france_case_law',
    CREATE_FROM_COUNSEL: 'create:from_counsel',
    CREATE_HARVEY_LIBRARY_ITEMS: 'create:harvey_library_items',
    CREATE_HMRC_QA: 'create:hmrc_qa',
    CREATE_HONG_KONG_TAX_QA: 'create:hong_kong_tax_qa',
    CREATE_ICERTIS_FIELD_EXTRACTION_API: 'create:icertis_field_extraction_api',
    CREATE_ICERTIS_REVIEW_API: 'create:icertis_review_api',
    CREATE_INDIA_TAX_QA: 'create:india_tax_qa',
    CREATE_INTERNET_BROWSING: 'create:internet_browsing',
    CREATE_IRELAND_TAX_QA: 'create:ireland_tax_qa',
    CREATE_ITALY_TAX_QA: 'create:italy_tax_qa',
    CREATE_JAPAN_TAX_QA: 'create:japan_tax_qa',
    CREATE_KNOWLEDGE_BASE_PROJECT: 'create:knowledge_base_project',
    CREATE_KOREA_TAX_QA: 'create:korea_tax_qa',
    CREATE_KSA_TAX_QA: 'create:ksa_tax_qa',
    CREATE_MEMOS_QA: 'create:memos_qa',
    CREATE_MEXICO_TAX_QA: 'create:mexico_tax_qa',
    CREATE_MULTI_DOC_QA: 'create:multi_doc_qa',
    CREATE_NETHERLANDS_TAX_QA: 'create:netherlands_tax_qa',
    CREATE_OGC_REVIEW: 'create:ogc_review',
    CREATE_PILLAR_TWO_TAX_QA: 'create:pillar_two_tax_qa',
    CREATE_PLAYBOOK_REVIEW: 'create:playbook_review',
    CREATE_PRIVATE_LIBRARY_PROMPTS: 'create:private_library_prompts',
    CREATE_PWC_HR_WORKFORCE_API: 'create:pwc_hr_workforce_api',
    CREATE_PWC_TAX_FEEDBACK_EXPORT: 'create:pwc_tax_feedback_export',
    CREATE_REDLINES: 'create:redlines',
    CREATE_RESPONSE_COMPARISON: 'create:response_comparison',
    CREATE_SEC_EDGAR_QA: 'create:sec_edgar_qa',
    CREATE_SWEDEN_TAX_QA: 'create:sweden_tax_qa',
    CREATE_SWITZERLAND_TAX_QA: 'create:switzerland_tax_qa',
    CREATE_TRANSLATION: 'create:translation',
    CREATE_TRANSLATION_API: 'create:translation_api',
    CREATE_UAE_TAX_QA: 'create:uae_tax_qa',
    CREATE_USER_LIBRARY_ITEMS: 'create:user_library_items',
    CREATE_USER_PROFILES: 'create:user_profiles',
    CREATE_USER_PROFILES_OPTIONAL: 'create:user_profiles_optional',
    CREATE_USER_PROFILES_OPTIONAL_STEPS: 'create:user_profiles_optional_steps',
    CREATE_VALS_API: 'create:vals_api',
    CREATE_VAULT: 'create:vault',
    CREATE_VAULT_API: 'create:vault_api',
    CREATE_VAULT_INTERNAL_ONLY: 'create:vault_internal_only',
    CREATE_VAULT_REVIEW_VERIFICATION: 'create:vault_review_verification',
    CREATE_VAULT_SANDBOX_PROJECT: 'create:vault_sandbox_project',
    CREATE_VAULT_SHARES: 'create:vault_shares',
    CREATE_VAULT_WORKFLOW_ADMIN: 'create:vault_workflow_admin',
    CREATE_VAULT_WORKFLOW_LOAN_PORTFOLIO_REVIEW: 'create:vault_workflow_loan_portfolio_review',
    CREATE_VAULT_WORKFLOW_REPS_WARRANTIES: 'create:vault_workflow_reps_warranties',
    CREATE_WORKSPACE_LIBRARY_ITEMS: 'create:workspace_library_items',
    CREATE_WWTS_QA: 'create:wwts_qa',
    DECIDE_RESPONSE_COMPARISON: 'decide:response_comparison',
    DELETE_USER_HISTORY: 'delete:user_history',
    DELETE_WORKSPACE_HISTORY: 'delete:workspace_history',
    DELETE_WORKSPACE_HISTORY_BULK: 'delete:workspace_history_bulk',
    DOCUMENT_COMPARISON: 'document_comparison',
    LEGACY_OPEN_ENDED: 'legacy_open_ended',
    MANAGE_USERS: 'manage:users',
    MODIFY_WORKSPACE_PROJECTS: 'modify:workspace_projects',
    PWC_DEALS: 'pwc_deals',
    READ_ALL_SENSITIVE: 'read:all_sensitive',
    READ_ALL_WORKSPACES: 'read:all_workspaces',
    READ_CLIENT_MATTERS: 'read:client_matters',
    READ_ENG_INTERNAL: 'read:eng_internal',
    READ_EXAMPLES: 'read:examples',
    READ_HISTORY: 'read:history',
    READ_MESSAGE_LEVEL_CREATED_TIMESTAMP: 'read:message_level_created_timestamp',
    READ_SOME_SENSITIVE_DATA: 'read:some_sensitive_data',
    READ_STATUS_BAR: 'read:status_bar',
    READ_WORKSPACE_HISTORY: 'read:workspace_history',
    READ_WORKSPACE_VAULT: 'read:workspace_vault',
    TRANSCRIPTS: 'transcripts',
    USE_ASSISTANT_COMPOSER: 'use:assistant_composer',
    USE_ASSISTANT_DRAFT_O1: 'use:assistant_draft_o1',
    USE_ASSISTANT_LOCALIZATION: 'use:assistant_localization',
    USE_ASSISTANT_RESEARCH_KS: 'use:assistant_research_ks',
    USE_ASSISTANT_TAX_KS: 'use:assistant_tax_ks',
    USE_ASSISTANT_V2: 'use:assistant_v2',
    USE_BREDIN_PRAT_KS: 'use:bredin_prat_ks',
    USE_CLAUDE_ENABLED_FEATURES: 'use:claude_enabled_features',
    USE_CMD_K: 'use:cmd_k',
    USE_COMPETITIVE_ANALYSIS: 'use:competitive_analysis',
    USE_CUSTOM_SIDEBAR_GUIDANCE: 'use:custom_sidebar_guidance',
    USE_DEALS_DISCOVERY_ASYNC_IMPL: 'use:deals_discovery_async_impl',
    USE_DEALS_DISCOVERY_FROM_VAULT: 'use:deals_discovery_from_vault',
    USE_DIARIZED_TRANSCRIPTION: 'use:diarized_transcription',
    USE_DISALLOWED_CLIENT_MATTERS: 'use:disallowed_client_matters',
    USE_DISCOVER_TAB: 'use:discover_tab',
    USE_DMS_ONE_WAY_SYNC: 'use:dms_one_way_sync',
    USE_DOC_PROCESSING_RPC: 'use:doc_processing_rpc',
    USE_EVENT_SHARING: 'use:event_sharing',
    USE_GOOGLE_DRIVE_INTEGRATION: 'use:google_drive_integration',
    USE_HARVEY_FOR_COPILOT: 'use:harvey_for_copilot',
    USE_HARVEY_FOR_CUSTOM_ENGINE_COPILOT: 'use:harvey_for_custom_engine_copilot',
    USE_HARVEY_FOR_WORD: 'use:harvey_for_word',
    USE_IMANAGE_INTEGRATION: 'use:imanage_integration',
    USE_KNOWLEDGE_BASE_PROJECT: 'use:knowledge_base_project',
    USE_LANCE_ENTERPRISE_VAULT: 'use:lance_enterprise_vault',
    USE_LEFEBVRE_KS: 'use:lefebvre_ks',
    USE_LIBRARY_EXAMPLES: 'use:library_examples',
    USE_MACFARLANES_KS: 'use:macfarlanes_ks',
    USE_NETDOCS_EXTENSION: 'use:netdocs_extension',
    USE_OPEN_ENDED_BRIDGE_ROUTING: 'use:open_ended_bridge_routing',
    USE_OPEN_ENDED_BRIDGE_ROUTING_FULL: 'use:open_ended_bridge_routing_full',
    USE_PASSWORD_PROTECTED_DOCS: 'use:password_protected_docs',
    USE_PERMISSION_BUNDLES: 'use:permission_bundles',
    USE_SHAREPOINT_INTEGRATION: 'use:sharepoint_integration',
    USE_SINGAPORE_CASE_LAW: 'use:singapore_case_law',
    USE_SKIP_RATE_LIMIT: 'use:skip_rate_limit',
    USE_SWEDEN_CASE_LAW: 'use:sweden_case_law',
    USE_TRANSCRIBE_AUDIO_TO_TEXT: 'use:transcribe_audio_to_text',
    USE_UNIFIED_ASSISTANT: 'use:unified_assistant',
    USE_VAULT_FILE_ORGANIZATION_IMPROVEMENTS: 'use:vault_file_organization_improvements',
    USE_VAULT_PROJECT_CLIENT_MATTER: 'use:vault_project_client_matter',
    USE_VAULT_SHARING: 'use:vault_sharing',
    USE_VAULT_WORKFLOW_BROWSER: 'use:vault_workflow_browser',
    USE_WORKSPACE_PERSONALIZATION: 'use:workspace_personalization',
    VIEW_CAN_USER_REDIRECT: 'view:can_user_redirect',
    VIEW_DMS_BACKEND_FILE_DOWNLOAD_VAULT: 'view:dms_backend_file_download_vault',
    VIEW_EXPORT_TIMEZONE_SELECTION: 'view:export_timezone_selection',
    VIEW_GDRIVE_ANNOUNCEMENT: 'view:gdrive_announcement',
    VIEW_HELP_CENTER: 'view:help_center',
    VIEW_LAST_ACTIVITY_DATE: 'view:last_activity_date',
    VIEW_TOUR_CLIENT_MATTER: 'view:tour_client_matter',
    VIEW_USAGE_DASHBOARD: 'view:usage_dashboard',
    VIEW_USAGE_DASHBOARD_V2: 'view:usage_dashboard_v2',
    VIEW_USER_INSPECTOR: 'view:user_inspector',
    VIEW_VAULT_WORKSPACE_PROJECTS: 'view:vault_workspace_projects',
    VIEW_WORD_ADD_IN_ANNOUNCEMENT: 'view:word_add_in_announcement',
    VIEW_WORKSPACE_INFO: 'view:workspace_info',
    WRITE_ALL_WORKSPACES: 'write:all_workspaces',
    WRITE_CLIENT_MATTERS: 'write:client_matters',
    WRITE_ENG_INTERNAL: 'write:eng_internal',
    WRITE_EXAMPLES: 'write:examples'
} as const;
export type Permission = typeof Permission[keyof typeof Permission];


export function instanceOfPermission(value: any): boolean {
    for (const key in Permission) {
        if (Object.prototype.hasOwnProperty.call(Permission, key)) {
            if (Permission[key as keyof typeof Permission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    return json as Permission;
}

export function PermissionToJSON(value?: Permission | null): any {
    return value as any;
}

export function PermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): Permission {
    return value as Permission;
}

