import * as React from 'react'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { isFileViewableInAssistant } from 'types/file'

import { cn } from 'utils/utils'

import { ExtraSpaceFileItem } from 'components/assistant/components/assistant-files'
import { FileUploadingState } from 'components/assistant/stores/assistant-store'
import { Button } from 'components/ui/button'
import { ScrollArea } from 'components/ui/scroll-area'

interface FileListProps {
  isFileListOpen: boolean
  allFiles: UploadedFile[]
  allowsMultipleFiles: boolean
  componentDisabled: boolean
  handleRemoveFile: (file: UploadedFile | FileUploadingState) => void
  handleRemoveAll: () => void
  handleOpenFilePicker: () => void
  onFileClick: (file: UploadedFile) => void
}

const FileList: React.FC<FileListProps> = ({
  isFileListOpen,
  allFiles,
  allowsMultipleFiles,
  componentDisabled,
  handleRemoveFile,
  handleRemoveAll,
  handleOpenFilePicker,
  onFileClick,
}) => {
  return (
    <div
      className={cn(
        'rounded-md bg-primary ',
        isFileListOpen ? 'visible' : 'hidden'
      )}
    >
      {allowsMultipleFiles && (
        <div className="flex justify-between px-4 pb-1 pt-3.5">
          <h4>Files</h4>
          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={handleOpenFilePicker}
              disabled={componentDisabled}
            >
              Attach more
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={handleRemoveAll}
              disabled={componentDisabled}
            >
              Remove all
            </Button>
          </div>
        </div>
      )}

      <ul className="h-full divide-y px-1.5 py-2">
        <ScrollArea
          maxHeight={cn('max-h-[244.75px]', allFiles.length > 5 && 'pr-2')}
        >
          {allFiles.map((file, i) => (
            <ExtraSpaceFileItem
              key={i}
              file={file}
              hasBorder={i !== allFiles.length - 1}
              handleRemoveFile={handleRemoveFile}
              isRemoveDisabled={componentDisabled}
              handleFileClick={
                isFileViewableInAssistant(file.contentType ?? undefined)
                  ? onFileClick
                  : undefined
              }
            />
          ))}
        </ScrollArea>
      </ul>
    </div>
  )
}

export default FileList
