import React, { useCallback, useEffect, useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EventStatus } from 'openapi/models/EventStatus'
import { WorkflowBlockTypes } from 'openapi/models/WorkflowBlockTypes'
import { WorkflowDefinition } from 'openapi/models/WorkflowDefinition'
import { WorkflowEventStatusStepsInner } from 'openapi/models/WorkflowEventStatusStepsInner'
import { Source } from 'types/task'

import { getDocxContentType, WordSection, WordSectionType } from 'utils/docx'
import { exportAsFormattedWordDocx } from 'utils/export'
import { displaySuccessMessage, displayErrorMessage } from 'utils/toast'
import { downloadFileFromUrl } from 'utils/utils'

import {
  AssistantWorkflowStepNameToDefinition,
  BlockTypeMap,
  ExportComponentProps,
} from 'components/assistant/workflows'
import { useWorkflowAnalytics } from 'components/assistant/workflows/hooks/use-workflow-analytics'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import ExportDialog from 'components/common/export/export-dialog'
import {
  CITATION_OPTION_GROUP,
  ExportOptionValues,
} from 'components/common/export/types'

import { getWorkflowExportFileName, getSourcesFromStep } from './utils/utils'

type HandleWorkflowExportProps = {
  refs: HTMLDivElement[]
  completedSteps: WorkflowEventStatusStepsInner[]
  workflowDefinition: WorkflowDefinition | null
  stepId?: string | null
  includeAnnotation?: boolean
}

const getTranslatedDocIfExists = (
  completedSteps: WorkflowEventStatusStepsInner[]
): {
  url: string
  file_name: string
} | null => {
  for (const step of completedSteps) {
    if (typeof step.blockParams !== 'object') continue
    if (!('answer' in step.blockParams)) continue

    try {
      const answer = step.blockParams.answer as {
        metadata?: { translatedDocUrl?: string; translatedDocName?: string }
      }
      if (
        answer.metadata?.translatedDocUrl &&
        answer.metadata.translatedDocName
      ) {
        return {
          url: answer.metadata.translatedDocUrl,
          file_name: answer.metadata.translatedDocName,
        }
      }
    } catch (error) {
      continue
    }
  }
  return null
}

export const handleWorkflowExport = async ({
  refs,
  completedSteps,
  workflowDefinition,
  stepId,
  includeAnnotation = false,
}: HandleWorkflowExportProps): Promise<boolean> => {
  if (refs.length === 0) {
    return false
  }

  // Special case for translation workflow: if a translatedDocUrl is present in metadata, download the document.
  if (workflowDefinition?.slug === 'translate-into-another-language') {
    try {
      const translatedDocInfo = getTranslatedDocIfExists(completedSteps)

      if (translatedDocInfo) {
        // Use the download function from utils/utils.ts
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await downloadFileFromUrl(
          translatedDocInfo.url,
          translatedDocInfo.file_name
        )
        return true
      }
    } catch (error) {
      console.error('Error downloading translated document:', error)
      displayErrorMessage('Failed to download translated document')
      return false
    }
  }

  const sections: WordSection[] = []

  // An array of the block types in the order of the steps, which matches the order of the refs
  const stepBlockTypes = completedSteps
    .map((step) => step.blockId)
    .map(
      (id) =>
        workflowDefinition?.steps.find((step) => step.id === id)?.blockType
    )

  let shouldExportRef: boolean[] = refs.map(() => true)

  let stepIndex: number | undefined
  if (stepId) {
    shouldExportRef = refs.map(() => false)

    stepIndex = completedSteps.findIndex((step) => step.stepId === stepId)
    if (!stepIndex || stepIndex === -1) {
      return false
    }

    shouldExportRef[stepIndex] = true

    // If the exported step is a chat response, include the query input
    if (
      (stepIndex !== 0 &&
        stepBlockTypes[stepIndex] === WorkflowBlockTypes.ANSWER &&
        stepBlockTypes[stepIndex - 1] === WorkflowBlockTypes.FOLLOW_UPS) ||
      (workflowDefinition?.name === 'Chat' &&
        stepBlockTypes[stepIndex] === WorkflowBlockTypes.ANSWER &&
        stepBlockTypes[stepIndex - 1] === WorkflowBlockTypes.TEXT)
    ) {
      shouldExportRef[stepIndex - 1] = true
    }
  }

  let previousHeaderContent: string | null = null
  let answerContent: string = ''
  refs.forEach((ref, index) => {
    if (!shouldExportRef[index]) {
      return
    }

    let cleanedContent = ref.innerHTML
    let previousContent
    do {
      previousContent = cleanedContent
      cleanedContent = cleanedContent.replace(
        /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi,
        ''
      )
      if (!includeAnnotation) {
        cleanedContent = cleanedContent.replace(
          /<span\b[^<]*(?:(?!<\/span>)<[^<]*)*<\/span>/gi,
          ''
        )
      }
    } while (cleanedContent !== previousContent)

    // Chat-related blocks have "Query" or "Response" headers,
    // while other blocks have "Input" or "Harvey" headers
    let headerContent = null
    switch (stepBlockTypes[index]) {
      case WorkflowBlockTypes.ANSWER:
        answerContent = ref.innerText.substring(0, 15)
        if (
          workflowDefinition?.name === 'Chat' ||
          (index > 0 &&
            stepBlockTypes[index - 1] === WorkflowBlockTypes.FOLLOW_UPS)
        ) {
          headerContent = `## Response\n\n`
        } else {
          headerContent = `## Harvey\n\n`
        }
        break
      case WorkflowBlockTypes.ANTITRUST_FILINGS_RENDER:
      case WorkflowBlockTypes.DRAFT:
      case WorkflowBlockTypes.FILES:
      case WorkflowBlockTypes.TABLE:
        headerContent = `## Harvey\n\n`
        break
      case WorkflowBlockTypes.FILE_UPLOAD:
      case WorkflowBlockTypes.SELECT:
      case WorkflowBlockTypes.TABLE_SELECT:
        headerContent = `## Input\n\n`
        break
      case WorkflowBlockTypes.FOLLOW_UPS:
        headerContent = `## Query\n\n`
        break
      case WorkflowBlockTypes.TEXT:
        if (workflowDefinition?.name === 'Chat') {
          headerContent = `## Query\n\n`
        } else {
          headerContent = `## Input\n\n`
        }
        break
      default:
        break
    }

    // Only add the header if it's different from the previous header
    // (i.e. put all input blocks under a single "Input" header)
    if (headerContent && headerContent !== previousHeaderContent) {
      if (index > 0) {
        sections.push({ content: `<br/>`, type: WordSectionType.HTML })
      }
      sections.push({
        content: headerContent,
        type: WordSectionType.MARKDOWN,
      })
    }
    previousHeaderContent = headerContent

    sections.push({
      content: cleanedContent,
      type: getDocxContentType(ref.innerHTML),
    })

    if (includeAnnotation) {
      const step = completedSteps[index]
      const sources = getSourcesFromStep(step, workflowDefinition)
      if (sources.length > 0) {
        sections.push({
          content: sources as Source[],
          type: WordSectionType.SOURCES,
        })
      }
    }
  })

  let sources: Source[] = []
  if (stepId && stepIndex) {
    const step = completedSteps[stepIndex]
    sources = getSourcesFromStep(step, workflowDefinition)
  } else {
    sources = completedSteps
      .map((step) => getSourcesFromStep(step, workflowDefinition))
      .flat()
  }

  const documentNames = Array.from(
    new Set(sources.map((source) => source.documentName || ''))
  )
  const filesSections =
    documentNames.length > 0
      ? ([
          {
            content: `## Files\n\n${documentNames
              .map((name) => `- ${name}`)
              .join('\n')}`,
            type: 'markdown',
          },
          { content: `<br/>`, type: 'html' },
        ] as WordSection[])
      : []
  sections.push(
    { content: `<br/>`, type: WordSectionType.HTML },
    ...filesSections
  )

  const fileName = getWorkflowExportFileName(
    workflowDefinition,
    documentNames,
    answerContent
  )

  try {
    await exportAsFormattedWordDocx({
      title: fileName,
      sections,
      includeAnnotation,
    })
    displaySuccessMessage('Exported successfully')
    return true
  } catch {
    displayErrorMessage('Sorry, something went wrong')
    return false
  }
}

export const WorkflowExportContainer: React.FC<{
  exportComponents: React.MutableRefObject<HTMLDivElement[]>
}> = ({ exportComponents }) => {
  const [currentEvent, workflowDefinition] = useAssistantWorkflowStore(
    useShallow((state) => [state.currentEvent, state.workflowDefinition])
  )
  const trackEvent = useWorkflowAnalytics()

  useEffect(() => {
    exportComponents.current = []
  }, [currentEvent?.eventId, exportComponents])

  const isExportDisabled = useMemo(() => {
    if (!currentEvent?.steps || currentEvent.steps.length === 0) {
      return true
    }

    return !['COMPLETED', 'ERRORED'].includes(
      currentEvent.steps[currentEvent.steps.length - 1].paramStatus
    )
  }, [currentEvent?.steps])

  const completedSteps = useMemo(
    () =>
      currentEvent?.steps.filter(
        (step) =>
          step.paramStatus === EventStatus.COMPLETED &&
          step.completionStatus === EventStatus.COMPLETED
      ) || [],
    [currentEvent]
  )

  const handleExport = useCallback(
    async (values: ExportOptionValues) => {
      const includeAnnotation = !!values[CITATION_OPTION_GROUP.id]

      const success = await handleWorkflowExport({
        refs: exportComponents.current,
        completedSteps,
        workflowDefinition,
        includeAnnotation,
      })

      if (success) {
        trackEvent('Workflow Exported', {
          workflow_name: workflowDefinition?.name,
          format: 'word',
          include_annotation: includeAnnotation,
        })
      }
    },
    // XXX: Doing completedSteps.length as a dependency rather than completedSteps
    // This is because completedSteps is a new array every time, so it would trigger
    // a re-render every time, which is not necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [completedSteps.length, workflowDefinition?.name, workflowDefinition?.steps]
  )

  const hasSources = useMemo(
    () =>
      completedSteps.some(
        (step) => getSourcesFromStep(step, workflowDefinition).length > 0
      ),
    [completedSteps, workflowDefinition]
  )

  // Slight HACK: checking if the workflow is a translation workflow to govern title and export behavior
  const shouldExportTranslatedDoc =
    workflowDefinition?.slug === 'translate-into-another-language'
  return useMemo(
    () => (
      <>
        <ExportDialog
          title={
            shouldExportTranslatedDoc ? 'Export Translated Document' : undefined
          }
          hasSources={hasSources && !shouldExportTranslatedDoc}
          onExport={(values) => handleExport(values)}
          disabled={isExportDisabled}
        />
        <div className="hidden">
          {completedSteps.map((step, index) => {
            const ExportComponent = AssistantWorkflowStepNameToDefinition[
              workflowDefinition?.steps.find((s) => s.id === step.blockId)
                ?.blockType as keyof BlockTypeMap
            ]().ExportComponent as
              | React.FC<ExportComponentProps<keyof BlockTypeMap>>
              | undefined

            if (!ExportComponent) return null

            return (
              <div
                key={step.stepIdx}
                ref={(el: HTMLDivElement) => {
                  exportComponents.current[index] = el
                }}
              >
                <ExportComponent
                  stepIdx={index}
                  blockParams={step.blockParams as any}
                  outputData={step.outputData as any}
                />
              </div>
            )
          })}
        </div>
      </>
    ),
    // XXX: Doing completedSteps.length as a dependency rather than completedSteps
    // This is because completedSteps is a new array every time, so it would trigger
    // a re-render every time, which is not necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [completedSteps.length, handleExport]
  )
}
