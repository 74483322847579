import { UserInfo } from 'models/user-info'
import { WorkspaceGuidance } from 'openapi/models/WorkspaceGuidance'
import { WorkspaceNotice } from 'openapi/models/WorkspaceNotice'
import Services from 'services'

export const WORKSPACE_NOTICE_PUBLISHED_METRIC = 'ui.workspace_notice_published'
export const WORKSPACE_GUIDANCE_PUBLISHED_METRIC =
  'ui.workspace_guidance_published'
export const WORKSPACE_NOTICE_ACCEPTED_METRIC = 'ui.workspace_notice_accepted'
export const WORKSPACE_NOTICE_POPUP_METRIC = 'ui.workspace_notice_popup'

export const MAX_GUIDANCE_TITLE_LENGTH = 15
export const MAX_NOTICE_TITLE_LENGTH = 80

export const guidanceTitleTooltipHelperText = `This name will appear in the sidebar and guidance modal for all users as "[Firm Name] Guidance."`
export const guidanceTitlePlaceholder = 'Shown to all users in the sidebar'
export const guidanceMessagePlaceholder =
  'Contents of the guidance pop-up – supports HTML and can include firm-specific tips or guidance on using Harvey'

export const noticeTitlePlaceholder = 'Shown in the notice title bar'
export const noticeMessagePlaceholder =
  'Contents of the notice – supports HTML and can include updates, tips, or reminders'

export const trackWorkspaceNoticePublished = (
  userInfo: UserInfo,
  notice: WorkspaceNotice
) => {
  Services.HoneyComb.Record({
    metric: WORKSPACE_NOTICE_PUBLISHED_METRIC,
    workspace_id: userInfo.workspace.id,
    noticeTitle: notice.title,
    noticeDisplayFrequency: notice.displayFrequency,
    noticeShowDisallowedMatters: notice.showDisallowedMatters,
  })
}

export const trackWorkspaceGuidancePublished = (
  userInfo: UserInfo,
  guidance: WorkspaceGuidance
) => {
  Services.HoneyComb.Record({
    metric: WORKSPACE_GUIDANCE_PUBLISHED_METRIC,
    workspace_id: userInfo.workspace.id,
    guidanceTitle: guidance.title,
  })
}

export const trackWorkspaceNoticeAccepted = (
  userInfo: UserInfo,
  notice: WorkspaceNotice
) => {
  Services.HoneyComb.Record({
    metric: WORKSPACE_NOTICE_ACCEPTED_METRIC,
    workspace_id: userInfo.workspace.id,
    noticeTitle: notice.title,
    noticeId: notice.id,
  })
}
