import React, { useState, useMemo } from 'react'

import { capitalize } from 'lodash'

import { RequestForInformationTableRow, sortRfiRows } from './utils'

type SortField = 'country' | 'type' | 'none'

type RequestForInformationProps = {
  requestForInformationRows: RequestForInformationTableRow[]
}

export const RequestForInformationComponent: React.FC<
  RequestForInformationProps
> = ({ requestForInformationRows }) => {
  const [sortField, setSortField] = useState<SortField>('country')

  // Apply sorting based on the current sortField
  const sortedData = useMemo(
    () => sortRfiRows(requestForInformationRows, sortField),
    [requestForInformationRows, sortField]
  )

  // Group the data by party name for display
  const groupedByParty = useMemo(() => {
    const groups: Record<
      string,
      {
        partyName: string
        partyType: string
        requests: RequestForInformationTableRow[]
      }
    > = {}

    sortedData.forEach((row) => {
      if (!groups[row.partyName]) {
        groups[row.partyName] = {
          partyName: row.partyName,
          partyType: row.partyType,
          requests: [],
        }
      }
      groups[row.partyName].requests.push(row)
    })

    // Convert to array and sort so General party type always comes last
    return Object.values(groups).sort((a, b) => {
      if (a.partyType === 'General' && b.partyType !== 'General') return 1
      if (a.partyType !== 'General' && b.partyType === 'General') return -1
      return a.partyName.localeCompare(b.partyName)
    })
  }, [sortedData])

  // Render UI based on processed and grouped data
  return (
    <div className="mt-4 space-y-6">
      <div className="mb-4 flex justify-end">
        <div className="flex items-center">
          <label htmlFor="sort-select" className="mr-2 text-sm font-medium">
            Sort by:
          </label>
          <select
            id="sort-select"
            value={sortField}
            onChange={(e) => setSortField(e.target.value as SortField)}
            className="rounded-md border px-3 py-1.5 text-sm"
          >
            <option value="country">Country</option>
            <option value="type">Type</option>
          </select>
        </div>
      </div>

      {groupedByParty.map((party) => (
        <div key={party.partyName} className="mb-6">
          <h2 className="mb-3 text-xl font-semibold">
            {party.partyName}{' '}
            {party.partyType !== 'General'
              ? `(${capitalize(party.partyType)})`
              : ''}
          </h2>
          <div className="overflow-hidden rounded-md border">
            <table className="w-full border-collapse">
              <thead className="bg-gray-50">
                <tr>
                  <th className="text-gray-500 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">
                    Country
                  </th>
                  <th className="text-gray-500 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">
                    Threshold
                  </th>
                  <th className="text-gray-500 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">
                    Req. Index
                  </th>
                  <th className="text-gray-500 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">
                    Request Type
                  </th>
                  <th className="text-gray-500 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">
                    Request
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-gray-200 divide-y">
                {party.requests.map((item, idx) => (
                  <tr
                    key={`${party.partyName}-${idx}`}
                    className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="px-4 py-3 text-sm font-medium">
                      {item.countryName}
                    </td>
                    <td className="px-4 py-3 text-sm">{item.threshold}</td>
                    <td className="px-4 py-3 text-center text-sm">
                      {item.requirementIndex > 0 ? item.requirementIndex : '-'}
                    </td>
                    <td className="px-4 py-3 text-sm">{item.requestType}</td>
                    <td className="px-4 py-3 text-sm">{item.request}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  )
}
