import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'

const calculateNegativeMargin = (workflowHasSources: boolean) => {
  const windowWidth = document.querySelector('#thread')?.clientWidth || 0
  // 288 is the width of the sources sidebar
  const sourcesWidth = workflowHasSources ? 288 : 0
  // 16px for each side
  const sourcesWidthAndMargin = sourcesWidth + 32
  // 1000 is the width of the main thread content
  const eachSideRemainingWidth = (windowWidth - 1000) / 2
  const oneSideRemainingWidth = eachSideRemainingWidth - sourcesWidthAndMargin
  // 108 is an arbitrary number that works well for the design
  // can be adjusted if needed
  const maxNegativeMargin = 108
  const negativeMargin = Math.min(maxNegativeMargin, oneSideRemainingWidth)
  if (!windowWidth || windowWidth < 1300 || oneSideRemainingWidth < 0) {
    return 0
  }
  return negativeMargin
}

export const useCalcTableMargin = () => {
  const [workflowHasSources] = useAssistantWorkflowStore(
    useShallow((s) => [s.workflowHasSources])
  )
  const [negativeMargin, setNegativeMargin] = React.useState(0)
  const [searchParams] = useSearchParams()
  const isFileViewerOpen = searchParams.get(FILE_ID_PARAM) !== null

  React.useEffect(() => {
    const handleResize = () => {
      const negativeMargin = calculateNegativeMargin(workflowHasSources)
      setNegativeMargin(negativeMargin)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [workflowHasSources, isFileViewerOpen])

  return negativeMargin
}
