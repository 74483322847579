import React, { useCallback, useEffect, useRef, useState } from 'react'

import { isAfter, addDays, addWeeks, addMonths, parseISO } from 'date-fns'
import _ from 'lodash'

import { getDisallowedClientMatters } from 'models/client-matters'
import { acknowledgeCustomNotice } from 'models/user-info'
import { WelcomeScreenAction } from 'models/user-settings'
import { DisplayFrequency } from 'openapi/models/DisplayFrequency'
import Services from 'services'
import type TelemtryEvent from 'services/honey-comb/telemetry-event'
import { Maybe } from 'types'

import { ClientMatter } from 'components/client-matters/client-matters-store'
import { useAuthUser } from 'components/common/auth-context'
import {
  trackWorkspaceNoticeAccepted,
  WORKSPACE_NOTICE_POPUP_METRIC,
} from 'components/settings/workspace/workspace-info/utils'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import SearchInput from 'components/ui/search-input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'

import Markdown from './markdown/markdown'

const CUSTOM_NOTICE_STORAGE_KEY = 'custom-notice'

const getCustomStorageKey = (id: string) => `${CUSTOM_NOTICE_STORAGE_KEY}-${id}`

const CustomNoticeInterstitial = () => {
  const telEventRef = useRef<TelemtryEvent | null>(null)

  const userInfo = useAuthUser()
  const interstitial = userInfo.GetDisallowedClientMatterInterstitial()
  const [disallowedClientMatters, setDisallowedClientMatters] =
    useState<Maybe<ClientMatter[]>>(undefined)
  const [filteredDisallowedClientMatters, setFilteredDisallowedClientMatters] =
    useState<Maybe<ClientMatter[]>>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const customNotice = userInfo.workspace.customNotice
  const displayFrequency =
    customNotice?.displayFrequency || DisplayFrequency.REFRESH

  useEffect(() => {
    if (!interstitial) return
    telEventRef.current = Services.HoneyComb.Start({
      metric: WORKSPACE_NOTICE_POPUP_METRIC,
      welcome_screen_id: interstitial.id,
      welcome_screen_type: interstitial.type,
      task_type: interstitial.taskType,
    })
  }, [interstitial])

  useEffect(() => {
    if (!userInfo.isDisallowClientMattersUser) {
      return
    }
    const fetchDisallowedClientMatters = async () => {
      setIsLoading(true)
      try {
        const beginTime = Date.now()
        const disallowedClientMatters = await getDisallowedClientMatters(
          userInfo.workspace.id
        )
        setDisallowedClientMatters(disallowedClientMatters)
        setFilteredDisallowedClientMatters(disallowedClientMatters)
        const endTime = Date.now()
        Services.HoneyComb.Record({
          metric: 'ui.fetch_disallowed_client_matters',
          disallowed_client_matters: disallowedClientMatters,
          duration: (endTime - beginTime) * 1000,
          workspace_id: userInfo.workspace.id,
        })
      } catch (error) {
        console.error('Error fetching disallowed client matters', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchDisallowedClientMatters()
  }, [userInfo.isDisallowClientMattersUser, userInfo.workspace.id])

  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    // no custom notice exists but there is an interstitial, always show the dialog
    if (_.isNil(customNotice) && !_.isNil(interstitial)) {
      setShowDialog(true)
      return
    }

    // no custom notice exists
    if (_.isNil(customNotice)) {
      setShowDialog(false)
      return
    }

    // custom notice exists but is empty
    if (
      _.isEmpty(customNotice.title.trim()) ||
      _.isEmpty(customNotice.message.trim())
    ) {
      setShowDialog(false)
      return
    }

    const storageKey = getCustomStorageKey(customNotice.id || '')

    let lastAcceptedTime = localStorage.getItem(storageKey)

    // If not in localStorage, check if we have acceptance data from the backend in userInfo
    if (
      !lastAcceptedTime &&
      userInfo.acceptedWorkspaceNotice &&
      userInfo.acceptedWorkspaceNotice.noticeId === customNotice.id
    ) {
      lastAcceptedTime = userInfo.acceptedWorkspaceNotice.acceptedAt

      localStorage.setItem(storageKey, lastAcceptedTime)
    }

    if (!lastAcceptedTime) {
      setShowDialog(true)
      return
    }

    const lastAccepted = parseISO(lastAcceptedTime)
    const now = new Date()

    switch (displayFrequency) {
      case DisplayFrequency.ONCE:
        setShowDialog(false)
        break
      case DisplayFrequency.DAILY: {
        const nextShowDate = addDays(lastAccepted, 1)
        setShowDialog(isAfter(now, nextShowDate))
        break
      }
      case DisplayFrequency.WEEKLY: {
        const nextShowDate = addWeeks(lastAccepted, 1)
        setShowDialog(isAfter(now, nextShowDate))
        break
      }
      case DisplayFrequency.MONTHLY: {
        const nextShowDate = addMonths(lastAccepted, 1)
        setShowDialog(isAfter(now, nextShowDate))
        break
      }
      case DisplayFrequency.REFRESH:
        setShowDialog(true)
        break
      default:
        setShowDialog(true)
    }
  }, [
    customNotice,
    displayFrequency,
    userInfo.acceptedWorkspaceNotice,
    interstitial,
  ])

  const onOk = useCallback(async (): Promise<void> => {
    setShowDialog(false)

    telEventRef.current?.Finish({
      action: WelcomeScreenAction.ACCEPTED,
      type: interstitial?.type,
    })
    telEventRef.current = null

    const now = new Date().toISOString()

    // Only store in localStorage if there's a custom notice
    if (customNotice) {
      // also clear other custom notices
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(CUSTOM_NOTICE_STORAGE_KEY)) {
          localStorage.removeItem(key)
        }
      })

      const storageKey = getCustomStorageKey(customNotice.id || '')
      localStorage.setItem(storageKey, now)
      trackWorkspaceNoticeAccepted(userInfo, customNotice)
      try {
        await acknowledgeCustomNotice(
          customNotice?.id || interstitial?.id || '',
          now
        )
      } catch (error) {
        console.error(
          'Failed to record notice acceptance',
          error,
          customNotice?.id || interstitial?.id || '',
          userInfo.workspace.id
        )
      }
    }
  }, [customNotice, interstitial?.id, interstitial?.type, userInfo])

  const onSearchValueChange = (value: string) => {
    value = value.trim()
    setSearchValue(value)
    if (value === '') {
      setFilteredDisallowedClientMatters(disallowedClientMatters)
      return
    }
    value = value.toLowerCase()
    const filteredDisallowedClientMatters = disallowedClientMatters?.filter(
      (clientMatter) =>
        clientMatter.name.toLowerCase().includes(value) ||
        clientMatter.description?.toLowerCase().includes(value)
    )

    setFilteredDisallowedClientMatters(filteredDisallowedClientMatters)
  }

  const title = customNotice
    ? customNotice.title
    : interstitial?.title || 'Some client matters are not allowed'

  const content =
    (customNotice ? customNotice.message : interstitial?.content) || ''

  const shouldShowDisallowedClientMatters =
    disallowedClientMatters &&
    disallowedClientMatters.length > 0 &&
    (_.isNil(customNotice) || customNotice?.showDisallowedMatters) // legacy notice always shows disallowed client matters

  // If there is no custom notice and no interstitial, don't render anything
  if (!interstitial && !customNotice) return null

  // If there is no custom notice and the dialog is not open, don't render anything
  if (!customNotice && !showDialog) return null

  return (
    <Dialog open={showDialog}>
      <DialogContent showCloseIcon={false} className="max-w-screen-md">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <Markdown content={content} enableMailLinks />

        {shouldShowDisallowedClientMatters && (
          <div>
            <div className="mt-4">
              <div className="mb-2 flex flex-row items-center justify-between">
                <p className="font-semibold">
                  List of restricted client matters
                </p>
                <SearchInput
                  value={searchValue}
                  setValue={onSearchValueChange}
                  handleClear={() => {
                    onSearchValueChange('')
                  }}
                />
              </div>
              <div className="max-h-80 min-h-80 overflow-hidden overflow-y-auto rounded-lg border">
                <Table className="w-full ">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="px-4 py-2 text-left font-semibold">
                        Client Matter #
                      </TableHead>
                      <TableHead className="px-4 py-2 text-left font-semibold">
                        Description
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={2} className="py-4 text-center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    )}
                    {filteredDisallowedClientMatters?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={2} className="py-4 text-center">
                          No client matters found
                        </TableCell>
                      </TableRow>
                    ) : (
                      _.orderBy(
                        filteredDisallowedClientMatters,
                        [
                          (cm) => cm.description?.toLowerCase() || `z${cm.id}`,
                          'id',
                        ],
                        ['asc', 'asc']
                      )?.map((clientMatter, index) => (
                        <TableRow key={index}>
                          <TableCell className="border-t px-4 py-2">
                            {clientMatter.name}
                          </TableCell>
                          <TableCell className="border-t px-4 py-2">
                            {clientMatter.description}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        )}

        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={onOk} disabled={isLoading}>
              Accept
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default CustomNoticeInterstitial
