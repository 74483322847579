import { VaultFolderAccessPermission } from 'openapi/models/VaultFolderAccessPermission'
import { VaultFolderShareStatus } from 'openapi/models/VaultFolderShareStatus'
import { SHARE_ERROR_MESSAGE, SHARE_SUCCESS_MESSAGE } from 'types/sharing'

import { SafeRecord } from 'utils/safe-types'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import {
  ShareVaultFolder,
  UpdateVaultFolderShare,
  UpdateVaultFolderShareParams,
} from './vault-fetcher'

export const isProjectShared = (
  sharedProjectIds: Set<string>,
  permissionsByProjectId: SafeRecord<string, VaultFolderShareStatus>,
  projectId?: string
) => {
  if (!projectId) {
    return false
  }

  if (sharedProjectIds.has(projectId)) {
    return true
  }
  // If user is the owner of the project and has shared it with others,
  // also count as shared project
  const projectPermissions = permissionsByProjectId[projectId as string]
  if (
    (projectPermissions?.permissionsByUser &&
      projectPermissions.permissionsByUser.length > 0) ||
    (projectPermissions?.permissionsByWorkspace &&
      projectPermissions.permissionsByWorkspace.length > 0)
  ) {
    return true
  }
  return false
}

export const changeSharedWithWorkspace = async ({
  sharedWithWorkspace,
  projectId,
  isWorkspaceAlreadyShared,
  workspaceId,
  isKnowledgeBaseProject,
  userDbId,
  setIsSharing,
  appendPermissionsForProjectId,
  setPermissionsForProjectId,
}: {
  sharedWithWorkspace: boolean
  projectId: string
  isWorkspaceAlreadyShared: boolean
  workspaceId: number
  isKnowledgeBaseProject: boolean
  userDbId: string
  setIsSharing: (isSharing: boolean) => void
  appendPermissionsForProjectId: ({
    projectId,
    userId,
    workspaceId,
    permissions,
  }: {
    projectId: string
    userId: string
    workspaceId: number
    permissions: VaultFolderShareStatus
  }) => void
  setPermissionsForProjectId: ({
    projectId,
    userId,
    workspaceId,
    permissions,
  }: {
    projectId: string
    userId: string
    workspaceId: number
    permissions: VaultFolderShareStatus | null
  }) => void
}) => {
  // do nothing if share setting is not changing
  if (sharedWithWorkspace === isWorkspaceAlreadyShared) {
    return
  }

  if (sharedWithWorkspace && !isWorkspaceAlreadyShared) {
    // if not previously shared, share vault folder with workspace
    try {
      setIsSharing(true)
      const response = await ShareVaultFolder({
        projectId,
        shareWithWorkspaces: [
          {
            workspaceId,
            permissionLevel: VaultFolderAccessPermission.VIEW,
            alwaysShowOnHomepage: isKnowledgeBaseProject,
          },
        ],
      })
      appendPermissionsForProjectId({
        projectId,
        userId: userDbId,
        workspaceId,
        permissions: response.shareStatus,
      })
      const successMessage = isKnowledgeBaseProject
        ? 'Shared with everyone successfully'
        : SHARE_SUCCESS_MESSAGE
      displaySuccessMessage(successMessage)
    } catch (error) {
      displayErrorMessage(SHARE_ERROR_MESSAGE)
    }
    setIsSharing(false)
    return
  }

  const updateParams: UpdateVaultFolderShareParams = {
    projectId,
  }

  if (sharedWithWorkspace && isWorkspaceAlreadyShared) {
    updateParams.updateShareWithWorkspaces = [
      {
        workspaceId,
        permissionLevel: VaultFolderAccessPermission.VIEW,
        alwaysShowOnHomepage: isKnowledgeBaseProject,
      },
    ]
  } else {
    updateParams.removeShareWithWorkspaces = [workspaceId]
  }
  try {
    setIsSharing(true)
    const response = await UpdateVaultFolderShare(updateParams)
    setPermissionsForProjectId({
      projectId,
      userId: userDbId,
      workspaceId,
      permissions: response.shareStatus,
    })
    displaySuccessMessage(SHARE_SUCCESS_MESSAGE)
  } catch (error) {
    displayErrorMessage(SHARE_ERROR_MESSAGE)
  }
  setIsSharing(false)
}
