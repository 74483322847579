import React from 'react'

import { Copy, Edit, ListPlusIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { ActionIcon } from 'components/assistant/components/assistant-question'
import { WorkflowStatus } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { useAuthUser } from 'components/common/auth-context'
import { SavePromptButton } from 'components/library/save-prompt-button'
import Icon from 'components/ui/icon/icon'

interface QueryToolbarProps {
  query: string
  onEdit?: () => void
  toolbarActionIconSize?: 'sm' | 'default' | 'lg'
  className?: string
}

export const QueryToolbar: React.FC<QueryToolbarProps> = ({
  query,
  onEdit,
  toolbarActionIconSize = 'sm',
  className,
}) => {
  const getWorkflowStatus = useAssistantWorkflowStore(
    useShallow((state) => state.getWorkflowStatus)
  )

  const workflowStatus = getWorkflowStatus()

  const userInfo = useAuthUser()

  const handleCopy = () => {
    if (!query) return
    navigator.clipboard
      .writeText(query)
      .then(() => {
        displaySuccessMessage('Copied query to clipboard')
      })
      .catch(() => {
        displaySuccessMessage('Failed to copy query to clipboard')
      })
  }

  return (
    <div className={cn('mt-2 flex items-center justify-between', className)}>
      <div>
        <ActionIcon
          onClick={handleCopy}
          size={toolbarActionIconSize}
          tooltip="Copy query"
          data-testid="copy-button"
          aria-label="Copy query"
        >
          <Icon icon={Copy} />
        </ActionIcon>
        {userInfo.IsLibraryPrivatePromptUser && (
          <SavePromptButton
            query={query}
            triggerComponent={(disabled, buttonTooltipText, onMouseEnter) => (
              <ActionIcon
                disabled={disabled}
                onMouseEnter={onMouseEnter}
                size={toolbarActionIconSize}
                tooltip={buttonTooltipText}
                tooltipClassName={cn({
                  'cursor-default': disabled,
                })}
                data-testid="save-prompt-button"
              >
                <Icon icon={ListPlusIcon} />
              </ActionIcon>
            )}
            eventKind={EventKind.ASSISTANT}
          />
        )}
        {onEdit && (
          <ActionIcon
            onClick={onEdit}
            size={toolbarActionIconSize}
            tooltip="Edit query"
            data-testid="edit-button"
            aria-label="Edit query"
            disabled={workflowStatus === WorkflowStatus.IN_PROGRESS}
          >
            <Icon icon={Edit} />
          </ActionIcon>
        )}
      </div>
    </div>
  )
}
