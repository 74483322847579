import { VaultFolder } from 'openapi/models/VaultFolder'

import { CreateVaultFolder } from 'components/vault/utils/vault-fetcher'

export const DEFAULT_PROJECT_CARD_HEIGHT = 180
export const DEFAULT_PROJECT_CARD_HEIGHT_SMALL = 140

interface CreateProjectProps {
  folderName: string
  parentId: string | null
  clientMatterId: string | null
  isKnowledgeBaseProject: boolean
  userId: string
  upsertVaultFolders: (
    vaultFolders: VaultFolder[],
    userId: string,
    isKnowledgeBaseProject: boolean,
    hierarchyRootFolderId: string
  ) => void
}

const createProject = async ({
  folderName,
  parentId,
  clientMatterId,
  isKnowledgeBaseProject,
  upsertVaultFolders,
  userId,
}: CreateProjectProps) => {
  const hierarchyRootFolder = await CreateVaultFolder({
    folderName,
    parentId,
    clientMatterId,
    isKnowledgeBaseProject,
  })
  upsertVaultFolders(
    [hierarchyRootFolder],
    userId,
    isKnowledgeBaseProject,
    hierarchyRootFolder.id
  )
  return hierarchyRootFolder
}

export { createProject }
