import { NavigateFunction } from 'react-router-dom'

import { driver, DriveStep } from 'driver.js'
import { create } from 'zustand'

import { UserInfo } from 'models/user-info'
import { FileCache } from 'stores/file-cache'

import { waitForElement } from 'utils/utils'

import { AssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { TrackEventFunction } from 'components/common/analytics/analytics-context'

type ProductTourState = {
  isTourActive: boolean
  startTour: () => void
  endTour: () => void
}

export const useKnowledgeBaseTourStore = create<ProductTourState>((set) => ({
  isTourActive: false,
  startTour: () => set({ isTourActive: true }),
  endTour: () => set({ isTourActive: false }),
}))

export const runKnowledgeBaseTour = async (
  navigate: NavigateFunction,
  userInfo?: UserInfo,
  options?: {
    trackEvent?: TrackEventFunction
    workflowStore?: AssistantWorkflowStore
    fileCache?: FileCache
  }
) => {
  const startRoute = window.location.pathname

  if (startRoute !== '/vault/projects') {
    navigate(`/vault/projects`)
    await waitForElement('#vault-create-knowledge-base-project-card')
  }

  const steps: DriveStep[] = [
    {
      element: '#sidebar-item--Vault',
      popover: {
        title: 'Go to Vault',
        description: 'Knowledge bases can be created from the Vault home page.',
      },
    },
    {
      element: '#vault-create-knowledge-base-project-card',
      popover: {
        title: 'Create a knowledge base',
        description:
          'Upload files to your knowledge base and share with individuals or everyone in your organization.',
      },
    },
  ]

  const closeKnowledgeBaseTour = () => {
    useKnowledgeBaseTourStore.getState().endTour()
    driverObj.destroy()
    navigate(startRoute)
  }

  const handleComplete = () => {
    closeKnowledgeBaseTour()
    if (options?.trackEvent) {
      options.trackEvent('Knowledge Base Tour Completed')
    }
  }

  const handleClose = () => {
    closeKnowledgeBaseTour()
    if (options?.trackEvent) {
      options.trackEvent('Knowledge Base Tour Closed')
    }
  }

  const driverObj = driver({
    showProgress: true,
    steps,
    disableActiveInteraction: true,
    onCloseClick: handleClose,
    onDestroyStarted: async () => {
      if (!driverObj.hasNextStep()) {
        handleComplete()
      }
    },
    popoverClass: 'assistant-tour',
  })

  driverObj.drive()
  useKnowledgeBaseTourStore.getState().startTour()
}
