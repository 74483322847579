import { useCallback } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { createTableSelectStore } from 'components/assistant/workflows/stores/table-select-store'

export const useTableSelectActions = (
  tableSelectStore: ReturnType<typeof createTableSelectStore>
) => {
  const [
    tableData,
    setTableData,
    setTempTableData,
    updateTempTableDataRow,
    replaceTableDataWithTempTableData,
    replaceTempTableDataWithTableData,
  ] = tableSelectStore(
    useShallow((state) => [
      state.tableData,
      state.setTableData,
      state.setTempTableData,
      state.updateTempTableDataRow,
      state.replaceTableDataWithTempTableData,
      state.replaceTempTableDataWithTableData,
    ])
  )

  const handleRemoveRow = useCallback(
    (rowIdx: number) => {
      if (!tableData) {
        return
      }
      setTableData({
        ...tableData,
        rows: tableData.rows.filter((_, idx) => idx !== rowIdx),
      })
    },
    [setTableData, tableData]
  )

  const handleEditRow = useCallback(
    (rowIdx: number) => {
      if (!tableData) {
        return
      }
      setTableData({
        ...tableData,
        rows: tableData.rows.map((row, idx) => ({
          ...row,
          isEditing:
            rowIdx === idx
              ? !tableData.rows[rowIdx].isEditing
              : tableData.rows[idx].isEditing,
        })),
      })
    },
    [setTableData, tableData]
  )

  const handleRowChange = useCallback(
    (rowIdx: number, key: string, value: string) =>
      updateTempTableDataRow(rowIdx, key, value),
    [updateTempTableDataRow]
  )

  const handleSaveRow = useCallback(
    (rowIdx: number) => {
      if (!tableData) {
        return
      }
      replaceTableDataWithTempTableData(rowIdx)
    },
    [tableData, replaceTableDataWithTempTableData]
  )

  const handleCancelRow = useCallback(
    (rowIdx: number) => {
      if (!tableData) {
        return
      }
      handleEditRow(rowIdx)
      replaceTempTableDataWithTableData(rowIdx)
    },
    [tableData, handleEditRow, replaceTempTableDataWithTableData]
  )

  const handleAddRow = () => {
    if (!tableData) {
      return
    }
    const newRow = {
      isEditing: true,
      isSelected: true,
      data:
        tableData.rows.length > 0
          ? Object.keys(tableData.rows[0].data).reduce(
              (acc, key) => {
                acc[key] = ''
                return acc
              },
              {} as Record<string, string>
            )
          : tableData.orderedColumnNames?.reduce(
              (acc, column) => {
                acc[column?.name ?? ''] = ''
                return acc
              },
              {} as Record<string, string>
            ) ?? {},
    }

    // Create a new table with the added row
    const newTable = {
      ...tableData,
      rows: [...tableData.rows, newRow],
    }

    // Update both tableData and tempTableData
    setTableData(newTable)
    setTempTableData(newTable)
  }

  return {
    handleRemoveRow,
    handleEditRow,
    handleRowChange,
    handleSaveRow,
    handleCancelRow,
    handleAddRow,
  }
}
