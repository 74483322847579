import { NavigateFunction } from 'react-router-dom'

import { driver, DriveStep } from 'driver.js'
import { create } from 'zustand'

import { UserInfo } from 'models/user-info'

import { waitForElement } from 'utils/utils'

type ProductTourState = {
  isTourActive: boolean
  startTour: () => void
  endTour: () => void
}

const useSelfServePermissionsTourStore = create<ProductTourState>((set) => ({
  isTourActive: false,
  startTour: () => set({ isTourActive: true }),
  endTour: () => set({ isTourActive: false }),
}))

export const runSelfServePermissionsTour = async (
  navigate: NavigateFunction,
  userInfo: UserInfo | undefined
) => {
  if (!userInfo) {
    return
  }

  const goToPage = {
    users: async () => {
      navigate('/settings/users', { state: { initialPageSize: 10 } })
      await waitForElement('#workspace-users-table')
    },
    roles: async () => {
      navigate('/settings/roles')
      await waitForElement('#workspace-roles-table')
    },
  }

  const startRoute = window.location.pathname
  await goToPage.users()

  const canEditPermissions = Boolean(userInfo.IsClientAdminSelfServePermsWriter)

  const closeProductTour = () => {
    useSelfServePermissionsTourStore.getState().endTour()
    driverObj.destroy()
    navigate(startRoute)
  }

  const steps: DriveStep[] = [
    {
      // 1
      element: '#admin-settings-nav-item-Users',
      popover: {
        title: 'Users page',
        description:
          'We improved the users page. Now you can view each user’s roles and permissions.',
      },
    },
    {
      // 2
      element: '#workspace-users-table',
      popover: {
        title: 'Users table',
        description:
          // eslint-disable-next-line harvey/no-straight-quotes
          'All users and their permissions are here. You can <b style="font-size: inherit">filter</b> by role or permission to see who has access to specific permissions.',
        onNextClick: async () => {
          if (!canEditPermissions) {
            await goToPage.roles()
          }
          driverObj.moveNext()
        },
      },
    },
    ...(canEditPermissions
      ? [
          {
            // 2.5
            element: '#users-manage-permissions-button',
            popover: {
              title: 'Manage user permissions',
              description:
                // eslint-disable-next-line harvey/no-straight-quotes
                'To add or remove permissions, select the users, then click <b style="font-size: inherit">Manage permissions</b>. You can modify permissions for individual or multiple users at once.',
              onNextClick: async () => {
                await goToPage.roles()
                driverObj.moveNext()
              },
            },
          },
        ]
      : []),
    {
      // 3
      element: '#admin-settings-nav-item-Roles',
      popover: {
        title: 'Roles page',
        description:
          // eslint-disable-next-line harvey/no-straight-quotes
          'Every user belongs to a <b style="font-size: inherit">role</b>. You can view and manage permissions associated with each role on this page.',
        onPrevClick: async () => {
          await goToPage.users()
          driverObj.movePrevious()
        },
      },
    },
    {
      // 4
      element: '#workspace-roles-table',
      popover: {
        title: 'Roles table',
        description:
          // eslint-disable-next-line harvey/no-straight-quotes
          'Checked boxes means everyone part of the role <b style="font-size: inherit">has access</b> to the checked permission.',
        onNextClick: async () => {
          if (canEditPermissions) {
            // Otherwise, partially hidden by scroll
            scrollElementIntoView('#workspace-roles-table-controls')
          }
          driverObj.moveNext()
        },
      },
    },
    ...(canEditPermissions
      ? [
          {
            // 4.5
            element: '#workspace-roles-table-controls',
            popover: {
              title: 'Manage roles',
              description:
                // eslint-disable-next-line harvey/no-straight-quotes
                'Click <b style="font-size: inherit">Create role</b> to define a new role and set its permissions, or <b style="font-size: inherit">Edit table</b> to modify permissions within existing roles.',
            },
          },
        ]
      : []),
  ]

  const scrollElementIntoView = (selector: string) => {
    const element = document.querySelector(selector)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const driverObj = driver({
    showProgress: true,
    smoothScroll: true,
    steps,
    disableActiveInteraction: true,
    onCloseClick: closeProductTour,
    onDestroyStarted: async () => {
      if (!driverObj.hasNextStep()) {
        closeProductTour()
      }
    },
    popoverClass: 'assistant-tour',
    nextBtnText: 'Next',
    prevBtnText: 'Previous',
  })

  driverObj.drive()
  useSelfServePermissionsTourStore.getState().startTour()
}
