/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CustomScreen = {
    NOTICE: 'notice',
    GUIDANCE: 'guidance'
} as const;
export type CustomScreen = typeof CustomScreen[keyof typeof CustomScreen];


export function instanceOfCustomScreen(value: any): boolean {
    for (const key in CustomScreen) {
        if (Object.prototype.hasOwnProperty.call(CustomScreen, key)) {
            if (CustomScreen[key as keyof typeof CustomScreen] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CustomScreenFromJSON(json: any): CustomScreen {
    return CustomScreenFromJSONTyped(json, false);
}

export function CustomScreenFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomScreen {
    return json as CustomScreen;
}

export function CustomScreenToJSON(value?: CustomScreen | null): any {
    return value as any;
}

export function CustomScreenToJSONTyped(value: any, ignoreDiscriminator: boolean): CustomScreen {
    return value as CustomScreen;
}

