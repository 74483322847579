/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DisplayFrequency = {
    ONCE: 'Once',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    REFRESH: 'Refresh'
} as const;
export type DisplayFrequency = typeof DisplayFrequency[keyof typeof DisplayFrequency];


export function instanceOfDisplayFrequency(value: any): boolean {
    for (const key in DisplayFrequency) {
        if (Object.prototype.hasOwnProperty.call(DisplayFrequency, key)) {
            if (DisplayFrequency[key as keyof typeof DisplayFrequency] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DisplayFrequencyFromJSON(json: any): DisplayFrequency {
    return DisplayFrequencyFromJSONTyped(json, false);
}

export function DisplayFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayFrequency {
    return json as DisplayFrequency;
}

export function DisplayFrequencyToJSON(value?: DisplayFrequency | null): any {
    return value as any;
}

export function DisplayFrequencyToJSONTyped(value: any, ignoreDiscriminator: boolean): DisplayFrequency {
    return value as DisplayFrequency;
}

