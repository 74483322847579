export enum BaseAppPath {
  Assistant = '/assistant',
  Research = '/research',
  Workflows = '/workflows',
  History = '/history',
  Library = '/library',
  Vault = '/vault',
  Settings = '/settings',
  Compare = '/compare',
  Evaluations = '/evaluations',
  Login = '/login',
  Shared = '/shared',
}

export enum LibraryPath {
  Examples = `${BaseAppPath.Library}/examples`,
}

export enum SettingsPath {
  Workspace = `${BaseAppPath.Settings}/workspace`,
  ClientMatters = `${BaseAppPath.Settings}/client-matters`,
  Sharing = `${BaseAppPath.Settings}/sharing`,
  Usage = `${BaseAppPath.Settings}/usage`,
  Users = `${BaseAppPath.Settings}/users`,
  Roles = `${BaseAppPath.Settings}/roles`,
  Integrations = `${BaseAppPath.Settings}/integrations`,
  Playbooks = `${BaseAppPath.Settings}/playbooks`,
  Profile = `${BaseAppPath.Settings}/profile`,
  WorkspaceHistory = `${BaseAppPath.Settings}/workspace-history`,
  WorkspaceProjects = `${BaseAppPath.Settings}/workspace-projects`,
  InternalAdmin = `${BaseAppPath.Settings}/internal_admin`,
  InternalAdminWorkspaces = `${SettingsPath.InternalAdmin}/workspaces`,
  InternalAdminPermissions = `${SettingsPath.InternalAdmin}/permissions`,
  InternalAdminUserManagement = `${SettingsPath.InternalAdmin}/user-management`,
  InternalAdminExperimentManagement = `${SettingsPath.InternalAdmin}/experiments`,
  InternalAdminPwcTaxExport = `${SettingsPath.InternalAdmin}/pwc_tax_feedback_export`,
  InternalAdminUserInspector = `${SettingsPath.InternalAdmin}/user-inspector`,
  InternalAdminRoleInspector = `${SettingsPath.InternalAdmin}/role-inspector`,
  InternalAdminLibraryEventsManager = `${SettingsPath.InternalAdmin}/library-events-manager`,
  InternalAdminIncidentManagement = `${SettingsPath.InternalAdmin}/incident-management`,
  InternalAdminWorkflowPermissions = `${SettingsPath.InternalAdmin}/workflow-permissions`,
}
