import * as Sentry from '@sentry/browser'

import TelemtryEvent from 'services/honey-comb/telemetry-event'

import { Env } from 'utils/env'
import { environment } from 'utils/server-data'
import { displayErrorMessage } from 'utils/toast'
import { FORCED_LOGOUT } from 'utils/use-harvey-socket-utils'

import { getUserFriendlyError, isKnownError } from './mapper'

interface CapturedError extends Error {
  known: boolean
}

const PROD_URLS = ['https://eu.app.harvey.ai', 'https://app.harvey.ai']

const STAGING_URLS = [
  'https://eu-staging.harvey.engineering',
  'https://staging.harvey.engineering',
]

const PERMITTED_REDIRECT_URLS =
  environment === Env.PRODUCTION
    ? PROD_URLS
    : environment === Env.STAGING
    ? STAGING_URLS
    : []

if (process.env.NODE_ENV === 'test') {
  module.exports.PERMITTED_REDIRECT_URLS = PERMITTED_REDIRECT_URLS
}

export const getErrorFields = (e: unknown): Record<string, any> => {
  let error: Error | undefined = undefined
  if (e instanceof Error) {
    error = e
  } else if (e instanceof ErrorEvent) {
    error = e.error
  } else if (e instanceof PromiseRejectionEvent) {
    error = e.reason
  } else if (e instanceof Event) {
    error = new Error(e.type)
  }

  error = error ?? new Error('Unknown error: ' + JSON.stringify(e))

  return {
    error: error.message,
    error_stack: error.stack,
    error_type: error.name,
    error_meta: JSON.stringify(e),
  }
}

export const getFileMetadata = (value: FormDataEntryValue | null) => {
  if (!(value instanceof File)) {
    return {}
  }

  return {
    file_size: value.size,
    file_type: value.type,
  }
}

export const captureError = async (args: {
  e: unknown
  telEvent: TelemtryEvent
  throwOnError?: boolean
  additionalErrorFields?: { [key: string]: any }
  logout: () => void
}) => {
  const { e, telEvent, throwOnError, additionalErrorFields, logout } = args
  const isAbortError = e instanceof Error && e.name === 'AbortError'
  // request was explicitly canceled, don't show user error
  if (isAbortError && !throwOnError) {
    return
  }

  const userFriendlyMessage = await getUserFriendlyError(e)
  // this is an expected error message
  if (userFriendlyMessage == FORCED_LOGOUT) {
    console.info('logging user out')
    logout()
    // reload page after 2s to ensure logout and any ungracefully handled error
    await new Promise((resolve) => {
      setTimeout(() => {
        window.location.reload()
        // should never reach here because we reload
        resolve(undefined)
        // give it enough time
      }, 2_000)
    })
    return
  }

  // mark error
  const fields = {
    ...getErrorFields(e),
    ...(additionalErrorFields ? additionalErrorFields : {}),
    known: isKnownError(userFriendlyMessage),
    userFriendlyMessage,
  }

  telEvent.Add(fields)
  Sentry.captureException(e)

  // TODO remove after removing sentry
  // capture error in datadog
  if (!isAbortError) {
    console.warn(e, fields)
  }

  // caller should handle this in their promise chain
  if (throwOnError) {
    // try to set the error message to the user friendly message
    // we need to wrap this in a try-catch because not all Errors subclasses
    // have an implementation for message
    // https://stackoverflow.com/a/55089376

    // If the error is an AbortError, we want to throw it so that the caller can handle it
    // AbortErrors do not have a message property that can be set
    if (isAbortError) {
      throw e
    }
    try {
      ;(e as Error).message = userFriendlyMessage
      ;(e as CapturedError).known = fields.known
    } catch (error) {
      console.error('Failed to set error message', error)
    }
    throw e
  }

  // show error modal
  displayErrorMessage(userFriendlyMessage)
}

/**
 * Handles potential redirects from the backend.
 *
 * If the error is a 404 and redirect_url is set, we redirect to the new site.
 *
 * @param e The error to handle.
 */
export const handlePotentialRedirect = async (e: any) => {
  if (!e.response) {
    return
  }

  if (e.response.status !== 404) {
    return
  }

  let errorData
  try {
    // clone, otherwise consuming the json response stream will cause downstream reads to fail
    const errResponseClone = e.response.clone()
    errorData = await errResponseClone.json()
  } catch (error) {
    // This error may be possible since we may not return json from the backend
    console.info('Failed to parse error data from response', error)
    return
  }

  const maybeRedirectUrl = errorData.error?.redirect_url
  if (maybeRedirectUrl) {
    if (PERMITTED_REDIRECT_URLS.includes(maybeRedirectUrl)) {
      console.info('redirect_url', maybeRedirectUrl)
      window.location.replace(maybeRedirectUrl)
      return
    } else {
      console.error('Unpermitted redirect_url', maybeRedirectUrl)
      return
    }
  }
}
