/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Server-to-Client response message type
 * @export
 */
export const ResponseMessageType = {
    AUTHENTICATED: 'AUTHENTICATED',
    CANCELLED: 'CANCELLED',
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR',
    RESPONSE: 'RESPONSE',
    ANALYTICS: 'ANALYTICS',
    ANSWER_EXCEPTION: 'ANSWER_EXCEPTION'
} as const;
export type ResponseMessageType = typeof ResponseMessageType[keyof typeof ResponseMessageType];


export function instanceOfResponseMessageType(value: any): boolean {
    for (const key in ResponseMessageType) {
        if (Object.prototype.hasOwnProperty.call(ResponseMessageType, key)) {
            if (ResponseMessageType[key as keyof typeof ResponseMessageType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ResponseMessageTypeFromJSON(json: any): ResponseMessageType {
    return ResponseMessageTypeFromJSONTyped(json, false);
}

export function ResponseMessageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseMessageType {
    return json as ResponseMessageType;
}

export function ResponseMessageTypeToJSON(value?: ResponseMessageType | null): any {
    return value as any;
}

export function ResponseMessageTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): ResponseMessageType {
    return value as ResponseMessageType;
}

