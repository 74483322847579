import React from 'react'

import { cn } from 'utils/utils'

interface PasswordIconProps {
  className?: string
}

const PasswordIcon: React.FC<PasswordIconProps> = ({ className = '' }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.84954 4.45868e-07H6.15044C6.38475 -8.44302e-06 6.58689 -1.28738e-05 6.75333 0.0135871C6.92907 0.0279427 7.10382 0.0596405 7.272 0.145325C7.52289 0.273156 7.72684 0.477129 7.85467 0.728014C7.94036 0.896183 7.97204 1.07095 7.9864 1.24665C8 1.41313 8 1.61524 8 1.84953V6.15049C8 6.38476 8 6.58689 7.9864 6.75333C7.97204 6.92907 7.94036 7.10382 7.85467 7.272C7.72684 7.52289 7.52289 7.72684 7.272 7.85467C7.10382 7.94036 6.92907 7.97204 6.75333 7.9864C6.58689 8 6.38476 8 6.15049 8H1.84953C1.61524 8 1.41313 8 1.24665 7.9864C1.07095 7.97204 0.896183 7.94036 0.728014 7.85467C0.477129 7.72684 0.273156 7.52289 0.145325 7.272C0.0596405 7.10382 0.0279427 6.92907 0.0135871 6.75333C-1.28738e-05 6.58689 -8.44302e-06 6.38476 4.45868e-07 6.15049V1.84953C-8.44302e-06 1.61525 -1.28738e-05 1.41313 0.0135871 1.24665C0.0279427 1.07095 0.0596405 0.896183 0.145325 0.728014C0.273156 0.477129 0.477129 0.273156 0.728014 0.145325C0.896183 0.0596405 1.07095 0.0279427 1.24665 0.0135871C1.41313 -1.28738e-05 1.61525 -8.44302e-06 1.84954 4.45868e-07ZM6.22222 2.66667C6.22222 2.42121 6.02324 2.22222 5.77778 2.22222C5.53231 2.22222 5.33333 2.42121 5.33333 2.66667V5.33333C5.33333 5.5788 5.53231 5.77778 5.77778 5.77778C6.02324 5.77778 6.22222 5.5788 6.22222 5.33333V2.66667ZM2.66667 4C2.66667 4.24547 2.46768 4.44444 2.22222 4.44444C1.97676 4.44444 1.77778 4.24547 1.77778 4C1.77778 3.75453 1.97676 3.55556 2.22222 3.55556C2.46768 3.55556 2.66667 3.75453 2.66667 4ZM4 4.44444C4.24547 4.44444 4.44444 4.24547 4.44444 4C4.44444 3.75453 4.24547 3.55556 4 3.55556C3.75453 3.55556 3.55556 3.75453 3.55556 4C3.55556 4.24547 3.75453 4.44444 4 4.44444Z"
        fill="#EAB308"
      />
    </svg>
  )
}

export default PasswordIcon
