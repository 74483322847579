import * as React from 'react'
import { FileRejection } from 'react-dropzone'

import _ from 'lodash'
import { PaperclipIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { FileContainerType } from 'openapi/models/FileContainerType'
import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { useIntegrationsStore } from 'stores/integrations-store'
import { FileTypeToExtension } from 'types/file'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import {
  FileUploadingState,
  useAssistantStore,
} from 'components/assistant/stores/assistant-store'
import {
  FileSource,
  useKnowledgeSources,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { ACCEPTED_FILE_TYPES } from 'components/assistant/utils/constants'
import { useAuthUser } from 'components/common/auth-context'
import { DropdownItemProps } from 'components/common/ks-input-dropdown/dropdown-item'
import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import GoogleDriveIcon from 'components/ui/icons/google-drive-icon'
import IManageIcon from 'components/ui/icons/imanage-icon'
import SharePointIcon from 'components/ui/icons/sharepoint-icon'

interface UseAssistantKsOptionsProps {
  onUploadFromComputer: () => void
  setShowDialog: (show: boolean) => void
  onDrop: (
    files: File[],
    fileRejections: FileRejection[],
    fileUploadSource: FileUploadSource
  ) => void
  maxFileCount: number
  maxFileSize: number
  includeVaultOption: boolean
}

// This hook is used to generate the dropdown options for:
// The assistant files input and workflows file uploader
// It also generates the description text for the input
export const useAssistantKsOptions = ({
  onUploadFromComputer,
  setShowDialog,
  onDrop,
  maxFileCount,
  maxFileSize,
  includeVaultOption,
}: UseAssistantKsOptionsProps) => {
  const userInfo = useAuthUser()
  const [
    documents,
    documentsUploading,
    knowledgeSource,
    zipFiles,
    pstFiles,
    setDocumentsUploading,
  ] = useAssistantStore(
    useShallow((s) => [
      s.documents,
      s.documentsUploading,
      s.knowledgeSource,
      s.zipFiles,
      s.pstFiles,
      s.setDocumentsUploading,
    ])
  )

  const trackEvent = useAssistantAnalytics()
  const knowledgeSources = useKnowledgeSources(userInfo)

  const fileSources = React.useMemo(
    () =>
      Object.values(FileSource).filter((source) =>
        knowledgeSources.has(source)
      ),
    [knowledgeSources]
  )

  const internalFileSources = React.useMemo(
    () => fileSources.filter((source) => source !== FileSource.FILES),
    [fileSources]
  )

  const vaultUploadEnabled = React.useMemo(
    () => internalFileSources.includes(FileSource.VAULT),
    [internalFileSources]
  )

  // TODO: make this properties of a hook or some pre-computed state
  const sharepointEnabled =
    IntegrationDefinitions[IntegrationType.SHAREPOINT].available(userInfo)

  const googleDriveEnabled =
    IntegrationDefinitions[IntegrationType.GOOGLE_DRIVE].available(userInfo)

  const iManageEnabled =
    IntegrationDefinitions[IntegrationType.I_MANAGE].available(userInfo)

  const onUploadFromIntegration = React.useCallback(
    async (files: File[], fileUploadSource: FileUploadSource) => {
      await onDrop(files, [], fileUploadSource)
    },
    [onDrop]
  )

  const [setSharepointPickerOpenState, setIntegrationFilePickerOpenState] =
    useIntegrationsStore(
      useShallow((state) => [
        state.setSharepointPickerOpenState,
        state.setIntegrationFilePickerOpenState,
      ])
    )

  const handleIntegrationClick = React.useCallback(
    (integrationType: IntegrationType) => {
      const acceptedFileExtensions = _.flatMap(
        ACCEPTED_FILE_TYPES,
        (type) => FileTypeToExtension[type] ?? []
      )
      if (integrationType === IntegrationType.SHAREPOINT) {
        setSharepointPickerOpenState({
          acceptedFileTypes: acceptedFileExtensions,
          onUploadFromSharepoint: (files) =>
            onUploadFromIntegration(
              files,
              IntegrationDefinitions[integrationType].fileUploadSource
            ),
        })
      } else if (integrationType === IntegrationType.I_MANAGE) {
        setIntegrationFilePickerOpenState({
          acceptedFileTypes: ACCEPTED_FILE_TYPES,
          onFileUploadToServer: (uploadedFiles: UploadedFile[]) => {
            const placeholderFileUploadingStates: FileUploadingState[] =
              uploadedFiles.map((file) => ({
                name: file.name,
                size: file.size ?? 0, // TODO see if we need accurate size
                promise: Promise.resolve(file),
                controller: new AbortController(),
              }))

            // need to ensure that the function is using the most recent state
            setDocumentsUploading([
              ...useAssistantStore.getState().documentsUploading,
              ...placeholderFileUploadingStates,
            ])
          },
          integrationType,
          maxFileCount: maxFileCount,
          containerType: FileContainerType.EVENT,
        })
      } else {
        setIntegrationFilePickerOpenState({
          acceptedFileTypes: ACCEPTED_FILE_TYPES,
          onUploadFromIntegration: (files) =>
            onUploadFromIntegration(
              files,
              IntegrationDefinitions[integrationType].fileUploadSource
            ),
          integrationType,
          maxFileCount: maxFileCount,
        })
      }
    },
    [
      setSharepointPickerOpenState,
      setIntegrationFilePickerOpenState,
      onUploadFromIntegration,
      maxFileCount,
      setDocumentsUploading,
    ]
  )

  const chooseFilesDropdownOptions: (DropdownItemProps | 'separator')[] =
    React.useMemo(() => {
      const hasVaultKS =
        !!knowledgeSource && knowledgeSource.type === FileSource.VAULT
      const hasUploadedFiles =
        documents.length > 0 ||
        documentsUploading.length > 0 ||
        zipFiles.length > 0 ||
        pstFiles.length > 0
      const hasResearchKS = !!knowledgeSource && 'filterIds' in knowledgeSource

      const result: (DropdownItemProps | 'separator')[] = [
        {
          icon: <PaperclipIcon />,
          title: `Upload ${pluralize('file', maxFileCount)}`,
          description: `Max ${maxFileSize}MB per file, ${maxFileCount} ${pluralize(
            'file',
            maxFileCount
          )}`,
          onClick: (e) => {
            e.stopPropagation()
            onUploadFromComputer()
          },
          disabled: hasVaultKS || hasResearchKS,
        },
      ]

      if (vaultUploadEnabled && includeVaultOption) {
        result.push({
          icon: <FolderShieldIcon />,
          title: 'Vault',
          description: 'Choose a project',
          onClick: (e) => {
            e.stopPropagation()
            setShowDialog(true)
            trackEvent('Assistant Choose From Vault Clicked')
          },
          disabled: hasUploadedFiles || hasResearchKS,
        })
      }

      if (sharepointEnabled || googleDriveEnabled) {
        result.push('separator')
      }

      const integrationSourceDisabled = hasResearchKS || hasVaultKS

      if (sharepointEnabled) {
        result.push({
          icon: <SharePointIcon />,
          title: IntegrationDefinitions[IntegrationType.SHAREPOINT].name,
          onClick: (e) => {
            e.stopPropagation()
            handleIntegrationClick(IntegrationType.SHAREPOINT)
          },
          disabled: integrationSourceDisabled,
        })
      }

      if (googleDriveEnabled) {
        result.push({
          icon: <GoogleDriveIcon />,
          title: IntegrationDefinitions[IntegrationType.GOOGLE_DRIVE].name,
          onClick: (e) => {
            e.stopPropagation()
            handleIntegrationClick(IntegrationType.GOOGLE_DRIVE)
          },
          disabled: integrationSourceDisabled,
        })
      }

      if (iManageEnabled) {
        result.push({
          icon: <IManageIcon className="size-20" />,
          title: IntegrationDefinitions[IntegrationType.I_MANAGE].name,
          onClick: (e) => {
            e.stopPropagation()
            handleIntegrationClick(IntegrationType.I_MANAGE)
          },
          disabled: integrationSourceDisabled,
        })
      }

      return result
    }, [
      knowledgeSource,
      documents.length,
      documentsUploading.length,
      zipFiles.length,
      pstFiles.length,
      onUploadFromComputer,
      vaultUploadEnabled,
      sharepointEnabled,
      googleDriveEnabled,
      iManageEnabled,
      trackEvent,
      handleIntegrationClick,
      setShowDialog,
      maxFileCount,
      maxFileSize,
      includeVaultOption,
    ])

  const descriptionText = React.useMemo(() => {
    const availableSources = ['your computer']
    if (vaultUploadEnabled && includeVaultOption) {
      availableSources.push('a Vault project')
    }
    if (sharepointEnabled) {
      availableSources.push('SharePoint')
    }
    if (googleDriveEnabled) {
      availableSources.push('Google Drive')
    }
    if (iManageEnabled) {
      availableSources.push('iManage')
    }

    switch (availableSources.length) {
      case 1:
        return `Choose files from ${availableSources[0]}`
      case 2:
        return `Choose files from ${availableSources
          .slice(0, -1)
          .join(', ')}, or ${availableSources.slice(-1)}`
      default:
        return `Choose files from ${availableSources
          .slice(0, 2)
          .join(', ')}, and more`
    }
  }, [
    vaultUploadEnabled,
    sharepointEnabled,
    googleDriveEnabled,
    iManageEnabled,
    includeVaultOption,
  ])

  return {
    chooseFilesDropdownOptions,
    descriptionText,
  }
}
