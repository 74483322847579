/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SocketMessageResponse } from './SocketMessageResponse';
import {
    SocketMessageResponseFromJSON,
    SocketMessageResponseFromJSONTyped,
    SocketMessageResponseToJSON,
    SocketMessageResponseToJSONTyped,
} from './SocketMessageResponse';

/**
 * 
 * @export
 * @interface WorkflowDraftRenderBlockBlockParams
 */
export interface WorkflowDraftRenderBlockBlockParams {
    /**
     * 
     * @type {string}
     * @memberof WorkflowDraftRenderBlockBlockParams
     */
    draftId: string;
    /**
     * 
     * @type {SocketMessageResponse}
     * @memberof WorkflowDraftRenderBlockBlockParams
     */
    draftRevision: SocketMessageResponse;
}

/**
 * Check if a given object implements the WorkflowDraftRenderBlockBlockParams interface.
 */
export function instanceOfWorkflowDraftRenderBlockBlockParams(value: object): value is WorkflowDraftRenderBlockBlockParams {
    if (!('draftId' in value) || value['draftId'] === undefined) return false;
    if (!('draftRevision' in value) || value['draftRevision'] === undefined) return false;
    return true;
}

export function WorkflowDraftRenderBlockBlockParamsFromJSON(json: any): WorkflowDraftRenderBlockBlockParams {
    return WorkflowDraftRenderBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowDraftRenderBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowDraftRenderBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'draftId': json['draft_id'],
        'draftRevision': SocketMessageResponseFromJSON(json['draft_revision']),
    };
}

export function WorkflowDraftRenderBlockBlockParamsToJSON(json: any): WorkflowDraftRenderBlockBlockParams {
    return WorkflowDraftRenderBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowDraftRenderBlockBlockParamsToJSONTyped(value?: WorkflowDraftRenderBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'draft_id': value['draftId'],
        'draft_revision': SocketMessageResponseToJSON(value['draftRevision']),
    };
}

