import React from 'react'

import { BookOpenIcon } from 'lucide-react'
import pluralize from 'pluralize'

import { ProjectMetadata } from 'openapi/models/ProjectMetadata'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { SafeRecord } from 'utils/safe-types'

import { useAuthUser } from 'components/common/auth-context'
import { DropdownItemProps } from 'components/common/ks-input-dropdown/dropdown-item'
import { Icon } from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { SkeletonBlock } from 'components/ui/skeleton'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { useVaultProjectsMetadata } from 'components/vault/utils/use-vault-project-metadata'

export const useGetVaultProjectsForDropdown = ({
  onClick,
  includeKnowledgeBases = true,
  matchTriggerWidth = false,
  filterProjectIds,
  getTooltipText,
}: {
  onClick: (project: VaultFolder) => void
  includeKnowledgeBases?: boolean
  matchTriggerWidth?: boolean
  filterProjectIds?: string[]
  getTooltipText?: (project: VaultFolder) => string
}): {
  vaultProjectsDropdownOptions: (DropdownItemProps | 'separator')[]
  showKnowledgeBases: boolean
  areProjectsLoaded: boolean
  allVaultProjects: VaultFolder[]
  isFetching: boolean
} => {
  const { allVaultProjects, isFetchingProjects, hasLoadedProjects } =
    useVaultProjects({ includeExampleProjects: false })

  const idsToFilter = React.useMemo(
    () => new Set(filterProjectIds ?? []),
    [filterProjectIds]
  )

  const [knowledgeBases, projects] = React.useMemo(() => {
    const knowledgeBases: VaultFolder[] = []
    const projects: VaultFolder[] = []
    allVaultProjects.forEach((project) => {
      if (idsToFilter.has(project.id)) {
        return
      }
      if (project.isKnowledgeBaseProject) {
        knowledgeBases.push(project)
      } else {
        projects.push(project)
      }
    })
    return [knowledgeBases, projects]
  }, [allVaultProjects, idsToFilter])

  const { projectsMetadata } = useVaultProjectsMetadata(
    projects.map((project) => project.id)
  )
  const userInfo = useAuthUser()

  const getDropdownOption = React.useCallback(
    (
      project: VaultFolder,
      projectsMetadata: SafeRecord<string, ProjectMetadata> | undefined,
      isKnowledgeBase: boolean
    ) => {
      const totalFileCount = projectsMetadata?.[project.id]?.filesCount || 0
      const icon = isKnowledgeBase ? (
        <Icon icon={BookOpenIcon} />
      ) : (
        <FolderShieldIcon />
      )

      const description = isKnowledgeBase
        ? 'Knowledge base'
        : `${totalFileCount} ${pluralize('file', totalFileCount)}`

      const isDescriptionLoading =
        !isKnowledgeBase && (!projectsMetadata || !projectsMetadata[project.id])

      return {
        icon: icon,
        title: project.name,
        description,
        onClick: () => {
          onClick(project)
        },
        isDescriptionLoading,
        className: 'min-w-[250px]',
        matchTriggerWidth,
        tooltipText: getTooltipText?.(project),
      }
    },
    [onClick, matchTriggerWidth, getTooltipText]
  )

  const showKnowledgeBases =
    includeKnowledgeBases &&
    knowledgeBases.length > 0 &&
    userInfo.IsKnowledgeBaseProjectUser

  const vaultProjectsDropdownOptions: (DropdownItemProps | 'separator')[] =
    React.useMemo(() => {
      if (allVaultProjects.length > 0) {
        const knowledgeBasesDropdownOptions = knowledgeBases.map(
          (knowledgeBase) => {
            return getDropdownOption(knowledgeBase, projectsMetadata, true)
          }
        )
        const projectsDropdownOptions = projects.map((project) => {
          return getDropdownOption(project, projectsMetadata, false)
        })
        return showKnowledgeBases
          ? [
              ...knowledgeBasesDropdownOptions,
              'separator',
              ...projectsDropdownOptions,
            ]
          : projectsDropdownOptions
      } else if (hasLoadedProjects) {
        return []
      } else {
        return new Array(4).fill(null).map(() => {
          return {
            icon: <SkeletonBlock className="h-4" />,
            title: '',
            description: '',
            isDescriptionLoading: true,
            isTitleLoading: true,
            className: 'min-w-[250px]',
            disabled: true,
            onClick: () => {},
          }
        })
      }
    }, [
      knowledgeBases,
      projects,
      projectsMetadata,
      getDropdownOption,
      showKnowledgeBases,
      allVaultProjects,
      hasLoadedProjects,
    ])

  return {
    vaultProjectsDropdownOptions,
    showKnowledgeBases,
    areProjectsLoaded: hasLoadedProjects,
    allVaultProjects,
    isFetching: isFetchingProjects,
  }
}
