import { WorkflowEventStatusStepsInner } from 'openapi/models/WorkflowEventStatusStepsInner'

import { KnowledgeSourceItem } from 'components/assistant/utils/assistant-knowledge-sources'

import { WorkflowCategory, WorkflowInfo } from './workflow-types'

export const ISSUES_LIST_NON_REDLINES_UPLOADED_METRIC =
  'ui.issues_list_non_redlines_uploaded'
export const ISSUES_LIST_ASSISTANT_REDIRECT_CLICKED_METRIC =
  'ui.issues_list_assistant_redirect_clicked'
export const AUTO_GENERATE_ISSUES_CLICKED_METRIC =
  'ui.auto_generate_issues_clicked'

export enum WorkflowSource {
  WORKFLOWS_PAGE = 'workflowsPage',
  VAULT_PROJECT_PAGE = 'vaultProjectPage',
  ASSISTANT_PAGE = 'assistantPage',
}

export const filterWorkflowsBySearchValue = (
  workflows: WorkflowInfo[],
  searchValue: string
) => {
  return workflows.filter((workflow) =>
    workflow.name.toLowerCase().includes(searchValue.toLowerCase())
  )
}

export const getCategoryToWorkflowsMap = (
  workflowInfo: WorkflowInfo[],
  searchValue: string
): Record<
  WorkflowCategory,
  { workflows: WorkflowInfo[]; filteredWorkflows: WorkflowInfo[] }
> => {
  // Initialize the result with all categories
  const result: Record<
    WorkflowCategory,
    { workflows: WorkflowInfo[]; filteredWorkflows: WorkflowInfo[] }
  > = Object.values(WorkflowCategory).reduce(
    (acc, category) => ({
      ...acc,
      [category]: { workflows: [], filteredWorkflows: [] },
    }),
    {} as Record<
      WorkflowCategory,
      { workflows: WorkflowInfo[]; filteredWorkflows: WorkflowInfo[] }
    >
  )

  workflowInfo.forEach((workflow) => {
    const matchesSearch = workflow.name
      .toLowerCase()
      .includes(searchValue.toLowerCase())

    if (workflow.categories) {
      workflow.categories.forEach((category) => {
        if (!result[category]) {
          result[category] = { workflows: [], filteredWorkflows: [] }
        }

        result[category].workflows.push(workflow)

        if (matchesSearch) {
          result[category].filteredWorkflows.push(workflow)
        }
      })
    }
  })

  return result
}

export function getWorkflowPath(workflow: WorkflowInfo): string | undefined {
  if ('path' in workflow) {
    return workflow.path
  }
  return undefined
}

export function getKnowledgeSources(
  workflowStep: WorkflowEventStatusStepsInner | undefined
): KnowledgeSourceItem[] {
  if (!workflowStep) return []

  const outputData = workflowStep.outputData as {
    knowledgeSources?: KnowledgeSourceItem[]
  }
  if (outputData.knowledgeSources?.length) {
    return outputData.knowledgeSources
  }

  return []
}
