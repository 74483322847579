import React, { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Maybe } from 'ag-grid-community/dist/types/core/columns/columnModel'

import { SourceType } from 'openapi/models/SourceType'
import { useFileCache } from 'stores/file-cache'

import { getSourceClickedFromSource } from 'utils/source'
import { Source } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'

import { useSetViewingFile } from 'components/assistant/workflows/components/workflow-file-popover'
import CitationPopover from 'components/common/citation/citation-popover'

interface SourceUtils {
  sources: Source[]
}

export const useSourceUtils = ({ sources }: SourceUtils) => {
  const setViewingFile = useSetViewingFile()
  const [getFile] = useFileCache((state) => [state.getFile])
  const [searchParams] = useSearchParams()

  const selectedSourceId = searchParams.get('sourceId')

  const handleSourceClick = useCallback(
    (fileId: Maybe<string>, sourceId?: string, _sources?: Source[]) => {
      // _sources gives the option to pass in a different (more specific) list of sources

      if (!fileId) return
      const sourcesArr = _sources || sources || []
      const source = sourceId
        ? getSourceClickedFromSource(sourceId, sourcesArr as Source[])
        : null
      const documentUrl = source?.documentUrl
      if (
        source &&
        source.sourceType === SourceType.EXTERNAL_WEB &&
        documentUrl &&
        typeof documentUrl === 'string'
      ) {
        try {
          new URL(documentUrl)
          if (documentUrl.startsWith('https')) {
            window.open(documentUrl, '_blank', 'noopener,noreferrer')
            return
          }
        } catch (e) {
          displayErrorMessage('Invalid URL format')
          return
        }
      }

      getFile(fileId)
        .then((file) => {
          if (file) {
            setViewingFile(file, sourceId, sourcesArr as Source[])
          }
        })
        .catch(() => {
          displayErrorMessage('Failed to load file')
        })
    },
    [getFile, setViewingFile, sources]
  )

  const getHrvyInfoMetadata = useCallback(
    (sourceId: string) => {
      const source = getSourceClickedFromSource(
        sourceId,
        (sources || []) as Source[]
      )

      if (!source || !source.documentId) return

      const hoverContent = (
        <CitationPopover
          source={source}
          onClick={() => handleSourceClick(source.documentId!, source.id)}
        />
      )

      return {
        hoverContent,
        onClick: () => handleSourceClick(source.documentId!, source.id),
        isSelected: selectedSourceId === source.id,
      }
    },
    [sources, handleSourceClick, selectedSourceId]
  )

  return {
    handleSourceClick,
    getHrvyInfoMetadata,
  }
}
