import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'

import AssistantCustomTips from 'components/assistant/components/assistant-custom-tips'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

import { useAuthUser } from './auth-context'
import Markdown from './markdown/markdown'

const CustomGuidanceInterstitial = () => {
  const userInfo = useAuthUser()
  const [customGuidanceInterstitialOpen, setCustomGuidanceInterstitialOpen] =
    useGeneralStore(
      useShallow((state) => [
        state.customGuidanceInterstitialOpen,
        state.setCustomGuidanceInterstitialOpen,
      ])
    )

  const customGuidance = userInfo.workspace.customGuidance

  return (
    <Dialog
      open={customGuidanceInterstitialOpen}
      onOpenChange={setCustomGuidanceInterstitialOpen}
    >
      <DialogContent className="max-w-screen-lg">
        <DialogHeader>
          <DialogTitle>
            {customGuidance
              ? customGuidance.title
              : `${userInfo.workspace.clientName}'s guidance`}
          </DialogTitle>
        </DialogHeader>
        {customGuidance ? (
          <div className="flex flex-col p-4">
            <Markdown
              className="max-w-4xl pt-2"
              content={customGuidance.message}
              enableMailLinks
            />
          </div>
        ) : (
          <div className="flex flex-col p-4">
            <AssistantCustomTips />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CustomGuidanceInterstitial
