import saveAs from 'file-saver'
import JSZip from 'jszip'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFolder } from 'openapi/models/VaultFolder'

import {
  displayErrorMessage,
  displayInfoMessage,
  displayWarningMessage,
} from 'utils/toast'

import { createFileName, sanitizeFileName } from './file-utils'

const BATCH_SIZE = 50
export const downloadUploadedFiles = async ({
  uploadedFiles,
  zippedFileName,
  shouldZipSingleFile = false,
}: {
  uploadedFiles: { id: string; name: string; url: string }[]
  zippedFileName?: string
  shouldZipSingleFile?: boolean
}) => {
  if (uploadedFiles.length === 1 && !shouldZipSingleFile) {
    const file = uploadedFiles[0]
    const response = await fetch(file.url)
    const blob = await response.blob()
    saveAs(blob, file.name)
    return true
  } else if (uploadedFiles.length > 0) {
    const zip = new JSZip()
    const namesForExistingFiles: string[] = []
    const documentIdToBlob: Record<string, Blob> = {}
    let errorCount = 0

    for (let i = 0; i < uploadedFiles.length; i += BATCH_SIZE) {
      const batch = uploadedFiles.slice(i, i + BATCH_SIZE)

      for (const file of batch) {
        try {
          const response = await fetch(file.url)
          const blob = await response.blob()
          documentIdToBlob[file.id] = blob
        } catch (error) {
          errorCount++
          console.error(error)
        }
      }
    }

    if (errorCount === uploadedFiles.length) {
      return false
    }
    const existingNamesSet = new Set(namesForExistingFiles)
    uploadedFiles.forEach((document) => {
      const fileName = createFileName(document.name, existingNamesSet)
      namesForExistingFiles.push(fileName)
      zip.file(fileName, documentIdToBlob[document.id])
    })
    const zipName = zippedFileName
      ? `${sanitizeFileName(zippedFileName)}.zip`
      : 'downloaded_files.zip'
    const generatedZip = await zip.generateAsync({ type: 'blob' })
    saveAs(generatedZip, zipName)
    return true
  }
}

const buildPathsForFiles = ({
  uploadedFiles,
  folders,
}: {
  uploadedFiles: {
    id: string
    name: string
    url: string
    folderId: string | null
  }[]
  folders: VaultFolder[]
}) => {
  const folderMap = new Map(folders.map((folder) => [folder.id, folder]))

  const relevantFolderIds = new Set(
    uploadedFiles.map((file) => file.folderId).filter(Boolean)
  )

  const folderPaths = new Map<string, string>()
  relevantFolderIds.forEach((folderId) => {
    if (folderId) {
      const parts: string[] = []
      let currentFolder = folderMap.get(folderId)

      while (currentFolder) {
        parts.unshift(sanitizeFileName(currentFolder.name))
        currentFolder = currentFolder.parentId
          ? folderMap.get(currentFolder.parentId)
          : undefined
      }

      folderPaths.set(folderId, parts.join('/'))
    }
  })

  const files = uploadedFiles.map((document) => {
    const folderPath = document.folderId
      ? folderPaths.get(document.folderId)
      : ''
    const fullPath = folderPath
      ? `${folderPath}/${document.name}`
      : document.name
    return {
      id: document.id,
      name: fullPath,
      url: document.url,
    }
  })
  return files
}

export const downloadVaultFiles = async ({
  uploadedFiles,
  folders,
  downloadFileName,
}: {
  uploadedFiles: {
    id: string
    name: string
    url: string
    folderId: string | null
  }[]
  folders: VaultFolder[]
  downloadFileName?: string
}) => {
  const files = buildPathsForFiles({ uploadedFiles, folders })
  const success = await downloadUploadedFiles({
    uploadedFiles: files,
    zippedFileName: downloadFileName,
    shouldZipSingleFile: true,
  })
  return success
}

export const fetchAndDownloadDocuments = async ({
  documents,
  eventId,
  exportTitle,
  getDocument,
  title,
}: {
  documents: UploadedFile[]
  eventId: string | null
  exportTitle: string | null
  getDocument: (
    eventId: string | null,
    documentId: string
  ) => Promise<UploadedFile | undefined>
  title?: string
}) => {
  displayInfoMessage('Your download will start shortly.', 5)

  // Sequentially fetch documents is fine since assistant file store will cache all docs for a given event
  const documentsDict = await documents.reduce(
    async (dict, doc) => {
      const acc = await dict
      const document = await getDocument(eventId ?? null, doc.id)
      if (document) {
        acc[doc.id] = document
      }
      return acc
    },
    Promise.resolve({} as Record<string, UploadedFile | undefined>)
  )

  const documentsReadyToDownload: UploadedFile[] = []
  const documentIdsNotReadyToDownload: string[] = []
  Object.entries(documentsDict).forEach(([id, document]) => {
    if (!!document && !!document.url) {
      documentsReadyToDownload.push(document)
    } else {
      documentIdsNotReadyToDownload.push(id)
    }
  })

  // This should never happen, but if it does, we'll display a warning message
  if (documentIdsNotReadyToDownload.length > 0) {
    if (documentsReadyToDownload.length > 0) {
      const message = `There are currently ${documentIdsNotReadyToDownload.length} files that are not available. Skipping these files.`
      displayWarningMessage(message)
      console.warn(message, {
        filesNotReadyToDownload: documentIdsNotReadyToDownload,
      })
    } else {
      // This really should never happen, but if it does, we'll display an error message
      const message =
        'There are currently no files that are available to download.'
      displayErrorMessage(message)
      console.error(message)
    }
  }

  await downloadUploadedFiles({
    uploadedFiles: documentsReadyToDownload.map((doc) => ({
      id: doc.id,
      name: doc.name,
      url: doc.url!,
    })),
    zippedFileName: `${exportTitle ?? ''}${title ? ` ${title}` : ''}`,
  })
}
