import React from 'react'

import { toast } from 'sonner'

import CollapsibleToast from 'components/ui/collapsible-toast'
import Toast from 'components/ui/toast'
import { CTA } from 'components/ui/types'

// Use this duration when user might not be looking at the screen and come back
// later to see the error toast, e.g. WebSocket or long running process
export const LONG_TOAST_DURATION = 60

export const displaySuccessMessage = (
  message: string | ((toastId: string | number) => React.ReactNode),
  durationInSeconds: number = 2,
  title?: string
): void => {
  displaySuccessMessageWithCTA({
    message,
    durationInSeconds,
    title,
    cta: undefined,
  })
}

export const displaySuccessMessageWithCTA = ({
  message,
  durationInSeconds,
  title,
  cta,
}: {
  message: string | ((toastId: string | number) => React.ReactNode)
  durationInSeconds: number
  title?: string
  cta?: CTA
}): string | number => {
  return toast.custom(
    (t) => (
      <Toast
        message={message}
        title={title}
        toastId={t}
        variant="success"
        cta={cta}
        dataTestId="system-message-success"
      />
    ),
    {
      position: 'bottom-right',
      duration: durationInSeconds * 1000,
    }
  )
}

export const displayErrorMessage = (
  message: string,
  durationInSeconds: number = 4,
  title?: string,
  dismissible: boolean = true
  // eslint-disable-next-line max-params
): void => {
  toast.custom(
    (t) => (
      <Toast
        message={message}
        toastId={t}
        variant="destructive"
        title={title}
        dataTestId="system-message-error"
      />
    ),
    {
      position: 'bottom-right',
      duration: dismissible ? durationInSeconds * 1000 : Infinity,
      dismissible: dismissible,
    }
  )
}

export const displayWarningMessage = (
  message: string,
  durationInSeconds: number = 2,
  title?: string
): string | number => {
  return toast.custom(
    (t) => (
      <Toast
        message={message}
        toastId={t}
        variant="warning"
        title={title}
        dataTestId="system-message-warning"
      />
    ),
    {
      position: 'bottom-right',
      duration: durationInSeconds * 1000,
    }
  )
}

export const displayInfoMessage = (
  message: string,
  durationInSeconds: number = 2,
  title?: string
): string | number => {
  return toast.custom(
    (t) => (
      <Toast
        message={message}
        toastId={t}
        title={title}
        dataTestId="system-message-info"
      />
    ),
    {
      position: 'bottom-right',
      duration: durationInSeconds * 1000,
    }
  )
}

export const displayCollapsibleMessage = ({
  title,
  content,
  analyticsMessage,
  width = 360,
  durationInSeconds = 2,
  persistent = false,
}: {
  title?: string | React.ReactNode
  content?: React.ReactNode
  analyticsMessage?: string
  width?: number
  durationInSeconds?: number
  persistent?: boolean
}): string | number => {
  return toast.custom(
    (t) => (
      <CollapsibleToast
        toastId={t}
        title={title}
        content={content}
        analyticsMessage={analyticsMessage}
        width={width}
        dataTestId="system-message-collapsible"
      />
    ),
    {
      position: 'bottom-right',
      duration: persistent ? Infinity : durationInSeconds * 1000,
      unstyled: true,
    }
  )
}
