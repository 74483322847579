import { NavigateFunction } from 'react-router-dom'

import { driver, DriveStep } from 'driver.js'

import { UserInfo } from 'models/user-info'
import { WorkflowDefinition } from 'openapi/models/WorkflowDefinition'
import { WorkflowEventStatus } from 'openapi/models/WorkflowEventStatus'
import { WorkflowEventStatusStepsInner } from 'openapi/models/WorkflowEventStatusStepsInner'
import { FileCache } from 'stores/file-cache'

import { waitForElement } from 'utils/utils'

import { AssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { useProductTourStore } from 'components/common/product-tours/product-tour-store'

import {
  EXAMPLE_WORKFLOW_TOUR_EVENT,
  EXAMPLE_WORKFLOW_UPLOADED_FILES,
} from './examples/workflows-product-tour-example-data'

const workflowDefinition = EXAMPLE_WORKFLOW_TOUR_EVENT.data
  .workflowDefinition as WorkflowDefinition
const completedWorkflow = EXAMPLE_WORKFLOW_TOUR_EVENT.data
  .workflowStatus as WorkflowEventStatus

async function simulateMouseClick(el: HTMLElement) {
  const opts = { view: window, bubbles: true, cancelable: true, buttons: 1 }
  el.dispatchEvent(new MouseEvent('mousedown', opts))
  await new Promise((r) => setTimeout(r, 50))
  el.dispatchEvent(new MouseEvent('mouseup', opts))
  el.dispatchEvent(new MouseEvent('click', opts))
}

const createInProgressWorkflow = (steps: number): WorkflowEventStatus => {
  return {
    ...EXAMPLE_WORKFLOW_TOUR_EVENT.data.workflowStatus,
    isCompleted: false,
    isPartialUpdate: false,
    steps: Array.from(
      { length: steps },
      (_, i) =>
        (i < steps - 1
          ? EXAMPLE_WORKFLOW_TOUR_EVENT.data.workflowStatus.steps[i]
          : {
              ...EXAMPLE_WORKFLOW_TOUR_EVENT.data.workflowStatus.steps[i],
              paramStatus: 'PENDING',
              completionStatus: 'PENDING',
              outputData: null,
            }) as WorkflowEventStatusStepsInner
    ),
  }
}

export const runWorkflowsProductTour = (
  navigate: NavigateFunction,
  userInfo: UserInfo | undefined,
  options: {
    workflowStore?: AssistantWorkflowStore
    fileCache?: FileCache
  }
  // eslint-disable-next-line max-params
) => {
  const { workflowStore, fileCache } = options
  const startRoute = window.location.pathname
  navigate('/assistant')

  if (!userInfo) {
    return
  }

  EXAMPLE_WORKFLOW_UPLOADED_FILES

  const steps: DriveStep[] = [
    {
      element: '#assistant-home-page-tab-workflows',
      popover: {
        title: 'Access new workflows',
        description:
          'See Assistant workflows that are relevant to you and your practice area in the homepage’s Workflows tab. Here, workflows will be recommended to you.',
        onPopoverRender: async () => {
          EXAMPLE_WORKFLOW_UPLOADED_FILES.forEach((file) => {
            fileCache?.addFile(file)
          })
        },
      },
    },
    {
      element: '#workflow-card-1098782a-1716-4d82-84c0-778133064295',
      popover: {
        title: 'Select a workflow',
        description:
          'Select a workflow on the homepage or on the Workflows tab. You can see the number of steps and the output type that a workflow will provide.',
        onNextClick: async () => {
          const el = document.getElementById(
            'workflow-card-1098782a-1716-4d82-84c0-778133064295'
          )
          if (el) {
            await simulateMouseClick(el)
          }
          await waitForElement(
            '#preview-modal-1098782a-1716-4d82-84c0-778133064295'
          )
          driverObj.moveNext()
        },
      },
    },
    {
      element: '#preview-modal-1098782a-1716-4d82-84c0-778133064295',
      popover: {
        title: 'Preview a workflow',
        description:
          'Preview information about a workflow such as the description, the workflow’s practice area, a required step, and a sample output.',
        disableButtons: ['close'],
        onPrevClick: async () => {
          const el = document.getElementById('dialog-close-button')
          if (el) {
            await simulateMouseClick(el)
          }
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          navigate('/assistant/workflows/draft-a-client-alert')
          await waitForElement(
            'h1.line-clamp-2.whitespace-pre-wrap.text-xl.font-medium'
          )
          workflowStore?.setCurrentWorkflow(
            workflowDefinition,
            createInProgressWorkflow(1)
          )
          await waitForElement('#assistant-workflow-block-0')
          driverObj.moveNext()
        },
        side: 'right',
      },
    },
    {
      element: '#assistant-workflow-block-0',
      popover: {
        title: 'Receive proactive guidance from Harvey',
        description:
          'Harvey will prompt you for specific input, guiding you every step of the way. No more prompting needed.',
        onPrevClick: async () => {
          navigate('/assistant')
          await waitForElement(
            '#workflow-card-1098782a-1716-4d82-84c0-778133064295'
          )
          const el = document.getElementById(
            'workflow-card-1098782a-1716-4d82-84c0-778133064295'
          )
          if (el) {
            await simulateMouseClick(el)
          }
          await waitForElement(
            '#preview-modal-1098782a-1716-4d82-84c0-778133064295'
          )
          driverObj.movePrevious()
        },
      },
    },
    {
      element: '[data-testid="dropzone"]',
      popover: {
        title: 'Provide the requested information',
        description: 'Upload files via your computer or other integrations.',
      },
    },
    {
      element: '#assistant-workflow-file-upload-text-input',
      popover: {
        title: 'Paste text option',
        description:
          'Some workflow steps will allow you to paste in requested text, making it easier to run through these workflows.',
        onNextClick: async () => {
          workflowStore?.setCurrentWorkflow(
            workflowDefinition,
            completedWorkflow,
            true
          )
          await waitForElement('[data-testid="workflow-loading-states-button"]')
          const ls = document.querySelector(
            '[data-testid="workflow-loading-states-button"]'
          )
          if (ls) {
            await simulateMouseClick(ls as HTMLElement)
          }
          await waitForElement('[data-testid="workflow-loading-state-0"]')
          const el = document.querySelector(
            '[data-testid="workflow-loading-states-button"]'
          )
          if (el) {
            el.scrollIntoView()
          }
          driverObj.moveNext()
        },
      },
    },
    {
      element: '[data-testid="workflow-loading-states"]',
      popover: {
        title: 'See Harvey thinking',
        description:
          'Workflows expose Harvey’s thinking states, offering transparency into Harvey’s process and providing incremental progress updates.',
        onPrevClick: async () => {
          workflowStore?.setCurrentWorkflow(
            workflowDefinition,
            createInProgressWorkflow(1),
            true
          )
          workflowStore?.resetBlockStore(0)
          await waitForElement('[data-testid="dropzone"]')
          driverObj.movePrevious()
        },
      },
    },
    {
      // element: '#assistant-workflow-draft-edit-button',
      element: '[data-testid="assistant-workflow-draft-container"]',
      popover: {
        title: 'Iterate on Draft outputs',
        description:
          'For drafting outputs, click on the draft container to launch into Draft mode and further iterate on the output. Other workflows will allow asking followup questions, like in Assist mode.',
        onNextClick: async () => {
          const el = document.getElementById(
            'assistant-workflow-draft-edit-button'
          )
          if (el) {
            await simulateMouseClick(el)
          }
          await waitForElement(
            '#assistant-message-content-0195b0c7-efa8-703c-8015-a7069971d783'
          )
          driverObj.moveNext()
        },
        align: 'start',
      },
    },
    {
      element: '#assistant-workflow-draft-header-back-to-workflow-button',
      popover: {
        title: 'Go back to the workflow',
        description:
          'Once you’re done iterating on the output, go back to the main workflow thread.',
        onPrevClick: async () => {
          const el = document.getElementById(
            'assistant-workflow-draft-header-back-to-workflow-button'
          )
          if (el) {
            await simulateMouseClick(el)
          }
          await waitForElement(
            'h1.line-clamp-2.whitespace-pre-wrap.text-xl.font-medium'
          )
          workflowStore?.setCurrentWorkflow(
            workflowDefinition,
            completedWorkflow
          )
          await waitForElement('[data-testid="workflow-loading-states-button"]')
          const ls = document.querySelector(
            '[data-testid="workflow-loading-states-button"]'
          )
          if (ls) {
            await simulateMouseClick(ls as HTMLElement)
          }
          await waitForElement('[data-testid="workflow-loading-state-0"]')
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          const el = document.getElementById(
            'assistant-workflow-draft-header-back-to-workflow-button'
          )
          if (el) {
            await simulateMouseClick(el)
          }
          await waitForElement(
            'h1.line-clamp-2.whitespace-pre-wrap.text-xl.font-medium'
          )
          workflowStore?.setCurrentWorkflow(
            workflowDefinition,
            completedWorkflow
          )
          await waitForElement('[data-testid="workflow-loading-states-button"]')
          const ls = document.querySelector(
            '[data-testid="workflow-loading-states-button"]'
          )
          if (ls) {
            await simulateMouseClick(ls as HTMLElement)
          }
          await waitForElement('[data-testid="workflow-loading-state-0"]')
          driverObj.moveNext()
        },
      },
    },
    {
      element: '[data-testid="export-dropdown-common"]',
      popover: {
        title: 'Export responses',
        description:
          'Export outputs, either the full workflow thread or the individual response.',
        onPrevClick: async () => {
          const el = document.getElementById(
            'assistant-workflow-draft-edit-button'
          )
          if (el) {
            await simulateMouseClick(el)
          }
          await waitForElement(
            '#assistant-workflow-draft-header-back-to-workflow-button'
          )
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          const el = document.getElementById('sidebar-toggle-open-button')
          if (el) {
            await simulateMouseClick(el)
          }
          await waitForElement('#sidebar-item--Workflows')
          driverObj.moveNext()
        },
      },
    },
    {
      element: '#sidebar-item--Workflows',
      popover: {
        title: 'Browse more workflows',
        description:
          'Explore more workflows across general and practice area specific use cases in the Workflows tab.',
        onPrevClick: async () => {
          const el = document.getElementById('sidebar-toggle-close-button')
          if (el) {
            await simulateMouseClick(el)
          }
          driverObj.movePrevious()
        },
      },
    },
  ]

  const closeProductTour = () => {
    useProductTourStore.getState().endTour()
    driverObj.destroy()
    navigate(startRoute)
  }

  const driverObj = driver({
    showProgress: true,
    steps,
    disableActiveInteraction: true,
    onCloseClick: closeProductTour,
    onDestroyStarted: async () => {
      if (!driverObj.hasNextStep()) {
        closeProductTour()
      }
    },
  })

  driverObj.drive()
  useProductTourStore.getState().startTour()
}

// export const markV1ProductTourAsCompleted = async () => {
//   try {
//     await updateUserSettings({
//       v1ProductTourCompleted: true,
//     })
//   } catch (e) {
//     console.error('Error while updating user’s v1WelcomeModalCompleted', e)
//     Sentry.captureException(e)
//   }
// }
