import * as React from 'react'

import { EventKind } from 'openapi/models/EventKind'

import { AssistantLoadPromptButton } from 'components/assistant/components/assistant-load-prompt-button'
import { Button } from 'components/ui/button'
import { Textarea } from 'components/ui/text-area'

export interface TextInputProps {
  id?: string
  onSubmit: (value: string) => void
  onCancel?: () => void
  optional?: boolean
  placeholder?: string
  isCompleted?: boolean
  value: string
  onChange: (value: string) => void
  isLoading?: boolean
  className?: string
  autoFocus?: boolean
  isSubmitDisabled?: boolean
  additionalLeftButtons?: React.ReactNode
  additionalRightButtons?: React.ReactNode
  rows?: number
  showLoadPromptButton?: boolean
}
const TextInput: React.FC<TextInputProps> = ({
  id,
  autoFocus,
  onSubmit,
  onCancel,
  optional,
  placeholder,
  isCompleted,
  value,
  onChange,
  isLoading,
  isSubmitDisabled,
  additionalLeftButtons,
  additionalRightButtons,
  rows = 1,
  showLoadPromptButton = true,
}) => {
  const [queryPreview, setQueryPreview] = React.useState<string | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  const handleSubmit = () => {
    if (isLoading || (!value && !optional)) return
    onSubmit(value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.metaKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div id={id} className="relative -m-[10px] items-center">
      <Textarea
        className="size-full max-h-80 resize-none border-none bg-transparent py-4 pb-16 pl-4 pr-20 focus-visible:ring-0 focus-visible:ring-offset-0"
        onChange={handleChange}
        placeholder={queryPreview || placeholder}
        value={queryPreview ? '' : value}
        onKeyDown={handleKeyDown}
        disabled={isCompleted || isLoading}
        rows={rows}
        isFluid
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus ?? false}
      />

      <FooterButtons
        onSubmit={handleSubmit}
        onCancel={onCancel}
        isCompleted={isCompleted}
        isLoading={isLoading}
        isSubmitDisabled={isSubmitDisabled}
        value={value}
        optional={optional}
        setQueryPreview={setQueryPreview}
        onChange={onChange}
        additionalLeftButtons={additionalLeftButtons}
        additionalRightButtons={additionalRightButtons}
        showLoadPromptButton={showLoadPromptButton}
      />
    </div>
  )
}

export default TextInput

interface FooterButtonsProps {
  onSubmit: () => void
  onCancel?: () => void
  isCompleted?: boolean
  isLoading?: boolean
  isSubmitDisabled?: boolean
  value: string
  optional?: boolean
  setQueryPreview: React.Dispatch<React.SetStateAction<string | null>>
  onChange: (value: string) => void
  additionalLeftButtons?: React.ReactNode
  additionalRightButtons?: React.ReactNode
  showLoadPromptButton?: boolean
}

const FooterButtons: React.FC<FooterButtonsProps> = ({
  onSubmit,
  onCancel,
  isCompleted,
  isLoading,
  isSubmitDisabled,
  value,
  optional,
  setQueryPreview,
  onChange,
  additionalLeftButtons,
  additionalRightButtons,
  showLoadPromptButton = true,
}) => {
  return (
    <div className="absolute bottom-[10px] flex w-full justify-between px-[10px]">
      <div className="flex items-center space-x-2">
        {additionalLeftButtons}
        {showLoadPromptButton && (
          <AssistantLoadPromptButton
            query={value}
            setQuery={onChange}
            setQueryPreview={setQueryPreview}
            eventKind={EventKind.ASSISTANT}
            disabled={false}
          />
        )}
      </div>
      <div className="flex items-center space-x-2">
        {onCancel && (
          <Button
            data-testid="cancel-button"
            variant="outline"
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={onSubmit}
          disabled={
            isSubmitDisabled ||
            isCompleted ||
            isLoading ||
            (!optional && !value)
          }
          isLoading={isCompleted || isLoading}
          data-testid="submit-button"
        >
          {optional && !value ? 'Skip' : 'Send'}
        </Button>
        {additionalRightButtons}
      </div>
    </div>
  )
}
