/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RelatedQuestion } from './RelatedQuestion';
import {
    RelatedQuestionFromJSON,
    RelatedQuestionFromJSONTyped,
    RelatedQuestionToJSON,
    RelatedQuestionToJSONTyped,
} from './RelatedQuestion';
import type { ResponseMessageType } from './ResponseMessageType';
import {
    ResponseMessageTypeFromJSON,
    ResponseMessageTypeFromJSONTyped,
    ResponseMessageTypeToJSON,
    ResponseMessageTypeToJSONTyped,
} from './ResponseMessageType';
import type { LoadingState } from './LoadingState';
import {
    LoadingStateFromJSON,
    LoadingStateFromJSONTyped,
    LoadingStateToJSON,
    LoadingStateToJSONTyped,
} from './LoadingState';
import type { SocketMessageResponseAnnotationsValue } from './SocketMessageResponseAnnotationsValue';
import {
    SocketMessageResponseAnnotationsValueFromJSON,
    SocketMessageResponseAnnotationsValueFromJSONTyped,
    SocketMessageResponseAnnotationsValueToJSON,
    SocketMessageResponseAnnotationsValueToJSONTyped,
} from './SocketMessageResponseAnnotationsValue';
import type { Source } from './Source';
import {
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
    SourceToJSONTyped,
} from './Source';

/**
 * 
 * @export
 * @interface SocketMessageResponse
 */
export interface SocketMessageResponse {
    /**
     * 
     * @type {ResponseMessageType}
     * @memberof SocketMessageResponse
     */
    type: ResponseMessageType;
    /**
     * 
     * @type {string}
     * @memberof SocketMessageResponse
     */
    responseVersion: string;
    /**
     * This is expected to be rendered to the user as html. It may contain html.
     * @type {string}
     * @memberof SocketMessageResponse
     */
    response: string;
    /**
     * 
     * @type {string}
     * @memberof SocketMessageResponse
     */
    headerText: string;
    /**
     * 
     * @type {string}
     * @memberof SocketMessageResponse
     */
    caption?: string;
    /**
     * 
     * @type {number}
     * @memberof SocketMessageResponse
     */
    progress: number;
    /**
     * this is structured data that a response payload may want
     * @type {object}
     * @memberof SocketMessageResponse
     */
    metadata?: object;
    /**
     * 
     * @type {Array<Source>}
     * @memberof SocketMessageResponse
     */
    sources: Array<Source>;
    /**
     * 
     * @type {Array<RelatedQuestion>}
     * @memberof SocketMessageResponse
     */
    relatedQuestions?: Array<RelatedQuestion>;
    /**
     * this is a lookup table for sources & annotations
     * @type {{ [key: string]: SocketMessageResponseAnnotationsValue; }}
     * @memberof SocketMessageResponse
     */
    annotations: { [key: string]: SocketMessageResponseAnnotationsValue; };
    /**
     * 
     * @type {Array<LoadingState>}
     * @memberof SocketMessageResponse
     */
    loadingStates?: Array<LoadingState>;
}



/**
 * Check if a given object implements the SocketMessageResponse interface.
 */
export function instanceOfSocketMessageResponse(value: object): value is SocketMessageResponse {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('responseVersion' in value) || value['responseVersion'] === undefined) return false;
    if (!('response' in value) || value['response'] === undefined) return false;
    if (!('headerText' in value) || value['headerText'] === undefined) return false;
    if (!('progress' in value) || value['progress'] === undefined) return false;
    if (!('sources' in value) || value['sources'] === undefined) return false;
    if (!('annotations' in value) || value['annotations'] === undefined) return false;
    return true;
}

export function SocketMessageResponseFromJSON(json: any): SocketMessageResponse {
    return SocketMessageResponseFromJSONTyped(json, false);
}

export function SocketMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocketMessageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ResponseMessageTypeFromJSON(json['type']),
        'responseVersion': json['response_version'],
        'response': json['response'],
        'headerText': json['header_text'],
        'caption': json['caption'] == null ? undefined : json['caption'],
        'progress': json['progress'],
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
        'sources': ((json['sources'] as Array<any>).map(SourceFromJSON)),
        'relatedQuestions': json['related_questions'] == null ? undefined : ((json['related_questions'] as Array<any>).map(RelatedQuestionFromJSON)),
        'annotations': (mapValues(json['annotations'], SocketMessageResponseAnnotationsValueFromJSON)),
        'loadingStates': json['loading_states'] == null ? undefined : ((json['loading_states'] as Array<any>).map(LoadingStateFromJSON)),
    };
}

export function SocketMessageResponseToJSON(json: any): SocketMessageResponse {
    return SocketMessageResponseToJSONTyped(json, false);
}

export function SocketMessageResponseToJSONTyped(value?: SocketMessageResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'type': ResponseMessageTypeToJSON(value['type']),
        'response_version': value['responseVersion'],
        'response': value['response'],
        'header_text': value['headerText'],
        'caption': value['caption'],
        'progress': value['progress'],
        'metadata': value['metadata'],
        'sources': ((value['sources'] as Array<any>).map(SourceToJSON)),
        'related_questions': value['relatedQuestions'] == null ? undefined : ((value['relatedQuestions'] as Array<any>).map(RelatedQuestionToJSON)),
        'annotations': (mapValues(value['annotations'], SocketMessageResponseAnnotationsValueToJSON)),
        'loading_states': value['loadingStates'] == null ? undefined : ((value['loadingStates'] as Array<any>).map(LoadingStateToJSON)),
    };
}

