/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SourceBadge } from './SourceBadge';
import {
    SourceBadgeFromJSON,
    SourceBadgeFromJSONTyped,
    SourceBadgeToJSON,
    SourceBadgeToJSONTyped,
} from './SourceBadge';

import { ExternalWebSource, ExternalWebSourceFromJSONTyped, ExternalWebSourceToJSON, ExternalWebSourceToJSONTyped } from './ExternalWebSource';
import { PdfKitSource, PdfKitSourceFromJSONTyped, PdfKitSourceToJSON, PdfKitSourceToJSONTyped } from './PdfKitSource';
/**
 * 
 * @export
 * @interface Source
 */
export interface Source {
    /**
     * The type of source. This can be 'base' for generic sources.
     * @type {string}
     * @memberof Source
     */
    sourceType: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    documentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    questionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    documentName?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    text: string;
    /**
     * 
     * @type {Array<SourceBadge>}
     * @memberof Source
     */
    badges?: Array<SourceBadge>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Source
     */
    metadata?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Source
     */
    footnote: number;
}

/**
 * Check if a given object implements the Source interface.
 */
export function instanceOfSource(value: object): value is Source {
    if (!('sourceType' in value) || value['sourceType'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('footnote' in value) || value['footnote'] === undefined) return false;
    return true;
}

export function SourceFromJSON(json: any): Source {
    return SourceFromJSONTyped(json, false);
}

export function SourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Source {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['source_type'] === 'external_web') {
            return ExternalWebSourceFromJSONTyped(json, ignoreDiscriminator);
        }
        if (json['source_type'] === 'pdf_kit') {
            return PdfKitSourceFromJSONTyped(json, ignoreDiscriminator);
        }
    }
    return {
        
        'sourceType': json['source_type'],
        'id': json['id'],
        'documentId': json['document_id'] == null ? undefined : json['document_id'],
        'questionId': json['question_id'] == null ? undefined : json['question_id'],
        'documentName': json['document_name'] == null ? undefined : json['document_name'],
        'text': json['text'],
        'badges': json['badges'] == null ? undefined : ((json['badges'] as Array<any>).map(SourceBadgeFromJSON)),
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
        'footnote': json['footnote'],
    };
}

export function SourceToJSON(json: any): Source {
    return SourceToJSONTyped(json, false);
}

export function SourceToJSONTyped(value?: Source | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (!ignoreDiscriminator) {
        switch (value['sourceType']) {
            case 'external_web':
                return ExternalWebSourceToJSONTyped(value as ExternalWebSource, ignoreDiscriminator);
            case 'pdf_kit':
                return PdfKitSourceToJSONTyped(value as PdfKitSource, ignoreDiscriminator);
            default:
                throw new Error(`No variant of Source exists with 'sourceType=${value['sourceType']}'`);
        }
    }

    return {
        
        'source_type': value['sourceType'],
        'id': value['id'],
        'document_id': value['documentId'],
        'question_id': value['questionId'],
        'document_name': value['documentName'],
        'text': value['text'],
        'badges': value['badges'] == null ? undefined : ((value['badges'] as Array<any>).map(SourceBadgeToJSON)),
        'metadata': value['metadata'],
        'footnote': value['footnote'],
    };
}

