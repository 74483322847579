import React from 'react'

import { ColumnDef, Row, Table } from '@tanstack/react-table'
import { HTTPError } from 'ky'
import { isNil } from 'lodash'

import { TagScope } from 'openapi/models/TagScope'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'

import { compareFileName } from 'utils/file-utils'
import { SafeRecord } from 'utils/safe-types'
import { displayWarningMessage } from 'utils/toast'

import { BaseAppPath } from 'components/base-app-path'
import { TrackEventFunction } from 'components/common/analytics/analytics-context'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import {
  DOCUMENT_CLASSIFICATION_TOOLTIP_TEXT,
  VaultItem,
  VaultItemStatus,
  VaultItemType,
  VaultItemWithIndex,
} from 'components/vault/utils/vault'
import { FetchVaultFolder } from 'components/vault/utils/vault-fetcher'
import {
  fetchFoldersMetadata,
  fileAsItem,
  filterAndSortFolders,
  folderAsItem,
  getContentTypeDisplayString,
  getDescendantFoldersForProject,
} from 'components/vault/utils/vault-helpers'
import { VaultError } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

import {
  VaultClassificationCell,
  VaultContentTypeCell,
  VaultLocationCell,
  VaultMenuCell,
  VaultNameCell,
  VaultSelectCell,
  VaultSelectHeader,
  VaultSizeCell,
  VaultTimeCell,
} from './vault-cells'
import {
  MISMATCH_MESSAGE_PLURAL,
  MISMATCH_MESSAGE_SINGULAR,
  WarningMessage,
} from './vault-file-explorer-warning-message'

/**
 * Handles row click events for vault file explorer
 */
export const handleVaultRowClick = (
  row: Row<VaultItem>,
  e: React.MouseEvent | React.KeyboardEvent,
  options: {
    selectedRows: VaultItemWithIndex[]
    setSelectedRows: (rows: VaultItemWithIndex[]) => void
    getPathForItem: (item: VaultItem) => string
    lastSelectedRow: VaultItemWithIndex | null
    setLastSelectedRow: (row: VaultItemWithIndex | null) => void
    handleShiftSelection: (row: Row<VaultItem>) => void
    isAddingFilesToQuery?: boolean
    existingSelectedFileIds?: Set<string>
    navigateToFile?: (fileId: string) => void
    navigateToFolder?: (folderId: string) => void
    clearSearchHandler?: () => void
    setSearchParams?: (
      updater: (prev: URLSearchParams) => URLSearchParams
    ) => void
    folderIdSearchParamKey?: string
    isSearching?: boolean
    setAreUploadButtonsDisabled?: (disabled: boolean) => void
    trackEvent?: TrackEventFunction
    shouldHideAllFiles?: boolean
  }
) => {
  const {
    selectedRows,
    setSelectedRows,
    getPathForItem,
    lastSelectedRow,
    setLastSelectedRow,
    handleShiftSelection,
    isAddingFilesToQuery = false,
    existingSelectedFileIds,
    navigateToFile,
    navigateToFolder,
    clearSearchHandler,
    setSearchParams,
    folderIdSearchParamKey,
    isSearching = false,
    setAreUploadButtonsDisabled,
    trackEvent,
    shouldHideAllFiles = false,
  } = options

  const isShiftKeyPressed = e.shiftKey
  if (
    row.original.status === VaultItemStatus.failedToUpload ||
    row.original.status === VaultItemStatus.unrecoverableFailure ||
    row.original.status === VaultItemStatus.recoverableFailure
  ) {
    return
  }
  if (
    isAddingFilesToQuery &&
    existingSelectedFileIds &&
    existingSelectedFileIds.has(row.original.id)
  ) {
    // if we're adding files to a query, and the current row is already selected, we don't select it again
    return
  }
  if (
    isAddingFilesToQuery &&
    row.original.type === VaultItemType.file &&
    row.original.status !== VaultItemStatus.readyToQuery
  ) {
    // if we're adding files to a query, and the current row is not ready to query, we don't select it
    return
  }
  if (
    row.original.type === VaultItemType.file &&
    (!isNil(row.original.url) || !isNil(row.original.docAsPdfUrl))
  ) {
    if (selectedRows.length > 0) {
      if (
        selectedRows.some(
          (selectedRow) =>
            selectedRow.type === row.original.type &&
            selectedRow.id === row.original.id
        )
      ) {
        // deselect if already selected
        setSelectedRows(
          selectedRows.filter(
            (selectedRow) =>
              selectedRow.type !== row.original.type ||
              selectedRow.id !== row.original.id
          )
        )
      } else {
        if (isShiftKeyPressed && lastSelectedRow !== null) {
          handleShiftSelection(row)
        } else {
          setSelectedRows([
            ...selectedRows,
            { ...row.original, index: getPathForItem(row.original) },
          ])
        }
      }
      // If some rows are already selected, we don't want to navigate to the file previewer.
      return
    }

    // if shift key is pressed and no rows are selected, we want to select the current row
    // or if we're adding files to a query, we want to select the current row
    if (isShiftKeyPressed || isAddingFilesToQuery) {
      setSelectedRows([
        ...selectedRows,
        { ...row.original, index: getPathForItem(row.original) },
      ])
      setLastSelectedRow({
        ...row.original,
        index: getPathForItem(row.original),
      })
      return
    }

    // navigate user to the file-previewer page
    if (navigateToFile && trackEvent) {
      const fileId = row.original.id
      trackEvent('Vault File Opened', {
        file_id: row.original.id,
      })

      const canOpenFile = row.original.status !== VaultItemStatus.uploading
      if (canOpenFile) {
        navigateToFile(fileId)
      } else {
        displayWarningMessage(
          `This file is not ready for previewing. Please try again later.`
        )
      }
    }
  } else if (row.original.type === VaultItemType.file) {
    displayWarningMessage(
      `This file is not ready for previewing. Please try again later.`
    )
  } else if (row.original.type === VaultItemType.folder) {
    if (
      isSearching &&
      clearSearchHandler &&
      setSearchParams &&
      folderIdSearchParamKey &&
      setAreUploadButtonsDisabled
    ) {
      clearSearchHandler()
      setAreUploadButtonsDisabled(false)
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        newParams.set(folderIdSearchParamKey, row.original.id)
        return newParams
      })
    } else if (
      isShiftKeyPressed &&
      !selectedRows.some(
        (selectedRow) =>
          selectedRow.type === row.original.type &&
          selectedRow.id === row.original.id
      )
    ) {
      // add all descendants to selection
      const descendantRows = row.getLeafRows().map((subRow) => ({
        ...subRow.original,
        index: getPathForItem(subRow.original),
      }))
      setSelectedRows([
        ...selectedRows,
        {
          ...row.original,
          index: getPathForItem(row.original),
          isAllDescendantsSelected: true,
        },
        ...descendantRows,
      ])
      // if no rows are selected, set the last selected row to the current row
      if (selectedRows.length === 0) {
        setLastSelectedRow({
          ...row.original,
          index: getPathForItem(row.original),
        })
      } else if (lastSelectedRow) {
        handleShiftSelection(row)
      }
    } else {
      const hasChildFolders = row
        .getLeafRows()
        .some((row) => row.original.type === VaultItemType.folder)
      const shouldDisallowTogglingFolder =
        shouldHideAllFiles && !hasChildFolders
      if (!shouldDisallowTogglingFolder) {
        if (navigateToFolder) {
          navigateToFolder(row.original.id)
        } else {
          row.toggleExpanded(!row.getIsExpanded())
        }
      }
    }
  } else {
    console.error('Unhandled row type', row.original.type)
  }
}

/**
 * Creates a shift selection handler for vault file explorer
 */
export const createShiftSelectionHandler = ({
  table,
  selectedRows,
  lastSelectedRow,
  getPathForItem,
  setSelectedRows,
}: {
  table: Table<VaultItem>
  selectedRows: VaultItemWithIndex[]
  lastSelectedRow: VaultItemWithIndex | null
  getPathForItem: (item: VaultItem) => string
  setSelectedRows: (rows: VaultItemWithIndex[]) => void
}) => {
  return (row: Row<VaultItem>) => {
    const dedupedFlatRows = table
      .getSortedRowModel()
      .flatRows.filter(
        (row: Row<VaultItem>, index: number, self: Row<VaultItem>[]) =>
          index === self.findIndex((r) => r.original.id === row.original.id)
      )
    const startIndex = dedupedFlatRows.findIndex(
      (r: Row<VaultItem>) => r.original.id === lastSelectedRow?.id
    )
    const endIndex = dedupedFlatRows.findIndex(
      (r: Row<VaultItem>) => r.original.id === row.original.id
    )
    const range = [startIndex, endIndex].sort((a, b) => a - b)

    const rowsInRange = dedupedFlatRows.slice(range[0], range[1] + 1)
    const vaultItemsInRange = rowsInRange
      .flatMap((row: Row<VaultItem>) => {
        const items = [
          {
            ...row.original,
            index: getPathForItem(row.original),
            isAllDescendantsSelected: row
              .getLeafRows()
              .every(
                (subRow: Row<VaultItem>) =>
                  rowsInRange.includes(subRow) ||
                  selectedRows.find(
                    (selectedRow) => selectedRow.id === subRow.original.id
                  )
              ),
          },
        ]
        // if last row selected is a folder, make sure to select all descendants
        const rowIndex = dedupedFlatRows.indexOf(row)
        if (row.original.type === 'folder' && rowIndex === endIndex) {
          items.push(
            ...row.getLeafRows().map((leafRow: Row<VaultItem>) => ({
              ...leafRow.original,
              index: getPathForItem(leafRow.original),
              isAllDescendantsSelected: leafRow.original.type === 'folder',
            }))
          )
        }
        return items
      })
      .filter(
        (vaultItem: VaultItemWithIndex) =>
          !selectedRows.some((selectedRow) => selectedRow.id === vaultItem.id)
      )
    setSelectedRows([...selectedRows, ...vaultItemsInRange])
  }
}

/**
 * Renders warning messages for file selection
 */
export const renderWarningMessages = (
  selectedRows: VaultItemWithIndex[],
  options: {
    numberOfFilesLimit?: number
    numberOfFilesWarningThreshold?: number
    showDocumentClassificationMismatchWarning?: boolean
    isAddingFilesToQuery?: boolean
    isAddingFilesToReviewQuery?: boolean
  }
) => {
  const {
    numberOfFilesLimit,
    numberOfFilesWarningThreshold,
    showDocumentClassificationMismatchWarning = false,
    isAddingFilesToQuery = false,
    isAddingFilesToReviewQuery = false,
  } = options

  const selectedFilesCount = selectedRows.filter(
    (row) => row.type === VaultItemType.file
  ).length
  const selectedFilesCountText = pluralizeFiles(selectedFilesCount)

  const shouldShowLargeQueryWarning =
    !isNil(numberOfFilesWarningThreshold) &&
    selectedRows.length >= numberOfFilesWarningThreshold &&
    isAddingFilesToQuery

  const exceedsFileLimit =
    !isNil(numberOfFilesLimit) && selectedRows.length > numberOfFilesLimit

  // Only show warning when adding files to a review query
  const showFileWarningMessage =
    (exceedsFileLimit || shouldShowLargeQueryWarning) &&
    isAddingFilesToReviewQuery

  if (showFileWarningMessage) {
    return (
      <WarningMessage
        isDestructive={exceedsFileLimit}
        message={
          exceedsFileLimit
            ? `File limit exceeded. Please select no more than ${pluralizeFiles(
                numberOfFilesLimit
              )}.`
            : `You are about to run a query on ${selectedFilesCountText}. This may take some time.`
        }
      />
    )
  }

  if (showDocumentClassificationMismatchWarning) {
    return (
      <WarningMessage
        isDestructive={false}
        message={
          selectedFilesCount === 1
            ? MISMATCH_MESSAGE_SINGULAR
            : MISMATCH_MESSAGE_PLURAL
        }
      />
    )
  }

  return null
}

/**
 * Creates a function to get the path for a vault item in the table structure
 */
export const createGetPathForItem = ({
  data,
  foldersMetadata,
  folderIdToVaultFolder,
  currentFolderId,
  projectId,
}: {
  data: VaultItem[]
  foldersMetadata: SafeRecord<string, VaultFolderMetadata>
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  currentFolderId: string | null
  projectId?: string
}) => {
  return (item: VaultItem): string => {
    const vaultItemsOnPath: VaultItem[] = []
    let currentItem: VaultItem | null = item

    // Build the path from the item up to the parent folder
    while (currentItem) {
      vaultItemsOnPath.push(currentItem)
      const parentFolderId =
        currentItem.type === VaultItemType.file
          ? currentItem.data.vaultFolderId
          : currentItem.data.parentId

      // Stop if we've reached the current folder or project
      const parentId = currentFolderId || projectId
      if (!parentFolderId || parentFolderId === parentId) break

      const parentFolder = folderIdToVaultFolder[parentFolderId]
      if (parentFolder) {
        currentItem = folderAsItem({
          folder: parentFolder,
          foldersMetadata,
          shouldExpand: false,
        })
      } else {
        currentItem = null
      }
    }

    // Traverse the path to find the indices in the data structure
    let currentItems: VaultItem[] = data
    return vaultItemsOnPath
      .reverse()
      .reduce((path, vaultItem) => {
        const index = currentItems.findIndex(
          (item) => item.id === vaultItem.id && item.type === vaultItem.type
        )
        if (index === -1) return path
        path.push(String(index))
        currentItem = currentItems[index]
        currentItems =
          currentItem.type !== VaultItemType.file
            ? currentItem.children ?? []
            : []
        return path
      }, [] as string[])
      .join('.')
  }
}

/**
 * Creates common column definitions for vault file explorer tables
 */
export const createVaultTableColumns = ({
  trackEvent,
  isAddingFilesToQuery = false,
  setLastSelectedRow,
  handleShiftSelection,
  fileIdToVaultFile,
  shouldHideAllFiles = false,
}: {
  trackEvent: TrackEventFunction
  isAddingFilesToQuery?: boolean
  setLastSelectedRow: (row: VaultItemWithIndex | null) => void
  handleShiftSelection: (row: Row<VaultItem>) => void
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  shouldHideAllFiles?: boolean
}): Array<ColumnDef<VaultItem>> => {
  const documentTypeMouseEnter = () => {
    trackEvent('Document type header hovered')
  }

  return [
    {
      id: 'select',
      header: ({ table }) => <VaultSelectHeader table={table} />,
      cell: ({ row }) => (
        <VaultSelectCell
          row={row}
          setLastSelectedRow={setLastSelectedRow}
          handleShiftSelection={handleShiftSelection}
          tooltip={
            shouldHideAllFiles && row.getLeafRows().length === 0
              ? 'No files in folder'
              : undefined
          }
        />
      ),
      enableSorting: false,
      size: 24,
    },
    {
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Name" />
      ),
      id: 'name',
      accessorKey: 'name',
      cell: ({ row }) => VaultNameCell({ row }),
      enableSorting: true,
      size: 280,
    },
    {
      header: ({ column }) => (
        <DataTableSortHeader
          column={column}
          header="Document type"
          tooltipPopoverClassName="max-w-48"
          tooltipDelay={200}
          tooltipText={DOCUMENT_CLASSIFICATION_TOOLTIP_TEXT}
          onMouseEnter={documentTypeMouseEnter}
        />
      ),
      id: 'documentType',
      accessorKey: 'tags',
      size: 128,
      enableResizing: false,
      cell: ({ row }) =>
        VaultClassificationCell({
          row,
          isAddingFilesToQuery,
          fileIdToVaultFile,
        }),
      sortingFn: (a, b) => {
        const aDocClassificationTag = a.original.tags?.find(
          (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
        )
        const bDocClassificationTag = b.original.tags?.find(
          (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
        )
        return (
          aDocClassificationTag?.name.localeCompare(
            bDocClassificationTag?.name ?? ''
          ) || 0
        )
      },
    },
    // Additional columns that can be conditionally included based on parameters
    {
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Updated" />
      ),
      id: 'updatedAt',
      accessorKey: 'updatedAt',
      cell: ({ row }) => VaultTimeCell({ row, timeKey: 'updatedAt' }),
      size: 100,
    },
    {
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="File type" />
      ),
      id: 'contentType',
      accessorKey: 'contentType',
      cell: ({ row }) => VaultContentTypeCell({ row }),
      size: 64,
      sortingFn: (a, b) => {
        return getContentTypeDisplayString(a.original).localeCompare(
          getContentTypeDisplayString(b.original)
        )
      },
    },
    {
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Size" />
      ),
      id: 'size',
      accessorKey: 'size',
      cell: ({ row }) => VaultSizeCell({ row }),
      size: 120,
    },
    {
      header: '',
      id: 'id',
      accessorKey: 'id',
      cell: ({ row }) => (
        <div className="flex w-full justify-center">
          <VaultMenuCell row={row} />
        </div>
      ),
      size: 40,
    },
    {
      header: 'Location',
      id: 'location',
      accessorKey: 'location',
      cell: ({ row }) => VaultLocationCell({ row }),
      size: 280,
    },
  ]
}

/**
 * Determines which columns to hide based on context
 */
export const getHiddenColumns = ({
  isSearching = false,
  isExampleProject = false,
  isAddingFilesToQuery = false,
  canCurrentUserSelectFiles = true,
  isDisplayingFilesProgress = false,
  shouldHideAllFiles = false,
}: {
  isSearching?: boolean
  isExampleProject?: boolean
  isAddingFilesToQuery?: boolean
  canCurrentUserSelectFiles?: boolean
  isDisplayingFilesProgress?: boolean
  shouldHideAllFiles?: boolean
}): string[] => {
  const columnsToHide = isSearching ? ['updatedAt', 'size', 'id'] : ['location']

  if (isExampleProject || !canCurrentUserSelectFiles) {
    columnsToHide.push('id')
    columnsToHide.push('select')
  }

  if (isAddingFilesToQuery) {
    columnsToHide.push('updatedAt')
    columnsToHide.push('id')
    columnsToHide.push('size')
    if (shouldHideAllFiles) {
      // Size and document type are not relevant if only showing folders
      columnsToHide.push('documentType')
    }
  }

  if (isDisplayingFilesProgress) {
    columnsToHide.push('id')
    columnsToHide.push('select')
    columnsToHide.push('updatedAt')
    columnsToHide.push('documentType')
  }

  return columnsToHide
}

/**
 * Prepares data for the vault file explorer
 */
export const prepareVaultExplorerData = ({
  projectId,
  currentFolderId,
  folderIdToVaultFolder,
  parentIdToVaultFolderIds,
  fileIdToVaultFile,
  folderIdToVaultFileIds,
  foldersMetadata,
  userId,
  exampleProjectIds,
  canUserAccessKnowledgeBaseProject,
  existingSelectedFileIds,
  isAddingFilesToQuery = false,
  isSharedProject = false,
  isSearching = false,
  searchValue = '',
  semanticSearchResults = [],
  projectIdToFolderIds = {},
  setNumRowsFound,
  shouldHideAllFiles = false,
  isVaultWorkspaceProjectsViewer,
}: {
  projectId?: string
  currentFolderId: string | null
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  parentIdToVaultFolderIds: SafeRecord<string, string[]>
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  folderIdToVaultFileIds: SafeRecord<string, string[]>
  foldersMetadata: SafeRecord<string, VaultFolderMetadata>
  userId: string
  exampleProjectIds: Set<string>
  canUserAccessKnowledgeBaseProject: boolean
  existingSelectedFileIds?: Set<string>
  isAddingFilesToQuery?: boolean
  isSharedProject?: boolean
  isSearching?: boolean
  searchValue?: string
  semanticSearchResults?: string[]
  projectIdToFolderIds?: SafeRecord<string, string[]>
  setNumRowsFound?: (count: number) => void
  shouldHideAllFiles?: boolean
  isVaultWorkspaceProjectsViewer: boolean
}): VaultItem[] => {
  if (!currentFolderId && !projectId) return []

  if (isSearching && projectId) {
    const projectFolderIds = new Set([
      projectId,
      ...(projectIdToFolderIds[projectId] ?? []),
    ])

    const localSearchedFiles = (
      Array.from(projectFolderIds)
        .flatMap((folderId) =>
          folderId ? folderIdToVaultFileIds[folderId] ?? [] : []
        )
        .map((fileId) => fileIdToVaultFile[fileId])
        .filter(Boolean) as VaultFile[]
    )
      .filter((file) =>
        file.name.toLowerCase().includes(searchValue.toLowerCase())
      )
      .sort(compareFileName)

    const localSearchedFolders = (
      projectId
        ? getDescendantFoldersForProject(
            projectId,
            projectIdToFolderIds,
            folderIdToVaultFolder
          )
        : []
    )
      .filter((folder) =>
        folder.name.toLowerCase().includes(searchValue.toLowerCase())
      )
      .sort(compareFileName)

    const localSearchedRows = [
      ...localSearchedFolders.map((folder: VaultFolder) =>
        folderAsItem({
          folder,
          foldersMetadata,
          shouldExpand: false,
          // If we are hiding all files, there is no purpose in showing empty folders
          shouldHideEmptyFolders: shouldHideAllFiles,
        })
      ),
      ...localSearchedFiles.map((file) => fileAsItem(file)),
    ]

    const semanticSearchedRows = (semanticSearchResults ?? [])
      .map((fileId) => fileIdToVaultFile[fileId])
      .filter((file) => file && projectFolderIds.has(file.vaultFolderId))
      .filter((file) => !localSearchedFiles.some((f) => f.id === file!.id))
      .map((file) => fileAsItem(file!))

    if (setNumRowsFound) {
      setNumRowsFound(localSearchedRows.length + semanticSearchedRows.length)
    }

    return [...localSearchedRows, ...semanticSearchedRows]
  }

  const parentId = currentFolderId || projectId
  if (!parentId) return []

  const filteredAndSortedFolders = filterAndSortFolders({
    folderIdToVaultFolder,
    parentIdToVaultFolderIds,
    userId,
    parentId,
    projectId: projectId!,
    exampleProjectIds,
    isSharedProject,
    canUserAccessKnowledgeBaseProject,
    fileIdToVaultFile,
    folderIdToVaultFileIds,
    isVaultWorkspaceProjectsViewer,
  })

  const folderFiles = (
    (folderIdToVaultFileIds[parentId] ?? [])
      .map((fileId) => fileIdToVaultFile[fileId])
      .filter(Boolean) as VaultFile[]
  ).sort(compareFileName)

  return [
    ...filteredAndSortedFolders.map((folder: VaultFolder) =>
      folderAsItem({
        folder,
        foldersMetadata,
        shouldExpand: true,
        folderIdToVaultFolder,
        parentIdToVaultFolderIds,
        fileIdToVaultFile,
        folderIdToVaultFileIds,
        existingSelectedFileIds,
        isAddingFilesToQuery,
        shouldHideEmptyFolders: shouldHideAllFiles,
      })
    ),
    ...folderFiles.map((file) =>
      fileAsItem(file, existingSelectedFileIds, isAddingFilesToQuery)
    ),
  ]
}

/**
 * Creates a function to poll project data
 */
export const createProjectDataPoller = async ({
  projectId,
  setCurrentProject,
  upsertVaultFolders,
  upsertVaultFiles,
  addToProjectsMetadata,
  userId,
  exampleProjectIds,
  deleteVaultFiles,
  deleteVaultFolders,
  setError,
  projectIdToFolderIds,
  folderIdToVaultFolder,
}: {
  projectId?: string
  setCurrentProject?: (project: VaultFolder) => void
  upsertVaultFolders: (
    folders: VaultFolder[],
    userId: string,
    shouldUpdateLastAccessed: boolean,
    projectId?: string
  ) => void
  upsertVaultFiles: (files: VaultFile[], projectId?: string) => void
  addToProjectsMetadata: (metadata: Record<string, any>) => void
  userId: string
  exampleProjectIds: Set<string>
  deleteVaultFiles: (fileIds: string[], projectId?: string) => void
  deleteVaultFolders: (folderIds: string[], projectId?: string) => void
  setError: (error: VaultError | null) => void
  projectIdToFolderIds: SafeRecord<string, string[]>
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
}) => {
  if (!projectId) return
  try {
    const project = await FetchVaultFolder(projectId, true)
    if (setCurrentProject) {
      setCurrentProject(project)
    }

    // fetch the metadata for the folder and its subfolders and files
    const projectMetadataResponse = await fetchFoldersMetadata(project)
    const projectData = projectMetadataResponse[projectId]
    const descendantFolders = getDescendantFoldersForProject(
      projectId,
      projectIdToFolderIds,
      folderIdToVaultFolder
    )

    upsertVaultFolders(
      [project, ...descendantFolders],
      userId,
      !exampleProjectIds.has(projectId),
      projectId
    )
    upsertVaultFiles(projectData.descendantFiles || [], projectId)
    addToProjectsMetadata(projectMetadataResponse)
  } catch (e) {
    console.error(e)
    if (e instanceof HTTPError && e.response.status === 404) {
      // Show error message and delete project data if project no longer found
      setError({
        message: `The requested Vault project does not exist.\nContact support@harvey.ai if this issue persists.`,
        cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
      })
      deleteVaultFiles(projectIdToFolderIds[projectId] ?? [], projectId)
      deleteVaultFolders(projectIdToFolderIds[projectId] ?? [], projectId)
    }
  }
}

/**
 * Creates a row selection handler for the table
 */
export const createRowSelectionHandler = ({
  table,
  rowSelection,
  setSelectedRows,
}: {
  table: Table<VaultItem>
  rowSelection: Record<string, boolean>
  setSelectedRows: (rows: VaultItemWithIndex[]) => void
}) => {
  return (
    updaterOrValue:
      | Record<string, boolean>
      | ((old: Record<string, boolean>) => Record<string, boolean>)
  ) => {
    let newRowSelection = updaterOrValue
    if (updaterOrValue instanceof Function) {
      newRowSelection = updaterOrValue(rowSelection)
    }

    // Don't select disabled rows
    for (const index in newRowSelection) {
      const row = table.getRow(index)
      if (
        row.original.disabled &&
        (newRowSelection as Record<string, boolean>)[index]
      ) {
        ;(newRowSelection as Record<string, boolean>)[index] = false
      }
    }

    const getItemByIndex = (
      index: string,
      newRowSelection: Record<string, boolean>
    ): VaultItemWithIndex | null => {
      const row = table.getRow(index)
      const isAllDescendantsSelected = row
        .getLeafRows()
        .every((subRow) => newRowSelection[subRow.id])
      const item = { ...row.original, index, isAllDescendantsSelected }
      return item
    }

    const newSelectedRows = Object.entries(newRowSelection)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([index, isSelected]) => isSelected)
      .map(([index]) =>
        getItemByIndex(index, newRowSelection as Record<string, boolean>)
      )
      .filter(Boolean) as VaultItemWithIndex[]

    setSelectedRows(newSelectedRows)
  }
}
