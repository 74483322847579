import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
  forwardRef,
} from 'react'

import { Command } from 'cmdk'
import { useRecentPages } from 'contexts/recent-pages-context'
import { subDays } from 'date-fns'
import {
  AlignLeft,
  ArrowDown,
  ArrowUp,
  CornerDownLeft,
  Search,
  FileText,
  LucideIcon,
  SquareMousePointer,
} from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { HistoryTypeEnum } from 'models/helpers/history-helper'
import { useHistoryPollingQuery } from 'models/queries/use-history-polling-query'
import { useGeneralStore } from 'stores/general-store'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { getRouteForEvent } from 'utils/routing'
import { getQueryForDisplay } from 'utils/task-definitions'
import { cn } from 'utils/utils'

import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { useLoadWorkflowsQuery } from 'components/assistant/workflows/workflow-loader'
import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import {
  REMOVE_PARAMS,
  projectsPath,
  filesPath,
} from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'

const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent)

export const CommandPaletteButton: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [isSidebarOpen] = useGeneralStore(useShallow((s) => [s.isSidebarOpen]))

  return (
    <>
      <Button
        size="icon"
        variant="unstyled"
        className={cn(
          'flex h-8 w-full cursor-pointer items-center rounded-md text-primary transition hover:bg-accent-hover active:bg-accent-hover active:text-primary',
          {
            'w-9 justify-center': !isSidebarOpen,
            'justify-between px-2.5': isSidebarOpen,
          }
        )}
        onClick={() => setOpen(true)}
      >
        <div className="flex items-center">
          <Search className="size-4 text-secondary" />
          {isSidebarOpen && (
            <span className="ml-2 text-sm text-secondary">Search</span>
          )}
        </div>
        {isSidebarOpen && (
          <div className="flex items-center px-1.5 py-0.5 text-xs font-medium text-muted">
            {isMac ? '⌘ K' : 'Ctrl+K'}
          </div>
        )}
      </Button>
      <CommandPalette isOpen={open} onOpenChange={setOpen} />
    </>
  )
}

const DisplayButton: React.FC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'grid size-6 place-items-center rounded-md bg-button-secondary text-xs text-muted',
        className
      )}
    >
      {children}
    </div>
  )
}

const CommandHeading: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <span className="px-2 text-xs font-medium text-muted">{children}</span>
}

interface CommandItemProps extends ComponentProps<typeof Command.Item> {}
export const CommandItem = ({
  className,
  children,
  ...props
}: CommandItemProps) => {
  return (
    <Command.Item
      {...props}
      className={cn(
        'group flex cursor-pointer items-center rounded-sm p-2 text-sm text-primary outline-none transition hover:bg-secondary aria-selected:bg-accent',
        className
      )}
    >
      {children}
    </Command.Item>
  )
}
interface CommandInputProps extends ComponentProps<typeof Command.Input> {}
export const CommandInput = forwardRef<HTMLInputElement, CommandInputProps>(
  ({ className, ...props }, ref) => {
    return (
      <Command.Input
        ref={ref}
        {...props}
        className={cn(
          'flex h-10 w-full rounded-md bg-secondary p-2 text-sm outline-none placeholder:text-muted disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
      />
    )
  }
)
CommandInput.displayName = 'CommandInput'

const CommandItemContent: React.FC<{
  children: React.ReactNode
  detail?: React.ReactNode
  icon?: LucideIcon
}> = ({ children, detail, icon }) => {
  return (
    <span className="relative flex w-full min-w-0 items-center gap-2">
      {icon && <Icon icon={icon} className="size-4 shrink-0" />}
      <span className="mr-4 flex flex-1 items-center justify-between">
        <span className="truncate text-sm">{children}</span>
        {detail && (
          <span className="-mr-4 ml-3 shrink-0 text-xs text-muted opacity-70 transition group-[&[data-selected=true]]:opacity-0">
            {detail}
          </span>
        )}
      </span>
      <CornerDownLeft className="absolute right-0 top-1/2 size-3 -translate-y-1/2 text-muted opacity-0 transition-opacity group-[&[data-selected=true]]:opacity-100" />
    </span>
  )
}

const CommandFooter = ({
  currentPage,
}: {
  currentPage: CommandPalettePage
}) => {
  switch (currentPage) {
    case CommandPalettePage.Root:
    default:
      return (
        <div className="flex h-12 items-center justify-between border-t-[.5px] p-3">
          <div className="flex w-full items-center justify-between">
            <span className="flex items-center gap-2 text-xs font-medium text-muted">
              <span className="flex items-center gap-1">
                <DisplayButton>
                  <ArrowDown className="size-3" />
                </DisplayButton>
                <DisplayButton>
                  <ArrowUp className="size-3" />
                </DisplayButton>
              </span>
              Select
            </span>

            <div className="flex items-center gap-6">
              <span className="flex items-center gap-2 text-xs font-medium text-muted">
                <DisplayButton className="w-8">Esc</DisplayButton>
                Exit
              </span>
              <span className="flex items-center gap-2 text-xs font-medium text-muted">
                <DisplayButton>
                  <CornerDownLeft className="size-3" />
                </DisplayButton>
                Choose
              </span>
            </div>
          </div>
        </div>
      )
  }
}

const ITEM_LIMIT = 10
const VAULT_PROJECTS_TO_FETCH = 5
enum CommandPalettePage {
  Root = 'root',
  AssistantChat = 'assistant-chat',
  AssistantDraft = 'assistant-draft',
}
interface CommandPaletteProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}
const CommandPalette: React.FC<CommandPaletteProps> = ({
  isOpen,
  onOpenChange,
}) => {
  const [page, setPage] = useState<CommandPalettePage[]>([
    CommandPalettePage.Root,
  ])
  const { reset: resetWorkflowStore } = useAssistantWorkflowStore(
    useShallow((s) => ({
      reset: s.reset,
    }))
  )
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()
  const [search, setSearch] = useState('')
  const { data: historyData, isPending: isHistoryPending } =
    useHistoryPollingQuery({
      fetchHistoryArgs: {
        currentPage: 1,
        pageSize: ITEM_LIMIT,
        workspaceSlug: userInfo.workspace.slug,
        historyType: HistoryTypeEnum.USER,
      },
      oldestDate: subDays(new Date(), 7),
      queryOptions: {
        refetchInterval: 10000,
      },
    })

  const {
    data: workflows,
    isPending: isWorkflowsPending,
    isError: isWorkflowsError,
  } = useLoadWorkflowsQuery()
  const { allVaultProjects: vaultProjects } = useVaultProjects()
  const projectIdToProjectName = vaultProjects.reduce(
    (acc, project) => {
      acc[project.id] = project.name
      return acc
    },
    {} as Record<string, string>
  )
  const allFileIdToVaultFile = useVaultStore(
    (state) => state.allFileIdToVaultFile
  )
  const allFileNamesAndIds = Object.entries(allFileIdToVaultFile).map(
    ([fileId, file]) => ({
      id: fileId,
      name: file?.name,
      vaultProjectId: file?.vaultProjectId,
      vaultProjectName: projectIdToProjectName[file?.vaultProjectId || ''],
    })
  )
  const { recentPages } = useRecentPages()

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey || e.ctrlKey) {
        if (e.key === 'k') {
          e.preventDefault()
          onOpenChange(!isOpen)
        }
      } else if (e.key === 'Escape') {
        e.preventDefault()
        onOpenChange(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isOpen, onOpenChange, page, search])

  useEffect(() => {
    setSearch('')
    setPage([CommandPalettePage.Root])
  }, [isOpen])

  const handleSelect = useCallback(
    (value: string) => {
      if (value.startsWith('/assistant/workflows/')) {
        // handle workflow bug
        resetWorkflowStore()
      }
      navigate(value, {}, REMOVE_PARAMS)
      onOpenChange(false)
    },
    [navigate, onOpenChange, resetWorkflowStore]
  )

  const recentlyViewed = recentPages
    .filter(
      (page) =>
        page.details && (page.label === 'Assistant' || page.label === 'Vault')
    )
    .slice(0, 5)

  const workflowItems =
    !isWorkflowsError &&
    workflows
      ?.filter(
        (workflow) =>
          !recentlyViewed.some(
            (page) => page.path === `/assistant/workflows/${workflow.slug}`
          )
      )
      .slice(0, ITEM_LIMIT)

  const vaultItems = vaultProjects
    .filter(
      (project) =>
        !recentlyViewed.some(
          (page) =>
            page.path === `${BaseAppPath.Vault}${projectsPath}${project.id}`
        )
    )

    .slice(0, search ? undefined : VAULT_PROJECTS_TO_FETCH)

  const pageContent = useMemo(() => {
    switch (page[page.length - 1]) {
      case CommandPalettePage.Root:
        return (
          <>
            <Command.Empty className="py-6 text-center text-sm">
              No results found.
            </Command.Empty>
            {isHistoryPending && (
              <Command.Loading>Loading recent threads...</Command.Loading>
            )}
            {isWorkflowsPending && (
              <Command.Loading>Loading workflows...</Command.Loading>
            )}
            <Command.Group>
              {recentlyViewed.map((page) => (
                <CommandItem
                  onSelect={handleSelect}
                  key={page.path}
                  value={page.path}
                >
                  <CommandItemContent
                    icon={
                      page.label === 'Assistant'
                        ? SquareMousePointer
                        : (FolderShieldIcon as LucideIcon)
                    }
                  >
                    {page.details}
                  </CommandItemContent>
                </CommandItem>
              ))}
            </Command.Group>
            {historyData.length > 0 && (
              <Command.Group
                className="mt-2"
                heading={<CommandHeading>Recent threads</CommandHeading>}
              >
                {historyData.slice(0, ITEM_LIMIT).map((item) => (
                  <CommandItem
                    key={`recent-${item.id}`}
                    value={getRouteForEvent(item, userInfo)}
                    onSelect={handleSelect}
                    keywords={getQueryForDisplay(item)
                      .split(' ')
                      .filter(Boolean)}
                  >
                    <CommandItemContent
                      icon={AlignLeft}
                      detail={new Date(
                        item.effectiveUpdatedAt
                      ).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    >
                      {getQueryForDisplay(item)}
                    </CommandItemContent>
                  </CommandItem>
                ))}
                {historyData.length > ITEM_LIMIT && (
                  <CommandItem
                    value="view-all-history"
                    onSelect={() => handleSelect('/history')}
                  >
                    <CommandItemContent>
                      <span className="text-sm text-muted">View all...</span>
                    </CommandItemContent>
                  </CommandItem>
                )}
              </Command.Group>
            )}
            {workflowItems && workflowItems.length > 0 && (
              <Command.Group
                className="mt-2"
                heading={<CommandHeading>Workflows</CommandHeading>}
              >
                {workflowItems.map((workflow) => (
                  <CommandItem
                    key={workflow.id}
                    value={`/assistant/workflows/${workflow.slug}`}
                    onSelect={() => {
                      // Need to reset to clear currentEvent, causes an issue somewhere in the
                      // workflow store otherwise.
                      resetWorkflowStore()
                      handleSelect(`/assistant/workflows/${workflow.slug}`)
                    }}
                    keywords={workflow.name.split(' ').filter(Boolean)}
                  >
                    <CommandItemContent icon={SquareMousePointer}>
                      {workflow.name}
                    </CommandItemContent>
                  </CommandItem>
                ))}
                {historyData.length > ITEM_LIMIT && (
                  <CommandItem
                    value="view-all-workflows"
                    onSelect={() => handleSelect('/workflows')}
                  >
                    <CommandItemContent>
                      <span className="text-sm text-muted">View all...</span>
                    </CommandItemContent>
                  </CommandItem>
                )}
              </Command.Group>
            )}

            {vaultItems.length > 0 && (
              <Command.Group
                className="mt-2"
                heading={<CommandHeading>Vault Projects</CommandHeading>}
              >
                {vaultItems.map((project) => (
                  <CommandItem
                    key={`vault-${project.id}`}
                    value={`${BaseAppPath.Vault}${projectsPath}${project.id}`}
                    keywords={project.name.split(' ').filter(Boolean)}
                    onSelect={handleSelect}
                  >
                    <CommandItemContent icon={FolderShieldIcon as LucideIcon}>
                      {project.name}
                    </CommandItemContent>
                  </CommandItem>
                ))}
              </Command.Group>
            )}
            {search && (
              <Command.Group
                className="mt-2"
                heading={<CommandHeading>Files</CommandHeading>}
              >
                {allFileNamesAndIds.map((file) => (
                  <CommandItem
                    key={file.id}
                    value={`${BaseAppPath.Vault}${projectsPath}${file.vaultProjectId}${filesPath}${file.id}`}
                    keywords={file.name?.split(' ').filter(Boolean)}
                    onSelect={handleSelect}
                  >
                    <CommandItemContent
                      detail={file.vaultProjectName}
                      icon={FileText}
                    >
                      {file.name}
                    </CommandItemContent>
                  </CommandItem>
                ))}
              </Command.Group>
            )}
          </>
        )
    }
  }, [
    handleSelect,
    historyData,
    isHistoryPending,
    isWorkflowsPending,
    page,
    resetWorkflowStore,
    search,
    userInfo,
    recentlyViewed,
    allFileNamesAndIds,
    vaultItems,
    workflowItems,
  ])

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className="max-w-xl overflow-hidden rounded-xl border shadow-sm outline-none"
        innerClassName="p-0"
        showCloseIcon={false}
      >
        <Command
          className="flex h-full flex-col"
          onKeyDown={(e) => {
            // Escape goes to previous page
            // Backspace goes to previous page when search is empty
            if (
              (e.key === 'Escape' || (e.key === 'Backspace' && !search)) &&
              page.length > 1
            ) {
              e.preventDefault()
              if (page.length > 1) {
                setPage((pages) => pages.slice(0, -1))
              } else if (e.key === 'Escape') {
                onOpenChange(false)
              }
            }
          }}
        >
          <div className="relative p-3">
            <CommandInput
              placeholder="Search Harvey…"
              value={search}
              onValueChange={setSearch}
              tabIndex={-1}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            <div className="absolute right-7 top-1/2 flex -translate-y-1/2 items-center text-xs font-medium text-muted">
              {isMac ? '⌘ K' : 'Ctrl+K'}
            </div>
          </div>
          <Command.List className="h-[440px] grow overflow-y-auto overflow-x-hidden  px-2 pb-3 transition duration-100">
            {pageContent}
          </Command.List>
          <CommandFooter currentPage={page[page.length - 1]} />
        </Command>
      </DialogContent>
    </Dialog>
  )
}

export default CommandPalette
