// This file is used to import all the markdown files and export them as an object.'
import {
  Announcement,
  AudienceType,
  PersistanceBehavior,
  AnnouncementType,
} from 'components/common/announcements/announcement.types'
import agentic_workflows_announcement_user from 'components/common/announcements/data/content/agentic-workflows-announcement-user'
import client_matter_announcement_admin from 'components/common/announcements/data/content/client-matter-announcement-admin'
// Markdown content imports
import client_matter_announcement_user from 'components/common/announcements/data/content/client-matter-announcement-user'
import test_long from 'components/common/announcements/data/content/test-long'
import test_short from 'components/common/announcements/data/content/test-short'
// Product tour imports
import { runClientMatterAdminTour } from 'components/common/product-tours/client-matter-tour-admin'
import { runClientMatterUserTour } from 'components/common/product-tours/client-matter-tour-user'
import { runKnowledgeBaseTour } from 'components/common/product-tours/knowledge-base-tour'
import { runKnowledgeSourceTour } from 'components/common/product-tours/knowledge-source-tour'
import { runWorkflowsProductTour } from 'components/common/product-tours/run-workflows-product-tour'
import { runSelfServePermissionsTour } from 'components/common/product-tours/self-serve-permissions-tour'
import {
  navigateToIntegrationsPage,
  openNetdocsMailTo,
} from 'components/settings/integrations/utils'

import { assistantSurveyAnnouncement } from './content/assistant-survey-announcement'
import { harveyForWordAnnouncement } from './content/harvey-for-word-announcement'
import { knowledgeBaseAnnouncement } from './content/knowledge-base-announcement'
import { knowledgeSourceAnnouncement } from './content/knowledge-source-announcement'
import { knowledgeSourceDeprecationAnnouncement } from './content/knowledge-source-deprecation-announcement'
import { vaultUpgradeAnnouncement } from './content/vault-upgrade-announcement'
import {
  isClientMatterTourEnabled,
  navigateToAssistantFeedbackForm,
  navigateToVaultUpgrade,
} from './utils'

// List of all announcements
// Add an element to this list below to add a new announcement
const AnnouncementList: Announcement[] = [
  {
    enabled: true,
    allowDismiss: true,
    zIndex: 10,
    announcementKey: 'vault-upgrades',
    cardTitle: 'Vault upgrades',
    cardDescription: 'Unlimited projects with faster upload speeds',
    actionButtonLabel: 'Try it out',
    announcementType: AnnouncementType.MODAL,
    modalContent: vaultUpgradeAnnouncement,
    announcementAction: navigateToVaultUpgrade,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      startDate: new Date('2025-02-05'),
      endDate: new Date('2025-03-14'),
      persistanceBehavior: PersistanceBehavior.HIDE_AFTER_VIEWED,
      customValidator: (userInfo) => {
        // only display for vault users and NOT A&O workspace
        return userInfo.IsVaultUser && userInfo.workspace.id !== 1
      },
    },
  },
  {
    enabled: false,
    announcementKey: 'test-short',
    cardTitle: 'Test Short Announcement',
    cardDescription: 'This is a fun announcement',
    announcementType: AnnouncementType.MODAL,
    modalContent: test_short,
    criteria: {
      startDate: new Date('2022-02-01'),
      endDate: new Date('2022-02-28'),
      audienceType: AudienceType.ONLY_USERS_CREATED_BEFORE_ANNOUNCEMENT,
      customValidator: (userInfo) => {
        return Boolean(userInfo)
      },
    },
    allowDismiss: true,
  },
  {
    enabled: false,
    announcementKey: 'test-long',
    cardTitle: 'Test Long Announcement',
    cardDescription: 'This is a second announcement',
    announcementType: AnnouncementType.MODAL,
    modalContent: test_long,
    modalHeight: 'h-[80vh]',
    modalWidth: 'max-w-4xl',
    criteria: {
      startDate: new Date('2022-02-01'),
      endDate: new Date('2022-02-28'),
      audienceType: AudienceType.ONLY_USERS_CREATED_BEFORE_ANNOUNCEMENT,
    },
    allowDismiss: true,
  },
  {
    enabled: true,
    announcementKey: 'client-matter-announcement-user',
    cardTitle: 'Client matter',
    cardDescription: 'Attach client matter numbers to queries',
    announcementType: AnnouncementType.MODAL,
    modalContent: client_matter_announcement_user,
    modalHeight: '',
    modalWidth: '',
    criteria: {
      audienceType: AudienceType.EVERYONE,
      persistanceBehavior: PersistanceBehavior.HIDE_AFTER_VIEWED,
      customValidator: (userInfo) => {
        return !userInfo.IsTerritoryAdmin && isClientMatterTourEnabled(userInfo)
        // Note: Using this would only tell us about user specific perms: userInfo.permissions.includes(Permission.CLIENT_MATTERS_READ)
      },
    },
    allowDismiss: true,
    announcementAction: runClientMatterUserTour,
  },
  {
    enabled: true,
    announcementKey: 'client-matter-announcement-admin',
    cardTitle: 'Client matter',
    cardDescription: 'Attach client matter numbers to queries',
    announcementType: AnnouncementType.MODAL,
    modalContent: client_matter_announcement_admin,
    zIndex: 11,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      persistanceBehavior: PersistanceBehavior.HIDE_AFTER_VIEWED,
      customValidator: (userInfo) => {
        return userInfo.IsTerritoryAdmin && isClientMatterTourEnabled(userInfo)
      },
    },
    allowDismiss: true,
    announcementAction: runClientMatterAdminTour,
  },
  {
    enabled: false,
    announcementKey: 'assistant-survey',
    cardTitle: 'Assistant Feedback',
    cardDescription: 'Share your thoughts on the new Assistant',
    actionButtonLabel: 'Share',
    announcementType: AnnouncementType.MODAL,
    modalContent: assistantSurveyAnnouncement,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      customValidator: (userInfo) => {
        return userInfo.IsAssistantV2User && userInfo.IsAssistantV2SurveyUser
      },
    },
    allowDismiss: true,
    autoOpen: true,
    announcementAction: navigateToAssistantFeedbackForm,
  },
  {
    enabled: false,
    announcementKey: 'knowledge-sources',
    cardTitle: 'Research modules have moved',
    cardDescription: 'Research modules have moved over to Assistant',
    actionButtonLabel: 'Learn more',
    announcementType: AnnouncementType.MODAL,
    modalContent: knowledgeSourceAnnouncement,
    criteria: {
      audienceType: AudienceType.EVERYONE,
    },
    allowDismiss: true,
    announcementAction: runKnowledgeSourceTour,
  },
  {
    enabled: true,
    announcementKey: 'knowledge-sources-deprecation',
    cardTitle: 'Research tab removal',
    cardDescription: 'The Research tab will be removed on Dec. 12.',
    announcementType: AnnouncementType.MODAL,
    modalContent: knowledgeSourceDeprecationAnnouncement,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      persistanceBehavior: PersistanceBehavior.HIDE_AFTER_VIEWED,
      endDate: new Date('2024-12-12'),
    },
    actionButtonLabel: 'Learn More',
    allowDismiss: true,
    hideActionButton: true,
  },
  {
    enabled: true,
    announcementKey: 'harvey-for-word',
    cardTitle: 'Use Harvey for Word',
    cardDescription:
      'Install the Microsoft Word Add-In to bring Harvey’s expertise directly into your documents.',
    announcementType: AnnouncementType.MODAL,
    modalContent: harveyForWordAnnouncement,
    modalWidth: 'max-w-[580px]',
    criteria: {
      audienceType: AudienceType.EVERYONE,
      customValidator: (userInfo) => {
        return userInfo.canViewWordAddInAnnouncement
      },
      startDate: new Date('2024-12-05'),
      endDate: new Date('2025-02-18'),
      persistanceBehavior: PersistanceBehavior.PERSIST_UNTIL_DISMISSED,
    },
    allowDismiss: true,
    actionButtonLabel: 'Get Started',
    zIndex: 2,
  },
  {
    enabled: true,
    announcementKey: 'google-drive-integration1',
    cardTitle: 'Connect Harvey with Google Drive',
    cardDescription:
      '[Visible to admins only] Once you enable this integration, users will be able to access their stored files directly within Harvey.',
    announcementType: AnnouncementType.NO_MODAL,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      customValidator: (userInfo) => {
        return userInfo.canViewGdriveAnnouncement && userInfo.isIntegrationAdmin
      },
      startDate: new Date('2025-02-03'),
      endDate: new Date('2025-02-13'),
      persistanceBehavior: PersistanceBehavior.PERSIST_UNTIL_DISMISSED,
    },
    allowDismiss: true,
    announcementAction: (navigate) => {
      navigateToIntegrationsPage(navigate)
    },
    zIndex: 3,
  },
  {
    enabled: false,
    announcementKey: 'netdocs-extension',
    cardTitle: 'Use NetDocuments with Harvey',
    cardDescription:
      'The NetDocuments browser extension now works with Harvey. This enables direct file uploads and downloads for your users. Reach out to Harvey to get started.',
    announcementType: AnnouncementType.NO_MODAL,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      customValidator: (userInfo) => {
        return userInfo.IsTerritoryAdmin
      },
      startDate: new Date('2025-02-12'),
      endDate: new Date('2025-02-19'),
      persistanceBehavior: PersistanceBehavior.PERSIST_UNTIL_DISMISSED,
    },
    allowDismiss: true,
    announcementAction: () => {
      openNetdocsMailTo('support@harvey.ai')
    },
    zIndex: 4,
  },
  {
    enabled: true,
    announcementKey: 'self-serve-permissions-view',
    cardTitle: 'View your users’ permissions',
    cardSubtitle: 'Only visible to admins',
    cardDescription:
      'You can now see permissions for all users in your workspace. To update their permissions, contact your Harvey admin for manage access.',
    announcementType: AnnouncementType.NO_MODAL,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      customValidator: (userInfo) => {
        return (
          userInfo.IsClientAdminSelfServePermsViewer &&
          !userInfo.IsClientAdminSelfServePermsWriter // Show write announcement instead below
        )
      },
      startDate: new Date('2025-03-20'),
      endDate: new Date('2025-03-27'),
      persistanceBehavior: PersistanceBehavior.PERSIST_UNTIL_DISMISSED,
    },
    allowDismiss: true,
    announcementAction: runSelfServePermissionsTour,
    zIndex: 5,
  },
  {
    enabled: true,
    announcementKey: 'self-serve-permissions-write',
    cardTitle: 'Manage your users’ permissions',
    cardSubtitle: 'Only visible to admins',
    cardDescription:
      'You now have full control to manage your users’ permissions and their access to Harvey.',
    announcementType: AnnouncementType.NO_MODAL,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      customValidator: (userInfo) => {
        return userInfo.IsClientAdminSelfServePermsWriter
      },
      startDate: new Date('2025-03-20'),
      endDate: new Date('2025-03-27'),
      persistanceBehavior: PersistanceBehavior.PERSIST_UNTIL_DISMISSED,
    },
    allowDismiss: true,
    announcementAction: runSelfServePermissionsTour,
    zIndex: 6,
  },
  {
    enabled: true,
    announcementKey: 'knowledge-base-projects',
    cardTitle: 'Knowledge bases',
    cardDescription: 'Distribute internal knowledge across your organization',
    announcementType: AnnouncementType.MODAL,
    modalContent: knowledgeBaseAnnouncement,
    criteria: {
      audienceType: AudienceType.EVERYONE,
      customValidator: (userInfo) => {
        return (
          userInfo.IsVaultUser &&
          userInfo.IsCreateKnowledgeBaseProjectUser &&
          userInfo.workspace.sharingSettings.vault.enabled
        )
      },
      persistanceBehavior: PersistanceBehavior.PERSIST_UNTIL_DISMISSED,
    },
    allowDismiss: true,
    announcementAction: runKnowledgeBaseTour,
    zIndex: 12,
  },
  {
    enabled: true,
    announcementKey: 'agentic-workflows-announcement-user',
    cardTitle: 'New Assistant Workflows',
    cardDescription:
      'Explore agentic workflows, trained for specialized use cases',
    announcementType: AnnouncementType.MODAL,
    modalContent: agentic_workflows_announcement_user,
    modalHeight: '',
    modalWidth: '',
    criteria: {
      audienceType: AudienceType.EVERYONE,
      persistanceBehavior: PersistanceBehavior.PERSIST_UNTIL_DISMISSED,
      customValidator: (userInfo) => {
        return userInfo.isDiscoverTabUser
      },
    },
    allowDismiss: true,
    announcementAction: runWorkflowsProductTour,
  },
]

// exports
export { AnnouncementList }
