import * as React from 'react'

import { TriangleAlert } from 'lucide-react'
import { ArrowUpRight } from 'lucide-react'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { TextLink } from 'components/ui/text-link'

const LooseFilesWarningBanner = () => {
  return (
    <div className="mt-4 flex items-center justify-between rounded-lg bg-secondary p-4">
      <div className="flex flex-row items-center gap-2">
        <Icon icon={TriangleAlert} size="small" />
        <p>
          Some of your files aren&apos;t in a folder. Move them into a folder to
          query them in Assistant.
        </p>
      </div>
      <Button variant="outline" className="flex items-center gap-1 px-2">
        <TextLink
          label="Help center"
          href="https://help.harvey.ai/en/articles/10629261-vault-knowledge-bases"
          openInNewTab
          isExternalLink
          noUnderline
          className="flex items-center gap-1"
          leadingIcon={<ArrowUpRight />}
        />
      </Button>
    </div>
  )
}

export default LooseFilesWarningBanner
