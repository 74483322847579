import * as React from 'react'

import {
  getSortedRowModel,
  getCoreRowModel,
  SortingState,
  ColumnDef,
  useReactTable,
  RowSelectionState,
  createColumnHelper,
  Row,
} from '@tanstack/react-table'
import _ from 'lodash'
import { ChevronsUpDown } from 'lucide-react'

import { TableSelect as TableSelectType } from 'openapi/models/TableSelect'
import { TableSelectOrderedColumnNamesInner } from 'openapi/models/TableSelectOrderedColumnNamesInner'

import { getHrvyInfoMetadata } from 'utils/source'
import { cn, snakeToCamel } from 'utils/utils'

import Markdown from 'components/common/markdown/markdown'
import Table from 'components/common/markdown/table'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import Icon from 'components/ui/icon/icon'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Textarea } from 'components/ui/text-area'

interface TableSelectProps<T> {
  title?: string
  columns: ColumnDef<T>[]
  data: T[]
  selectedRows?: RowSelectionState
  onRowChange?: (value: string) => void
  canAddRemoveRows?: boolean
  onAddRow?: () => void
  onSaveRow?: () => void
  openSourceId?: string
}
interface RenderedCellProps {
  camelCaseKey: string
  row: Row<TableSelectType['rows'][number]>
  column: TableSelectOrderedColumnNamesInner
  onChange?: (rowIdx: number, key: string, value: string) => void
  onSave?: (rowIdx: number) => void
  getHrvyInfoMetadata: getHrvyInfoMetadata
  showValidation: boolean
}

const RenderedCell: React.FC<RenderedCellProps> = React.memo(function Rec({
  camelCaseKey,
  row,
  column,
  onChange,
  onSave,
  getHrvyInfoMetadata,
  showValidation = false,
}) {
  const [value, setValue] = React.useState(row.original.data[camelCaseKey])
  const [hovered, setHovered] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  const isRequired = column.required
  const isEmpty = _.isEmpty(value)
  const showError =
    isRequired && isEmpty && (row.original.isEditing || showValidation)

  // Determine if this field should be rendered as a select
  const isSelectField =
    column.inputType === 'select' && Array.isArray(column.options)

  if (!column.editable) {
    return (
      <Markdown
        getHrvyInfoMetadata={getHrvyInfoMetadata}
        content={value}
        className={cn('!prose-xs text-wrap', !value && 'text-muted')}
        width="100%"
      />
    )
  }

  if (!editing) {
    const placeholderText = isSelectField
      ? 'Select option…'
      : 'Enter text here…'
    return (
      <div
        className="size-full"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <button
          className={cn(
            'flex w-full justify-between rounded-sm p-1 text-left align-text-top text-xs text-primary',
            'cursor-text hover:bg-button-secondary-hover'
          )}
          onClick={() => setEditing(true)}
        >
          <Markdown
            getHrvyInfoMetadata={getHrvyInfoMetadata}
            content={value ? value : placeholderText}
            className={cn('!prose-xs text-wrap', !value && 'text-muted')}
            width="100%"
          />
          {isSelectField && hovered ? (
            <Icon
              icon={ChevronsUpDown}
              size="small"
              className="relative right-2 mt-0.5"
            />
          ) : (
            <div className="min-h-3 min-w-3" />
          )}
        </button>
      </div>
    )
  }

  if (isSelectField && column.options) {
    return (
      <div className="text-xs text-primary">
        {showError && (
          <div className="mb-1 text-xs text-destructive">Required</div>
        )}
        <Select
          value={value}
          onValueChange={(newValue) => {
            onChange?.(row.index, camelCaseKey, newValue)
            onSave?.(row.index)
            setValue(newValue)
            setEditing(false)
            setHovered(false)
          }}
          defaultOpen
        >
          <SelectTrigger className="h-5 w-full">
            <SelectValue placeholder="Select option" />
          </SelectTrigger>
          <SelectContent>
            {column.options.map((option) => (
              <SelectItem key={option.value} value={option.value} fontSize="xs">
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    )
  }

  return (
    <Textarea
      className="min-h-0 w-full resize-none border-none bg-[hsl(var(--neutral-200))] p-1 align-text-top text-xs outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
      value={value}
      onChange={(e) => {
        onChange?.(row.index, camelCaseKey, e.target.value)
        setValue(e.target.value)
        e.target.style.height = ''
        e.target.style.height = e.target.scrollHeight + 'px'
      }}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      isFluid
      onFocus={(e) => {
        e.target.setSelectionRange(e.target.value.length, e.target.value.length)
      }}
      onBlur={() => {
        onSave?.(row.index)
        setEditing(false)
      }}
      rows={1}
    />
  )
})

/* eslint-disable max-params */
export const generateColumns = (
  tableData: TableSelectType,
  getHrvyInfoMetadata: getHrvyInfoMetadata,
  onRowChange?: (rowIdx: number, key: string, value: string) => void,
  onSaveRow?: (rowIdx: number) => void,
  showValidation?: boolean
) => {
  const columnHelper = createColumnHelper<TableSelectType['rows'][number]>()

  if (tableData.orderedColumnNames?.length) {
    return tableData.orderedColumnNames.reduce(
      (acc: ColumnDef<TableSelectType['rows'][number]>[], column) => {
        const { key, name } = column
        if (!key || !name) return acc
        const camelCaseKey = snakeToCamel(key)
        acc.push(
          columnHelper.accessor(`data.${camelCaseKey}`, {
            header: () => <TableSelectHeader>{name}</TableSelectHeader>,
            cell: ({ row }) => {
              return (
                <RenderedCell
                  camelCaseKey={camelCaseKey}
                  row={row}
                  column={column}
                  onChange={onRowChange}
                  getHrvyInfoMetadata={getHrvyInfoMetadata}
                  showValidation={
                    _.isNil(showValidation) ? false : showValidation
                  }
                  onSave={onSaveRow}
                />
              )
            },
            size: column.width ?? 0,
            minSize: 0,
          })
        )
        return acc
      },
      []
    )
  }

  return []
}

const TableSelectHeader: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="text-xs tracking-wide text-primary">{children}</div>
}

const TableSelectCell: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="text-xs text-primary">{children}</div>
}

const TableSelect = <T,>({
  title,
  data,
  columns,
  selectedRows,
  canAddRemoveRows,
  onAddRow,
  openSourceId,
}: TableSelectProps<T>) => {
  const [sorting, setSorting] = React.useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection: selectedRows || {},
    },
  })

  return (
    <Table
      title={title}
      dialogClassName="flex flex-col w-full"
      hasContainer
      trailingChildren={
        canAddRemoveRows && (
          <Button
            className="w-full rounded-none border-t"
            variant="ghost"
            size="lg"
            onClick={onAddRow}
          >
            + Add Row
          </Button>
        )
      }
      openSourceId={openSourceId}
    >
      <DataTable
        table={table}
        className="w-full"
        tableBodyCellClassName="align-top"
        renderWithoutTableContainer
        hideTableBorder
      />
    </Table>
  )
}

export { TableSelectHeader, TableSelectCell }
export default TableSelect
