/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InternalOnlyPermission = {
    READ_ALL_WORKSPACES: 'read:all_workspaces',
    WRITE_ALL_WORKSPACES: 'write:all_workspaces',
    MANAGE_USERS: 'manage:users',
    READ_ALL_SENSITIVE: 'read:all_sensitive',
    READ_SOME_SENSITIVE_DATA: 'read:some_sensitive_data',
    USE_SKIP_RATE_LIMIT: 'use:skip_rate_limit',
    CREATE_VAULT_INTERNAL_ONLY: 'create:vault_internal_only',
    CREATE_VAULT_SANDBOX_PROJECT: 'create:vault_sandbox_project',
    CREATE_HARVEY_LIBRARY_ITEMS: 'create:harvey_library_items',
    CREATE_RESPONSE_COMPARISON: 'create:response_comparison',
    READ_ENG_INTERNAL: 'read:eng_internal',
    WRITE_ENG_INTERNAL: 'write:eng_internal',
    VIEW_USER_INSPECTOR: 'view:user_inspector'
} as const;
export type InternalOnlyPermission = typeof InternalOnlyPermission[keyof typeof InternalOnlyPermission];


export function instanceOfInternalOnlyPermission(value: any): boolean {
    for (const key in InternalOnlyPermission) {
        if (Object.prototype.hasOwnProperty.call(InternalOnlyPermission, key)) {
            if (InternalOnlyPermission[key as keyof typeof InternalOnlyPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function InternalOnlyPermissionFromJSON(json: any): InternalOnlyPermission {
    return InternalOnlyPermissionFromJSONTyped(json, false);
}

export function InternalOnlyPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalOnlyPermission {
    return json as InternalOnlyPermission;
}

export function InternalOnlyPermissionToJSON(value?: InternalOnlyPermission | null): any {
    return value as any;
}

export function InternalOnlyPermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): InternalOnlyPermission {
    return value as InternalOnlyPermission;
}

