import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { BookOpen } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { ProjectMetadata } from 'openapi/models/ProjectMetadata'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { LoadingText } from 'components/ui/loading-text'
import { SkeletonBlock } from 'components/ui/skeleton'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip'
import { MenuDropdown } from 'components/vault/components/file-explorer/vault-cells'
import { DEFAULT_PROJECT_CARD_HEIGHT } from 'components/vault/components/new-project/create-project-helpers'
import VaultProjectEditClientMatterDialog from 'components/vault/dialogs/vault-project-edit-client-matter-dialog'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { getSharingStatusAndIconForVaultFolder } from 'components/vault/utils/get-sharing-status-and-icon-for-vault-folder'
import { useVaultProjectHistoryStats } from 'components/vault/utils/use-vault-project-history-stats'
import { DOT_SEPARATOR, projectsPath } from 'components/vault/utils/vault'
import {
  getProjectMetadataFromVaultFolderMetadata,
  isEmptyMetadata,
  projectAsItem,
} from 'components/vault/utils/vault-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

const VaultProjectCard = ({
  vaultProject,
  isShared,
  isLoading,
  isLoadingMetadata,
  projectMetadata,
  disabled,
  disabledTooltip,
  onClick,
}: {
  vaultProject: VaultFolder
  isShared?: boolean
  isLoading?: boolean
  isLoadingMetadata: boolean
  projectMetadata?: ProjectMetadata
  disabled?: boolean
  disabledTooltip?: string
  onClick?: (project: VaultFolder) => void
}) => {
  const location = useLocation()
  const { trackEvent } = useAnalytics()
  const [
    isEditClientMatterDialogOpen,
    exampleProjectIds,
    setIsEditClientMatterDialogOpen,
    setCurrentProject,
    setShowProcessingProgress,
  ] = useVaultStore(
    useShallow((s) => [
      s.isEditClientMatterDialogOpen,
      s.exampleProjectIds,
      s.setIsEditClientMatterDialogOpen,
      s.setCurrentProject,
      s.setShowProcessingProgress,
    ])
  )

  const projectRow = projectAsItem(
    vaultProject,
    projectMetadata?.totalSize,
    projectMetadata?.filesCount
  )

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  const isExampleProject = useMemo(
    () => exampleProjectIds.has(vaultProject.id),
    [vaultProject.id, exampleProjectIds]
  )
  const isKnowledgeBaseProject = vaultProject.isKnowledgeBaseProject
  const { doesCurrentUserHaveFullAccessPermission } = useSharingPermissions({
    projectId: vaultProject.id,
  })

  const destination = useMemo(() => {
    if (disabled) {
      return undefined
    }
    return {
      pathname: `${BaseAppPath.Vault}${projectsPath}${vaultProject.id}`,
      search: location.search,
    }
  }, [location.search, vaultProject.id, disabled])

  const shouldShowMenuDropdown =
    (isHovered || isDropdownOpen) &&
    !isExampleProject &&
    doesCurrentUserHaveFullAccessPermission &&
    !disabled &&
    !onClick // onClick means we're using the button variant, which doesn't allow for editing

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        height: DEFAULT_PROJECT_CARD_HEIGHT,
      }}
      className={cn(
        'relative flex w-full flex-col rounded-lg bg-secondary p-4 transition',
        {
          'hover:bg-secondary-hover': !disabled,
          'cursor-pointer border-input-focused':
            (isHovered || isDropdownOpen) && !disabled,
        }
      )}
    >
      <Button
        to={onClick ? undefined : destination?.pathname}
        onClick={() =>
          onClick
            ? onClick(vaultProject)
            : () => {
                setCurrentProject(vaultProject)
                if (
                  projectMetadata &&
                  (projectMetadata.failedFilesCount !== 0 ||
                    projectMetadata.completedFilesCount !==
                      projectMetadata.filesCount)
                ) {
                  setShowProcessingProgress(vaultProject.id, true)
                }
                trackEvent('Vault Project Loaded', {
                  is_example_project: isExampleProject,
                  is_shared_project: isShared,
                })
              }
        }
        variant="unstyled"
        className="flex h-full flex-col justify-end px-0"
        disabled={disabled}
        tooltip={disabledTooltip}
      >
        <ProjectCardContents
          vaultProject={vaultProject}
          isHovered={isHovered}
          isKnowledgeBaseProject={isKnowledgeBaseProject}
          isExampleProject={isExampleProject}
          isLoading={isLoading}
          isLoadingMetadata={isLoadingMetadata}
          projectMetadata={projectMetadata}
          disabled={disabled}
        />
      </Button>

      {shouldShowMenuDropdown && (
        <div className="absolute right-2 top-2">
          <MenuDropdown
            dropdownAlign="end"
            row={projectRow}
            onMenuDropdownChangeHandler={setIsDropdownOpen}
          />
          <VaultProjectEditClientMatterDialog
            modalOpen={isEditClientMatterDialogOpen}
            setModalOpen={setIsEditClientMatterDialogOpen}
            projectId={projectRow.data.id}
          />
        </div>
      )}
    </div>
  )
}

const ProjectCardContents = ({
  vaultProject,
  isHovered,
  isKnowledgeBaseProject,
  isExampleProject,
  isLoading,
  isLoadingMetadata,
  projectMetadata,
  disabled,
}: {
  vaultProject: VaultFolder
  isHovered: boolean
  isKnowledgeBaseProject?: boolean
  isExampleProject?: boolean
  isLoading?: boolean
  isLoadingMetadata?: boolean
  projectMetadata?: ProjectMetadata
  disabled?: boolean
}) => {
  const userInfo = useAuthUser()
  const { historyStats, isLoadingHistoryStats } = useVaultProjectHistoryStats(
    vaultProject.id
  )

  const [foldersMetadata] = useVaultStore(
    useShallow((s) => [s.foldersMetadata])
  )

  const [permissionsByProjectId] = useVaultSharingStore(
    useShallow((s) => [s.permissionsByProjectId])
  )

  const sizeDisplayText = useMemo(() => {
    // Read folder metadata if it's loaded, otherwise read cached project metadata
    const folderMetadata = foldersMetadata[vaultProject.id]
    const metadata =
      !!folderMetadata && !isEmptyMetadata(folderMetadata)
        ? getProjectMetadataFromVaultFolderMetadata(folderMetadata)
        : projectMetadata ?? undefined
    const numProjectFiles = metadata?.filesCount || 0
    if (numProjectFiles === 0) {
      return `No files uploaded`
    }
    return pluralizeFiles(numProjectFiles)
  }, [projectMetadata, foldersMetadata, vaultProject.id])

  const queryCountDisplayText = useMemo(() => {
    if (!historyStats) {
      return ''
    }
    return `${historyStats.totalCount} ${pluralize(
      'query',
      historyStats.totalCount
    )}`
  }, [historyStats])

  const { sharingStatusIcon, sharingStatusTooltip } =
    getSharingStatusAndIconForVaultFolder({
      permissionsByProjectId,
      vaultProjectId: vaultProject.id,
      clientName: userInfo.workspace.clientName,
      projectMetadata,
      isKnowledgeBaseProject,
    })

  const isMetadataLoaded = !isLoadingMetadata && !isLoadingHistoryStats

  const hasQueries = historyStats && historyStats.totalCount > 0

  const shouldShowHoveredIconState = isHovered && !disabled

  return (
    <div className="flex w-full flex-col gap-y-4">
      {isKnowledgeBaseProject ? (
        <Icon
          icon={BookOpen}
          className={cn('size-[72px] self-center stroke-[1.5px] transition', {
            'stroke-[hsl(var(--neutral-300))]': !shouldShowHoveredIconState,
            'stroke-[hsl(var(--neutral-400))]': shouldShowHoveredIconState,
          })}
        />
      ) : (
        <FolderShieldIcon
          className="size-[72px] self-center transition"
          strokeWidth={1.5}
          strokeColor={cn({
            'hsl(var(--neutral-300))': !shouldShowHoveredIconState,
            'hsl(var(--neutral-400))': shouldShowHoveredIconState,
          })}
        />
      )}
      <div>
        <LoadingText
          text={vaultProject.name}
          isLoading={isLoading || false}
          size="sm"
          className="line-clamp-3 w-full text-left font-medium"
        />
        <div className="flex w-full items-center justify-between text-muted">
          <div>
            <ProjectSubtitle
              isKnowledgeBaseProject={!!isKnowledgeBaseProject}
              hasQueries={!!hasQueries}
              sizeDisplayText={sizeDisplayText}
              queryCountDisplayText={queryCountDisplayText}
              isMetadataLoaded={isMetadataLoaded}
            />
          </div>
          {!isExampleProject &&
            (disabled ? (
              <Icon icon={sharingStatusIcon} size="default" />
            ) : (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <div className="cursor-pointer">
                      <Icon icon={sharingStatusIcon} size="default" />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent sideOffset={5}>
                    <p className="text-xs">{sharingStatusTooltip}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
        </div>
      </div>
    </div>
  )
}

const ProjectSubtitle = ({
  isKnowledgeBaseProject,
  hasQueries,
  sizeDisplayText,
  queryCountDisplayText,
  isMetadataLoaded,
}: {
  isKnowledgeBaseProject: boolean
  hasQueries: boolean
  sizeDisplayText: string
  queryCountDisplayText: string
  isMetadataLoaded: boolean
}) => {
  if (isKnowledgeBaseProject) {
    return <p className="text-xs">Knowledge Base</p>
  }
  if (!isMetadataLoaded) {
    return <SkeletonBlock className="h-3 w-16" />
  }
  if (hasQueries) {
    return (
      <p className="text-xs">
        {sizeDisplayText} {DOT_SEPARATOR} {queryCountDisplayText}
      </p>
    )
  } else {
    return <p className="text-xs">{sizeDisplayText}</p>
  }
}

export default VaultProjectCard
