import * as React from 'react'

import { cn } from 'utils/utils'

import { DropdownMenuItem } from 'components/ui/dropdown-menu'
import { LoadingText } from 'components/ui/loading-text'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

export interface DropdownItemProps {
  icon: JSX.Element
  title: string
  description?: string
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  disabled?: boolean
  children?: React.ReactNode
  className?: string
  isTitleLoading?: boolean
  isDescriptionLoading?: boolean
  matchTriggerWidth?: boolean
  tooltipText?: string
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  icon,
  title,
  description,
  onClick,
  disabled,
  children,
  className,
  isTitleLoading = false,
  isDescriptionLoading = false,
  matchTriggerWidth = false,
  tooltipText,
}) => {
  return (
    <DropdownMenuItem
      onClick={onClick}
      disabled={disabled}
      data-testid="input-source-button-dropdown-item"
      className={cn('p-2', className)}
    >
      <div className="flex w-full items-center gap-6">
        <div
          className={cn(
            'flex flex-1 flex-col',
            !matchTriggerWidth && 'max-w-56'
          )}
        >
          {isTitleLoading ? (
            <LoadingText text={title} isLoading size="sm" />
          ) : (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="block w-full">
                  <LoadingText
                    className="truncate"
                    text={title}
                    isLoading={false}
                    size="sm"
                  />
                </span>
              </TooltipTrigger>
              <TooltipContent className="max-w-sm">
                {tooltipText ? tooltipText : title}
              </TooltipContent>
            </Tooltip>
          )}
          <LoadingText
            text={description}
            isLoading={isDescriptionLoading}
            size="xs"
            className="line-clamp-2 text-muted"
          />
        </div>
        <div className="flex size-4 items-center justify-center">
          {React.cloneElement(icon, {
            className: 'size-4 text-muted',
          })}
        </div>
      </div>
      {children}
    </DropdownMenuItem>
  )
}
