/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SensitiveUserPermission = {
    ADMIN_ORGANIZATION: 'admin:organization',
    ADMIN_TERRITORY: 'admin:territory',
    READ_ALL_WORKSPACES: 'read:all_workspaces',
    WRITE_ALL_WORKSPACES: 'write:all_workspaces',
    MANAGE_USERS: 'manage:users',
    READ_ALL_SENSITIVE: 'read:all_sensitive',
    READ_SOME_SENSITIVE_DATA: 'read:some_sensitive_data',
    DELETE_WORKSPACE_HISTORY_BULK: 'delete:workspace_history_bulk',
    CREATE_HARVEY_LIBRARY_ITEMS: 'create:harvey_library_items',
    READ_ENG_INTERNAL: 'read:eng_internal',
    WRITE_ENG_INTERNAL: 'write:eng_internal'
} as const;
export type SensitiveUserPermission = typeof SensitiveUserPermission[keyof typeof SensitiveUserPermission];


export function instanceOfSensitiveUserPermission(value: any): boolean {
    for (const key in SensitiveUserPermission) {
        if (Object.prototype.hasOwnProperty.call(SensitiveUserPermission, key)) {
            if (SensitiveUserPermission[key as keyof typeof SensitiveUserPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SensitiveUserPermissionFromJSON(json: any): SensitiveUserPermission {
    return SensitiveUserPermissionFromJSONTyped(json, false);
}

export function SensitiveUserPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensitiveUserPermission {
    return json as SensitiveUserPermission;
}

export function SensitiveUserPermissionToJSON(value?: SensitiveUserPermission | null): any {
    return value as any;
}

export function SensitiveUserPermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): SensitiveUserPermission {
    return value as SensitiveUserPermission;
}

