import React from 'react'
import ReactMarkdown, { Components } from 'react-markdown'

import deepmerge from 'deepmerge'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize'
import { remarkExtendedTable } from 'remark-extended-table'
import remarkGfm from 'remark-gfm'

import { getHrvyInfoMetadata } from 'utils/source'
import { Source } from 'utils/task'
import { cn } from 'utils/utils'

import { getMarkdownComponents, urlTransform } from './markdown.helpers'

export const MARKDOWN_SANITIZER_SCHEMA = deepmerge(defaultSchema, {
  tagNames: ['mark', 'highlight-end', 'figure'],
  clobberPrefix: '',
  attributes: { '*': ['style', 'target', 'dataHrvyId', 'className', 'class'] },
  protocols: {
    src: ['data'],
    href: ['data'],
  },
})

export interface MarkdownProps {
  components?: Partial<Components> | null | undefined
  content: string
  getHrvyInfoMetadata?: getHrvyInfoMetadata
  className?: string
  lineClamp?: 1 | 3
  expectImageDataUri?: boolean
  expectExcelDataUri?: boolean
  isLoading?: boolean
  sources?: Source[]
  width?: string
  enableMailLinks?: boolean
  innerClassName?: string
  isRTL?: boolean
  preventCitationSelection?: boolean
}

const Markdown: React.FC<MarkdownProps> = ({
  components,
  content,
  getHrvyInfoMetadata,
  className,
  lineClamp,
  expectImageDataUri,
  expectExcelDataUri,
  isLoading,
  sources,
  width,
  enableMailLinks = false,
  innerClassName,
  isRTL = false,
  preventCitationSelection = true,
}) => {
  const handleUrlTransform = (url: string, key: string, node: any) => {
    if (
      expectImageDataUri &&
      node.tagName === 'img' &&
      key === 'src' &&
      url.startsWith('data:image/png;base64')
    ) {
      return url
    } else if (
      expectExcelDataUri &&
      node.tagName === 'a' &&
      key === 'href' &&
      url.startsWith(
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
      )
    ) {
      return url
    }
    return urlTransform(url, key, node)
  }

  return (
    <ReactMarkdown
      className={cn(
        'notranslate prose prose-sm prose-harvey max-w-full break-words',
        className,
        {
          'line-clamp-1': lineClamp === 1,
          'line-clamp-3': lineClamp === 3,
        }
      )}
      remarkPlugins={[remarkGfm, remarkExtendedTable]}
      rehypePlugins={[rehypeRaw, [rehypeSanitize, MARKDOWN_SANITIZER_SCHEMA]]}
      urlTransform={handleUrlTransform}
      components={{
        ...getMarkdownComponents({
          innerClassName,
          getHrvyInfoMetadata,
          isLoading,
          sources,
          width,
          enableMailLinks,
          isRTL,
          preventCitationSelection,
        }),
        ...components,
      }}
    >
      {content}
    </ReactMarkdown>
  )
}

export default Markdown
