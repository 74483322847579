import { MarkdownDiff } from 'utils/markdown-diff'

import { AssistantMessage } from 'components/assistant/types'

export const getMessageDiff = (
  messages: AssistantMessage[],
  selectedMessage: AssistantMessage | undefined,
  useDiffView: boolean
) => {
  const prevMessage = messages.find(
    (m) => m.messageId && m.messageId === selectedMessage?.prevMessageId
  )
  if (useDiffView && prevMessage && selectedMessage) {
    const prevResponse = prevMessage.response
    const selectedResponse = selectedMessage.response
    let response = selectedResponse
    try {
      response = new MarkdownDiff().run(prevResponse, selectedResponse)
    } catch (e) {
      // TODO: We should tell the user we couldn't generate the diff
      console.error('Error generating markdown diff, skipping', e)
    }
    return response
  }
  return selectedMessage?.response ?? ''
}

// used to memoize the result of the runMessageDiff function
const diffCache = new Map<string, string>()
const MAX_CACHE_SIZE = 20

export const runMessageDiff = (
  newerContent: string,
  olderContent: string,
  newerRevisionId: string
) => {
  // Check if we have a cached result
  if (diffCache.has(newerRevisionId)) {
    return diffCache.get(newerRevisionId)!
  }

  try {
    const diff = new MarkdownDiff().run(olderContent, newerContent)

    // If cache is full, remove the oldest entry (first inserted)
    if (diffCache.size >= MAX_CACHE_SIZE) {
      const firstKey = diffCache.keys().next().value
      if (firstKey !== undefined) {
        diffCache.delete(firstKey)
      }
    }

    // Cache the result
    diffCache.set(newerRevisionId, diff)

    return diff
  } catch (e) {
    // TODO: We should tell the user we couldn't generate the diff
    console.error('Error generating markdown diff, skipping', e)
    return newerContent
  }
}
