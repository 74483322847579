import React, { useCallback, useMemo, useRef, useState } from 'react'

import { isEmpty } from 'lodash'
import { ChevronUp, ChevronDown } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { DiligenceSection } from 'openapi/models/DiligenceSection'
import { UploadedFile } from 'openapi/models/UploadedFile'

import { cn } from 'utils/utils'

import FullscreenLoading from 'components/common/fullscreen-loading'
import PdfPushSheet from 'components/common/pdf-viewer/pdf-push-sheet'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { EXPIRATION_URL_KEY } from 'components/vault/utils/vault'
import { FetchVaultFile } from 'components/vault/utils/vault-fetcher'
import { isUrlExpired } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useMeasureNodeHeight } from 'components/workflows/workflow/discovery/common/use-measure-node-height'
import { useSelectedCitation } from 'components/workflows/workflow/discovery/common/use-selected-citation'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'
import { getSizeInPercentage } from 'components/workflows/workflow/discovery/util'
import { buildCitationMaps } from 'components/workflows/workflow/discovery/util'

import { FollowUpSectionWrapper } from './follow-up-input-section-wrapper'
import { ManySections } from './many-sections'

interface Props {
  sectionsWithAnswers: DiligenceSection[]
  progress: number
}

export const ManySectionsWithPDF: React.FC<Props> = ({
  sectionsWithAnswers,
  progress,
}) => {
  const isLoading = (sectionsWithAnswers: DiligenceSection[]) => {
    return sectionsWithAnswers.some((section) => section.isLoading)
  }

  const [followUpQAPairs] = useDiligenceStore(
    useShallow((state) => [state.followUpQAPairs])
  )

  const { selectedSourceFileId, selectedSourceId } = useSelectedCitation()

  const getDocument = useCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      eventId: string | null,
      fileId: string
    ): Promise<UploadedFile | undefined> => {
      const documents = useDiligenceStore.getState().documents
      const knowledgeSource = useDiligenceStore.getState().knowledgeSource
      if (!knowledgeSource) {
        return documents.find((d) => d.file.id === fileId)?.file
      }

      const vaultFile =
        useVaultStore.getState().currentProjectFileIdToVaultFile[fileId]
      const vaultProjectId = useVaultStore.getState().currentProject?.id
      if (vaultFile) {
        const vaultFileUrl = vaultFile.url || vaultFile.docAsPdfUrl || ''
        if (
          !isEmpty(vaultFileUrl) &&
          !isUrlExpired(vaultFileUrl, EXPIRATION_URL_KEY)
        ) {
          return vaultFile
        }
      }
      const file = await FetchVaultFile(fileId)
      useVaultStore.getState().upsertVaultFiles([file], vaultProjectId)
      return file
    },
    []
  )

  const relevantCitationData = useMemo(() => {
    if (!selectedSourceId || !selectedSourceFileId) {
      return null
    }

    const citationMaps = buildCitationMaps(sectionsWithAnswers, followUpQAPairs)

    const taskContext = citationMaps.sourceIdToTask.get(selectedSourceId)
    if (!taskContext) {
      return null
    }

    // Get all sources from the same task
    const relatedSources =
      citationMaps.taskIdToSources.get(taskContext.taskId) || []

    return {
      taskContext,
      relatedSources,
    }
  }, [
    selectedSourceId,
    selectedSourceFileId,
    sectionsWithAnswers,
    followUpQAPairs,
  ])

  // Then create pushSheet using the memoized data
  const pushSheet = useMemo(() => {
    if (!relevantCitationData || !selectedSourceFileId) {
      return null
    }

    return (
      <PdfPushSheet
        fileId={selectedSourceFileId}
        getDocument={getDocument}
        sources={relevantCitationData.relatedSources}
      />
    )
  }, [relevantCitationData, selectedSourceFileId, getDocument])

  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)

  const innerResizeablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)

  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout([100, 0])
    }
  }

  const { containerHeight, containerRef } = useMeasureNodeHeight()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  // We need to set the min, max, and default sizes for the drawer when it is open or closed
  // Below, we choose the size in pixels, and then compute the percentage size to use for the ResizablePanel
  const drawerOpenHeight = 280 // pixels
  const drawerClosedHeight = 40 // pixels
  const defaultDrawerSize = isDrawerOpen
    ? getSizeInPercentage(drawerOpenHeight, containerHeight)
    : getSizeInPercentage(drawerClosedHeight, containerHeight)
  const minDrawerSize = defaultDrawerSize
  const maxDrawerSize = isDrawerOpen ? undefined : defaultDrawerSize
  const defaultReportPanelSize = 100 - defaultDrawerSize

  // This handler changes the layout from closed drawer to open drawer and vice versa
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
    if (innerResizeablePanelGroupRef.current && containerHeight) {
      innerResizeablePanelGroupRef.current.setLayout([
        defaultReportPanelSize,
        minDrawerSize,
      ])
    }
  }

  return (
    <div className="flex size-full" ref={containerRef}>
      {isLoading(sectionsWithAnswers) && (
        <FullscreenLoading
          isLoading={isLoading(sectionsWithAnswers)}
          progress={progress}
          zIndex="z-1"
        />
      )}
      <ResizablePanelGroup direction="horizontal" ref={resizablePanelGroupRef}>
        {/* The report and drawer inner resizable panel */}
        <ResizablePanel defaultSize={100} minSize={20}>
          <ResizablePanelGroup
            direction="vertical"
            ref={innerResizeablePanelGroupRef}
          >
            <ResizablePanel defaultSize={defaultReportPanelSize} minSize={20}>
              <ManySections sections={sectionsWithAnswers} />
            </ResizablePanel>

            {/* The drawer is below */}
            {isDrawerOpen && <ResizableHandle withHandle />}

            <ResizablePanel
              defaultSize={defaultDrawerSize}
              minSize={minDrawerSize}
              maxSize={maxDrawerSize}
              className="z-20 transition-all ease-in-out"
            >
              <div
                className={cn('relative h-full border bg-button-secondary', {
                  'bg-primary': isDrawerOpen,
                })}
              >
                <Button
                  className="left-2 top-2 h-6 min-w-[100px] rounded-t-md p-2 transition hover:bg-button-secondary-hover focus:outline-none focus-visible:ring focus-visible:ring-ring"
                  variant="ghost"
                  size="sm"
                  onClick={toggleDrawer}
                >
                  <Icon
                    className="mr-2"
                    icon={isDrawerOpen ? ChevronDown : ChevronUp}
                  />
                  <p>Follow-up questions</p>
                </Button>
                {/* Drawer Content */}
                <div className="flex flex-col items-center overflow-auto p-4">
                  {isDrawerOpen && <FollowUpSectionWrapper />}
                </div>
                {/* End Drawer Content */}
              </div>
            </ResizablePanel>
            {/* End drawer */}
          </ResizablePanelGroup>
        </ResizablePanel>

        {/* The side panel for viewing documents */}
        {selectedSourceFileId && (
          <>
            <ResizableHandle withHandle onDoubleClick={resetLayout} />
            <ResizablePanel defaultSize={0} minSize={40}>
              {pushSheet}
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </div>
  )
}
