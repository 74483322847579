import { Site } from 'utils/site'

import { Env } from './env'

// Module for using data set dynamically by the server.
//
// In dev and during the transition to Azure, the "server data" will be set by
// process.env vars.
//
// In Azure, a variable called `window.SERVER_DATA` is set by the
// frontend server.
//
// For vars that are defined here, do not use the process.env vars in other
// files directly. Always use the exported vars from this file.
//
// Also must add the vars to `frontend-server/index.js`
declare global {
  interface Window {
    SERVER_DATA?: {
      PSPDFKIT_STANDALONE_LICENSE_KEY?: string
      BACKEND_REST_URL?: string
      BACKEND_WS_URL?: string
      CDN_URL?: string
      ALT_CDN_URL?: string
      ENVIRONMENT?: string
      AUTH_DOMAIN?: string
      AUTH_CLIENT_ID?: string
      AUTH_AUDIENCE?: string
      SITE?: string
      MIXPANEL_TOKEN?: string
      GOOGLE_DRIVE_INTEGRATION_API_KEY?: string
      GOOGLE_DRIVE_INTEGRATION_APP_ID?: string
      AUTH_CLIENT_ID_SYNCLY?: string
      EVAL_DATA_REST_URL?: string
    }
  }
}

export const pspdfkitStandaloneLicenseKey =
  window.SERVER_DATA?.PSPDFKIT_STANDALONE_LICENSE_KEY ||
  process.env.REACT_APP_PSPDFKIT_STANDALONE_LICENSE_KEY

export const backendRestUrl =
  window.SERVER_DATA?.BACKEND_REST_URL || process.env.REACT_APP_BACKEND_REST_URL

export const backendWebsocketUrl =
  window.SERVER_DATA?.BACKEND_WS_URL || process.env.REACT_APP_BACKEND_WS_URL

export const cdnUrl =
  window.SERVER_DATA?.CDN_URL || process.env.REACT_APP_CDN_URL

const altCdnUrl =
  window.SERVER_DATA?.ALT_CDN_URL || process.env.REACT_APP_ALT_CDN_URL

export const cdnUrls = [cdnUrl, altCdnUrl].filter((url) => !!url)

export const environment: Env =
  (window.SERVER_DATA?.ENVIRONMENT as Env) ||
  (process.env.REACT_APP_ENVIRONMENT as Env)

export const authDomain =
  window.SERVER_DATA?.AUTH_DOMAIN || process.env.REACT_APP_AUTH_DOMAIN

export const authClientId =
  window.SERVER_DATA?.AUTH_CLIENT_ID || process.env.REACT_APP_AUTH_CLIENT_ID

export const authAudience =
  window.SERVER_DATA?.AUTH_AUDIENCE || process.env.REACT_APP_AUTH_AUDIENCE

export const site: Site =
  (window.SERVER_DATA?.SITE as Site) || (process.env.REACT_APP_SITE as Site)

export const mixpanelToken =
  window.SERVER_DATA?.MIXPANEL_TOKEN ||
  process.env.REACT_APP_MIXPANEL_TOKEN ||
  ''

export const googleDriveIntegrationApiKey =
  window.SERVER_DATA?.GOOGLE_DRIVE_INTEGRATION_API_KEY ||
  process.env.REACT_APP_GOOGLE_DRIVE_INTEGRATION_API_KEY ||
  ''

export const googleDriveIntegrationAppId =
  window.SERVER_DATA?.GOOGLE_DRIVE_INTEGRATION_APP_ID ||
  process.env.REACT_APP_GOOGLE_DRIVE_INTEGRATION_APP_ID ||
  ''

export const authClientIdSyncly =
  window.SERVER_DATA?.AUTH_CLIENT_ID_SYNCLY ||
  process.env.REACT_APP_AUTH_CLIENT_ID_SYNCLY ||
  'PCyJklA5RhhwRLBT3SnyW9eDhkoeipQo'

export const evalDataRestUrl =
  window.SERVER_DATA?.EVAL_DATA_REST_URL ||
  process.env.REACT_APP_EVAL_DATA_REST_URL
