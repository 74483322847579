import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'

import { fetchRecentKnowledgeSources } from 'components/assistant/utils/assistant-api'

export const useAssistantRecentKnowledgeSources = (isEnabled: boolean) => {
  return useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.AssistantRecentKnowledgeSources],
    queryFn: () => fetchRecentKnowledgeSources(),
    enabled: isEnabled,
  })
}
