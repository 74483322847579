/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductLabel = {
    TAX: 'Tax',
    EDGAR: 'EDGAR',
    MEMOS: 'Memos',
    COMPANY_PROFILE: 'Company Profile',
    REDLINE_QA: 'Redline Q&A',
    REDLINE_ISSUES_LIST: 'Redline Issues List',
    TRANSLATION: 'Translation',
    OGC_CONTRACT_REVIEW: 'OGC Contract Review',
    DRAFTING: 'Drafting',
    DOCUMENT_COMPARISON: 'Document Comparison',
    US_CASE_LAW: 'US Case Law',
    FRENCH_CASE_LAW: 'French Case Law',
    EUR_LEX: 'EUR-Lex',
    TRANSCRIPTS: 'Transcripts',
    ASSIST__AUSTRALIA_BREACH_REPORTING: 'Assist: Australia Breach Reporting',
    VAULT_ASK: 'Vault Ask',
    VAULT_REVIEW: 'Vault Review',
    CUATRECASAS: 'Cuatrecasas',
    FROM_COUNSEL: 'From Counsel',
    DISCOVERY: 'Discovery',
    DILIGENCE_TRANSCRIPTS: 'Diligence Transcripts',
    COMPETITIVE_ANALYSIS: 'Competitive Analysis',
    WORD_ADD_IN: 'Word Add-In',
    ASSIST__VAULT: 'Assist: Vault',
    ASSIST__NO_UPLOADED_FILES: 'Assist: No Uploaded Files',
    ASSIST__USER_UPLOADED_FILES: 'Assist: User Uploaded Files',
    ASSIST__EDGAR: 'Assist: EDGAR',
    ASSIST__FRENCH_CASE_LAW: 'Assist: French Case Law',
    ASSIST__SWEDEN_CASE_LAW: 'Assist: Sweden Case Law',
    ASSIST__SINGAPORE_CASE_LAW: 'Assist: Singapore Case Law',
    ASSIST__MACFARLANES: 'Assist: Macfarlanes',
    ASSIST__LEFEBVRE: 'Assist: Lefebvre',
    ASSIST__BREDIN_PRAT: 'Assist: Bredin Prat',
    ASSIST__WEB_BROWSING: 'Assist: Web Browsing',
    ASSIST__US_CASE_LAW: 'Assist: US Case Law',
    ASSIST__EUR_LEX: 'Assist: EUR-Lex',
    ASSIST__MEMOS: 'Assist: Memos',
    ASSIST__TAX: 'Assist: Tax',
    ASSIST__CUATRECASAS: 'Assist: Cuatrecasas',
    ASSIST__KNOWLEDGE_BASE: 'Assist: Knowledge Base',
    DRAFT__NO_UPLOADED_FILES: 'Draft: No Uploaded Files',
    DRAFT__USER_UPLOADED_FILES: 'Draft: User Uploaded Files',
    DRAFT__VAULT: 'Draft: Vault',
    DRAFT__KNOWLEDGE_BASE: 'Draft: Knowledge Base',
    ADVANCED_ANALYTICS: 'Advanced Analytics',
    ASSIST: 'Assist',
    DRAFT: 'Draft',
    ASSISTANT_WORKFLOW: 'Assistant Workflow'
} as const;
export type ProductLabel = typeof ProductLabel[keyof typeof ProductLabel];


export function instanceOfProductLabel(value: any): boolean {
    for (const key in ProductLabel) {
        if (Object.prototype.hasOwnProperty.call(ProductLabel, key)) {
            if (ProductLabel[key as keyof typeof ProductLabel] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ProductLabelFromJSON(json: any): ProductLabel {
    return ProductLabelFromJSONTyped(json, false);
}

export function ProductLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductLabel {
    return json as ProductLabel;
}

export function ProductLabelToJSON(value?: ProductLabel | null): any {
    return value as any;
}

export function ProductLabelToJSONTyped(value: any, ignoreDiscriminator: boolean): ProductLabel {
    return value as ProductLabel;
}

