import { AntitrustFilingsAnalysisPayload } from 'openapi/models/AntitrustFilingsAnalysisPayload'
import { AntitrustFilingsAnalysisResult } from 'openapi/models/AntitrustFilingsAnalysisResult'

import { Source } from 'utils/task'

export const computeStatus = (analysis: AntitrustFilingsAnalysisResult) => {
  if (!analysis.thresholds.length) {
    return 'No merger control regime'
  }

  const metThresholds = analysis.thresholds.filter(
    (threshold) => threshold.overallConclusion === 'likely to be met'
  ).length

  const unmetThresholds = analysis.thresholds.filter(
    (threshold) => threshold.overallConclusion === 'not likely to be met'
  ).length

  if (metThresholds > 0) {
    return `${metThresholds} Threshold${metThresholds > 1 ? 's' : ''} met`
  }

  if (unmetThresholds === analysis.thresholds.length) {
    return 'Thresholds not met'
  }

  return 'More information required'
}

export const formatConclusion = (conclusion: string) => {
  return conclusion
    .toLowerCase()
    .replace('more information required', 'More Information Required')
    .replace('not likely to be met', 'Not Met')
    .replace('likely to be met', 'Met')
}

export interface SummaryProps {
  className?: string
  data: AntitrustFilingsAnalysisPayload
  handleSourceClick: (source: Source) => void
}

export const getCountryCodesSortedByCountryName = (
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return Object.entries(data)
    .sort((a, b) => a[1].countryName.localeCompare(b[1].countryName))
    .map(([countryCode]) => countryCode)
}

export const getCountriesWithAllThresholdsMet = (
  sortedCountryCodes: string[],
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return sortedCountryCodes.filter((countryCode) => {
    const status = computeStatus(data[countryCode])
    return status.includes('met') && !status.includes('not met')
  })
}

export const getCountriesWithAllThresholdsNotMet = (
  sortedCountryCodes: string[],
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return sortedCountryCodes.filter((countryCode) => {
    const status = computeStatus(data[countryCode])
    return status.includes('not met')
  })
}

export const getCountriesWithMoreInfoRequired = (
  sortedCountryCodes: string[],
  data: SummaryProps['data']['countryCodeToAnalysis']
) => {
  return sortedCountryCodes.filter((countryCode) => {
    return computeStatus(data[countryCode]) === 'More information required'
  })
}

/**
 * Calculate the combined domestic turnover for a country
 * @param partyRevenues Array of party revenues for a country
 * @returns The combined domestic turnover
 */
export const calculateCombinedDomesticTurnover = (
  partyRevenues: AntitrustFilingsAnalysisResult['partyRevenues']
): number => {
  return partyRevenues.reduce((sum, party) => sum + party.revenue, 0)
}

/**
 * Function to randomly choose one element from an array
 */
export const randomElement = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)]
}

export type RequestForInformationTableRow = {
  countryName: string
  partyType: string
  partyName: string
  threshold: string
  requirement: string
  requirementIndex: number
  requestType: string
  request: string
}

export const createRequestForInformationTableRow = (countryCodeToAnalysis: {
  [key: string]: AntitrustFilingsAnalysisResult
}) => {
  const rows: RequestForInformationTableRow[] = []
  for (const countryCode in countryCodeToAnalysis) {
    const countryAnalysis = countryCodeToAnalysis[countryCode]
    const requestsByPartyName: Record<string, Record<string, string>[]> =
      countryAnalysis.requestsForInformation.requestByPartyName as Record<
        string,
        Record<string, string>[]
      >

    for (const partyName in requestsByPartyName) {
      const partyRequests = requestsByPartyName[partyName]
      const partyType =
        countryAnalysis.requestsForInformation.partyTypes[partyName]
      for (const request of partyRequests) {
        const rfiRow: RequestForInformationTableRow = {
          countryName: countryAnalysis.countryName,
          partyType,
          partyName,
          threshold: request.thresholdName,
          requirement: request.requirement,
          requirementIndex: parseInt(request.requirementIndex),
          requestType: request.requestType,
          request: request.request,
        }
        rows.push(rfiRow)
      }
    }

    const generalRequests = countryAnalysis.requestsForInformation
      .generalRequests as Record<string, string>[]
    for (const generalRequest of generalRequests) {
      const rfiRow: RequestForInformationTableRow = {
        countryName: countryAnalysis.countryName,
        partyType: 'General',
        partyName: 'General',
        threshold: generalRequest.thresholdName,
        requirement: generalRequest.requirement,
        requirementIndex: parseInt(generalRequest.requirementIndex),
        requestType: generalRequest.requestType,
        request: generalRequest.request,
      }
      rows.push(rfiRow)
    }
  }
  return rows
}

/**
 * Sort processed party requests by the specified field
 */
export const sortRfiRows = (
  rfiRows: RequestForInformationTableRow[],
  sortField: 'country' | 'type' | 'none'
): RequestForInformationTableRow[] => {
  // Create a copy of the array to avoid mutating the original
  const rowsCopy = [...rfiRows]

  if (sortField === 'none') return rowsCopy

  if (sortField === 'type') {
    return rowsCopy.sort((a, b) => {
      // First sort by type
      const typeComparison = a.requestType.localeCompare(b.requestType)
      // If types are equal, sort by country as secondary criteria
      return typeComparison !== 0
        ? typeComparison
        : a.countryName.localeCompare(b.countryName)
    })
  } else if (sortField === 'country') {
    return rowsCopy.sort((a, b) => {
      // First sort by country
      const countryComparison = a.countryName.localeCompare(b.countryName)
      // If countries are equal, sort by type as secondary criteria
      return countryComparison !== 0
        ? countryComparison
        : a.requestType.localeCompare(b.requestType)
    })
  } else {
    return rowsCopy
  }
}
