import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { DialogDescription } from '@radix-ui/react-dialog'
import { isEmpty } from 'lodash'
import { ChevronsUpDown } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { Source } from 'utils/task'
import { displayErrorMessage, displaySuccessMessageWithCTA } from 'utils/toast'

import { AssistantCreateNewVaultProjectDropdownButton } from 'components/assistant/components/assistant-create-new-vault-project-dropdown-button'
import { AssistantThreadSidebarSubSection } from 'components/assistant/components/assistant-thread-layout'
import { useGetVaultProjectsForDropdown } from 'components/assistant/components/use-get-vault-projects-for-dropdown'
import { useAssistantFileStore } from 'components/assistant/stores/assistant-file-store'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { isVaultKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'
import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import KsInputDropdown from 'components/common/ks-input-dropdown'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogTitle,
  DialogHeader,
  DialogContent,
  DialogFooter,
} from 'components/ui/dialog'
import { Icon } from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { Input } from 'components/ui/input'
import { Switch } from 'components/ui/switch'
import { getSharingStatusAndIconForVaultFolder } from 'components/vault/utils/get-sharing-status-and-icon-for-vault-folder'
import { projectsPath, REMOVE_PARAMS } from 'components/vault/utils/vault'
import { DuplicateFilesToFolder } from 'components/vault/utils/vault-fetcher'
import {
  fetchFoldersMetadata,
  getProjectMetadataFromVaultFolderMetadata,
} from 'components/vault/utils/vault-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

import { DownloadSection } from './download-section'
import { FilesNameCollisionModal } from './files-name-collision-modal'

export const AssistantSaveFilesButtons = ({
  documents,
  title,
  showVaultDownload = false,
  sources,
  isStreaming,
}: {
  documents?: UploadedFile[]
  title?: string
  showVaultDownload?: boolean
  sources?: Source[] | null
  isStreaming?: boolean
}) => {
  const hasFiles = !!documents && !isEmpty(documents)
  const { trackEvent } = useAnalytics()
  const [eventId, userCaption, messages, knowledgeSource] = useAssistantStore(
    useShallow((s) => [s.eventId, s.userCaption, s.messages, s.knowledgeSource])
  )
  const [
    upsertVaultFiles,
    upsertVaultFolders,
    addToProjectsMetadata,
    allFoldersMetadata,
  ] = useVaultStore(
    useShallow((s) => [
      s.upsertVaultFiles,
      s.upsertVaultFolders,
      s.addToProjectsMetadata,
      s.allFoldersMetadata,
    ])
  )
  const exportTitle = userCaption || messages[0]?.caption || eventId
  const getDocument = useAssistantFileStore((s) => s.getDocument)
  const userInfo = useAuthUser()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedVaultProject, setSelectedVaultProject] =
    useState<null | VaultFolder>(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const shouldShowVaultButton = showVaultDownload && userInfo.IsVaultUser
  const [newProjectName, setNewProjectName] = useState('')
  const [isCreatingProject, setIsCreatingProject] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [includeAdditionalSources, setIncludeAdditionalSources] = useState(true)
  const [selectedDocuments, setSelectedDocuments] = useState<UploadedFile[]>([])
  const [isCollisionDialogOpen, setIsCollisionDialogOpen] = useState(false)
  const [permissionsByProjectId] = useVaultSharingStore(
    useShallow((s) => [s.permissionsByProjectId])
  )
  const getTooltipText = useCallback(
    (project: VaultFolder) => {
      const folderMetadata = allFoldersMetadata[project.id]
      if (!folderMetadata) {
        return ''
      }
      const { sharingStatusTooltip } = getSharingStatusAndIconForVaultFolder({
        permissionsByProjectId: permissionsByProjectId,
        vaultProjectId: project.id,
        clientName: userInfo.workspace.clientName,
        projectMetadata:
          getProjectMetadataFromVaultFolderMetadata(folderMetadata),
        isKnowledgeBaseProject: project.isKnowledgeBaseProject,
      })
      return `${folderMetadata.totalFiles} ${pluralize(
        'file',
        folderMetadata.totalFiles
      )} • ${sharingStatusTooltip}`
    },
    [allFoldersMetadata, permissionsByProjectId, userInfo.workspace.clientName]
  )
  const navigate = useNavigateWithQueryParams()

  const { vaultProjectsDropdownOptions, areProjectsLoaded, allVaultProjects } =
    useGetVaultProjectsForDropdown({
      onClick: (project) => {
        setSelectedVaultProject(project)
      },
      includeKnowledgeBases: false,
      matchTriggerWidth: true,
      filterProjectIds:
        knowledgeSource &&
        isVaultKnowledgeSource(knowledgeSource) &&
        knowledgeSource.folderId
          ? [knowledgeSource.folderId]
          : [],
      getTooltipText,
    })

  const buttonLabel =
    selectedVaultProject === null
      ? 'Select Vault project'
      : selectedVaultProject.name

  const handleOpenChange = (isOpen: boolean) => {
    setIsDialogOpen(isOpen)
    if (!isOpen) handleClose()
  }
  const handleClose = () => {
    setSelectedVaultProject(null)
    setIsDialogOpen(false)
    setIsDropdownOpen(false)
    setNewProjectName('')
    setIsCreatingProject(false)
    setIncludeAdditionalSources(true)
    setIsDropdownOpen(false)
    setIsSaving(false)
    setSelectedDocuments(documents || [])
    setIsCollisionDialogOpen(false)
  }

  const noProjects = areProjectsLoaded && allVaultProjects.length === 0

  const onConfirmFiles = (location: 'Vault' | 'local') => {
    trackEvent('Confirmed Download Thread Sources', {
      downloaded_additional_sources:
        additionalSourcesCount > 0 ? includeAdditionalSources : null,
      num_sources: selectedDocuments.length,
      download_location: location,
    })
  }
  const handleOnSaveToProject = async () => {
    if (duplicateFiles.length > 0) {
      setIsCollisionDialogOpen(true)
      return
    }
    setIsSaving(true)
    try {
      const response = await DuplicateFilesToFolder(
        selectedDocuments,
        selectedVaultProject?.id,
        newProjectName
      )

      onConfirmFiles('Vault')
      handleClose()
      displaySuccessMessageWithCTA({
        message: `${selectedDocuments.length} ${pluralize(
          'file',
          selectedDocuments.length
        )} added to the project`,
        durationInSeconds: 2,
        cta: {
          label: 'Click here to visit the project.',
          onClick: () => {
            const newPath = `${BaseAppPath.Vault}${projectsPath}${
              selectedVaultProject
                ? selectedVaultProject.id
                : response.folder?.id
            }`
            navigate(newPath, {}, REMOVE_PARAMS)
          },
        },
      })
      if (!selectedVaultProject && response.folder) {
        upsertVaultFolders(
          [response.folder],
          userInfo.dbId,
          false,
          response.folder.id
        )
        const projectMetadataResponse = await fetchFoldersMetadata(
          response.folder
        )
        addToProjectsMetadata(projectMetadataResponse)
      }
      upsertVaultFiles(
        response.files,
        selectedVaultProject ? selectedVaultProject.id : response.folder?.id
      )
    } catch (error) {
      displayErrorMessage('Sorry, something went wrong')
      console.error(error)
    }
    setIsSaving(false)
  }

  const sourcesDocumentIds = useMemo(
    () => new Set(sources?.map((source) => source.documentId)),
    [sources]
  )

  const additionalSourcesCount = useMemo(
    () => (documents ? documents.length - sourcesDocumentIds.size : 0),
    [documents, sourcesDocumentIds]
  )

  const handleSwitchChange = useCallback(
    (checked: boolean) => {
      if (!documents) return
      let newSelectedDocuments: UploadedFile[] = []
      if (checked) {
        newSelectedDocuments = documents
      } else {
        newSelectedDocuments = documents.filter((document) => {
          return sourcesDocumentIds.has(document.id)
        })
      }
      setSelectedDocuments(newSelectedDocuments)
      setIncludeAdditionalSources(checked)
    },
    [documents, sourcesDocumentIds]
  )

  useEffect(() => {
    handleSwitchChange(additionalSourcesCount > 0)
  }, [additionalSourcesCount, handleSwitchChange])

  const allFileIdToVaultFile = useVaultStore((s) => s.allFileIdToVaultFile)
  const allFolderIdToVaultFileIds = useVaultStore(
    (s) => s.allFolderIdToVaultFileIds
  )

  const projectDocuments: VaultFile[] = useMemo(() => {
    if (
      selectedVaultProject &&
      selectedVaultProject.id &&
      allFolderIdToVaultFileIds[selectedVaultProject.id]
    ) {
      return allFolderIdToVaultFileIds[selectedVaultProject.id]
        ?.map((fileId) => allFileIdToVaultFile[fileId])
        .filter(Boolean) as VaultFile[]
    }
    return []
  }, [selectedVaultProject, allFileIdToVaultFile, allFolderIdToVaultFileIds])

  const existingNamesSet = useMemo(() => {
    return new Set<string>(projectDocuments.map((file) => file.name))
  }, [projectDocuments])

  const duplicateFiles = useMemo(() => {
    return selectedDocuments.filter((file) => existingNamesSet.has(file.name))
  }, [selectedDocuments, existingNamesSet])

  if (!documents || !hasFiles || isStreaming) return null

  const showInputField = noProjects || isCreatingProject

  const handleOnClick = (location: 'Vault' | 'local') => {
    trackEvent('Clicked Download Thread Sources', {
      has_additional_sources: additionalSourcesCount > 0,
      download_location: location,
    })
  }
  return (
    <AssistantThreadSidebarSubSection className="flex flex-col gap-1 px-0 py-3">
      <DownloadSection
        selectedDocuments={selectedDocuments}
        exportTitle={exportTitle}
        eventId={eventId}
        getDocument={getDocument}
        title={title}
        additionalSourcesCount={additionalSourcesCount}
        includeAdditionalSources={includeAdditionalSources}
        setIncludeAdditionalSources={handleSwitchChange}
        onClick={handleOnClick}
        onConfirmFiles={onConfirmFiles}
      />
      <FilesNameCollisionModal
        isOpen={isCollisionDialogOpen}
        setIsOpen={setIsCollisionDialogOpen}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        existingNamesSet={existingNamesSet}
        duplicateFiles={duplicateFiles}
      />
      {shouldShowVaultButton && (
        <Button
          variant="outline"
          size="sm"
          className="w-full"
          onClick={() => {
            setIsDialogOpen(true)
            handleOnClick('Vault')
          }}
        >
          <FolderShieldIcon className="mr-2 size-4" />
          Save to Vault
        </Button>
      )}
      <Dialog open={isDialogOpen} onOpenChange={handleOpenChange}>
        <DialogContent
          showCloseIcon
          className="p-0"
          innerClassName="flex flex-col p-0 py-3 space-y-0 h-full"
          closeIconClassName="right-1 top-1"
        >
          <DialogHeader className="shrink-0 border-b border-b-primary p-3 pt-0">
            <DialogTitle className="text-base">
              Save to Vault project
            </DialogTitle>
          </DialogHeader>
          <div className="p-5">
            {showInputField ? (
              <>
                <DialogDescription className="pb-2 text-sm font-normal leading-5 text-muted">
                  Project name
                </DialogDescription>
                <Input
                  className="py-0"
                  onChange={(e) => setNewProjectName(e.target.value)}
                  value={newProjectName}
                />
              </>
            ) : (
              <KsInputDropdown
                matchTriggerWidth
                dropdownOpen={isDialogOpen && isDropdownOpen}
                setDropdownOpen={setIsDropdownOpen}
                triggerComponent={
                  <Button
                    variant="outline"
                    className="flex h-10 w-full min-w-32 items-center justify-between"
                  >
                    <span className="truncate text-sm">{buttonLabel}</span>
                    <Icon
                      icon={ChevronsUpDown}
                      className="ml-2 size-4 shrink-0 opacity-50"
                    />
                  </Button>
                }
                dropdownItems={vaultProjectsDropdownOptions}
                maxHeight="max-h-[182px]"
                footerComponent={
                  <AssistantCreateNewVaultProjectDropdownButton
                    onClick={() => setIsCreatingProject(true)}
                    isLoading={false}
                  />
                }
              />
            )}
            {additionalSourcesCount > 0 && (
              <div className="flex justify-between pt-4 text-sm">
                Include {documents.length - sourcesDocumentIds.size} additional
                sources
                <Switch
                  checked={includeAdditionalSources}
                  onCheckedChange={handleSwitchChange}
                  disabled={additionalSourcesCount === 0}
                />
              </div>
            )}
          </div>
          <DialogFooter className="border-t px-3 pt-3">
            <div className="flex w-full items-center justify-between">
              <span className="text-xs leading-4 text-muted">
                {selectedDocuments.length} files selected
              </span>
              <div className="flex gap-2">
                <Button variant="outline" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleOnSaveToProject}
                  disabled={
                    (!selectedVaultProject && !newProjectName) || isSaving
                  }
                  isLoading={isSaving}
                >
                  Save to project
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </AssistantThreadSidebarSubSection>
  )
}
