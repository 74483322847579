import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useMount } from 'react-use'

import { AbortControllerProvider } from 'providers/abort-controller-provider'
import { ModalFlowNavigator } from 'providers/modal-flow-provider/modal-flow-navigator'
import PSPDFKit from 'pspdfkit'

import { getPdfKitConfig } from 'utils/pspdfkit'
import { requestIdleCallback } from 'utils/request-idle-callback'

import { FLOWS } from 'components/common/flows'
import { TooltipProvider } from 'components/ui/tooltip'
import { useFetchVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { vaultPathRaw, projectsPathRaw } from 'components/vault/utils/vault'

import { AnalyticsProvider } from './common/analytics/analytics-context'
import { useAuthUser } from './common/auth-context'
import CustomGuidanceInterstitial from './common/custom-guidance-interstitial'
import CustomNoticeInterstitial from './common/custom-notice-interstitial'
import GoogleDriveFilePicker from './common/integrations/google-drive-file-picker'
import SharepointFilePicker from './common/integrations/sharepoint-file-picker'
import SynclyPicker from './common/integrations/syncly-picker'
import WelcomeInterstitial from './common/welcome-interstitial'
import WelcomeMessage from './common/welcome-message'
import Sidebar from './sidebar/sidebar'
import { Toaster } from './ui/toaster'

const VaultProjectsProvider = () => {
  const userInfo = useAuthUser()
  const location = useLocation()
  const { fetchProjects } = useFetchVaultProjects()
  useMount(() => {
    if (!userInfo.IsVaultUser) {
      // Only fetch projects if user is a vault user
      return
    }
    const paths = location.pathname.split('?')[0].split('/')
    const potentialProjectId =
      paths.length >= 4 &&
      paths[1] === vaultPathRaw &&
      paths[2] === projectsPathRaw
        ? paths[3]
        : undefined
    void fetchProjects(potentialProjectId)
  })
  return null
}

const preloadPdfKit = () => {
  const config = getPdfKitConfig()
  void PSPDFKit.preloadWorker(config)
}

const AppLayout = () => {
  const userInfo = useAuthUser()

  useMount(() => {
    requestIdleCallback(preloadPdfKit)
  })

  return (
    <AbortControllerProvider>
      <AnalyticsProvider>
        <TooltipProvider>
          <ModalFlowNavigator flows={FLOWS}>
            <VaultProjectsProvider />
            <div className="safe-h-screen flex items-stretch overflow-hidden">
              <Sidebar />
              <main className="flex grow flex-col">
                <Outlet />
              </main>
              {!userInfo.isCreateUserProfilesUser && <WelcomeMessage />}
              <WelcomeInterstitial />
              <CustomNoticeInterstitial />
              <CustomGuidanceInterstitial />
              <Toaster
                position="bottom-right"
                expand
                closeButton
                visibleToasts={5}
                className="pointer-events-auto list-none"
              />
              <SharepointFilePicker />
              <GoogleDriveFilePicker />
              <SynclyPicker />
              {/* need target to load pspdfkit document against for validation */}
              <div
                id="pspdfkit-container"
                className="fixed bottom-0 right-0 hidden size-px"
              />
            </div>
          </ModalFlowNavigator>
        </TooltipProvider>
      </AnalyticsProvider>
    </AbortControllerProvider>
  )
}

export default AppLayout
