import React from 'react'

import { ListEndIcon } from 'lucide-react'

import { EventKind } from 'openapi/models/EventKind'

import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import LoadPromptButton from 'components/library/load-prompt-button'
import { Button, ButtonVariantProps } from 'components/ui/button'

import { AssistantConsolidatedPromptButton } from './assistant-consolidated-prompt-button'

interface Props extends ButtonVariantProps {
  setQuery: (query: string) => void
  setQueryPreview: (query: string | null) => void
  eventKind: EventKind
  handleLoadPromptPopoverChange?: (open: boolean) => void
  disabled?: boolean
  query: string
}

export const AssistantLoadPromptButton = ({
  query,
  setQuery,
  setQueryPreview,
  eventKind,
  handleLoadPromptPopoverChange,
  disabled,
}: Props) => {
  const userInfo = useAuthUser()

  const handlePromptsPopoverChange = (isOpen: boolean) => {
    if (handleLoadPromptPopoverChange) {
      handleLoadPromptPopoverChange(isOpen)
    }
  }

  const handleSelectPrompt = (prompt: string) => {
    setQueryPreview(null)
    setQuery(prompt)
  }

  if (!userInfo.IsLibraryPromptsUser) {
    return null
  }

  const showConsolidated = userInfo.IsVaultInternalOnlyUser
  if (showConsolidated) {
    return (
      <AssistantConsolidatedPromptButton
        onPopoverOpen={handlePromptsPopoverChange}
        setQuery={handleSelectPrompt}
        setQueryPreview={setQueryPreview}
        eventKind={eventKind}
        isLoadDisabled={disabled}
        query={query}
        size="sm"
        isCreateAvailable={userInfo.IsLibraryPrivatePromptUser}
        isSuggestionsEnabled={false}
      />
    )
  }

  return (
    <LoadPromptButton
      onPopoverOpen={handlePromptsPopoverChange}
      setQuery={handleSelectPrompt}
      setQueryPreview={setQueryPreview}
      triggerComponent={(disabled, buttonTooltipText) => (
        <Button
          className="pointer-events-auto justify-start whitespace-nowrap"
          variant="ghost"
          disabled={disabled}
          tooltip={buttonTooltipText}
          tooltipClassName={cn({
            'cursor-default': disabled,
          })}
          size="sm"
          id="load-prompt"
        >
          <ListEndIcon size={16} className="mr-1" />
          Load prompt
        </Button>
      )}
      eventKind={eventKind}
      disabled={disabled}
    />
  )
}
