import { Row } from '@tanstack/react-table'

import { DocumentClassificationTag } from 'openapi/models/DocumentClassificationTag'
import { EventKind } from 'openapi/models/EventKind'
import { FileFailureCategory } from 'openapi/models/FileFailureCategory'
import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { ReviewEventRunType } from 'openapi/models/ReviewEventRunType'
import { Tag } from 'openapi/models/Tag'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFilesApiResponseData } from 'openapi/models/VaultFilesApiResponseData'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { Maybe } from 'types'
import { FileType, GoogleDriveNativeFileType } from 'types/file'

import { TaskStatus } from 'utils/task'

export const MAX_CAPTION_LENGTH = 100
export const SUB_MENU_HEIGHT = 256
export const DOCUMENT_CLASSIFICATION_TOOLTIP_TEXT = `Harvey will automatically categorize your document, but you can override if you'd like. Harvey can use this to better understand your document.`

export const HIGHLIGHT_COLOR = '#1452f133'
export const EXPIRATION_URL_KEY = 'se'
export const NUM_ALL_QUERIES_TO_FETCH = 100

export const folderIdSearchParamKey = 'folder_id'
export const fileIdSearchParamKey = 'file_id'
export const queryIdSearchParamKey = 'queryId'
export const sourceIdSearchParamKey = 'source_id'
export const questionIdSearchParamKey = 'question_id'
export const viewSearchParamKey = 'view'
export const filtersSearchParamKey = 'filters'
export const homePageTabSearchParamKey = 'tab'
export const projectDetailPageTabSearchParamKey = 'project_detail_tab'
export const isKnowledgeBaseProjectSearchParamKey = 'isKnowledgeBaseProject'

export const vaultPathRaw = 'vault'
export const vaultPath = `/${vaultPathRaw}/`
export const projectsPathRaw = 'projects'
export const projectsPath = `/${projectsPathRaw}/`
export const filesPathRaw = 'files'
export const filesPath = `/${filesPathRaw}/`
export const queriesPathRaw = 'queries'
export const queriesPath = `/${queriesPathRaw}/`

export const REMOVE_PARAMS = [
  folderIdSearchParamKey,
  fileIdSearchParamKey,
  queryIdSearchParamKey,
  sourceIdSearchParamKey,
  questionIdSearchParamKey,
  viewSearchParamKey,
  filtersSearchParamKey,
  // want to preserve home page tab state
  // homePageTabSearchParamKey,
  projectDetailPageTabSearchParamKey,
  isKnowledgeBaseProjectSearchParamKey,
]

export const MAX_FILE_SIZE_IN_MB = 100
export const MAX_EXCEL_FILE_SIZE_IN_MB = 100
export const MAX_TOTAL_FILE_SIZE_IN_MB = 5120
export const ACCEPTED_FILE_TYPES = [
  FileType.PDF,
  FileType.WORD,
  FileType.WORD_LEGACY,
  FileType.ZIP,
  FileType.EXCEL,
  FileType.EXCEL_LEGACY,
  FileType.POWERPOINT,
  FileType.POWERPOINT_LEGACY,
  FileType.TEXT,
  FileType.CSV,
  FileType.EMAIL,
  FileType.OUTLOOK,
  FileType.RTF,
]

export const ACCEPTED_GOOGLE_DRIVE_NATIVE_FILE_TYPES = [
  GoogleDriveNativeFileType.GOOGLE_DOCS,
  GoogleDriveNativeFileType.GOOGLE_SLIDES,
  GoogleDriveNativeFileType.GOOGLE_SHEETS,
]

export const DOT_SEPARATOR = '・'

export const MIN_QUESTION_CHAR_LENGTH = 8
export const MAX_QUESTION_CHAR_LENGTH = 250
// TODO: Remove this once we verify questions can be unlimited
export const MAX_QUESTIONS_LIMIT = 500

export const PUSHSHEET_REVIEW_PDF_PERCENTAGE = 50
export const PUSHSHEET_REVIEW_ANSWERS_PERCENTAGE = 50

export const FILE_COUNT_OR_SIZE_OVER_LIMIT_TOOLTIP =
  'You have to remove some files because you’re over the limit'

export const ETA_TOOLTIP_TEXT =
  'This is the time taken for our AI to ingest & understand your document. This time may vary based on the size/complexity of the files & time of day.'

export const MAX_TOKEN_LIMIT_MESSAGE =
  '\n\n<i>(response was cut-off due to the query answer being too long. This might be a better query to run in Vault Review instead)</i>'

export const RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_OR_FILES_MESSAGE =
  'You must resume running review before adding new columns or files.'

export const RESUME_RUN_BEFORE_ADDING_NEW_COLUMNS_MESSAGE =
  'You must resume running review before adding new columns.'

export const RESUME_RUN_BEFORE_ADDING_NEW_FILES_MESSAGE =
  'You must resume running review before adding new files.'

export const LOADING_QUERY_TITLE = 'Loading…'
export const UNTITLED_QUERY_TITLE = 'Untitled'

export const EXAMPLES_USER_EMAIL = 'examples@harvey.ai'

interface FileHierarchy {
  id: string
  name: string
  prefix: string
  files: FileToUpload[]
  children: FileHierarchy[]
}

interface QueryQuestion {
  id: string
  text: string
  header?: string
  columnDataType?: ColumnDataType
  options?: string
  backingReviewColumn?: ReviewColumn
}

interface QueryAnswer {
  columnId: string
  long: boolean
  text: string
}

export type GenerateNNRequestType =
  | 'new'
  | 'retry'
  | 'extra_files'
  | 'extra_columns'
  | 'extra_files_and_columns'
  | 'retry_empty'
  | 'retry_error'

export interface DocumentClassificationAnalyticsData {
  groupingDocumentClassification: string
  typeDocumentClassification: string
  numDocuments: number
}

export enum ColumnDataType {
  freeResponse = 'free_response',
  date = 'date',
  duration = 'duration',
  classify = 'classify',
  currency = 'currency',
  numeric = 'numeric',
  extraction = 'extraction',

  // not user specified options
  list = 'list',
  empty = 'empty',
  compoundResponse = 'compound_response',
  noFormat = 'no_format',

  // to delete after Review V2
  string = 'string',
  binary = 'binary',
}

export const COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION = [
  ColumnDataType.list,
  ColumnDataType.empty,
  ColumnDataType.compoundResponse,
  ColumnDataType.string,
  ColumnDataType.binary,
  ColumnDataType.noFormat,
]

export const STRICT_COLUMN_DATA_TYPES = [
  ColumnDataType.date,
  ColumnDataType.duration,
  ColumnDataType.currency,
  ColumnDataType.numeric,
]

export interface ReviewAnswer {
  columnId: string
  long: boolean
  text: string
  columnDataType?: ColumnDataType
  rawResponse?: ReviewCellResponse[] | null
  backingCell: ReviewCell
}

export interface ReviewError {
  columnId: string
  text: string
}

// fetcher types
interface FileToUpload {
  file: File
  name: string
  fileSource?: FileUploadSource
}

interface JobQueueEtaApiResponseData {
  etaInSeconds: number
}

interface VaultDeleteFolder {
  deletedFilesMetadata: VaultFile[]
  deletedFoldersMetadata: VaultFolder[]
  failedFilesWithError: string[]
  failedFoldersWithError: string[]
}

// vault cell types
export enum VaultItemType {
  project = 'project',
  folder = 'folder',
  file = 'file',
}

export enum VaultItemStatus {
  default = '',
  uploading = 'uploading',
  processing = 'processing',
  failedToUpload = 'failedToUpload',
  recoverableFailure = 'recoverableFailure',
  unrecoverableFailure = 'unrecoverableFailure',
  readyToQuery = 'readyToQuery',
  allFilesFailed = 'allFilesFailed',
  readyToQueryWithFailedFiles = 'readyToQueryWithFailedFiles',
}

export enum FileProcessingStatus {
  UPLOADED = 'UPLOADED',
  PROCESSING = 'PROCESSING',
  READY_TO_QUERY = 'READY_TO_QUERY',
  RECOVERABLE_FAILURE = 'RECOVERABLE_FAILURE',
  UNRECOVERABLE_FAILURE = 'UNRECOVERABLE_FAILURE',
}

type VaultItemBase = {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  status: VaultItemStatus
  totalFiles?: Maybe<number>
  numPages?: Maybe<number>
  size?: Maybe<number>
  parentId?: Maybe<string>
  contentType?: Maybe<string>
  failureReason?: Maybe<string>
  failureCategory?: Maybe<FileFailureCategory>
  url?: Maybe<string>
  docAsPdfUrl?: Maybe<string>
  disabled?: boolean
  isAlreadySelected?: boolean
  tags?: (Tag | DocumentClassificationTag)[]
}

interface VaultProjectItem extends VaultItemBase {
  type: VaultItemType.project
  data: VaultFolder
  children?: VaultItem[]
}

interface VaultFolderItem extends VaultItemBase {
  type: VaultItemType.folder
  data: VaultFolder
  children?: VaultItem[]
  isSomeAlreadySelected?: boolean
}

interface VaultFileItem extends VaultItemBase {
  type: VaultItemType.file
  data: VaultFile
}

type VaultItem = VaultProjectItem | VaultFolderItem | VaultFileItem

type VaultItemWithIndex = VaultItem & {
  index: string
  isAllDescendantsSelected?: boolean
}

interface CellProps {
  row: Row<VaultItem>
}

interface TimeCellProps extends CellProps {
  timeKey: 'createdAt' | 'updatedAt'
}

export type ReviewEventRun = {
  id: string
  runType: ReviewEventRunType
  query: string
  createdAt: string
  updatedAt: string
  eta: string | null
}

export type ReviewColumn = {
  id: string
  dataType: ColumnDataType
  displayId: number
  fullText: string
  userPrompt?: string
  header: string
  order: number
  isHidden: boolean
  createdAt: string
  updatedAt: string
  reviewEventRunId: string
  reviewWorkflowId?: string
  parentWorkflowColumnId?: string
  options?: string[]
}

export type ReviewRow = {
  id: string
  fileId: string
  order: number
  isHidden: boolean
  createdAt: string
  updatedAt: string
  reviewEventRunId: string
}

export type ReviewRowResponse = ReviewRow & {
  eventId: string
  eventStatus: TaskStatus
  eventCreatedAt: string
  eventUpdatedAt: string
  eventTitle: string
  cells: ReviewCell[]
  sources: ReviewSource[]
  columns: ReviewColumn[]
}

export enum ReviewCellStatus {
  EMPTY = 'EMPTY',
  COMPLETED = 'COMPLETED',
  ERRORED = 'ERRORED',
  ERRORED_IGNORED = 'ERRORED_IGNORED',
  USER_INPUT = 'USER_INPUT',
}

export type ReviewCellResponse = {
  currencyCode: string | null
  type: ColumnDataType
  value: string
}

export type ReviewCell = {
  id: string
  userId: string
  columnDataType: ColumnDataType
  error: string | null
  response: string
  shortResponse: string | null
  rawShortResponse: ReviewCellResponse[] | null
  rawResponse: ReviewCellResponse[] | null
  longColumnDataType?: ColumnDataType
  status: ReviewCellStatus
  createdAt: string
  updatedAt: string
  reviewColumnId: string
  reviewRowId: string
  reviewEventRunId: string
  verifiedAt?: string
  verifiedByUserId?: string
  approvedAt?: string
  approvedByUserId?: string
}

export type ReviewSource = {
  id: string
  cellId: string
  footnote: number
  pageNumber: number
  text: string
  createdAt: string
  updatedAt: string
  questionId?: string
}

export type ReviewEvent = {
  id: string
  eventId: number
  eventKind: EventKind
  eventStatus: TaskStatus
  eventCreatedAt: string
  // Prefer to use eventUpdatedAt over updatedAt as that's usually the latest timestamp
  eventUpdatedAt: string
  eventCreatorEmail: string
  title: string
  vaultFolderId: string
  userId: string
  runs: ReviewEventRun[]
  columns: ReviewColumn[]
  rows: ReviewRow[]
  cells: ReviewCell[]
  sources: ReviewSource[]
  // Used for diffing
  lastUpdatedAt?: string
  isDiff?: boolean
}

interface VaultFilesApiResponseDataWithException
  extends VaultFilesApiResponseData {
  exception?: string
}

export type {
  // VaultFilesApiResponseData
  VaultFilesApiResponseDataWithException,
  FileHierarchy,
  // streaming types
  QueryQuestion,
  QueryAnswer,

  // fetcher types
  FileToUpload,
  JobQueueEtaApiResponseData,
  VaultDeleteFolder,

  // cell types
  VaultItem,
  VaultItemWithIndex,
  VaultProjectItem,
  CellProps,
  TimeCellProps,
}
