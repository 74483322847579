// some utils for announcements
import { NavigateFunction } from 'react-router-dom'

import { Permission } from 'models/user-info'
import { UserInfo } from 'models/user-info'

export const isClientMatterTourEnabled = (userInfo: UserInfo) => {
  // check if they have the client matters read perm, and the client matter tour perm
  return (
    userInfo.permissions.includes(Permission.CLIENT_MATTERS_READ) &&
    userInfo.permissions.includes(Permission.VIEWTOUR_CLIENT_MATTER)
  )
}

export const navigateToAssistantFeedbackForm = (
  _navigate: NavigateFunction,
  userInfo?: UserInfo
) => {
  const userId = userInfo?.dbId
  const url =
    'https://10kvd0mkk7w.typeform.com/to/xqchZd3a' +
    (userId ? `#user_id=${userId}` : '')
  window.open(url, '_blank', 'noopener')
}

export const navigateToVaultUpgrade = (_navigate: NavigateFunction) => {
  _navigate('/vault')
}
