import React from 'react'

import { ChevronDown, PlusIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useAssistantRecentKnowledgeSources } from 'components/assistant/hooks/use-assistant-recent-ks'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { LoadPromptButtonProps } from 'components/library/load-prompt-button'
import LoadPromptButton from 'components/library/load-prompt-button'
import {
  SavePromptButton,
  SavePromptButtonProps,
} from 'components/library/save-prompt-button'
import { Button, ButtonProps } from 'components/ui/button'
import { DropdownMenuSeparator } from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'

import { SuggestionTag } from './suggestion-tag'

type Props = Omit<LoadPromptButtonProps, 'triggerComponent' | 'disabled'> &
  Omit<SavePromptButtonProps, 'triggerComponent' | 'disabled'> & {
    isLoadDisabled?: boolean
    isCreateDisabled?: boolean
    size?: ButtonProps['size']
    isCreateAvailable: boolean
    isSuggestionsEnabled: boolean
  }

export const AssistantConsolidatedPromptButton = ({
  setQuery,
  setQueryPreview,
  eventKind,
  query,
  isLoadDisabled,
  isCreateDisabled,
  size,
  isCreateAvailable,
  isSuggestionsEnabled,
  ...props
}: Props) => {
  const { data: recentKnowledgeSourcesData } =
    useAssistantRecentKnowledgeSources(true)
  const [knowledgeSource] = useAssistantStore(
    useShallow((s) => [s.knowledgeSource])
  )
  const isSuggestionSelected = knowledgeSource !== null
  const showSuggestions = isSuggestionsEnabled && !isSuggestionSelected
  return (
    <>
      <LoadPromptButton
        {...props}
        triggerComponent={(disabled, buttonTooltipText) => (
          <Button
            variant="secondary"
            className="border py-1.5 pl-4 pr-3"
            disabled={disabled}
            tooltip={buttonTooltipText}
            size={size}
          >
            Prompts <Icon icon={ChevronDown} />
          </Button>
        )}
        setQuery={setQuery}
        setQueryPreview={setQueryPreview}
        eventKind={eventKind}
        disabled={isLoadDisabled}
        footerComponent={
          isCreateAvailable && (
            <div className="mt-auto w-full p-2">
              <DropdownMenuSeparator className="-mx-2 my-2" />
              <SavePromptButton
                {...props}
                query={query}
                eventKind={eventKind}
                disabled={isCreateDisabled}
                triggerComponent={(
                  disabled,
                  buttonTooltipText,
                  onMouseEnter
                ) => (
                  <Button
                    variant="link"
                    className="flex w-full items-center justify-between gap-2 bg-primary px-2 transition-colors hover:bg-secondary-hover hover:text-primary hover:disabled:bg-primary"
                    onClick={onMouseEnter}
                    disabled={disabled}
                    tooltip={buttonTooltipText}
                  >
                    <div className="flex-1 truncate text-left text-sm font-normal leading-5">
                      Create new prompt
                    </div>
                    <Icon icon={PlusIcon} />
                  </Button>
                )}
              />
            </div>
          )
        }
      />
      {showSuggestions &&
        recentKnowledgeSourcesData?.knowledgeSources.map(
          (suggestion, index) => (
            <SuggestionTag key={index} knowledgeSource={suggestion} />
          )
        )}
    </>
  )
}
